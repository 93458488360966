import { useDisclosure } from "@chakra-ui/hooks";
import { Flex, Box } from "@chakra-ui/layout";
import { addTopic } from "API/topicApi";
import AddAndClose from "components/smart/AddAndClose";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";

interface AddTopicProps {
  program: IProgram | undefined;
  subjectId: string;
  chapterId: string;
}

const AddTopic: FC<AddTopicProps> = ({ program, subjectId, chapterId }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const cache = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(addTopic, {
    onSuccess: () => {
      cache.invalidateQueries(["program", program?._id, subjectId]);
      onClose();
    },
  });
  useErrorToast(error);
  return (
    <>
    <Box p="4" bg="purple.100">
      <AddAndClose text="Add Topic" isOpen={isOpen} onToggle={onToggle} />
      </Box>
      {isOpen && (
        <Formik
          initialValues={{ topicName: "" }}
          onSubmit={async (values) => {
            try {
              await mutateAsync({
                topicName: values.topicName,
                chapterId: chapterId ?? "",
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Form>
            <Flex align="flex-end" p="4">
              <InputControl name="topicName" label="Topic  Name" />
              <SubmitButton isLoading={isLoading} ml="8">
                Add Topic
              </SubmitButton>
            </Flex>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default AddTopic;
