import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
interface ProgramState {
  program: IProgram | undefined;
  subjectId: string;
  programId: string | undefined;
}

// Define the initial state using that type
const initialState: ProgramState = {
  program: undefined,
  subjectId: "",
  programId: undefined,
};

export const programSlice = createSlice({
  name: "program",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setProgram: (
      state,
      action: PayloadAction<{
        program: IProgram | undefined;
        subjectId: string;
      }>
    ) => {
      state.program = action.payload.program;
      state.subjectId = action.payload.subjectId;
    },
    setProgramId: (state, action: PayloadAction<string>) => {
      state.programId = action.payload;
    },
  },
});

export const { setProgram, setProgramId } = programSlice.actions;

export default programSlice.reducer;
