import { Stack, Text, VStack } from "@chakra-ui/react";
import { getPersonalInterviewData } from "API/personalInterviewApi";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

const AreaOfExpertise = ({ user }) => {
  const [areaOfExpertise, setAreaOfExpertise] = useState([
    {
      label: "Population of State /District / Taluka / Village",
      values: "",
    },
    { label: "Geographical importance", values: "" },
    { label: "Historical importance", values: "" },
    { label: "Major trade commerce & Industry", values: "" },
    { label: "State Budget / deficit", values: "" },
    { label: "Seasons", values: "" },
    { label: "Crop", values: "" },
    { label: "Detail, positions, qualification", values: "" },
    { label: "Nature of job", values: "" },
    { label: "Growth in Job", values: "" },
    {
      label: "Basic information of the industry working",
      values: "",
    },
    { label: "Home maker / working", values: "" },
    { label: "Nature of work", values: "" },
    { label: "Growth in profession", values: "" },
    { label: "Earnings", values: "" },
  ]);

  const { data: personalInterviewData } = useQuery(
    ["personalInterviewData"],
    () => getPersonalInterviewData(user)
  );

  useEffect(() => {
    if (
      personalInterviewData?.data?.areaOfExpertise &&
      personalInterviewData?.data?.areaOfExpertise.length !== 0
    ) {
      const newFields = [];
      personalInterviewData?.data?.areaOfExpertise.map((field) =>
        newFields.push({ label: field.label, values: field.values })
      );
      setAreaOfExpertise(newFields);
    }
  }, [personalInterviewData]);

  return (
    <VStack
      alignItems={"flex-start"}
      w={"100%"}
      paddingX={"25px"}
      marginBottom={"10px"}
    >
      <Text as={"b"} fontSize={"20px"}>
        Area Of Expertise
      </Text>
      <Stack
        flexDirection={"row"}
        flexWrap={"wrap"}
        w={"100%"}
        justifyContent={"space-between"}
      >
        {areaOfExpertise.map((data, index) => (
          <Text
            key={index}
            w={"30%"}
            color={"black"}
            style={{
              marginTop: "5px",
            }}
          >
            <Text
              display={"inline"}
              fontWeight={600}
            >{`${data.label} : `}</Text>{" "}
            {data.values === "" ? "N/A" : data.values}
          </Text>
        ))}
      </Stack>
    </VStack>
  );
};

export default AreaOfExpertise;
