import StudentDashboard from "pages/dashboard/StudentDashboard/StudentDashboard";
import StudentProgram from "pages/dashboard/StudentDashboard/StudentProgram/StudentProgram";
import StudentProgramRecordedVideos from "pages/dashboard/StudentDashboard/StudentProgramRecordedVideos/StudentProgramRecordedVideos";
import StudentProgramTests from "pages/dashboard/StudentDashboard/StudentProgramTests/StudentProgramTests";
import StudentTest from "pages/dashboard/StudentDashboard/StudentTest/StudentTest";
import TestResultDetails from "pages/dashboard/StudentDashboard/TestResultPreview/TestResultPreview";
import TestGivingPanel from "pages/dashboard/StudentDashboard/TestGivingPanel/TestGivingPanel";
import TestResult from "pages/dashboard/StudentDashboard/TestResult/TestResult";
import TestWaiting from "pages/dashboard/StudentDashboard/TestWaiting/TestWaiting";
import StudentBatches from "pages/dashboard/StudentDashboard/StudentBatches/StudentBatches";
import PerformanceReports from "pages/dashboard/shared/Users/Students/PerformanceReports";
import PerformanceReportView from "pages/dashboard/shared/Users/Students/PerformanceReportView";
import ReportsStudents from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/ReportsStudent";
import StudentCorrectionParameter from "pages/dashboard/StudentDashboard/StudentCorrectionParameter/StudentCorrectionParameter";
import CareerPlanView from "pages/dashboard/StudentDashboard/CareerPlan/CareerPlanView";
import CareerPlanEdit from "pages/dashboard/StudentDashboard/CareerPlan/CareerPlanEditi";

// import EditPersonalInterview from "pages/product/EditPersonalInterview";

export const studentRoutes = [
  {
    type: ["student"],
    path: "/student/dashboard/correction-parameter",
    component: StudentCorrectionParameter,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/career-plan",
    component: CareerPlanView,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/career-plan/edit",
    component: CareerPlanEdit,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/batches",
    component: StudentBatches,
    exact: true,
    protect: true,
  },

  {
    type: ["student"],
    path: "/student/dashboard/reports",
    component: ReportsStudents,
    protect: true,
    exact: false,
  },

  {
    type: ["student"],
    path: "/student/dashboard/performance-reports/:idPerformance/view",
    component: PerformanceReportView,
    protect: true,
    exact: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/performance-reports",
    component: PerformanceReports,
    protect: true,
    exact: false,
  },
  {
    type: ["student"],
    path: "/student/dashboard",
    component: StudentDashboard,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/programs/:id/viewalltests",
    component: StudentProgramTests,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/programs/:id",
    component: StudentProgram,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/programs/:id/recordedvideos",
    component: StudentProgramRecordedVideos,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/test/:testId/:retest",
    component: StudentTest,
    exact: false,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/start-test/:testId/:retest/:activeProgramId/:userTestId",
    component: TestGivingPanel,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/test-result/:testId",
    component: TestResult,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/test-review/:testId",
    component: TestResultDetails,
    exact: true,
    protect: true,
  },
  {
    type: ["student"],
    path: "/student/dashboard/test-wait",
    component: TestWaiting,
    exact: true,
    protect: true,
  },
];
