import { Box, Container, Link, Stack, Text } from '@chakra-ui/react'

const StaticFooter = () => {
    return (

        <Box bg="gray.800">
            <Container py={'4'} maxW="container.xl">
                <Stack direction={['column', 'row']} spacing='0px'>
                    <Box w={["100%", "50%"]} textAlign={['center', 'left']}>
                        <Text fontSize={'12px'} color={'gray.100'}>Copyright © {new Date().getFullYear()} KP Training Academy</Text>
                    </Box>
                    <Box w={["100%", "50%"]} textAlign={['center', 'right']}>
                        <Link mx="1" href='/contact-us' fontSize={'12px'} color={'gray.100'}>Contact</Link>
                        <Link mx="1" href='/refund' fontSize={'12px'} color={'gray.100'}>Refund</Link>
                        <Link mx="1" href='/terms-and-conditions' fontSize={'12px'} color={'gray.100'}>Terms and Conditions</Link>
                        <Link mx="1" href='/privacy-policy' fontSize={'12px'} color={'gray.100'}>Privacy Policy</Link>
                    </Box>
                </Stack>
            </Container>
        </Box>

    )
}

export default StaticFooter
