import { Heading, VStack } from "@chakra-ui/layout";
import { setQuestionAnswer } from "app/features/question/questionSlice";
import { useAppDispatch } from "app/hook";
import { Formik, Form } from "formik";
import { TextareaControl } from "formik-chakra-ui";
import { FC } from "react";
import PrevAndNextButton from "./PrevAndNextButton";

interface LAQuestionProps {
  question: IAnsweredLAQuestion;
  prevQuestionHandler: () => void;
  nextQuestionHandler: () => void;
  index: number;
  isLastQuestion: boolean;
  setIsLastQuestion: React.Dispatch<React.SetStateAction<boolean>>;
}

const LAQuestion: FC<LAQuestionProps> = ({
  question,
  prevQuestionHandler,
  nextQuestionHandler,
  index,
  isLastQuestion,
  setIsLastQuestion,
}) => {
  const dispatch = useAppDispatch();
  console.log(question, index);

  return (
    <>
      <Heading fontSize="md" fontWeight="semibold">
        Type your answer
      </Heading>
      <Formik
        enableReinitialize
        initialValues={{
          answer: question?.laSubmittedAnswer ?? "",
          isPrev: false,
        }}
        onSubmit={(values) => {
          console.log(values);

          if (values.answer !== "") {
            dispatch(
              setQuestionAnswer({
                index,
                laSubmittedAnswer: values.answer,
                attempted: true,
              })
            );
          } else {
            dispatch(
              setQuestionAnswer({
                index,
                attempted: false,
              })
            );
          }

          values.isPrev ? prevQuestionHandler() : nextQuestionHandler();
          if (isLastQuestion) {
            setIsLastQuestion(true);
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <VStack>
              <TextareaControl name="answer" textareaProps={{ rows: 10 }} />
            </VStack>
            <PrevAndNextButton setFieldValue={setFieldValue} />
          </Form>
        )}
      </Formik>
    </>
  );
};
export default LAQuestion;
