import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
interface QuestionFilterState {
  page: number;
  size: number;
  level: string | undefined;
  questionType: string | undefined;
  subject: string | undefined;
  searchText: string;
}

// Define the initial state using that type
const initialState: QuestionFilterState = {
  page: 1,
  size: 10,
  level: undefined,
  questionType: undefined,
  subject: undefined,
  searchText: "",
};

export const questionFilterSlice = createSlice({
  name: "questionFilterSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPageDefault: (state) => {
      state.page = initialState.page;
    },
    incrementPage: (state) => {
      if (state.page >= 1) {
        state.page++;
      }
    },
    decrementPage: (state) => {
      if (state.page > 1) {
        state.page--;
      }
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      console.log(action.payload);

      state.searchText = action.payload;
    },
    setQuestionType: (state, action: PayloadAction<string>) => {
      state.questionType = action.payload;
    },
    setSubject: (state, action: PayloadAction<string>) => {
      state.subject = action.payload;
    },
    setLevel: (state, action: PayloadAction<string>) => {
      state.level = action.payload;
    },
    filterDefaultState: (state) => {
      state.page = 1;
      state.size = 10;
      state.level = undefined;
      state.questionType = undefined;
      state.subject = undefined;
      state.searchText = "";
    },
  },
});

export const {
  filterDefaultState,
  setPageDefault,
  incrementPage,
  decrementPage,
  setLevel,
  setSize,
  setSearchText,
  setQuestionType,
  setSubject,
} = questionFilterSlice.actions;

export default questionFilterSlice.reducer;
