import React from "react";
import { FC, useState, useEffect } from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { getActiveProgram } from "API/activeProgramApi";
import useErrorToast from "hook/error-toast";
import { Heading, HStack, Icon } from "@chakra-ui/react";
import { FaPlayCircle } from "react-icons/fa";

interface TopicsProps {
  subjId: string;
  setvideosrc: (videosrc: string | undefined) => void;
  setPlayingVideoId: (videosrc: string | undefined) => void;
  playingVideoId: string | undefined;
  isdefaultavailable: boolean;
  setisdefaultavailable: (isdefaultvideoavailable: boolean) => void;
}

const Topics: FC<TopicsProps> = ({
  subjId,
  setvideosrc,
  setPlayingVideoId,
  playingVideoId,
  isdefaultavailable,
  setisdefaultavailable,
}) => {
  const { id } = useParams<{ id: string }>();
  const { data: activeProgram, error } = useQuery<IActiveProgram>(
    ["activeProgram", id, subjId],
    () => getActiveProgram(id, subjId)
  );
  useState<Boolean>(false);
  useErrorToast(error);
  useEffect(() => {
    if (activeProgram) {
      if (!isdefaultavailable) {
        const videourls: string[] = [];
        const docids: string[] = [];
        activeProgram.program.chapters.forEach((chapter) => {
          chapter.topics.forEach((topic) => {
            if (topic.videolink) {
              videourls.push(topic.videolink);
              docids.push(topic._id);
            }
          });
        });
        if (videourls.length > 0) {
          setvideosrc(videourls[0]);
          setPlayingVideoId(docids[0]);
        }
        setisdefaultavailable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProgram]);
  return (
    <>
      {activeProgram?.program.chapters.map((chap: IChapter) =>
        chap.topics.map((topic: ITopic) => {
          if (topic.videolink) {
            return (
              <div key={topic._id}>
                <HStack
                  spacing={8}
                  backgroundColor={
                    topic._id === playingVideoId ? "teal.200" : "white"
                  }
                  justifyContent="space-between"
                  onClick={() => {
                    setvideosrc(topic.videolink);
                    setPlayingVideoId(topic._id);
                  }}
                  borderTop={"1px"}
                  borderTopColor={"gray.100"}
                  cursor={"pointer"}
                  pr={["10px", "20px"]}
                >
                  <Heading size="sm" padding={["10px", "20px"]}>
                    {topic?.name}
                  </Heading>
                  <Icon as={FaPlayCircle} />
                </HStack>
              </div>
            );
          } else {
            return null;
          }
        })
      )}
    </>
  );
};

export default Topics;
