import { useState, FC } from "react";
import {
  ButtonGroup,
  Box,
  Button,
  useColorModeValue,
  HStack,
  IconButton,
  Text,
  InputGroup,
  Stack,
  Select,
} from "@chakra-ui/react";
import { GoSearch } from "react-icons/go";

import { questionAdderFilter } from "API/questionApi";
import { useQuery } from "react-query";
import useErrorToast from "hook/error-toast";
import LevelSelect from "../../../../../../Questions/LevelSelect";
import QuestionTypeSelect from "../../../../../../Questions/QuestionTypeSelect";
import { Form, Formik } from "formik";
import { InputControl } from "formik-chakra-ui";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
} from "app/features/questionFilter/questionFilterSlice";
import QuestionAdderTable from "./QuestionAdderTable";

interface QuestionAdderProps {
  subjectId: string;
  topicId: string;
  onClose: () => void;
  programId: string;
}

const QuestionAdder: FC<QuestionAdderProps> = ({
  subjectId,
  topicId,
  onClose,
  programId,
}) => {
  const [resetCheckBox, setResetCheckBox] = useState(false);

  const { level, size, questionType, page, searchText } = useAppSelector(
    (state) => state.filterQuestion
  );
  const dispatch = useAppDispatch();

  console.log(level, size, questionType, page, searchText);

  const { data, isLoading, error } = useQuery(
    [
      "questions",
      page,
      size,
      level,
      questionType,
      subjectId,
      searchText,
      programId,
    ],
    () => {
      setResetCheckBox(true);
      return questionAdderFilter({
        page,
        size,
        questionType,
        level,
        subjectId,
        searchText,
        programId,
      });
    },
    { onSettled: () => setResetCheckBox(false), keepPreviousData: true }
  );

  useErrorToast(error);

  return (
    <>
      <Box
        mb="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderTopRadius="md"
        pos="relative"
      >
        <Stack w="100%" direction={["column", "row"]} p="15px">
          <Box
            pe="{[0, 6]}"
            w="100%"
            maxW={{ base: "100%", sm: "250px", lg: "300px", xl: "400px" }}
          >
            <Formik
              initialValues={{ search: searchText }}
              onSubmit={(values) => {
                dispatch(setSearchText(values.search));
                dispatch(setPageDefault());
              }}
            >
              <Form>
                <InputGroup size="sm">
                  <InputControl
                    name="search"
                    inputProps={{
                      size: "sm",
                      rounded: "md",
                      placeholder: "Search questions",
                    }}
                  />
                  <IconButton
                    ml="2"
                    aria-label="search"
                    size="sm"
                    type="submit"
                    colorScheme="teal"
                    icon={<GoSearch color="gray.50" />}
                  />
                </InputGroup>
              </Form>
            </Formik>
          </Box>
          <Box flex="1" textAlign="right">
            <HStack>
              <QuestionTypeSelect />
              <LevelSelect />
            </HStack>
          </Box>
        </Stack>
        <QuestionAdderTable
          onClose={onClose}
          resetCheckBox={resetCheckBox}
          filterQuestion={data}
          isLoading={isLoading}
          topicId={topicId}
        />
        <Stack
          w="100%"
          direction={["column", "row"]}
          p="15px"
          alignItems="center"
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Box pe="{[0, 6]}">
            <Select
              placeholder="Show entries"
              size="sm"
              rounded="md"
              onChange={(e) => {
                dispatch(setPageDefault());
                dispatch(
                  setSize(e.target.value === "" ? 5 : parseInt(e.target.value))
                );
              }}
            >
              <option value="5">Entries 5</option>
              <option value="10">Entries 10</option>
              <option value="20">Entries 20</option>
            </Select>
          </Box>
          <Box>
            <Text fontSize="sm" colorScheme="gray.200">
              Showing {(page - 1) * size + 1} to {(page - 1) * size + size} of{" "}
              {data?.count ?? "20"} entries
            </Text>
          </Box>
          <Box>
            <ButtonGroup variant="outline" size="sm" isAttached>
              <Button
                isDisabled={page === 1}
                onClick={() => {
                  dispatch(decrementPage());
                }}
              >
                Previous
              </Button>
              <Button
                isDisabled={page === data?.totalPage}
                onClick={() => {
                  dispatch(incrementPage());
                }}
              >
                Next
              </Button>
            </ButtonGroup>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default QuestionAdder;
