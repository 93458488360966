import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/layout";
import { FC } from "react";
import { FaPlusCircle } from "react-icons/fa";

interface AddAndCloseProps {
  isOpen: boolean;
  onToggle: () => void;
  text: string;
}

const AddAndClose: FC<AddAndCloseProps> = ({ isOpen, onToggle, text }) => {
  return (
    <>
      {isOpen ? (
        <CloseIcon cursor="pointer" onClick={onToggle}/>
      ) : (
        <Flex align="center" onClick={onToggle} cursor="pointer">
          <FaPlusCircle /> <Text ml="2">{text}</Text>
        </Flex>
      )}
    </>
  );
};
export default AddAndClose;
