import { useQuery } from "react-query";

import useErrorToast from "hook/error-toast";
import { FC } from "react";

import { getPrograms } from "API/programApi";
import AddProgram from "./Program/AddProgram/AddProgram";
import {
  Stack,
  HStack,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";

import { useDisclosure } from "@chakra-ui/hooks";
import Program from "./Program/Program";

interface ProgramsProps {}

const Programs: FC<ProgramsProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: programData,
    isLoading: programLoading,
    error: programError,
  } = useQuery<IAllProgram[]>("programs", getPrograms);

  useErrorToast(programError);

  return (
    <>
      {/* {programLoading ? (
        <Spinner />
      ) : (
        <>
          <AddAndClose isOpen={isOpen} onToggle={onToggle} text="Add Program" />
          {isOpen && <AddProgram onClose={onClose} />}
          <Stack mt="4">
            {programData
              ?.map((program) => (
                <Program program={program} key={program._id} />
              ))
              .reverse()}
          </Stack>
        </>
      )} */}
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">Programs</Heading>
        <Button onClick={onOpen} colorScheme="teal">
          Add Program
        </Button>
      </HStack>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderTopRadius="md"
        pos="relative"
      >
        {programLoading ? (
          <Spinner />
        ) : (
          <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Modal Title</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <AddProgram onClose={onClose} />
                </ModalBody>
              </ModalContent>
            </Modal>

            <Stack spacing="0px">
              {programData
                ?.map((program) => (
                  <Program program={program} key={program._id} />
                ))
                .reverse()}
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default Programs;
