import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  createPersonalInterview,
  getPersonalInterviewData,
} from "API/personalInterviewApi";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

const EditCurrentInfoAndKnowledge = ({ tab, setTab, user: currUser }) => {
  const toast = useToast();
  const history = useHistory();
  const [currentAndGeneralInfoLabels, setCurrentAndGeneralInfoLabels] =
    useState([
      {
        label: "Population Statistics / General Knowledge",
        values: "",
        new: false,
      },
      {
        label:
          "Geographical Policies / schemes / Statistics / General Knowledge",
        values: "",
        new: false,
      },
      {
        label: "Historical Policies / schemes / Statistics / General Knowledge",
        values: "",
        new: false,
      },
      {
        label:
          "Major trade commerce & Industry Policies / schemes / Statistics / General Knowledge",
        values: "",
        new: false,
      },
      {
        label:
          "State Budget / deficit / Policies / schemes / Statistics / General Knowledge",
        values: "",
        new: false,
      },
      {
        label: "Seasons Policies / schemes / Statistics / General Knowledge",
        values: "",
        new: false,
      },
      {
        label: "Crop Policies / schemes / Statistics / General Knowledge",
        values: "",
        new: false,
      },
      { label: "Job Key task", values: "", new: false },
      { label: "Job Financial / designation", values: "", new: false },
      {
        label: "Job Sector & industry manufacturing / growing",
        values: "",
        new: false,
      },
      { label: "Job Key activities", values: "", new: false },
    ]);

  const createPersonalInterviewMutation = useMutation(createPersonalInterview);

  // const { user } = useAppSelector((state) => state.auth);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(currUser);
  }, [currUser]);

  const { data: personalInterviewData } = useQuery(
    ["personalInterview"],
    () => getPersonalInterviewData(user),
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    if (
      personalInterviewData?.data?.currentAndGeneralInfo &&
      personalInterviewData?.data?.currentAndGeneralInfo.length !== 0
    ) {
      const newFields = [];
      personalInterviewData?.data?.currentAndGeneralInfo.map((field) =>
        newFields.push({
          label: field.label,
          values: field.values,
          new: false,
        })
      );
      setCurrentAndGeneralInfoLabels(newFields);
    }
  }, [personalInterviewData]);

  const handleInputChange = (e, index) => {
    const newFields = [...currentAndGeneralInfoLabels];
    newFields[index].values = e.target.value;
    setCurrentAndGeneralInfoLabels(newFields);
  };

  const handleNewInputLabelChange = (e, index) => {
    const newFields = [...currentAndGeneralInfoLabels];
    newFields[index].label = e.target.value;
    setCurrentAndGeneralInfoLabels(newFields);
  };

  const handleInsertField = (index) => {
    const newFields = [...currentAndGeneralInfoLabels];
    newFields.splice(index + 1, 0, { label: "", values: "", new: true });
    setCurrentAndGeneralInfoLabels(newFields);
  };

  const handleRemoveInputField = (index) => {
    const newFields = [...currentAndGeneralInfoLabels];
    newFields.splice(index, 1);
    setCurrentAndGeneralInfoLabels(newFields);
  };

  const handleSaveClick = async ({ next }) => {
    const data = [];
    currentAndGeneralInfoLabels.map((field, index) => {
      if (field.label !== "") {
        data.push({
          label: field.label,
          values: field.values === "" ? "N / A" : field.values,
          order: index,
        });
      }
      return "";
    });
    let response = await createPersonalInterviewMutation.mutateAsync({
      data,
      type: "currentAndGeneralInfo",
      user,
    });
    if (response.success) {
      toast({
        title: response.message,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      if (next) setTab(tab + 1);
    }
  };

  return (
    <>
      <VStack w={"100%"} px={4}>
        {currentAndGeneralInfoLabels.map((key, index) =>
          !key.new ? (
            <Box w={"100%"} key={index}>
              <Text>{key.label}</Text>
              <HStack>
                <Input
                  onChange={(e) => handleInputChange(e, index)}
                  value={key.values}
                ></Input>
                <Button
                  colorScheme="teal"
                  fontSize={"xl"}
                  onClick={() => handleInsertField(index)}
                >
                  +
                </Button>
              </HStack>
            </Box>
          ) : (
            <Box w={"100%"} key={index}>
              {/* <HStack my={"16px"}>
                <Input value={"Field Label"} readOnly></Input>
                <Input value="Field Value" readOnly></Input>
                <Button
                  colorScheme="red"
                  onClick={() => handleRemoveInputField(index)}
                >
                  -
                </Button>
              </HStack> */}
              <HStack>
                <Input
                  placeholder="Field Label"
                  value={key.label}
                  onChange={(e) => handleNewInputLabelChange(e, index)}
                ></Input>
                <Input
                  placeholder="Field Value"
                  onChange={(e) => handleInputChange(e, index)}
                  value={key.values}
                ></Input>
                <Button
                  colorScheme="red"
                  onClick={() => handleRemoveInputField(index)}
                >
                  -
                </Button>
              </HStack>
            </Box>
          )
        )}
      </VStack>
      <HStack w="100%" px={4} py={"30px"}>
        <Button
          colorScheme="teal"
          onClick={() => handleSaveClick({ next: false })}
        >
          Save
        </Button>
        <Button colorScheme="teal" onClick={() => setTab(tab - 1)}>
          Previous
        </Button>
        <Button
          colorScheme="teal"
          onClick={() => handleSaveClick({ next: true })}
        >
          Save & Next
        </Button>
        <Button
          bg={"grey"}
          color={"White"}
          onClick={() =>
            history.push({
              pathname: "/interview",
              state: { item: user },
            })
          }
        >
          Cancel
        </Button>
      </HStack>
    </>
  );
};

export default EditCurrentInfoAndKnowledge;
