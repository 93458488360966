import { HStack, Heading, Text, Box, Stack, Badge } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Link as RoLink } from "react-router-dom";
import { getPerformanceReportByStudentIdAndPerformanceId } from "API/performanceReportApi";
import { useQuery } from "react-query";
import useErrorToast from "hook/error-toast";
import { useEffect, useState } from "react";

const PerformanceReport = (props: any) => {
  const [performanceReport, setPerformanceReport] = useState<any>();
  const { data, error: errorPerformanceReport } = useQuery(
    ["performanceReport", props.student._id, props.performanceReport._id],
    () =>
      getPerformanceReportByStudentIdAndPerformanceId({
        studentId: props.student._id,
        performanceId: props.performanceReport._id,
      })
  );

  useEffect(() => {
    if (data) {
      setPerformanceReport(data[0]);
    }
  }, [data]);

  useErrorToast(errorPerformanceReport);

  return (
    <Stack
      p="4"
      key={props.performanceReport._id}
      justify="space-between"
      borderBottom="1px"
      borderColor="gray.200"
      spacing={"0px"}
      direction={["column", "row"]}
      alignItems={"end"}
    >
      <Box w={"75%"}>
        <HStack spacing={"20px"} alignItems={'end'}>
          <Heading fontSize="sm" w={'50px'} flexShrink={'0'}>{props.performanceReport?.name}</Heading>
          {props.performanceReport?.program?.subjects?.map((subject: any) => (
            <Stack spacing={"2px"} key={subject._id} w={'80px'} flexShrink={'0'}>
              <Text key={subject._id} textTransform="capitalize" fontSize={'xs'}>
                {subject?.name}
              </Text>
              <Badge>
                {data && data.length === 0 && "0 / 10"}
                {performanceReport &&
                  performanceReport.parameters_result
                    .filter((parameter: any) => {
                      return parameter.subject === subject._id;
                    })
                    .reduce(
                      (a: any, b: any) => a + Number(b.marks_obtained),
                      0
                    ) /
                    performanceReport.parameters_result.filter(
                      (parameter: any) => {
                        return parameter.subject === subject._id;
                      }
                    ).length +
                    " / "}{" "}
                {performanceReport &&
                  performanceReport.parameters_result
                    .filter((parameter: any) => {
                      return parameter.subject === subject._id;
                    })
                    .reduce((a: any, b: any) => a + Number(b.marks), 0) /
                    performanceReport.parameters_result.filter(
                      (parameter: any) => {
                        return parameter.subject === subject._id;
                      }
                    ).length}
              </Badge>
            </Stack>
          ))}
        </HStack>
      </Box>

      <HStack>
        {!props.isUpdateDisabled && (
          <Button
            w={"100%"}
            colorScheme={"teal"}
            onClick={() => props.handleClick(props.performanceReport)}

            // as={RoLink}
            // to={`/school/dashboard/users/student/edit/${student?._id}/performance-reports/${performanceReport._id}/edit`}
          >
            Edit
          </Button>
        )}
        <Button
          w={"100%"}
          colorScheme={"blue"}
          as={RoLink}
          to={
            !props.isUpdateDisabled
              ? `/school/dashboard/users/student/edit/${props.student?._id}/performance-reports/${props.performanceReport._id}/view`
              : `/student/dashboard/performance-reports/${props.performanceReport._id}/view`
          }
        >
          View
        </Button>
      </HStack>
    </Stack>
  );
};

export default PerformanceReport;
