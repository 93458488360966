import { FC, useState } from "react";

import { Select } from "@chakra-ui/select";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSubjects } from "API/subjectApi";
import useErrorToast from "hook/error-toast";
import { Spinner, Button } from "@chakra-ui/react";
import { updateProgramSubject } from "API/programApi";
import { useToast } from "@chakra-ui/toast";
interface UpdateProgramSubjectProps {
  program: IAllProgram;
  onClose: () => void;
}

const UpdateProgramSubject: FC<UpdateProgramSubjectProps> = ({
  program,
  onClose,
}) => {
  const [subjectId, setSubjectId] = useState("");
  const cache = useQueryClient();
  const toast = useToast();

  const {
    data: subjectData,
    error,
    isLoading,
  } = useQuery<ISubject[]>("subjects", getSubjects);
  useErrorToast(error);
  const filteredSubjects = subjectData?.filter(
    (subject: ISubject) =>
      !program.subjects.map((s) => s._id).includes(subject._id)
  );
  const {
    error: updateSubjectError,
    isLoading: updateSubjectLoading,
    mutateAsync,
  } = useMutation(updateProgramSubject, {
    onSuccess: () => {
      cache.invalidateQueries("programs");
      toast({ title: "Subject added successfully", status: "success" });
      onClose();
    },
  });
  useErrorToast(updateSubjectError);

  const handleSubmit = async () => {
    if (subjectId) {
      try {
        await mutateAsync({
          programId: program._id,
          subjectId,
        });
      } catch (error) {}
    }
  };

  return (
    <>
      {" "}
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Select
            mt="8"
            placeholder="Select Subject"
            rounded="md"
            textTransform="capitalize"
            value={subjectId}
            onChange={(e) => {
              setSubjectId(e.target.value);
            }}
          >
            {filteredSubjects?.map((e: ISubject) => (
              <option key={e?.name} value={e._id}>
                {e.name}
              </option>
            ))}
          </Select>
          {subjectId && (
            <Button
              isLoading={updateSubjectLoading}
              mt="8"
              onClick={handleSubmit}
            >
              Add Subject
            </Button>
          )}
        </>
      )}{" "}
    </>
  );
};

export default UpdateProgramSubject;
