import * as yup from "yup";
// level: "",
// topicIds: topicIds,
// numberOfTest: 0,
// testNames: [],
export const generateTestValidation = yup.object().shape({
  level: yup.string().required("Level is required"),
  topicIds: yup.array().required("Topics are required"),
  numberOfTest: yup
    .number()
    .min(1, "Minimum 1 test required!")
    .required("Number of test is required"),
  testNames: yup
    .array()
    .of(yup.string().required("Test name is required"))
    .required("Test name is required"),
});
export const testQuestionFetchValidation = yup.object().shape({
  level: yup.string().required("Level is required"),
  topicIds: yup.array().required("Topics are required"),
});
