import {
  Box,
  Container,
  Stack,
  Image,
  Heading,
  List,
  ListItem,
  ListIcon,
  Text,
  Flex,
  Square,
  Tbody,
  Tr,
  Td,
  Table,
  Thead,
  Th,
  AspectRatio,
  HStack,
  IconButton,
  Avatar,
} from "@chakra-ui/react";
import bannerlogoimg from "assets/images/banner-logo.jpeg";
import whatweoffers from "assets/images/whatweoffer-01.jpg";
// import testiimg01 from "assets/images/testi-01.jpg";
import { BsCheckCircleFill, BsFillPlayCircleFill } from "react-icons/bs";
import StaticFooter from "./StaticFooter";
import { Link as RoLink } from "react-router-dom";
// import CompTestimonials from "./CompTestimonials";
import colnadaf from "assets/images/nadaf.jpg";
import sohailprofile from "assets/images/sohailShaikh.jpg";
import aninditaSain from "assets/images/aninditaSain.jpg";
import Shailesh from "assets/images/shailesh.jpg";
import Shizia from "assets/images/shizia.jpg";
import Saba from "assets/images/saba.jpg";
import Lukman from "assets/images/lukman.jpg";
import Firoz from "assets/images/firoz.jpg";
import Shruti from "assets/images/shruti.jpg";
import Cherry from "assets/images/cherry.jpg";
const HomePage = () => {
  return (
    <>
      <Box bg="pink.700">
        <Container py={[5, 0]} maxW="container.xl">
          <Stack direction={["column", "row"]} spacing="24px">
            <Box w={["100%", "75%"]} color="white" textAlign="center" py="8">
              <Heading
                as="h1"
                mt={[0, 10]}
                mb="6"
                lineHeight="60px"
                fontSize={["30px", "45px"]}
              >
                Preparing For Competitive Exams
                <br />
                (Academics And Jobs)
                <br />
                Class VIII onwards{" "}
              </Heading>
              <Box
                as={RoLink}
                to="/signup"
                px={8}
                py={4}
                textTransform="uppercase"
                color="white"
                fontWeight="bold"
                borderRadius="full"
                bgGradient="linear(to-r, teal.500, green.500)"
                _hover={{
                  bgGradient: "linear(to-r, red.500, yellow.500)",
                }}
              >
                Sign Up Now
              </Box>
            </Box>
            <Box w={["100%", "25%"]} display={{ base: "none", md: "flex" }}>
              <Image src={bannerlogoimg} alt="kp exam" boxSize="100%" />
            </Box>
          </Stack>
        </Container>
      </Box>
      <Container py={["24px", "100px"]} maxW="container.xl">
        <Stack direction={["column", "row"]} spacing="24px">
          <Box w={["100%", "40%"]}>
            <Image src={whatweoffers} alt="what we offers" boxSize="100%" />
          </Box>
          <Box w={["100%", "60%"]} pl={[0, 10]}>
            <Heading as="h2" mb="5" fontSize={["24px", "36px"]}>
              What we offer
            </Heading>
            <List spacing={4}>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Online Lectures
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Recorded lectures ( student can view multiple times)
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Query handling
              </ListItem>
              {/* You can also use custom icons from react-icons */}
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Topic-wise test
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Graded levels of Assessment
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Performance Analysis and Feedback
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Training on Note-making skills
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Personality Development Programs
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Developing writing skills
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Preparation for Mains ( UPSC – C.S.E.)
              </ListItem>
              <ListItem fontSize={["16px", "18px"]}>
                <ListIcon
                  as={BsCheckCircleFill}
                  fontSize={["18px", "20px"]}
                  color="green.500"
                />
                Personal interview guidelines
              </ListItem>
            </List>
          </Box>
        </Stack>
      </Container>
      <Box bgGradient="linear(to-l, #71ce7e, #caf880)" id="programs">
        <Container py={["24px", "100px"]} maxW="container.xl">
          <Box w={["100%", "60%"]} m="auto" textAlign="center" mb="5">
            <Heading as="h2" mb="6" fontSize={["24px", "36px"]}>
              Our programs
            </Heading>
            <Text>
              These programs are designed for masses helping, students who are
              financially low & students fall short of capabilities but aspiring
              to get into professional courses or government job.{" "}
            </Text>
          </Box>
          <Stack direction={["column", "row"]} spacing="24px">
            <Box w={["100%", "50%"]}>
              <Heading as="h3" mb="4" fontSize={["14px", "20px"]}>
                Foundation for different competitive exam
              </Heading>
              <Flex
                mb="3"
                as={"a"}
                href="/graduation-program-L-1"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-1</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class VIII</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={"a"}
                href="/graduation-program-L-2"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-2</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class IX</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={"a"}
                href="/graduation-program-L-3"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-3</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class X</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={RoLink}
                to="#"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-4</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class XI</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={RoLink}
                to="#"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-5</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class XII</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={RoLink}
                to="#"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-6</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class F. Y (First year University)</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={RoLink}
                to="#"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-7</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class S. Y (Second year University)</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={RoLink}
                to="#"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>L-8</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Class T. Y  (Third year University)</Text>
                </Box>
              </Flex>
            </Box>
            <Box w={["100%", "50%"]}>
              <Heading as="h3" mb="4" fontSize={["14px", "20px"]}>
                Civil Services Foundation Program
              </Heading>
              <Flex
                mb="3"
                as="a"
                href="/civil-services-level-1"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>2.1</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>First Year – Foundation</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as="a"
                href="/civil-services-level-1"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>2.2</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Second Year – Foundation</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as="a"
                href="/civil-services-level-1"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>2.3</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Third Year – Foundation </Text>
                </Box>
              </Flex>
              <Heading as="h3" mt="6" mb="4" fontSize={["14px", "20px"]}>
                CLAT (Common Law Admission Test)
              </Heading>
              <Flex
                mb="3"
                as={"a"}
                href="/clat-program"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>3.0</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Integrated law degree (5 year Degree - after 12th)</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={"a"}
                href="/clat-program"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>3.1</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Integrated law degree (Year - 1)</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={"a"}
                href="/clat-program"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>3.2</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Integrated law degree (Year - 2)</Text>
                </Box>
              </Flex>
              <Flex
                mb="3"
                as={"a"}
                href="/clat-program"
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>3.3</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Law Degree after Graduation (3 Years)</Text>
                </Box>
              </Flex>
            </Box>

          </Stack>
          <Box>
          <Heading as="h3" mb="4" mt="4" fontSize={["14px", "20px"]}>
                NIOS
              </Heading>
              <Flex
                mb="3"
                as={"a"}
                href="/nios"
               
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>4.1</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>NIOS (National Institute of Open Studies) Preparing students for class X</Text>
                </Box>
              </Flex>
          </Box>
          <Box>
          <Heading as="h3" mb="4" mt="5" fontSize={["14px", "20px"]}>
          Specialized Programs
              </Heading>
              <Stack direction={["column", "row"]} spacing={["3", "0"]} flexWrap={"wrap"}>
                <Box w={['100%', '50%']} pr={['0', '24px']} pb={['0', '12px']}>
              <Flex
                as={"a"}
                href="#"               
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>5.1</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Phonetics to IELTS</Text>
                </Box>
              </Flex>
              </Box>
              <Box w={['100%', '50%']} pb={['0', '12px']}>
              <Flex
                as={"a"}
                href="#"
               
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>5.2</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Personality Development Programs</Text>
                </Box>
              </Flex>
              </Box>
              <Box w={['100%', '50%']} pr={['0', '24px']} pb={['0', '12px']}>
              <Flex
                as={"a"}
                href="#"
               
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>5.3</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Logical Reasoning (C-SAT)</Text>
                </Box>
              </Flex>
              </Box>
              <Box w={['100%', '50%']} pb={['0', '12px']}>
              <Flex           
                as={"a"}
                href="#"
               
                bg="white"
                alignItems={"center"}
                textDecoration={"none"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Square bg="blue.200" size="40px">
                  <Text>5.4</Text>
                </Square>
                <Box flex="1" px="2">
                  <Text>Vedic Math</Text>
                </Box>
              </Flex>
              </Box>
              </Stack>
          </Box>
        </Container>
      </Box>
      <Container py={["24px", "100px"]} maxW="container.xl">
        <Heading as="h2" mb="5" fontSize={["24px", "36px"]} textAlign={'center'}>
          Announcements
        </Heading>
        <Box overflow="hidden">
          <Box overflowX="auto">
            <Table border='1px' borderColor='gray.200'>
              <Thead>
                <Tr bg={'blue.100'}>
                  <Th borderRight={'1px'} borderRightColor='gray.200' fontSize={'14px'}>NO.</Th>
                  <Th borderRight={'1px'} borderRightColor='gray.200' fontSize={'14px'}>DETAILS</Th>
                  <Th borderRight={'1px'} borderRightColor='gray.200' fontSize={'14px'}>PROGRAM STARTING​</Th>
                  <Th fontSize={'14px'}>ADMISSION STATUS​</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>1</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>Foundation Program for competitive exam.  L-1​</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>3rd week of October 2022</Td>
                  <Td>Open for Registration​​</Td>
                </Tr>
                <Tr>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>2</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>Foundation Program for competitive exam.  L-2</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>​15 July 2022</Td>
                  <Td>Open for Registration</Td>
                </Tr>
                <Tr bg={'gray.100'}>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>3</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>CLAT (First Year)​​</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>16 August 2022​</Td>
                  <Td>Open for Registration​​</Td>
                </Tr>
                <Tr>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>4</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>Civil Services Foundation Program. (Level -1)</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>16 August 2022​</Td>
                  <Td>Open for Registration​​</Td>
                </Tr>
                {/* <Tr bg={'gray.100'}>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>5</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>VIII – Graduation Program for Level -1 ( L-1)​</Td>
                  <Td borderRight={'1px'} borderRightColor='gray.200'>March 2022​</Td>
                  <Td>Registration open for upcoming batch​​</Td>
                </Tr> */}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Container>
      <Box bgGradient="linear(to-l, #e9e96b, #caf880)" id="programs">
        <Container py={["24px", "100px"]} maxW="container.xl">
          <Box w={["100%", "60%"]} m="auto" textAlign="center" mb="5">
            <Heading as="h2" mb="6" fontSize={["24px", "36px"]}>
              OUR FACULTY
            </Heading>
          </Box>
          <Stack direction={["column", "row"]} spacing={["0px", "24px"]}>
            <Box w={["100%", "50%"]}>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                  <Avatar size={'full'} name='M Nadaf' src={colnadaf} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Col. M Nadaf
                    </Text>
                    <Text>
                      National Defence
                      Academy, Pune and Indian Military
                      Academy, Dehradun, M-Tech, IIT -
                      Mumbai
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: Geography</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    Geography
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      as="a"
                      target={"_blank"}
                      href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                  <Avatar size={'full'} name='Sohail Shaikh' src={sohailprofile} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Mr. Sohail Shaikh
                    </Text>
                    <Text>
                      B.Com. MBA, DLL-,
                      LL.B-1 Vice President managing 3
                      countries. Worked for different multi
                      national companies. visiting faculty at
                      Pune University
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: Economics &
                      Current</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    Economics &
                    Current
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                  <Avatar size={'full'} name='Anindita Sain Walia' src={aninditaSain} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Ms. Anindita Sain Walia
                    </Text>
                    <Text>
                      Ranked 2nd
                      on the merit list at the M.A. Examinations
                      conducted under the University of Pune,
                      2003-2005

                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: Political Science</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    Political Science
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      as="a"
                      target={"_blank"}
                      href="https://vimeo.com/746743568/2b4103cdce"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} name='Shailesh Kolekar' src={Shailesh} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Dr. Shailesh Kolekar
                    </Text>
                    <Text>
                      BUMS, Resource
                      Person for History. Lectures. Teaching for
                      UPSC & MPSC.
                      Had cleared Mains of UPSC & MPSC and
                      had reached Personal interview round.
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>
                      Subject: History</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    History
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} name='Shizia Iqbal' src={Shizia} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Ms. Shizia Iqbal
                    </Text>
                    <Text>
                      MA – English. Teaching
                      TOEFL / ILTS / PTE and corporate training
                      for last 15 years
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>
                      Subject: C-SAT</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    C-SAT
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>

            </Box>
            <Box w={["100%", "50%"]}>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} name='Saba Sayyed' src={Saba} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Ms Saba Sayyed
                    </Text>
                    <Text>
                      M.Phil – Psychology, 20 year
                      experience in Teaching Math & Logical reasoning
                      for competitive exam at Mathematic & Gyna
                      Prabodh
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: C-SAT</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    C-SAT
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} name='Lukman Qazi' src={Lukman} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Mr. Lukman Qazi
                    </Text>
                    <Text>
                      B.E Civil Eng. Corporate
                      training, training management staff and students
                      Behavioural correction and personality
                      development

                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: Personality
                      Development</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    <Text fontSize={'14px'}>Personality Development</Text>
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} name='Firoz Shaikh' src={Firoz} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Mr. Firoz Shaikh
                    </Text>
                    <Text>
                      Engineer by profession, more
                      that 10 years of experience in IT. Provide training
                      to corporate & students on behavioral
                      corrections and personality development
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: Information
                      Technology</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    <Text fontSize={'14px'}>Information
                      Technology</Text>
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} name='Shruti Metha' src={Shruti} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Ms. Shruti Metha
                    </Text>
                    <Text>
                      B.E. MA Faculty for Civil
                      Services and MPSC at different institute.
                      Mentoring and guiding students for mains paper
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: C-SAT</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    C-SAT
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} name='Cherry Bindra' src={Cherry} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Ms. Cherry Bindra
                    </Text>
                    <Text>
                      B.Com MBA, Faculty for
                      English, Training students for competitive exams
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: C-SAT</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    C-SAT
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box bg='white' borderRadius={'10px'} p={'15px'} mb={["10px"]}>
                <HStack spacing={'5px'}>
                  <Box w={['20%', '15%']}>
                    <Avatar size={'full'} />
                  </Box>
                  <Box w={['80%', '60%']} pl={['10px', '15px']}>
                    <Text fontWeight={'bold'}>
                      Mr. Wasim Shaikh
                    </Text>
                    <Text>
                      BE Technical Consultant for
                      engineering companies. Tutor & guide for
                      ddfl
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'12px'} display={['block', 'none']}>Subject: C-SAT</Text>
                  </Box>
                  <Box w={['10%', '15%']} display={['none', 'block']} textAlign={'center'}>
                    <Text textTransform={'uppercase'} fontSize={'12px'}>Subject</Text>
                    C-SAT
                  </Box>
                  <Box w={['10%', '10%']} textAlign={'right'} display={['none', 'block']}>
                    <IconButton
                      // as="a"
                      // target={"_blank"}
                      // href="https://www.youtube.com/watch?v=KgXyKQgkmBE"
                      fontSize='30px'
                      variant={'gost'}
                      color={'teal'}
                      aria-label='Play video'
                      icon={<BsFillPlayCircleFill />}
                    />
                  </Box>
                </HStack>
              </Box>
            </Box>


          </Stack>
        </Container>
      </Box>
      <Box bg={"gray.50"}>
        <Container py={["24px", "100px"]} maxW="container.lg">
          <Heading
            textAlign={"center"}
            as="h2"
            mb="12"
            fontSize={["24px", "36px"]}
          >
            What Our Students Have to Say
          </Heading>

          <Stack direction={["column", "row"]} spacing="30px">

            <Box w={["100%", "50%"]}>
              <AspectRatio maxW="940px" ratio={16 / 9}>
                <iframe
                  title="What Our Students Have to Say"
                  src="https://player.vimeo.com/video/663237518?h=f271673ca0"
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
            <Box w={["100%", "50%"]}>
              <AspectRatio maxW="940px" ratio={16 / 9}>
                <iframe
                  title="What Our Students Have to Say"
                  src="https://www.youtube.com/embed/OACMyQb0uao"
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
          </Stack>
          {/* <Stack direction={["column", "row"]} spacing="30px" mt="30px">
            <Box w={["100%", "50%"]}>
              <CompTestimonials
                studentname="Kelvin Black"
                desc="Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
                imgurl={testiimg01}
              />
            </Box>
            <Box w={["100%", "50%"]}>
              <CompTestimonials
                studentname="Kelvin Black"
                desc="Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
                imgurl={testiimg01}
              />
            </Box>
          </Stack> */}
        </Container>
      </Box>
      <StaticFooter />
    </>
  );
};

export default HomePage;
