import { useAppSelector } from "app/hook";
import Students from "pages/dashboard/shared/Users/Students/Students";

const SchoolStudents = () => {
  const { uid } = useAppSelector((state) => state.auth);

  return (
    <>
      {uid && (
        <Students
          addRoute="/school/dashboard/users/student/add"
          editRoute="/school/dashboard/users/student/edit"
          schoolId={uid ?? ""}
        />
      )}
    </>
  );
};

export default SchoolStudents;
