import { FC } from "react";
import {
  Box,
} from "@chakra-ui/react";
import { getStudentById } from "API/studentApi";
import { getUserTestByStudentSchoolTestID } from "API/userTestApi";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "app/hook";

interface TestProps {
  test: ITest;
}

const Test: FC<TestProps> = ({ test }) => {
  const { id:idParams } = useParams<{ id: string }>();

  const { uid,role } = useAppSelector((state) => state.auth);
  const id = role==="student"?uid:idParams;
  
  

  const { data: student } = useQuery<IStudent>(
    ["student", id],
    () => getStudentById(id)
  );
  const { data: userTest } = useQuery<IUserTest>(
    ["userTest", test._id, student?._id, student?.school._id],
    () =>
      getUserTestByStudentSchoolTestID({
        testId: test._id,
        studentId: student?._id,
        schoolId: student?.school._id,
      }),

    {
      enabled:
        student !== undefined &&
        student?.school !== undefined &&
        test._id !== undefined,
    }
  );
  
  
  
  return (
    <>


      <Box w={'60%'} p={'10px'}>
        <Link
          to={`/${role}/dashboard/test/${!userTest ? test._id + "/false" : userTest._id + "/true"
            }`}
        >
          {test.name}
        </Link>
      </Box>
      <Box w={'10%'} p={'10px'}>
        {userTest?.testAttempts.length}
      </Box>
      <Box w={'10%'} p={'10px'} textTransform={'capitalize'}>{test.level}</Box>
      <Box w={'10%'} p={'10px'}>
        {userTest &&
          new Date(
            Math.floor(
              (userTest as IUserTest).testAttempts.reduce(
                (total, next) => total + next.timeTaken,
                0
              ) / (userTest as IUserTest).testAttempts.length
            ) * 1000
          )
            .toISOString()
            .substr(14, 5)}
      </Box>
      <Box w={'10%'} p={'10px'}>
        {userTest &&
          Math.ceil(
            (userTest as IUserTest).testAttempts.reduce(
              (total, next) => total + next.totalMarks,
              0
            ) / (userTest as IUserTest).testAttempts.length
          )}
      </Box>


      {/* <Td>
        <Link
          to={`/${role}/dashboard/test/${
            !userTest ? test._id + "/false" : userTest._id + "/true"
          }`}
        >
          {test.name}
        </Link>
        </Td>
        <Td textAlign={'center'}>{userTest?.testAttempts.length}</Td>
        <Td>{test.level}</Td>
        <Td>
          {userTest &&
            new Date(
              Math.floor(
                (userTest as IUserTest).testAttempts.reduce(
                  (total, next) => total + next.timeTaken,
                  0
                ) / (userTest as IUserTest).testAttempts.length
              ) * 1000
            )
              .toISOString()
              .substr(14, 5)}
        </Td>
        <Td>
          {userTest &&
            Math.ceil(
              (userTest as IUserTest).testAttempts.reduce(
                (total, next) => total + next.totalMarks,
                0
              ) / (userTest as IUserTest).testAttempts.length
            )}
        </Td> */}

    </>
  );
};
export default Test;
