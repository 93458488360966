import { AspectRatio, Box, Container, Heading, List, ListIcon, ListItem, Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr, Image } from '@chakra-ui/react'
import programbannerimg from "assets/images/graduation-program-l1.png";
// import testiimg01 from "assets/images/testi-01.jpg";
import {  BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Link as RoLink } from "react-router-dom";
// import CompTestimonials from './CompTestimonials';
import StaticFooter from './StaticFooter';
const ProgramCivilServices = () => {
    return (
        <>
            <Box bg="gray.50">
                <Container py={["24px", "80px"]} maxW="container.xl">
                    <Stack direction={['column-reverse', 'row']} alignItems={'center'}>
                        <Box w={['100%', '70%']}>
                            <Heading as='h1' mb="6" fontSize={["30px", "45px"]}>CIVIL SERVICES FOUNDATION PROGRAM LEVEL -1</Heading>
                            <Text mb="40px" fontSize={['14px', '20px']}>Civil Services Foundation Program is a three years program after class XII. The program intends to prepare students systematically for civil services exams in three years’ time, which includes preparation for:
                                <br /><br />
                                1. Prelims<br />
                                2. Mains Paper – How to prepare and write the mains papers.<br />
                                3. Personal interview – Step up training for personal interview.
                                <br /><br />
                                Our coaching involves, how to study, strategize the syllabus, system of making notes, managing mental stress, guiding the students at all the levels.
                            </Text>
                            <Box
                                as={RoLink} to="/signup"
                                px={8}
                                py={4}

                                textTransform="uppercase"
                                color='white'
                                fontWeight='bold'
                                borderRadius='full'
                                bgGradient='linear(to-r, teal.500, green.500)'
                                _hover={{
                                    bgGradient: 'linear(to-r, red.500, yellow.500)',
                                }}
                            >
                                Sign Up Now
                            </Box>
                        </Box>
                        <Box w={['100%', '30%']}>
                            <Image src={programbannerimg} alt="CIVIL SERVICES FOUNDATION PROGRAM LEVEL -1" boxSize="100%" />
                            {/* <AspectRatio maxW='940px' ratio={1 / 1}>
                                <iframe
                                    title="VIII – Graduation Program"
                                    src="https://www.youtube.com/embed/Fipb2E_3LMw"
                                    allowFullScreen
                                />
                            </AspectRatio> */}
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box bg={'purple.200'}>
                <Container py={["24px", "100px"]} maxW="container.lg" textAlign={'center'}>
                    <Heading as='h2' mb="50px" fontSize={["24px", "36px"]}>What we offer</Heading>
                    {/* <Text mb="40px" fontSize={['14px', '18px']}>These programs are designed for masses helping, students who are financially low & students fall short of capabilities but aspiring to get into professional courses or government job.</Text> */}

                    <Stack direction={['column', 'row']} spacing={'12px'} fontSize={['18px', '20px']} mt={'30px'}>
                        <Box w={['100%', '50%']} textAlign={'left'}>
                            <List spacing={5}>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Working on fundamentals
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Live lectures
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Recorded lectures for revision
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Test on each topic. Students can take multiple test and practice
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Coaching for Prelims, Mains & Personal Interview
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Separate log in for each student
                                </ListItem>

                            </List>
                        </Box>
                        <Box w={['100%', '50%']} textAlign={'left'}>
                            <List spacing={5}>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Lectures will be conducted twice / thrice a week.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Query handling by respective faculty
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Personality development program
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Highly experience and professional faculty
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Nominal fees
                                </ListItem>

                            </List>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container py={["24px", "100px"]} maxW="container.xl" textAlign={'center'}>
                    <Heading as='h2' mb="5" fontSize={["24px", "36px"]}>Program content</Heading>
                    <Box overflow="hidden">
                        <Box overflowX="auto">


                            <Table colorScheme='purple' border='1px' borderColor='gray.200' borderRadius="10px">
                                <TableCaption>Starting to the closing of the program – 8-9 months. Remaining time for revising lectures and writing tests. The lecture will be conducted twice a week.
                                </TableCaption>
                                <Thead>
                                    <Tr bg={'blue.100'}>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>SUBJECTS</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>TOPICS</Th>
                                       
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>ENGLISH​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>
                                        1. Vocabulary Building.<br /> 
                                        2. Spelling Errors.<br /> 
                                        3. Error Spotting<br /> 
                                        4. Fill in the blanks.<br /> 
                                        5. One word substitute<br /> 
                                        6. Sentence correction.<br /> 
                                        7. Spelling Errors
                                        </Td>
                                       
                                    </Tr>
                                    <Tr bg={'gray.100'}>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>CURRENT STUDIES (Basics)</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>
                                        1. Economics<br /> 
                                        2. Political Science<br /> 
                                        3. Geography<br /> 
                                        4. Science & Technology.,. <br />
                                        5. Environment
                                        </Td>
                                       
                                    </Tr>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>QUANTITATIVE APTITUDE</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>
                                        1. Average.<br /> 
                                        2. Percentages.<br /> 
                                        3. Partnership<br /> 
                                        4. Profit & Loss / Discount.<br /> 
                                        5. Time, Work & Wages<br /> 
                                        6. Boats & Streams.<br /> 
                                        7. Calendars, Clocks<br /> 
                                        8. Compound & Simple Interest<br /> 
                                        9. Divisibility & Remainder Fractions
                                        </Td>
                                       
                                    </Tr>
                                    <Tr bg={'gray.100'}>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>LOGICAL REASONING</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>
                                        1. Puzzles <br />
                                        2. Blood relation.<br /> 
                                        3. Order and ranking.<br /> 
                                        4. Syllogism, Alphabet & Number Series.<br /> 
                                        5. Deriving Conclusion from Passage, Statement & Assumption and Argument, Strengthening & Weakening arguments
                                        </Td>
                                       
                                    </Tr>
                                    <Tr >
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>POLITICAL SCIENCE</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>
                                        1. Constitution of India<br /> 
                                        2. Law of Torts.<br /> 
                                        3. Family Law.<br /> 
                                        4. International Law
                                        </Td>
                                       
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box></Box>
                   
                </Container>
            </Box>
            <Box bg={"gray.50"}>
                <Container py={["24px", "100px"]} maxW="container.md">
                    <Heading textAlign={'center'} as='h2' mb="12" fontSize={["24px", "36px"]}>What Our Students Have to Say</Heading>
                    <AspectRatio maxW="940px" ratio={16 / 9}>
                        <iframe
                            title="What Our Students Have to Say"
                            src="https://player.vimeo.com/video/663237518?h=f271673ca0"
                            allowFullScreen
                        />
                    </AspectRatio>
                    {/* <Stack direction={['column', 'row']} spacing='30px'>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                    </Stack>
                    <Stack direction={['column', 'row']} spacing='30px' mt='30px'>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                    </Stack> */}
                </Container>
            </Box>
            <StaticFooter />
        </>
    )
}

export default ProgramCivilServices
