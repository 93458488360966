import { Badge, Center, Grid, GridItem, Text } from "@chakra-ui/layout";
import { FC } from "react";
import Test from "./Test/Test";

interface TestsProps {
  tests: ITest[];
}

const Tests: FC<TestsProps> = ({ tests }) => {
  return (
    <>
      <Badge fontSize="lg">TESTS</Badge>

      <Grid
        gap={{ base: 1, md: 2, lg: 3 }}
        gridTemplateColumns={{
          base: `repeat(auto-fill,1fr)`,
          md: "repeat(auto-fit,minmax(400px,1fr))",
        }}
      >
        {tests.length === 0 ? (
          <Center>
            <Text>No Test Generated!</Text>
          </Center>
        ) : (
          tests.map((test) => (
            <GridItem key={test._id}>
              <Test test={test} />
            </GridItem>
          ))
        )}
      </Grid>
    </>
  );
};
export default Tests;
