import { AspectRatio, Box, Container, Heading, Icon, List, ListIcon, ListItem, Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
// import programbannerimg from "assets/images/graduation-program-l1.png";
// import testiimg01 from "assets/images/testi-01.jpg";
import { BsCheckLg, BsFillArrowRightCircleFill, BsXLg } from 'react-icons/bs';
import { Link as RoLink } from "react-router-dom";
// import CompTestimonials from './CompTestimonials';
import StaticFooter from './StaticFooter';
const ProgramL2 = () => {
    return (
        <>
            <Box bg="gray.50">
                <Container py={["24px", "80px"]} maxW="container.xl">
                    <Stack direction={['column-reverse', 'row']} alignItems={'center'}>
                        <Box w={['100%', '70%']}>
                            <Heading as='h1' mb="6" fontSize={["30px", "45px"]}>IX – Graduation Program L-2</Heading>
                            <Text mb="40px" fontSize={['14px', '20px']}>The program aims at preparing aspirants for competitive exams which could be either for government services or getting admission into professional courses.
                                <br /><br />
                                This would build the foundation of the aspirants and develop their core capabilities to compete.
                                <br /><br />
                                It is an eight years step up program, starting from class IX onward till graduation.
                                <br /><br />
                                The programs would help aspirants to identify career goals and pursue them at an early age.
                            </Text>
                            <Box
                                as={RoLink} to="/signup"
                                px={8}
                                py={4}

                                textTransform="uppercase"
                                color='white'
                                fontWeight='bold'
                                borderRadius='full'
                                bgGradient='linear(to-r, teal.500, green.500)'
                                _hover={{
                                    bgGradient: 'linear(to-r, red.500, yellow.500)',
                                }}
                            >
                                Sign Up Now
                            </Box>
                        </Box>
                        <Box w={['100%', '30%']}>
                            {/* <Image src={programbannerimg} alt="VIII – Graduation Program L-1" boxSize="100%" /> */}
                            <AspectRatio maxW='940px' ratio={1 / 1}>
                                <iframe
                                    title="VIII – Graduation Program"
                                    src="https://www.youtube.com/embed/Fipb2E_3LMw"
                                    allowFullScreen
                                />
                            </AspectRatio>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box bg={'purple.200'}>
                <Container py={["24px", "100px"]} maxW="container.lg" textAlign={'center'}>
                    <Heading as='h2' mb="50px" fontSize={["24px", "36px"]}>What we offer</Heading>
                    {/* <Text mb="40px" fontSize={['14px', '18px']}>These programs are designed for masses helping, students who are financially low & students fall short of capabilities but aspiring to get into professional courses or government job.</Text> */}

                    <Stack direction={['column', 'row']} spacing={'12px'} fontSize={['18px', '20px']} mt={'30px'}>
                        <Box w={['100%', '50%']} textAlign={'left'}>
                            <List spacing={5}>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Lectures will be twice or thrice a week
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Live lectures on fixed days and time.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Separate log-in for every student.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Recorded lectures for revision.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Test conducted on each topic.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Student can take multiple test on each topic.
                                </ListItem>

                            </List>
                        </Box>
                        <Box w={['100%', '50%']} textAlign={'left'}>
                            <List spacing={5}>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Query handling by respective faculty.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Question bank for students to practice.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Personality development program
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Highly experienced and professional faculty
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Nominal fees
                                </ListItem>

                            </List>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container py={["24px", "100px"]} maxW="container.xl" textAlign={'center'}>
                    <Heading as='h2' mb="5" fontSize={["24px", "36px"]}>Program content</Heading>
                    <Box overflow="hidden">
                        <Box overflowX="auto">


                            <Table colorScheme='purple' border='1px' borderColor='gray.200' borderRadius="10px">
                                <TableCaption>Starting to closing of the program – 6 month. Total 60 hours. Remaining time for revising lectures and writing test. Lecture will be conducted twice a week.
                                    <br />
                                    Lecture schedule (date –Time) will be communicated shortly
                                </TableCaption>
                                <Thead>
                                    <Tr bg={'blue.100'}>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>ENGLISH</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>LOGICAL REASONING</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>QUANTITATIVE APTITUDE</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>PERSONALITY DEVELOPMENT</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>CURRENT STUDIES</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>INFORMATION TECHNOLOGY</Th>
                                        <Th fontSize={'14px'}>PROFESSIONAL GUIDANCE</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Idioms & Phrases​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Logical Sequence</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Ratio & Proportion​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Developing Creativity​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Concept of Money ​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200' rowSpan={2}>Artificial Intelligence</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Career in Law​</Td>
                                    </Tr>
                                    <Tr bg={'gray.100'}>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Synonyms</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Coding Decoding​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Partnership, Profit, Loss & Discount​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Levels in communication​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Member of Parliament & State Legislator. Process of election & Duties​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Career In Indian Defense & NDA</Td>
                                    </Tr>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Antonyms</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Direction Sense Test​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Speed, distance, Time & Work​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Planning & Execution​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Environment Problem & international laws ​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200' rowSpan={3}>Robotics</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Process of Civil services selection & challenges​</Td>
                                    </Tr>
                                    <Tr bg={'gray.100'}>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>One Word Substitution​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Venn Diagram​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Interest simple & compound​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Fighting yourself​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>New research in Science &Technology​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Career In​ Architecture​</Td>
                                    </Tr>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Reading Skills​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Mathematical / Numerical Operation​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Linear Equations​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Basic Preparation for Personal Interview​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>How Map Reading​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Value & Ethics​</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box></Box>
                    <Heading as='h3' my="5" fontSize={["18px", "24px"]}>COVERING OF SYLLABUS L-2</Heading>
                    <Box overflow="hidden">
                        <Box overflowX="auto">
                            <Table colorScheme='purple' border='1px' borderColor='gray.200' borderRadius="10px">
                                <Thead>
                                    <Tr>
                                        <Th bg={'red.50'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>SUBJECTS</Th>
                                        <Th textAlign={'center'} bg={'gray.100'} color={'gray.300'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>VIII ​<br />
                                            (L-1)</Th>
                                        <Th textAlign={'center'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>IX ​<br />
                                            (L-2)</Th>
                                        <Th bg={'black'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'></Th>
                                        <Th bg={'black'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'></Th>
                                        <Th bg={'black'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'></Th>
                                        <Th bg={'black'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'></Th>
                                        <Th bg={'black'} fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'></Th>
                                        <Th bg={'black'}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            English
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'green.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Logical Reasoning
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'green.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Quantities reasoning
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'green.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Current Information
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'green.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Personality Development
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'green.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Economics
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'red.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Polity
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'red.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Geography
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'red.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            History
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'red.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Science Environment
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'red.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td bg={'red.50'} borderRight={'1px'} borderRightColor='gray.200'>
                                            Moral Values
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'red.500'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsXLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'} borderRight={'1px'} borderRightColor='gray.200'>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                        <Td textAlign={'center'} color={'gray.300'} bg={'gray.100'}>
                                            <Icon as={BsCheckLg} />
                                        </Td>
                                    </Tr>

                                </Tbody>
                            </Table>
                        </Box>
                    </Box>
                    <Stack direction={'row'} spacing={'0px'}>
                        <Box p={'4'} w={'50%'} textAlign={'center'} bg={'orange.100'} border={'1px'} borderColor='gray.200'>
                            <Text as="span" fontWeight={'bold'} color={'red.500'}>JOBS:</Text> Civil Services, MPSC, Railways, SSC all state & central government jobs
                        </Box>
                        <Box p={'4'} w={'50%'} textAlign={'center'} bg={'purple.100'} border={'1px'} borderColor='gray.200'>
                        <Text as="span" fontWeight={'bold'} color={'red.500'}>ENTRANCE EXAMS:</Text>  JEE/ NEET CLAT/CA / CGT, MBA, All Masters program<br />
                            NGO entrance  Test Rehamina & Super 30, Saheen
                        </Box>

                    </Stack>
                </Container>
            </Box>
            <Box bg={"gray.50"}>
                <Container py={["24px", "100px"]} maxW="container.md">
                    <Heading textAlign={'center'} as='h2' mb="12" fontSize={["24px", "36px"]}>What Our Students Have to Say</Heading>
                    <AspectRatio maxW="940px" ratio={16 / 9}>
                        <iframe
                            title="What Our Students Have to Say"
                            src="https://player.vimeo.com/video/663237518?h=f271673ca0"
                            allowFullScreen
                        />
                    </AspectRatio>
                    {/* <Stack direction={['column', 'row']} spacing='30px'>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                    </Stack>
                    <Stack direction={['column', 'row']} spacing='30px' mt='30px'>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                    </Stack> */}
                </Container>
            </Box>
            <StaticFooter />
        </>
    )
}

export default ProgramL2
