import {
  AspectRatio,
  Box,
  Container,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
// import programbannerimg from "assets/images/clatimg.jpg";
// import testiimg01 from "assets/images/testi-01.jpg";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link as RoLink } from "react-router-dom";
// import CompTestimonials from "./CompTestimonials";
import StaticFooter from "./StaticFooter";

const ProgramClat = () => {
  return (
    <>
      <Box bg="gray.50">
        <Container py={["24px", "80px"]} maxW="container.xl">
          <Heading as="h1" mb="6" fontSize={["30px", "45px"]} textAlign={'center'}>
            CLAT <br />
            <Text as="span" fontSize={'24px'}>(Common Law Admission Test)</Text>
          </Heading>
          <Stack direction={["column-reverse", "row"]}>
            <Box w={["100%", "70%"]}>

              <Text fontSize={["14px", "20px"]} pr="30px">
                Recently we have started CLAT program and hence intend to give a brief on the same.  CLAT is conducted by Consortium of National Law Universities to achieve highest standards of legal education in the country. It is conducted for admission to the
                22 top law colleges of India like:<br /><br />
                1. National Law School of India University - Bangalore. <br />
                2. National Academy of Legal Studies & Research – Hyderabad and others.<br /><br />


              </Text>
              <Text fontSize={["14px", "20px"]}> Law as a profession offers great earning potential, intellectual rewards, excellent work environment and most importantly an opportunity to help others. 100% placement is a yearly thing at these Law Colleges with basic
                pay packages starting from around 12lacs per annum.</Text>
              <Box mt={'40px'}>
                <Box
                  as={RoLink}
                  to="/signup"
                  px={8}
                  py={4}
                  textTransform="uppercase"
                  color="white"
                  fontWeight="bold"
                  borderRadius="full"
                  bgGradient="linear(to-r, teal.500, green.500)"
                  _hover={{
                    bgGradient: "linear(to-r, red.500, yellow.500)",
                  }}
                >
                  Sign Up Now
                </Box>
              </Box>
            </Box>
            <Box w={["100%", "30%"]}>
              <AspectRatio maxW='940px' ratio={1 / 1}>
                <iframe
                  title="Common Law Admission Test"
                  src="https://www.youtube.com/embed/yTwoyiv_fGw"
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
          </Stack>

        </Container>
      </Box>
      <Box bg={'green.200'}>
        <Container py={["24px", "100px"]} maxW="container.lg" textAlign={'center'}>
          <Heading as='h2' mb="50px" fontSize={["24px", "36px"]}>What we offer</Heading>
          {/* <Text mb="40px" fontSize={['14px', '18px']}>These programs are designed for masses helping, students who are financially low & students fall short of capabilities but aspiring to get into professional courses or government job.</Text> */}

          <Stack direction={['column', 'row']} spacing={'12px'} fontSize={['18px', '20px']} mt={'30px'}>
            <Box w={['100%', '50%']} textAlign={'left'}>
              <List spacing={5}>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Two years of preparation after class X
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Lectures will be twice or thrice a week
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Live lectures on fixed days and time.
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Separate log-in for every student.
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Recorded lectures for revision.
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Test conducted on each topic.
                </ListItem>


              </List>
            </Box>
            <Box w={['100%', '50%']} textAlign={'left'}>
              <List spacing={5}>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Student can take multiple test on each topic.
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Query handling by respective faculty.
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Question bank for students to practice.
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Personality development program
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Highly experienced and professional faculty
                </ListItem>
                <ListItem>
                  <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                  Nominal fees
                </ListItem>

              </List>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Box>
        <Container
          py={["24px", "100px"]}
          maxW="container.xl"
          textAlign={"center"}
        >
          <Heading as="h2" mb="5" fontSize={["24px", "36px"]}>
            Program content
          </Heading>
          <Box overflow="hidden">
            <Box overflowX="auto">
              <Table

                colorScheme="purple"
                border="1px"
                borderColor="gray.200"
                borderRadius="10px"
              >
                <TableCaption>
                  Starting to the closing of the program – 8-9 months. Remaining time for revising lectures and writing tests.
                  The lecture will be conducted twice a week.
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th fontSize={'14px'} borderRight={'1px'} borderRightColor="gray.200" bg={'blue.50'} >Subject</Th>
                    <Th fontSize={'14px'} borderRight={'1px'} borderRightColor="gray.200" colSpan={2} textAlign={'center'} bg={'blue.100'}>TOPICS Year -1</Th>
                    <Th fontSize={'14px'} borderRightColor="gray.200" colSpan={2} textAlign={'center'} bg={'blue.200'}>TOPICS Year -2</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td borderRight={'1px'} borderRightColor="gray.200" bg={'blue.50'}>ENGLISH</Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. Vocabulary Building<br />
                      2. Spelling Errors<br />
                      3. Error Spotting<br />
                      4. Fill in the blanks
                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. One word substitute<br />
                      2. Sentence correction<br />
                      3. Spelling Errors

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. Foreign Words<br />
                      2. Cloze Test

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. Idioms & Phrases<br />
                      2. Para Jumble<br />
                      3. Sentence Rearrangement
                    </Td>
                  </Tr>
                  <Tr bg={'purple.50'}>
                    <Td borderRight={'1px'} borderRightColor="gray.200" bg={'blue.50'}>CURRENT STUDIES</Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. Economics<br />
                      2. Political Science<br />
                      3. Geography<br />
                      4. Science & Tech.<br />
                      5. Environment
                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. Economics<br />
                      2. Political Science<br />
                      3. Geography<br />
                      4. Science & Tech.<br />
                      5. Environment

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. Economics<br />
                      2. Political Science<br />
                      3. Geography<br />
                      4. Science & Tech.<br />
                      5. Environment

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      1. Economics<br />
                      2. Political Science<br />
                      3. Geography<br />
                      4. Science & Tech.<br />
                      5. Environment
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={'1px'} borderRightColor="gray.200" bg={'blue.50'}>QUANTITATIVE APTITUDE</Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      Average, Percentages,  Partnership, Profit & Loss / Discount
                      Time, Work & Wages
                      Boats & Streams
                      Calendars, Clocks

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      Compound & Simple Interest,
                      Divisibility & Remainder
                      Fractions


                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      HCF & LCM,
                      Linear Equations,
                      Mathematical Relations,
                      Mixture & Alligations,
                      Pipes & Cistern


                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      Problem on Ages,
                      Ratio & Proportion,
                      Number Series – Missing & Wrong Number Series,
                      Probability and P&C,
                      Quadratic Equations++

                    </Td>
                  </Tr>
                  <Tr bg={'purple.50'}>
                    <Td borderRight={'1px'} borderRightColor="gray.200" bg={'blue.50'}>LOGICAL REASONING</Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      Puzzles,
                      Blood relation,
                      Order and ranking,
                      Syllogism,
                      Alphabet & Number Series,


                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      Deriving Conclusion from Passage,
                      Statement & Assumption and Argument,
                      Strengthening & Weakening arguments

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      Statement & Course of Action,
                      Statement & Conclusion,
                      Assertion & Reason,
                      Cause & Effect

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">

                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={'1px'} borderRightColor="gray.200" bg={'blue.50'}>LEGAL LOGIC</Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      Constitution of India,
                      Law of Torts,
                      Family Law,
                      International Law

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      (give a Feel of the subject)

                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      10 Hr Detail information
                    </Td>
                    <Td borderRight={'1px'} borderRightColor="gray.200">
                      15 Hr. current

                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box bg={"gray.50"}>
        <Container py={["24px", "100px"]} maxW="container.lg">
          <Heading
            textAlign={"center"}
            as="h2"
            mb="12"
            fontSize={["24px", "36px"]}
          >
            What Our Students Have to Say
          </Heading>
          <Stack direction={["column", "row"]} spacing="30px">
          
            <Box w={["100%", "50%"]}>
            <AspectRatio maxW="940px" ratio={16 / 9}>
            <iframe
              title="What Our Students Have to Say"
              src="https://player.vimeo.com/video/663237518?h=f271673ca0"
              allowFullScreen
            />
          </AspectRatio>
            </Box>
            <Box w={["100%", "50%"]}>
            <AspectRatio maxW="940px" ratio={16 / 9}>
            <iframe
              title="What Our Students Have to Say"
              src="https://www.youtube.com/embed/OACMyQb0uao"
              allowFullScreen
            />
          </AspectRatio>
            </Box>
          </Stack>
          {/* <Stack direction={["column", "row"]} spacing="30px" mt="30px">
            <Box w={["100%", "50%"]}>
              <CompTestimonials
                studentname="Kelvin Black"
                desc="Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
                imgurl={testiimg01}
              />
            </Box>
            <Box w={["100%", "50%"]}>
              <CompTestimonials
                studentname="Kelvin Black"
                desc="Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
                imgurl={testiimg01}
              />
            </Box>
          </Stack> */}
        </Container>
      </Box>
      <StaticFooter />
    </>
  );
};

export default ProgramClat;
