import { createSlice } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
export enum BatchStatusState {
  ALL = "ALL",
  ASSIGNED = "ASSIGNED",
  NOTASSIGNED = "NOTASSIGNED",
}

// Define the initial state using that type
const initialState = {
  status: BatchStatusState.ALL,
};

export const batchStatusFilter = createSlice({
  name: "batchStatus",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setStatus } = batchStatusFilter.actions;

export default batchStatusFilter.reducer;
