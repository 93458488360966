import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
interface AuthState {
  user: any;
  role: RoleType | null | undefined;
  token: string | null | undefined;
  uid: string | null | undefined;
}

// Define the initial state using that type
const initialState: AuthState = {
  user: null,
  role: null,
  token: null,
  uid: null,
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUID: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setRole: (state, action: PayloadAction<RoleType>) => {
      state.role = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },

    setAllAuth: (state, action: PayloadAction<any>) => {
      state.uid = action.payload.uid;
      state.user = action.payload.user;
      state.role = action.payload.role;
      state.token = action.payload.token;
    },
    defaultState: (state) => {
      console.log("reset");
      state = initialState;
    },
  },
});

export const { setUser, setRole, setToken, defaultState, setUID, setAllAuth } =
  authSlice.actions;

export default authSlice.reducer;
