import { AspectRatio, Box, Container, Heading, List, ListIcon, ListItem, Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
// import programbannerimg from "assets/images/graduation-program-l1.png";
// import testiimg01 from "assets/images/testi-01.jpg";
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Link as RoLink } from "react-router-dom";
// import CompTestimonials from './CompTestimonials';
import StaticFooter from './StaticFooter';
const ProgramL3 = () => {
    return (
        <>
            <Box bg="gray.50">
                <Container py={["24px", "80px"]} maxW="container.xl">
                    <Stack direction={['column-reverse', 'row']} alignItems={'center'}>
                        <Box w={['100%', '70%']}>
                            <Heading as='h1' mb="6" fontSize={["30px", "45px"]}>X – Graduation Program L-3</Heading>
                            <Text mb="40px" fontSize={['14px', '20px']}>The program aims at preparing aspirants for competitive exams which could be either for government services or getting admission into professional courses.
                                <br /><br />
                                This would build the foundation of the aspirants and develop their core capabilities to compete.
                                <br /><br />
                                It is an eight years step up program, starting from class X onward till graduation.
                                <br /><br />
                                The programs would help aspirants to identify career goals and pursue them at an early age.
                            </Text>
                            <Box
                                as={RoLink} to="/signup"
                                px={8}
                                py={4}

                                textTransform="uppercase"
                                color='white'
                                fontWeight='bold'
                                borderRadius='full'
                                bgGradient='linear(to-r, teal.500, green.500)'
                                _hover={{
                                    bgGradient: 'linear(to-r, red.500, yellow.500)',
                                }}
                            >
                                Sign Up Now
                            </Box>
                        </Box>
                        <Box w={['100%', '30%']}>
                            {/* <Image src={programbannerimg} alt="VIII – Graduation Program L-1" boxSize="100%" /> */}
                            <AspectRatio maxW='940px' ratio={1 / 1}>
                                <iframe
                                    title="VIII – Graduation Program"
                                    src="https://www.youtube.com/embed/Fipb2E_3LMw"
                                    allowFullScreen
                                />
                            </AspectRatio>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box bg={'purple.200'}>
                <Container py={["24px", "100px"]} maxW="container.lg" textAlign={'center'}>
                    <Heading as='h2' mb="50px" fontSize={["24px", "36px"]}>What we offer</Heading>
                    {/* <Text mb="40px" fontSize={['14px', '18px']}>These programs are designed for masses helping, students who are financially low & students fall short of capabilities but aspiring to get into professional courses or government job.</Text> */}

                    <Stack direction={['column', 'row']} spacing={'12px'} fontSize={['18px', '20px']} mt={'30px'}>
                        <Box w={['100%', '50%']} textAlign={'left'}>
                            <List spacing={5}>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Lectures will be twice or thrice a week
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Live lectures on fixed days and time.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Separate log-in for every student.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Recorded lectures for revision.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Test conducted on each topic.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Student can take multiple test on each topic.
                                </ListItem>

                            </List>
                        </Box>
                        <Box w={['100%', '50%']} textAlign={'left'}>
                            <List spacing={5}>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Query handling by respective faculty.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Question bank for students to practice.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Personality development program
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Highly experienced and professional faculty
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={BsFillArrowRightCircleFill} color='purple.500' />
                                    Nominal fees
                                </ListItem>

                            </List>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container py={["24px", "100px"]} maxW="container.xl" textAlign={'center'}>
                    <Heading as='h2' mb="5" fontSize={["24px", "36px"]}>Program content</Heading>
                    <Box overflow="hidden">
                        <Box overflowX="auto">


                            <Table colorScheme='purple' border='1px' borderColor='gray.200' borderRadius="10px">
                                <TableCaption>Starting to closing of the program – 6 month. Total 60 hours. Remaining time for revising lectures and writing test. Lecture will be conducted twice a week.
                                    <br />
                                    Lecture schedule (date –Time) will be communicated shortly
                                </TableCaption>
                                <Thead>
                                    <Tr bg={'blue.100'}>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>ENGLISH</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>LOGICAL REASONING</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>QUANTITATIVE APTITUDE</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>PERSONALITY DEVELOPMENT</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>CURRENT STUDIES</Th>
                                        <Th fontSize={'14px'} borderRight={'1px'} borderRightColor='gray.200'>INFORMATION TECHNOLOGY</Th>
                                        <Th fontSize={'14px'}>ECONOMICS & POLITICAL SCIENCE</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Writing Skills</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Blood Relation</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Divisibility, Remainder, Fractions</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Inrtoduction lecture. Purpose of Program</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>World Index & India</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200' rowSpan={2}>Artificial Intelligence</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Micro & Macro Economics</Td>
                                    </Tr>
                                    <Tr bg={'gray.100'}>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>How to write comprehension & letter</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Direction Test</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Number system HCF & LCM</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Process of Solving personal Problems</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Reasons & Impact Russia - Ukraine war</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Global & Indian Economy</Td>
                                    </Tr>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Vocabulary Building</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Ranking</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Mixture & Allegations</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Managing your desires & emotions</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Technology used in Mobile & the system of working</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200' rowSpan={3}>Robotics</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Democracy & Diversity - Political Science</Td>
                                    </Tr>
                                    <Tr bg={'gray.100'}>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Correcting Spelling Error</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Inequality</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Problem Age</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>What is Behaviour & How to improve​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Wealth in Ocean & study​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Outcomes of Democracy - Political Science​</Td>
                                    </Tr>
                                    <Tr>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Improving Reading skills​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Syllogism</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Surds & Indices​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Handling criticism & feedback​</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Enforcement Directorate (ED) / Central Bureau (CBI) powers, strucutre, Duty</Td>
                                        <Td borderRight={'1px'} borderRightColor='gray.200'>Value & Ethic</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box></Box>
                    
                    <Stack direction={'row'} spacing={'0px'}>
                        <Box p={'4'} w={'50%'} textAlign={'center'} bg={'orange.100'} border={'1px'} borderColor='gray.200'>
                            <Text as="span" fontWeight={'bold'} color={'red.500'}>JOBS:</Text> Civil Services, MPSC, Railways, SSC all state & central government jobs
                        </Box>
                        <Box p={'4'} w={'50%'} textAlign={'center'} bg={'purple.100'} border={'1px'} borderColor='gray.200'>
                        <Text as="span" fontWeight={'bold'} color={'red.500'}>ENTRANCE EXAMS:</Text>  JEE/ NEET CLAT/CA / CGT, MBA, All Masters program<br />
                            NGO entrance  Test Rehamina & Super 30, Saheen
                        </Box>

                    </Stack>
                </Container>
            </Box>
            <Box bg={"gray.50"}>
                <Container py={["24px", "100px"]} maxW="container.md">
                    <Heading textAlign={'center'} as='h2' mb="12" fontSize={["24px", "36px"]}>What Our Students Have to Say</Heading>
                    <AspectRatio maxW="940px" ratio={16 / 9}>
                        <iframe
                            title="What Our Students Have to Say"
                            src="https://player.vimeo.com/video/663237518?h=f271673ca0"
                            allowFullScreen
                        />
                    </AspectRatio>
                    {/* <Stack direction={['column', 'row']} spacing='30px'>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                    </Stack>
                    <Stack direction={['column', 'row']} spacing='30px' mt='30px'>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}>
                            <CompTestimonials
                                studentname='Kelvin Black'
                                desc='Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                                imgurl={testiimg01}
                                />
                        </Box>
                    </Stack> */}
                </Container>
            </Box>
            <StaticFooter />
        </>
    )
}

export default ProgramL3
