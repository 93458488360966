import { axios } from "./AxiosInstance";

type ISubmitUserTest = {
  testId: string;

  questions:
  | IAnsweredTFQuestion
  | IAnsweredMCQSQuestion
  | IAnsweredMCQMQuestion
  | IAnsweredLAQuestion[];
  totalMarks: number | null;
  attemptedQuestion: number | null;
  notAttemptedQuestion: number | null;
  correctAnswer: number | null;
  wrongAnswer: number | null;
  timeTaken: number | null;
  activeProgramId: string;
};
type IRetestSubmit = {
  userTestId: string;

  questions:
  | IAnsweredTFQuestion
  | IAnsweredMCQSQuestion
  | IAnsweredMCQMQuestion
  | IAnsweredLAQuestion[];
  totalMarks: number | null;
  attemptedQuestion: number | null;
  notAttemptedQuestion: number | null;
  correctAnswer: number | null;
  wrongAnswer: number | null;
  timeTaken: number | null;
};
export const submitUserTest = async ({
  testId,

  questions,
  totalMarks,
  attemptedQuestion,
  notAttemptedQuestion,
  correctAnswer,
  wrongAnswer,
  timeTaken,

  activeProgramId,
}: ISubmitUserTest) => {
  try {
    const { data } = await axios.post(`/user-test/submit`, {
      testId,

      questions,
      totalMarks,
      attemptedQuestion,
      notAttemptedQuestion,
      correctAnswer,
      wrongAnswer,
      timeTaken,
      activeProgramId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getUserTestById = async (testId: string) => {
  try {
    const { data } = await axios.post(`/user-test/get-user-test/`, { testId });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getUserTestByStudentSchoolTestID = async ({
  testId,
  studentId,
  schoolId
}: {
  testId: string;
  studentId: string | undefined;
  schoolId: string | undefined;
}) => {
  try {
    const { data } = await axios.post(`/user-test/getUserTestByStudentSchoolTestID/`, { testId, studentId, schoolId });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};


export const retestSubmit = async ({
  userTestId,
  questions,
  totalMarks,
  attemptedQuestion,
  notAttemptedQuestion,
  correctAnswer,
  wrongAnswer,
  timeTaken,
}: IRetestSubmit) => {
  try {
    const { data } = await axios.post(`/user-test/retest-submit`, {
      userTestId,
      questions,
      totalMarks,
      attemptedQuestion,
      notAttemptedQuestion,
      correctAnswer,
      wrongAnswer,
      timeTaken,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
