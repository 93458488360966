import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Table as CTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
  Heading,
} from "@chakra-ui/react";
import { deleteProduct } from "API/productApi";
import AlertDialog from "components/dumb/AlertDialog";
import useErrorToast from "hook/error-toast";
import { FC, useRef, useState } from "react";
import { FcFinePrint } from "react-icons/fc";
import { GoKebabVertical } from "react-icons/go";
import { useMutation, useQueryClient } from "react-query";
import AddEditProduct from "./AddEditProduct";
interface IProductTableProps {
  products: IProduct[];
  isLoading: boolean;
  program: string;
}
const ProductTable: FC<IProductTableProps> = ({
  products,
  isLoading,
  program,
}) => {
  const cache = useQueryClient();
  const toast = useToast();
  const mutateDelete = useMutation(deleteProduct, {
    onSuccess: () => {
      cache.invalidateQueries("products");
      toast({
        title: "Product Deleted",
        description: "Product has been deleted successfully",
        status: "success",
        duration: 2000,
      });
    },
  });
  const onDelete = (productId: string) => {
    mutateDelete.mutate(productId);
  };
  const [productId, setProductId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertState = useDisclosure();
  const firstField = useRef(null);
  const onEdit = (id: string) => {
    setProductId(id);
    onOpen();
  };
  useErrorToast(mutateDelete.error);
  const deleteConfirmation = (id: string) => {
    setProductId(id);
    alertState.onOpen();
  };

  const filteredProducts = products.filter((product) => {
    return product.program._id === program || program === "";
  });

  return (
    <>
      <AlertDialog
        onClose={alertState.onClose}
        isOpen={alertState.isOpen}
        dialogHeading="Delete Product"
        dialogMessage="Are you sure you want to delete this product?"
        onConfirm={() => {
          onDelete(productId);
          alertState.onClose();
        }}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Update Product</DrawerHeader>
          <DrawerBody>
            <AddEditProduct onClose={onClose} isUpdate productId={productId} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {isLoading ? (
        <Box width="100%" height="100%">
          <Spinner />
        </Box>
      ) : products?.length === 0 ? (
        <Flex w="100%" minH="50vh" align="center" justify="center">
          <VStack>
            <Icon as={FcFinePrint} w={16} h={16} />
            <Text>No Product Found!</Text>
          </VStack>
        </Flex>
      ) : (
        <CTable variant="simple">
          <Thead>
            <Tr>
              <Th>Product name</Th>
              <Th>Duration</Th>
              <Th>Type</Th>
              <Th>Permission</Th>
              <Th>Program</Th>
              <Th>Price/user</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <>
              {filteredProducts
                ?.map((product) => (
                  <Tr key={product?._id}>
                    <Td>{product?.name}</Td>
                    <Td>{product?.duration} months</Td>
                    <Td textTransform="capitalize">{product?.type}</Td>
                    <Td>
                      {product?.access.map((a) => (
                        <Badge colorScheme={"teal"} mr="1" key={a}>
                          {a}
                        </Badge>
                      ))}
                    </Td>
                    <Td>{product?.program?.name}</Td>
                    <Td>
                      <Text fontSize="md" fontWeight={"bold"} color={"teal"}>
                        Rs.{product?.price}
                      </Text>
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<GoKebabVertical />}
                          variant="ghost"
                        />
                        <MenuList>
                          <MenuItem onClick={() => onEdit(product?._id)}>
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => deleteConfirmation(product._id)}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))
                .reverse()}
              {filteredProducts?.length === 0 && (
                <Heading size="md" m={5}>
                  No Data Found!
                </Heading>
              )}
            </>
          </Tbody>
        </CTable>
      )}
    </>
  );
};

export default ProductTable;
