import { axios } from "./AxiosInstance";

export const saveAudio = async (formData: any) => {
  try {
    const { data } = await axios.post("/speaking-skill/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getSpeakingSkill = async (user: any) => {
  try {
    const { data } = await axios.get(`/speaking-skill/${user._id}`);

    return data;
  } catch (error: any) {
    throw Error(error);
  }
};

export const updateSkillData = async (newSKillData: any, user: any) => {
  try {
    const { data } = await axios.post(
      `/speaking-skill/${user._id}`,
      newSKillData
    );

    return data;
  } catch (error: any) {
    throw Error(error);
  }
};
