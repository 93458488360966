import Students from "pages/dashboard/shared/Users/Students/Students";
import { useParams } from "react-router";

const AdminSchoolStudents = () => {
  const { schoolId } = useParams<{ schoolId: string }>();

  return (
    <Students
      editRoute={`/admin/dashboard/users/${schoolId}/student/edit`}
      addRoute={`/admin/dashboard/users/${schoolId}/student/add`}
      schoolId={schoolId ?? ""}
    />
  );
};

export default AdminSchoolStudents;
