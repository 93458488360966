import { axios } from "./AxiosInstance";

export const getAdminByID = async (id: string) => {
    try {
        const { data } = await axios.get(`/admin/${id}`);
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

