import { Select } from "@chakra-ui/select";
import { useAppDispatch, useAppSelector } from "app/hook";
import { FC } from "react";

import {
  setProgramId,
  setPageDefault,
} from "app/features/batchFilter/batchFilter";
import { getAllPrograms } from "API/programApi";

import { useQuery } from "react-query";

interface ProgramSelectProps {}

const ProgramSelect: FC<ProgramSelectProps> = () => {
  const { programId } = useAppSelector((state) => state.batchFilter);
  const dispatch = useAppDispatch();

  const { data } = useQuery<IProgram[]>("allprograms", getAllPrograms);

  return (
    <Select
      placeholder="Programs"
      size="sm"
      value={programId ?? ""}
      rounded="md"
      display={{ base: "none", md: "block", lg: "block" }}
      textTransform="capitalize"
      onChange={(e) => {
        dispatch(setProgramId(e.target.value));
        dispatch(setPageDefault());
      }}
    >
      {data &&
        data?.map((e: IProgram) => (
          <option key={e?.name} value={e?._id}>
            {e.name}
          </option>
        ))}
    </Select>
  );
};
export default ProgramSelect;
