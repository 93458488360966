import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { theme } from "theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { store } from "app/store";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";
// const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: twentyFourHoursInMs,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <>
              <ColorModeScript
                initialColorMode={theme.config.initialColorMode}
              />
              <Router>
                <App />
              </Router>
              <ReactQueryDevtools initialIsOpen={false} />
            </>
          </QueryClientProvider>
        </ChakraProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
