import { Text, Box, VStack, ListItem, OrderedList } from "@chakra-ui/react";

// interface LAResultProps {
//   question: IAnsweredLAQuestion;
// }

const LAResult = ({ question }: { question: any }) => {
  let keyValueArray: [string, string][] = [];
  if (question?.correctionParameterJustification) {
    keyValueArray = Object.entries(question?.correctionParameterJustification);
  }

  return (
    <>
      {/* {question.attempted && question.isCorrect ? (
        <Text color="green">Correct</Text>
      ) : (
        <Text color="red">Incorrect</Text>
      )} */}
      {/* <Text>{question.laSubmittedAnswer}</Text> */}
      <VStack w={"100%"} px={"15px"} align="start">
        <Text>{question.laSubmittedAnswer}</Text>
        <VStack align="start">
          <Text as="b">Correction Parameter</Text>
          <Box>
            <OrderedList>
              {keyValueArray.length > 0 ? (
                keyValueArray.map(([key, value]) => (
                  <ListItem>
                    {key} <Text as="b">{value}</Text>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <Text as="b">NOT APPLICABLE</Text>
                </ListItem>
              )}
            </OrderedList>
          </Box>
        </VStack>
        <VStack
          align="start"
          w="75%"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Text as="b">Remark:</Text>
          <Text style={{ marginTop: "0" }}>{question.remark ?? ""}</Text>
        </VStack>
      </VStack>
    </>
  );
};
export default LAResult;
