import { Center, Grid, GridItem, Heading } from "@chakra-ui/layout";
import { Flex, Spinner } from "@chakra-ui/react";
import { addSchool, getSchoolById, updateSchool } from "API/schoolApi";
import { Formik, Form } from "formik";
import {
  InputControl,
  SelectControl,
  SubmitButton,
  TextareaControl,
} from "formik-chakra-ui";
import { FC } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addSchoolValidationSchema,
  updateSchoolValidationSchema,
} from "validation/schoolValidation";
import { useToast } from "@chakra-ui/toast";
import useErrorToast from "hook/error-toast";
import { useHistory, useParams } from "react-router";
interface AddAndUpdateSchoolProps {
  isUpdate?: boolean;
}

const AddAndUpdateSchool: FC<AddAndUpdateSchoolProps> = ({
  isUpdate = false,
}) => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const {
    data,
    isLoading: isLoadingSchool,
    error: errorSchool,
  } = useQuery<ISchool>(["school", id], () => getSchoolById(id), {
    enabled: isUpdate,
  });

  const {
    error: updateError,
    isLoading: updateIsLoading,
    mutateAsync: updateMutate,
  } = useMutation("updateSchool", updateSchool, {
    onSuccess: () => {
      toast({
        title: "School Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/dashboard/users/school");
    },
  });
  const {
    error: addError,
    isLoading: addIsLoading,
    mutateAsync: addMutate,
  } = useMutation("addSchool", addSchool, {
    onSuccess: () => {
      toast({
        title: "School Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/dashboard/users/school");
    },
  });
  useErrorToast(updateError);
  useErrorToast(errorSchool);
  useErrorToast(addError);

  return (
    <>
      {isLoadingSchool ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Formik
          validationSchema={
            isUpdate ? updateSchoolValidationSchema : addSchoolValidationSchema
          }
          initialValues={
            isUpdate
              ? {
                  name: data?.name ?? "",
                  mobile: data?.mobile ?? 0,
                  email: data?.email ?? "",
                  password: "",
                  confirmPassword: "",
                  address: data?.contact?.address ?? "",
                  city: data?.contact?.city ?? "",
                  state: data?.contact?.state ?? "",
                  pincode: data?.contact?.pincode ?? 0,
                }
              : {
                  name: "",
                  mobile: 0,
                  email: "",
                  password: "",
                  confirmPassword: "",
                  address: "",
                  city: "",
                  state: "",
                  pincode: 0,
                }
          }
          onSubmit={async (values) => {
            if (isUpdate) {
              try {
                await updateMutate({
                  userId: id,
                  ...values,
                });
              } catch (error) {}
            } else {
              try {
                await addMutate({
                  ...values,
                });
              } catch (error) {}
            }
          }}
        >
          <Form>
            <Grid gridColumn={{ base: 1, sm: 2 }} gridGap="4">
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <Heading fontSize="xl">
                  {isUpdate ? "Update" : "Add"} School
                </Heading>
              </GridItem>

              <GridItem>
                <InputControl
                  name="name"
                  inputProps={{ placeholder: "Enter School Name" }}
                  label="School Name"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="email"
                  inputProps={{
                    placeholder: "Enter School Email",
                    type: "email",
                  }}
                  label="School Email"
                />
              </GridItem>
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <InputControl
                  name="mobile"
                  inputProps={{
                    placeholder: "Enter School Mobile No.",
                    type: "number",
                  }}
                  label="School Mobile No."
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="password"
                  inputProps={{
                    placeholder: "Enter Password",
                    type: "password",
                  }}
                  label="Password"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="confirmPassword"
                  inputProps={{
                    placeholder: "Confirm Password",
                    type: "password",
                  }}
                  label="Confirm Password"
                />
              </GridItem>
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <TextareaControl
                  name="address"
                  textareaProps={{
                    placeholder: "Enter Address",
                  }}
                  label="Address"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="city"
                  inputProps={{ placeholder: "Enter City" }}
                  label="City"
                />
              </GridItem>
              <GridItem>
                <SelectControl name="state" label="State">
                  <option value="">Select State</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </SelectControl>
              </GridItem>
              <GridItem>
                <InputControl
                  name="pincode"
                  label="Pincode"
                  inputProps={{ placeholder: "Enter Pincode" }}
                />
              </GridItem>
              <Flex align="flex-end">
                <SubmitButton
                  isLoading={addIsLoading || updateIsLoading}
                  width="full"
                >
                  Submit
                </SubmitButton>
              </Flex>
            </Grid>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default AddAndUpdateSchool;
