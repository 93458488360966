import { Spinner } from "@chakra-ui/spinner";
import { getSchoolById } from "API/schoolApi";
import useErrorToast from "hook/error-toast";
import AssignProgramToStudent from "pages/dashboard/shared/Users/Students/AssignProgramToStudent/AssignProgramToStudent";
import { useQuery } from "react-query";
import { useParams } from "react-router";

const AdminAssignProgramStudent = () => {
  const params = useParams<{ schoolId: string }>();
  const {
    data: school,
    isLoading,
    error,
  } = useQuery<ISchool>(["school", params.schoolId], () =>
    getSchoolById(params.schoolId)
  );
  useErrorToast(error);

  return (
    <>
      {isLoading && <Spinner />}{" "}
      {school && <AssignProgramToStudent school={school} />}{" "}
    </>
  );
};

export default AdminAssignProgramStudent;
