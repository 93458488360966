import { axios } from "./AxiosInstance";

export const addTopic = async ({
  chapterId,
  topicName,
}: {
  chapterId: string;
  topicName: string;
}) => {
  try {
    const { data } = await axios.post(`/topic/add`, {
      chapterId,
      topicName,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const addQuestionToTopic = async ({
  topicId,
  questions,
}: {
  topicId: string;
  questions: string[];
}) => {
  try {
    const { data } = await axios.post(`/topic/addQuestions`, {
      topicId,
      questions,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const deleteTopicQuestionById = async ({
  ids,
  topicId,
}: {
  ids: string[];
  topicId: string;
}) => {
  try {
    const { data } = await axios.post(`/topic/deleteMany`, { ids, topicId });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const deleteTopicById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/topic/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateTopic = async ({
  id,
  topicName,
  topicVideoLink
}: {
  id: string;
  topicName: string;
  topicVideoLink: string | undefined;
}) => {
  try {
    const { data } = await axios.patch(`/topic`, { topicName, topicId: id, topicVideoLink });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
