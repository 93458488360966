import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Container,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
// import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { useAuth } from "hook/auth-hooks";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { useAppSelector } from "app/hook";
import { useState, useEffect } from "react";

const NAV_ITEMS: NavItem[] = [
  {
    label: "Dashboard",
    href: "/student/dashboard",
  },
  {
    label: "Program registration",
    href: "/buy-products",
  },
  {
    label: "Batches",
    href: "/student/dashboard/batches",
  },
  {
    label: "My reports",
    href: "/student/dashboard/reports",
  },
  {
    label: "Career Plan",
    href: "/student/dashboard/career-plan",
  },
  {
    label: "Personal Interview",
    href: "/interview",
  },
  {
    label: "Speaking Skill",
    href: "/speaking-skill",
  },
  {
    label: "Writing Skill",
    href: "/writing-skill",
  },
];

const ADMIN_NAV_ITEMS: NavItem[] = [
  {
    label: "Dashboard",
    href: "/admin/dashboard",
  },
  {
    label: "Question Bank",
    href: "/admin/dashboard/questions",
  },

  {
    label: "Performance Management",
    href: "/admin/dashboard/performances",
  },

  {
    label: "Programs",
    href: "/admin/dashboard/programs",
  },
  {
    label: "Test Manager",
    href: "/admin/dashboard/test-manager",
  },
  {
    label: "Users",
    children: [
      {
        label: "Schools",
        subLabel: "Manage all schools",
        href: "/admin/dashboard/users/school",
      },
      {
        label: "Teacher",
        subLabel: "Manage all teacher",
        href: "/admin/dashboard/users/staffs",
      },
    ],
  },
  // {
  //   label: "Reports",
  //   href: "/Reports",
  // },
  {
    label: "More",
    children: [
      {
        label: "Subjects",
        subLabel: "Manage all subjects",
        href: "/admin/dashboard/subjects",
      },
      {
        label: "Products",
        subLabel: "Manage all products",
        href: "/admin/dashboard/products",
      },
      {
        label: "Batches",
        subLabel: "Manage all batches",
        href: "/admin/dashboard/batches",
      },
    ],
  },
];
const SCHOOL_NAV_ITEMS: NavItem[] = [
  {
    label: "Dashboard",
    href: "/school/dashboard",
  },
  // {
  //   label: "Question Bank",
  //   href: "/school/dashboard/questions",
  // },
  // {
  //   label: "Programs",
  //   href: "/school/dashboard/programs",
  // },
  // {
  //   label: "Test Manager",
  //   href: "/school/dashboard/test-manager",
  // },
  {
    label: "Program registration",
    href: "/buy-products",
  },
  {
    label: "Users",
    children: [
      {
        label: "Students",
        subLabel: "Manage all students",
        href: "/school/dashboard/users/student",
      },
    ],
  },
];
const STAFF_NAV_ITEMS: NavItem[] = [
  {
    label: "Dashboard",
    href: "/staff/dashboard",
  },

  {
    label: "Students",
    href: "/staff/dashboard/users/students",
  },

  // {
  //   label: "Question Bank",
  //   href: "/staff/dashboard/questions",
  // },
  // {
  //   label: "Programs",
  //   href: "/staff/dashboard/programs",
  // },
  // {
  //   label: "Test Manager",
  //   href: "/staff/dashboard/test-manager",
  // },
];
export default function DashboardNavigation() {
  const { isOpen, onToggle } = useDisclosure();
  // const { signOut } = useGoogleLogout({
  //   clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  // });

  const { logout } = useAuth();

  const { uid, role, user } = useAppSelector((state) => state.auth);

  const [navItem, setNavItem] = useState<NavItem[]>([]);

  useEffect(() => {
    switch (role) {
      case "school":
        setNavItem(SCHOOL_NAV_ITEMS);
        break;

      case "student":
        setNavItem(NAV_ITEMS);
        break;

      case "staff":
        setNavItem(STAFF_NAV_ITEMS);
        break;

      case "admin":
        setNavItem(ADMIN_NAV_ITEMS);
        break;
      default:
        setNavItem([]);
    }
  }, [role, setNavItem]);
  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.600", "white")}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
    >
      <Container maxW="container.xl">
        <Flex minH={"60px"} py={{ base: 2 }} align={"center"}>
          <Flex
            flex={{ base: 0, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              fontWeight="bold"
              color={useColorModeValue("gray.800", "white")}
              to="/"
              as={Link}
            >
              KP Training Academy
            </Text>

            <Flex
              display={{ base: "none", lg: "flex" }}
              ml={10}
              justify={{ base: "end", md: "end" }}
            >
              <DesktopNav NAV_ITEMS={navItem} />
            </Flex>
          </Flex>

          <Stack
            flex={{ base: 0, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            {uid && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  _focus={{
                    boxShadow: "none",
                  }}
                  minW={0}
                >
                  <Avatar
                    size={"md"}
                    src={
                      process.env.NODE_ENV === "production"
                        ? user?.avatar
                        : process.env.REACT_APP_API_URL?.split("/api")[0] +
                          user?.avatar
                    }
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem textTransform={"capitalize"}>
                    {role === "student" ? user?.name : "User"} -
                    {role === "admin"
                      ? "Admin"
                      : role === "school"
                      ? "Institution"
                      : role === "student"
                      ? "Student"
                      : role === "staff"
                      ? "Teacher"
                      : ""}
                  </MenuItem>
                  {role === "student" && (
                    <MenuItem
                      _disabled={{
                        color: "black",
                        cursor: "not-allowed",
                      }}
                      isDisabled
                    >
                      {user?.school.name}
                    </MenuItem>
                  )}
                  <MenuItem as={Link} to="/MyProfile">
                    My Profile
                  </MenuItem>
                  <MenuItem as={Link} to="/Settings">
                    Settings
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            )}
          </Stack>
        </Flex>
      </Container>
      <Collapse in={isOpen} animateOpacity>
        {navItem.length !== 0 && <MobileNav NAV_ITEMS={navItem} />}
      </Collapse>
    </Box>
  );
}
