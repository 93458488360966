import { HStack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/table";
import { Tag } from "@chakra-ui/tag";
import { getTestById } from "API/testApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useQuery } from "react-query";

interface TestQuestionsTableProps {
  test: ITest;
}

const TestQuestionsTable: FC<TestQuestionsTableProps> = ({ test }) => {
  const { data, isLoading, error } = useQuery<ITest>(["test", test._id], () =>
    getTestById(test._id)
  );
  console.log(data);
  useErrorToast(error);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Question</Th>
              <Th>Type</Th>
            </Tr>
          </Thead>

          <>
            <Tbody>
              {data?.questions?.map((question, i) => (
                <Tr key={question._id}>
                  <Td maxW="400px">
                    {question?.question}
                    <HStack spacing={2} mt="10px">
                      {question?.tags.map((tag) => (
                        <Tag size="sm" key={tag} colorScheme="cyan">
                          {tag}
                        </Tag>
                      ))}
                    </HStack>
                  </Td>
                  <Td>{question?.type}</Td>
                </Tr>
              ))}
            </Tbody>
          </>
        </Table>
      )}
    </>
  );
};
export default TestQuestionsTable;
