import { axios } from "./AxiosInstance";

export const createPersonalInterview = async (obj: any) => {
  try {
    const { data } = await axios.post(`/personal-interview/`, obj);
    return data;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getPersonalInterviewData = async (user: any) => {
  try {
    const { data } = await axios.get(`/personal-interview/${user._id}`);

    return data;
  } catch (error: any) {
    throw Error(error);
  }
};
