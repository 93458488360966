import { Avatar, Box, Container, Divider, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import StaticFooter from "./StaticFooter";
import aninditaprofile from "assets/images/anindita-photo.jpg";

const Anindita = () => {
    return (
        <>
            <Container py={["24px", "80px"]} maxW="container.md">
                <Box border={'1px'} borderColor={'gray.100'} borderRadius={'15px'}>
                    <Stack direction={'row'} spacing={'0px'} p="4" alignItems={'center'}>
                        <Box>
                            <Avatar size='2xl' name='Anindita Sain Walia' src={aninditaprofile} />{' '}
                        </Box>
                        <Box pl={'20px'} flex={'1'}>
                            <Text fontSize={'20px'} fontWeight={'bold'} color={'purple'}>Anindita Sain Walia</Text>
                            <Text fontWeight={'bold'}>Project Director – CLAT</Text>
                            <Text>BA, MA (2nd rank holder)</Text>
                        </Box>
                    </Stack>
                    <Divider />
                    <Stack p="4" spacing={'4'}>
                    <Text fontWeight={'bold'}>Academic Experiences / Projects Undertaken:</Text>
                    <UnorderedList pl="16px">
                            <ListItem>Muslim Politics In West Bengal</ListItem>
                            <ListItem>United States of America and its relation with India since the Cold War era</ListItem>
                            <ListItem>Globalization and the Indian State</ListItem>
                            <ListItem>China-India Bilateral Trade Relations</ListItem>
                            <ListItem>World Trade Organization and the Environment </ListItem>
                            <ListItem>Represented University of Pune in the First South Asian Summer University 2005
                                Conducted by Friedrich Ebert Stiftung, Germany at New Delhi.</ListItem>
                        </UnorderedList>
                        <Text fontWeight={'bold'}>Academic Achievement:</Text>
                        <UnorderedList pl="16px">
                            <ListItem>Have passed the National Eligibility Test for Lectureships (Political Science)
                                Conducted by U.G.C. in June, 2006.</ListItem>
                            <ListItem>Ranked 2nd on the merit list at the M.A. Examinations conducted under the
                                University of Pune, 2003-2005.</ListItem>
                        </UnorderedList>
                        <Text fontWeight={'bold'}>HIGHLIGHTS OF EXPERIENCE</Text>
                        <UnorderedList pl="16px">
                            <ListItem>Have worked as a Lecturer (Political Science) with Deccan Education Society’s Law
                                College, Pune from 2005 to 2008</ListItem>
                            <ListItem>Have been conducting lectures on Indian Constitution, Indian Polity and Public
                                Administration for UPSC aspirants at Chanakya Mandal Pariwar, Pune since 2006.</ListItem>
                            <ListItem>Have been conducting lectures on Indian Constitution and Indian Polity for UPSC
                                aspirants at Jnana Prabhodini Competitive Examination Centre, Pune since 2006.</ListItem>
                            <ListItem>Have been a visiting faculty for UPSC aspirants at Competitive Examination Centre,
                                Savitri Phule Pune University, Pune since 2015.</ListItem>
                            <ListItem>Have been on the Interview Panel(Mock) for Civil Service Examination Interview
                                at various Institutes including Chanakya Mandal Pariwar, Jnana Prabhodini , CEC-Pune
                                University</ListItem>
                            <ListItem>Appointed as External Expert (Political Science) on the Board of Studies at the
                                School of Indian Civil Services, MITADT University , Pune.</ListItem>
                            <ListItem>Project Director – CLAT at Knowledge Park Trust, Pune</ListItem>
                        </UnorderedList>
                        
                        
                    </Stack>
                </Box>
            </Container>
            <StaticFooter />
        </>
    )
}

export default Anindita
