import {
  Flex,
  Box,
  IconButton,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Stack,
} from "@chakra-ui/react";
import { useAppSelector } from "app/hook";
import { FC, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/hooks";
import Modal from "components/UI/Modal";
import UpdateProgram from "./UpdateProgram";
import { GoKebabVertical } from "react-icons/go";
import useErrorToast from "hook/error-toast";
import { useMutation, useQueryClient } from "react-query";
import { deleteProgramById } from "API/programApi";
interface ProgramProps {
  program: IAllProgram;
}

const Program: FC<ProgramProps> = ({ program }) => {
  const {
    isOpen: isModalUpdateOpen,
    onOpen: onModalUpdateOpen,
    onClose: onModalUpdateClose,
  } = useDisclosure();
  const cache = useQueryClient();
  const { isLoading, mutateAsync, error } = useMutation(deleteProgramById, {
    onSuccess: () => {
      cache.invalidateQueries("programs");
    },
  });
  useErrorToast(error);

  const { role } = useAppSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);
  return (
    <>
      <Flex
        p="4"
        key={program._id}
        justify="space-between"
        align="center"
        borderBottom="1px"
        borderColor="gray.200"
        mt="0"
      >
        <Box>
          <Heading
            as="h5"
            size="sm"
            mb="2"
            color={"teal"}
            textTransform={"uppercase"}
          >
            {program.name}
          </Heading>

          <Stack direction={["column", "row"]} spacing="0px">
            <Box>
              <Text as="span">Subjects: </Text>{" "}
              {program.subjects.map((subject) => (
                <Text
                  key={subject._id}
                  as="span"
                  textTransform="capitalize"
                  pr="1"
                >
                  {subject.name},
                </Text>
              ))}
            </Box>
          </Stack>
        </Box>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<GoKebabVertical />}
            variant="ghost"
          />
          <MenuList>
            <MenuItem
              as={Link}
              to={`/${role}/dashboard/programs/${program._id}`}
            >
              Syllabus builder
            </MenuItem>
            <MenuItem
              as={Link}
              to={`/${role}/dashboard/programs/${program._id}/programdetails`}
            >
              Program Detalis
            </MenuItem>
            <MenuItem onClick={onModalUpdateOpen}>Edit</MenuItem>
            <MenuItem onClick={() => setIsOpen(true)}>Delete</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Program
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? All the data related to this program will be
              deleted.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={isLoading}
                onClick={async () => {
                  try {
                    await mutateAsync(program._id);
                  } catch (error) {}
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {
        <Modal
          modalSize="xl"
          onClose={onModalUpdateClose}
          open={isModalUpdateOpen}
        >
          <UpdateProgram onClose={onModalUpdateClose} program={program} />
        </Modal>
      }
    </>
  );
};
export default Program;
