import { Badge, Text, Flex, Stack } from "@chakra-ui/react";

import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { generateTest, getQuestionCountByLevel } from "API/testApi";
import useErrorToast from "hook/error-toast";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { useToast } from "@chakra-ui/toast";
import {
  generateTestValidation,
  testQuestionFetchValidation,
} from "validation/testValidation";
interface TestGeneratorProps {
  topicIds: string[];
  resetTopicsId: () => void;
}

const TestGenerator: FC<TestGeneratorProps> = ({ topicIds, resetTopicsId }) => {
  const toast = useToast();
  const cache = useQueryClient();
  const [count, setCount] = useState(null);

  const { error, mutateAsync, isLoading } = useMutation(
    getQuestionCountByLevel,
    {
      onSuccess: (data) => {
        setCount(data?.questionsCount);
      },
    }
  );

  useErrorToast(error);
  const {
    error: generateError,
    mutateAsync: genrateMutateAsync,
    isLoading: generateLoading,
  } = useMutation(generateTest, {
    onSuccess: () => {
      toast({
        title: "Test Generated",
        description: "Test Generated Successfully",
        status: "success",
      });
      cache.invalidateQueries("program");
      setCount(null);
      resetTopicsId();
    },
  });
  useErrorToast(generateError);

  return (
    <>
      <Formik
        validationSchema={
          count ? generateTestValidation : testQuestionFetchValidation
        }
        initialValues={{
          level: "",
          topicIds: topicIds,
          numberOfTest: 0,
          testNames: [],
          questionType: undefined,
        }}
        onSubmit={async (values) => {
          values.topicIds = topicIds;
          values.topicIds = topicIds;
          try {
            count
              ? await genrateMutateAsync({
                  topicsId: values.topicIds,
                  level: values.level,
                  numberOfTest: values.numberOfTest,
                  testNames: values.testNames,
                  questionType: values.questionType,
                })
              : await mutateAsync({
                  topicsId: values.topicIds,
                  level: values.level,
                  questionType: values.questionType,
                });
          } catch (error) {}
        }}
      >
        {({ values }) => (
          <Form>
            <Stack spacing="4">
              <Badge fontSize="lg" my="4">
                Test Generator
              </Badge>

              <Text>Select Topics For generating test</Text>

              <SelectControl
                name="level"
                isRequired
                onChange={() => {
                  setCount(null);
                }}
              >
                <option>Select Question Level</option>
                <option value="easy">Easy</option>
                <option value="normal">Normal</option>
                <option value="difficult">Difficult</option>
              </SelectControl>

              <SelectControl
                name="questionType"
                onChange={() => {
                  setCount(null);
                }}
              >
                <option value="">Select Question Type</option>
                <option value="mcqm">Muliple Choice (Multi)</option>
                <option value="mcqs">Multiple Choice </option>
                <option value="la">Long Question</option>
                <option value="tf">True False</option>
              </SelectControl>

              {!count && (
                <SubmitButton alignSelf="flex-end" isLoading={isLoading}>
                  Get Questions
                </SubmitButton>
              )}
              {count && (
                <Stack spacing={4}>
                  <Flex align="end">
                    <Text
                      fontSize="2xl"
                      flex="1"
                    >{`${count} Questions Available`}</Text>
                    <InputControl
                      label="Number of Test"
                      flex="1"
                      name="numberOfTest"
                      inputProps={{ type: "number " }}
                    />
                  </Flex>
                  {values.numberOfTest > 0 && (
                    <FieldArray
                      name="testNames"
                      render={() => (
                        <>
                          {new Array(parseInt(values.numberOfTest.toString()))
                            .fill(0)
                            .map((_, index) => (
                              <Flex key={index}>
                                <InputControl
                                  inputProps={{
                                    placeholder: `Enter ${index + 1} Test Name`,
                                  }}
                                  label={`Test ${index + 1}`}
                                  name={`testNames[${index}]`}
                                />
                                <ErrorMessage name={`testNames[${index}]`} />
                              </Flex>
                            ))}
                        </>
                      )}
                    />
                  )}
                  <SubmitButton
                    alignSelf="flex-end"
                    isLoading={generateLoading}
                  >
                    Generate Test
                  </SubmitButton>
                </Stack>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default TestGenerator;
