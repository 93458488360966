import { Badge, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import { FC } from "react";

import { useDisclosure } from "@chakra-ui/hooks";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

import { RoundedCard } from "chakra";
import Topics from "./Topics/Topics";
interface ChapterProps {
  chapter: IChapter;
  handleTopicClick: (topic: string, index: number) => void;
  index: number;
  topicIds: string[];
}

const Chapter: FC<ChapterProps> = ({
  chapter,
  handleTopicClick,
  index,
  topicIds,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const handleTopic = (topicId: string) => {
    handleTopicClick(topicId, index);
  };

  return (
    <>
      <RoundedCard
        bg={isOpen ? "gray.100" : "white"}
        transition="all 0.2s ease-in-out"
        _hover={{
          transform: isOpen ? "Scale(1)" : "Scale(1.025)",
          transition: "all 0.2s ease-in-out",
        }}
        mt="8"
        minH="16"
        display="flex"
        flexDirection="column"
      >
        <Stack>
          <Flex justify="center" key={chapter._id} onClick={onToggle}>
            <Stack width="100%">
              <Flex justify="space-between">
                <Text>
                  <Badge>Chapter </Badge> : {chapter?.name}
                </Text>
              </Flex>
            </Stack>
            {isOpen ? (
              <ChevronRightIcon color="teal.400" fontSize="2xl" />
            ) : (
              <ChevronDownIcon color="teal.400" fontSize="2xl" />
            )}
          </Flex>

          {isOpen && (
            <>
              <Divider />
              <Topics
                handleTopicClick={handleTopic}
                topics={chapter.topics}
                topicIds={topicIds}
              />
              {/* <TestGenerator topics={chapter.topics} /> */}
              {/* <Tests tests={chapter.tests} /> */}
            </>
          )}
        </Stack>
      </RoundedCard>
    </>
  );
};
export default Chapter;
