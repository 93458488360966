import {
  Flex,
  Box,
  Stack,
  Link,
  Heading,
  Text,
  Divider,
  Button,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Link as RoLink } from "react-router-dom";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "hook/auth-hooks";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useErrorToast from "hook/error-toast";
import { googleUserLogin, userLogin, sendEmailVerifyToken, verifyCaptcha } from "API/authApi";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import { Form, Formik } from "formik";
import { loginValidationSchema } from "validation";
import { FcGoogle } from "react-icons/fc";
import ResetPasswordLink from "../components/ResetPasswordLink";
import Modal from "components/UI/Modal";
import { useDisclosure } from "@chakra-ui/hooks";
import ReCAPTCHA from "react-google-recaptcha";
import EmailSent from "../components/EmailSent";
// import { useColorModeValue } from "@chakra-ui/color-mode";
import { useCookies } from "react-cookie";
import React from "react";
// import { axios } from "API/AxiosInstance";
// import { url } from "inspector";
const Login = () => {
  const [cookies, ,] = useCookies(["jwt"]);
  const jwt = cookies?.jwt;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const { login } = useAuth();
  const {
    isLoading: tokenLoading,
    error: tokenError,
    mutateAsync: tokenMutateAsync,
  } = useMutation(sendEmailVerifyToken, {
    onSuccess: () => {
      // history.push(`/email-sent/${email}`);

      onOpen();
    },
  });

  useErrorToast(tokenError);

  const { isLoading, error, mutateAsync, data } = useMutation(userLogin, {
    onError: async (e: any) => {
      if (e.message === "Please verify your email ID!") {
        try {
          if (email) {
            await tokenMutateAsync(email);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });
  const {
    isLoading: isLoadingGoogle,
    error: errorGoogle,
    mutateAsync: mutateAsyncGoogle,
    data: dataGoogle,
  } = useMutation(googleUserLogin);

  useErrorToast(error);
  useErrorToast(errorGoogle);

  useEffect(() => {
    if (jwt?.token && jwt?.role) {
      switch (jwt?.role) {
        case "school":
          history.replace("/school/dashboard");
          break;
        case "student":
          history.replace("/student/dashboard");
          break;
        case "staff":
          history.replace("/staff/dashboard");
          break;
        case "admin":
          history.replace("/admin/dashboard");
          break;
      }
    }
  }, [jwt, history]);
  useEffect(() => {
    const userRedirect = (data: any) => {
      if (data) {
        login(data.uid, data.token, data.role);
        switch (data.role) {
          case "school":
            history.replace("/school/dashboard");
            break;
          case "student":
            history.replace("/student/dashboard");
            break;
          case "staff":
            history.replace("/staff/dashboard");
            break;
          case "admin":
            history.replace("/admin/dashboard");
            break;
        }
      }
    };
    userRedirect(data);
    userRedirect(dataGoogle);
  }, [data, dataGoogle, history, login]);

  const onSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    try {
      await mutateAsyncGoogle(response);
    } catch (error) {
      console.log(error);
    }
  };
  const onFailure = (response: any) => {
    // console.log(response);
  };
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [isDisabled, setIsDisabled] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const onChange = async (value: any) => {
    setRecaptchaValue(value);
    try {
      const data = await verifyCaptcha({ recaptchaValue });
      data.message === "success" ? setIsDisabled(false) : setIsDisabled(true);

    } catch (error) {

    }
  }

  return (
    <>
      <Modal onClose={onClose} open={isOpen}>
        {email ? (
          <EmailSent email={email} password={password} />
        ) : (
          <ResetPasswordLink onClose={onClose} />
        )}
      </Modal>
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} w={"100%"} maxW={"500px"} px={[0, 6]}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} px={[5, 0]} textAlign="center">
              Sign in to your account
            </Heading>
            <Text fontSize={"lg"} color={"gray.600"}>
              Don't have an account yet?{" "}
              <Link color={"blue.400"} as={RoLink} to="/signup">
                Sign Up
              </Link>
            </Text>
          </Stack>
          <Formik
            validationSchema={loginValidationSchema}
            initialValues={{ email: "", password: "" }}
            onSubmit={async (values) => {

              setEmail(values.email);
              setPassword(values.password);
              await mutateAsync({
                email: values.email,
                password: values.password,
              });
              queryClient.invalidateQueries("user");
            }}
          >
            <Form>
              <Box
                shadow={[null, "base"]}
                borderWidth={[null, "1px"]}
                // borderColor={useColorModeValue("gray.200", "gray.500")}
                borderRadius={"xl"}
                px={[5, 8]}
                py={[8]}
              >
                <Stack spacing={4}>
                  <InputControl
                    label="Email address"
                    name="email"
                    inputProps={{ type: "email" }}
                  />
                  <InputGroup size="md">
                    <InputControl
                      inputProps={{ type: show ? "text" : "password" }}
                      label="Password"
                      name="password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        mt="4.0rem"
                        onClick={handleClick}
                      >
                        {show ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <Stack spacing={10}>
                    <Stack
                      direction={"column"}
                      align={"start"}
                      justify={"space-between"}
                    >
                      {/* <Checkbox>Remember me</Checkbox> */}
                      <Box
                        color={"blue.400"}
                        onClick={() => {
                          setEmail("");
                          onOpen();
                        }}
                      >
                        Forgot password?
                      </Box>
                      <Box>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY??""}
                          onChange={onChange}
                        />
                      </Box>
                    </Stack>
                    {/* Google recaptcha code here */}

                    <SubmitButton
                      isDisabled={isDisabled}
                      isLoading={tokenLoading || isLoading}
                      bg={"blue.400"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Sign in
                    </SubmitButton>
                    <Divider />

                    <GoogleLogin
                      clientId={process.env.REACT_APP_CLIENT_ID ?? ""}
                      buttonText="Sign in with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          colorScheme="red.800"
                          isLoading={isLoadingGoogle}
                          variant="outline"
                          leftIcon={<FcGoogle />}
                        >
                          Sign In with Google
                        </Button>
                      )}
                      cookiePolicy={"single_host_origin"}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Form>
          </Formik>
        </Stack>
      </Flex>
    </>
  );
};

export default Login;
