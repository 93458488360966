import {
  Heading,
  Box,
  Stack,
  VStack,
  HStack,
  Text,
  Switch,
  Input,
  Textarea,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { useState } from "react";
import { DeleteIcon } from "@chakra-ui/icons";

const CorrectionParameter = () => {
  const [isOn, setIsOn] = useState(true);
  setIsOn(true);
  const parameters = [
    {
      parameter: "Undestanding of the Question",
      options: ["Yes", "No"],
    },
    {
      parameter: "Justice to the Question",
      options: ["Yes", "No"],
    },
    {
      parameter: "Use of right Vocabulary",
      options: ["Yes", "No", "Need Improvement"],
    },
    {
      parameter: "Logically & Sequentially written",
      options: ["Yes", "No", "Need Improvement"],
    },
    {
      parameter: "Conclusion",
      options: ["Yes", "No", "Need Improvement"],
    },
  ];
  const [correctionParameter, setCorrectionParameter] = useState(parameters);
  setCorrectionParameter(parameters);
  //   const [tests, setTests] = useState<ITestShowCase[]>([]);
  //   const { subjectId } = useAppSelector((state) => state.programState);
  //   const dispatch = useAppDispatch();
  //   const [searchTest, setSearchTest] = useState("");
  //   const bgClr = useColorModeValue("red.50", "gray.700");

  //   const { id } = useParams<{ id: string }>();

  //   const {
  //     data: activeProgram,
  //     isLoading,
  //     error,
  //   } = useQuery<IActiveProgram>(["activeProgram", id, subjectId], () =>
  //     getActiveProgram(id, subjectId)
  //   );

  //   useEffect(() => {
  //console.log(activeProgram);
  //     if (activeProgram && subjectId) {
  //       const testIds = activeProgram?.tests.map((t) => t?.test?._id);
  //       const testNotTaken = activeProgram?.program?.tests.filter((test) => {
  //         return subjectId === test.subject.toString() &&
  //           testIds?.includes(test._id)
  //           ? false
  //           : true;
  //       });
  //       const testTaken = activeProgram?.tests
  //         .filter((t) =>
  //           t?.test?.subject.toString() === subjectId ? true : false
  //         )
  //         .map((t) => {
  //           return {
  //             ...t?.test,
  //             attempts: t.testAttempts.length,
  //             userTestId: t._id,
  //           };
  //         });
  //       setTests([...testNotTaken, ...testTaken]);
  //     }
  //   }, [activeProgram, subjectId]);

  //   useErrorToast(error);
  //   const borderClr = useColorModeValue("gray.100", "gray.700");

  return (
    <>
      {/* {( false) ? (
          <Grid placeItems="center" h="100vh">
            <Spinner />
          </Grid>
        ) : (
          <></>
        )} */}
      <Heading size="md">May 08, 2023 07:44 PM, LA test</Heading>
      <Text color="gray.500">
        Test Level: normal | Subject: Logical Reasoning
      </Text>
      <VStack mt={"25px"} border={"1px solid gray"} borderRadius={"10px"}>
        <HStack
          w={"100%"}
          justifyContent={"space-between"}
          px={"15px"}
          py={"15px"}
          borderBottom={"1px solid gray"}
        >
          <HStack>
            <Text>Questions attempted: 26 |</Text>
            <Text>Not attempted: 0 |</Text>
            <Text>Time Taken: 75s</Text>
          </HStack>
          <VStack align={"start"}>
            <HStack>
              <Switch isChecked={isOn} />
              <Text color={"red.200"} fontWeight={"bold"}>
                In review
              </Text>
            </HStack>
            <Text as="b">Reviewed By: teacher for rcshool</Text>
          </VStack>
        </HStack>
        <VStack w={"100%"} px={"15px"} align="start">
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text as="b">1/26: Ques madsaoludfan mnasifhw </Text>
            <Text>1 marks</Text>
          </HStack>
          <Text as={"b"}>Your answer: Ques madsaoludfan mnasifhw </Text>
          <form style={{ width: "100%" }}>
            <HStack w={"100%"} justifyContent={"space-between"}>
              <VStack align="start" w={"60%"}>
                <Text as="b">Correction Parameter</Text>
                <Box w={"100%"}>
                  {correctionParameter.map((item, id) => (
                    <HStack key={id} justifyContent={"space-between"}>
                      <Text>{item.parameter}</Text>
                      <RadioGroup w={"50%"}>
                        <Stack direction="row">
                          {item.options.map((option) => (
                            <Radio name={id.toString()} value={option}>
                              {option}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    </HStack>
                  ))}
                </Box>
              </VStack>
              <VStack
                w={"40%"}
                display={"flex"}
                align={"start"}
                alignItems={"flex-start "}
              >
                <Text as={"b"}>Remark</Text>
                <Textarea
                  style={{ border: "1px solid black", minHeight: "100px" }}
                ></Textarea>
              </VStack>
            </HStack>

            <HStack my={"20px"} justifyContent={"space-between"}>
              <VStack w={"60%"} align={"start"}>
                <Text as={"b"} color={"teal"}>
                  Add more parameter
                </Text>
                <HStack w={"100%"}>
                  <Input type="text" />
                  <RadioGroup w={"100%"}>
                    <Stack direction="row">
                      <Radio name={"additional"} value={"Yes"}>
                        {"Yes"}
                      </Radio>
                      <Radio name={"additional"} value={"No"}>
                        {"No"}
                      </Radio>
                      <Radio name={"additional"} value={"Need Improvement"}>
                        {"Need Improvement"}
                      </Radio>
                      <DeleteIcon color={"red"} fontSize={"20px"} />
                    </Stack>
                  </RadioGroup>
                </HStack>
              </VStack>
              <VStack w={"40%"} align={"start"}>
                <Text as={"b"}>Marks for answer</Text>
                <Input variant="filled" />
              </VStack>
            </HStack>
          </form>
        </VStack>
      </VStack>
    </>
  );
};
export default CorrectionParameter;
