import { Select } from "@chakra-ui/select";
import { useAppDispatch, useAppSelector } from "app/hook";
import { FC } from "react";

import {
  setLevel,
  setPageDefault,
} from "app/features/questionFilter/questionFilterSlice";
interface LevelSelectProps {}

const LevelSelect: FC<LevelSelectProps> = () => {
  const { level } = useAppSelector((state) => state.filterQuestion);
  const dispatch = useAppDispatch();
  return (
    <Select
      placeholder="Levels"
      size="sm"
      value={level ?? ""}
      rounded="md"
      display={{ base: "none", md: "block", lg: "block" }}
      textTransform="capitalize"
      onChange={(e) => {
        dispatch(setLevel(e.target.value));
        dispatch(setPageDefault());
      }}
    >
      <option value="easy">Easy</option>
      <option value="normal">Normal</option>
      <option value="difficult">Difficult</option>
    </Select>
  );
};
export default LevelSelect;
