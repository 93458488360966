import { Box, Divider, HStack, Stack, Text } from "@chakra-ui/react";
import { addProduct, getProductById, updateProduct } from "API/productApi";
import { getPrograms } from "API/programApi";
import { Form, Formik } from "formik";
import {
  CheckboxControl,
  InputControl,
  SelectControl,
  SubmitButton,
  TextareaControl,
  NumberInputControl,
} from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
interface IAddEditProductProps {
  isUpdate: boolean;
  productId?: string;
  onClose: () => void;
}

const AddEditProduct: FC<IAddEditProductProps> = ({
  isUpdate,
  productId,
  onClose,
}) => {
  const cache = useQueryClient();
  const { data: programs } = useQuery<IAllProgram[]>("programs", getPrograms);
  const { data: product } = useQuery<IProduct>(
    ["product", productId],
    (key) => getProductById(productId ?? ""),
    {
      enabled: !!productId,
    }
  );
  const mutateAddProduct = useMutation(addProduct, {
    onSuccess: () => {
      cache.invalidateQueries("products");
      onClose();
    },
  });
  const mutateEditProduct = useMutation(updateProduct, {
    onSuccess: () => {
      cache.invalidateQueries("products");
      cache.invalidateQueries(["product", productId]);
      onClose();
    },
  });
  useErrorToast(mutateAddProduct.error);
  useErrorToast(mutateEditProduct.error);

  return (
    <>
      <Formik
        enableReinitialize={product ? true : false}
        initialValues={
          isUpdate
            ? {
                name: product?.name ?? "",
                description: product?.description ?? "",
                price: product?.price ?? 0,
                minQ: product?.minQ ?? 0,
                duration: product?.duration ?? 0,
                access: product?.access ?? [],
                programId: product?.program?._id ?? "",
                type: product?.type ?? "",
              }
            : {
                name: "",
                description: "",
                price: 0,
                minQ: 1,
                duration: 1,
                access: [],
                programId: "",
                type: "",
              }
        }
        onSubmit={(values) => {
          isUpdate
            ? mutateEditProduct.mutate({
                ...values,
                programId: values.programId,
                productId: productId ?? "",
              })
            : mutateAddProduct.mutate({
                ...values,
                programId: values.programId,
              });
        }}
      >
        {({ values }) => (
          <Form>
            <Stack spacing="24px">
              <InputControl isRequired name="name" label=" Product Name" />
              <NumberInputControl
                isRequired
                name="price"
                label="Product price per user (Rs)"
              />
              <NumberInputControl
                isRequired
                name="duration"
                label="Duration in months"
              />
              <Stack>
                <Text>
                  <strong>Permissions</strong>
                </Text>
                <HStack flexWrap={"wrap"} gap={"4"}>
                  <CheckboxControl
                    label="Coaching"
                    value={"coaching"}
                    name="access"
                  />
                  <Box mx={2} />
                  <CheckboxControl
                    label="Test Series"
                    value={"test"}
                    name="access"
                  />{" "}
                  <Box mx={2} />
                  <CheckboxControl
                    label="Performance Report"
                    value={"report"}
                    name="access"
                  />
                  <Box mx={2} />
                  <CheckboxControl
                    label="Personal Interview"
                    value={"interview"}
                    name="access"
                  />
                  <Box mx={2} />
                  <CheckboxControl
                    label="Speaking Skill"
                    value={"speakingSkill"}
                    name="access"
                  />
                  <Box mx={2} />
                  <CheckboxControl
                    label="Writing Skill"
                    value={"writingSkill"}
                    name="access"
                  />
                </HStack>
              </Stack>

              {/* Make program field Required if permisson is Coaching or Test series or both */}
              <SelectControl
                isRequired
                label="Select a program"
                name="programId"
                selectProps={{ placeholder: "Select any" }}
              >
                {programs?.map((program) => (
                  <option key={program._id} value={program._id}>
                    {program.name}
                  </option>
                ))}
              </SelectControl>
              <SelectControl
                label="Product Type"
                name="type"
                selectProps={{ placeholder: "Select Product Type" }}
              >
                <option value="school">School</option>
                <option value="student">Student</option>
              </SelectControl>
              {values.type === "school" && (
                <InputControl
                  inputProps={{ type: "number" }}
                  name="minQ"
                  label=" Minimum licenses has to purchase (qty)"
                />
              )}

              <TextareaControl name="description" label="Description" />
            </Stack>
            <Box py={"4"}>
              <Divider />
            </Box>
            <SubmitButton
              isLoading={
                mutateAddProduct.isLoading || mutateEditProduct.isLoading
              }
              colorScheme="teal"
            >
              Submit
            </SubmitButton>
          </Form>
        )}
        {/* <DrawerFooter borderTopWidth="1px">
            <SubmitButton
              isLoading={
                mutateAddProduct.isLoading || mutateEditProduct.isLoading
              }
              colorScheme="teal"
            >
              Submit
            </SubmitButton>
          </DrawerFooter> */}
      </Formik>
    </>
  );
};

export default AddEditProduct;
