import React from "react";
import EmailSent from "../components/EmailSent";
import { useParams, useLocation } from "react-router-dom";

const EmailSentPage = () => {
  const { email } = useParams<{ email: string }>();
  const location = useLocation();
  const { password } = location.state as { password: string };
  return (
    <div>
      <EmailSent email={email} password={password} />
    </div>
  );
};

export default EmailSentPage;
