import { Box, Stack } from "@chakra-ui/layout";
import { Button, Heading } from "@chakra-ui/react";
import { getStaffById } from "API/staffApi";
import { getStudents } from "API/studentApi";
import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
} from "app/features/userFilter/userFilter";
import { useAppDispatch, useAppSelector } from "app/hook";
import TablePaginator from "components/dumb/Table/TablePaginator";
import TableSearch from "components/dumb/Table/TableSearch";
import useErrorToast from "hook/error-toast";
import { FC, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import StudentTable from "./StudentTable";

interface StudentsProps {
  schoolId: string;
  editRoute: string;
  addRoute: string;
  showAddButton?: boolean;
}

const Students: FC<StudentsProps> = ({
  schoolId,
  editRoute,
  addRoute,
  showAddButton = true,
}) => {
  const TABLE_HEAD = [
    { id: "Student", label: "Student", alignRight: false },
    { id: "School", label: "School Name", alignRight: false },
    {
      id: "actions",
      label: "Actions",
      alignRight: false,
    },
  ];

  const { role, user } = useAppSelector((state) => state.auth);
  const userId = user?._id;

  const { data: staffData } = useQuery<IStaff>(
    ["staff", user?._id],
    () => getStaffById(user?._id),
    {
      enabled: !!userId && role === "staff",
    }
  );

  const { size, page, searchText } = useAppSelector(
    (state) => state.userFilter
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setPageDefault());
  }, [dispatch]);

  // const dispatch = useAppDispatch();
  const { data, isLoading, error } = useQuery<IStudentFilters>(
    ["students", page, size, searchText],
    () =>
      getStudents({
        page,
        size,
        searchText,
        schoolId,
      }),
    {}
    // { enabled: schoolId ? true : false }
  );

  useErrorToast(error);
  // console.log(data);

  return (
    <>
      <Heading fontSize="2xl">Manage Students</Heading>

      {staffData && !staffData.school.isDefaultSchool ? (
        <Box>Sorry, you do not have permission to access this page.</Box>
      ) : (
        data && (
          <>
            <Stack
              w="100%"
              direction={["column", "row"]}
              p="15px"
              justify="space-between"
            >
              <TableSearch
                searchText={searchText}
                setPageDefault={setPageDefault}
                setSearchText={setSearchText}
              />
              <Box flex="0">
                {showAddButton && (
                  <Button
                    rightIcon={<GoPlus />}
                    colorScheme="teal"
                    variant="solid"
                    size="sm"
                    as={Link}
                    to={addRoute}
                  >
                    Add Student
                  </Button>
                )}
              </Box>
            </Stack>
            <StudentTable
              editRoute={editRoute}
              isLoading={isLoading}
              items={data?.students}
              tableHeader={TABLE_HEAD}
              disableUpdate={false}
            />
            <TablePaginator
              count={data?.count}
              decrementPage={decrementPage}
              incrementPage={incrementPage}
              page={data?.page}
              setPageDefault={setPageDefault}
              setSize={setSize}
              size={size}
              totalPages={data?.totalPage}
            />
          </>
        )
      )}
    </>
  );
};
export default Students;
