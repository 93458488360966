import { Select } from "@chakra-ui/select";
import { FC } from "react";

import { useAppDispatch, useAppSelector } from "app/hook";
import {
  setPageDefault,
  setQuestionType,
} from "app/features/questionFilter/questionFilterSlice";
interface QuestionTypeSelectProps {}

const QuestionTypeSelect: FC<QuestionTypeSelectProps> = () => {
  const { questionType } = useAppSelector((state) => state.filterQuestion);
  const dispatch = useAppDispatch();
  return (
    <Select
      placeholder="Question Types"
      size="sm"
      rounded="md"
      value={questionType ?? ""}
      display={{ base: "none", md: "block", lg: "block" }}
      textTransform="capitalize"
      onChange={(e) => {
        dispatch(setQuestionType(e.target.value));
        dispatch(setPageDefault());
      }}
    >
      <option value="TF">TF</option>
      <option value="MCQS">MCQS</option>
      <option value="MCQM">MCQM</option>
      <option value="LA">LA</option>
    </Select>
  );
};
export default QuestionTypeSelect;
