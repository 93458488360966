import { useState } from "react";
import {
  Heading,
  Box,
  Button,
  useColorModeValue,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { GoPlus } from "react-icons/go";
import { Link as RoLink } from "react-router-dom";
import { filterQuestion } from "API/questionApi";
import { useQuery } from "react-query";
import useErrorToast from "hook/error-toast";
import AdminQuestionTable from "./QuestionTable/QuestionTable";
import SubjectSelect from "./SubjectSelect";
import LevelSelect from "./LevelSelect";
import QuestionTypeSelect from "./QuestionTypeSelect";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
  setSubject,
} from "app/features/questionFilter/questionFilterSlice";
import TableSearch from "components/dumb/Table/TableSearch";
import TablePaginator from "components/dumb/Table/TablePaginator";

const Questions = () => {
  const [resetCheckBox, setResetCheckBox] = useState(false);
  const { role } = useAppSelector((state) => state.auth);
  const { level, size, questionType, page, subject, searchText } =
    useAppSelector((state) => state.filterQuestion);
  const dispatch = useAppDispatch();

  const { data, isLoading, error } = useQuery(
    ["questions", page, size, level, questionType, subject, searchText],
    () => {
      setResetCheckBox(true);
      return filterQuestion({
        page,
        size,
        questionType,
        level,
        subject,
        searchText,
      });
    },
    { onSettled: () => setResetCheckBox(false), keepPreviousData: true }
  );

  useErrorToast(error);

  return (
    <>
      <Heading size="md">All questions</Heading>

      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderTopRadius="md"
        pos="relative"
      >
        <Stack w="100%" direction={["column", "row"]} p="15px">
          <Box w={'30%'}>
          <TableSearch
            searchText={searchText}
            setPageDefault={setPageDefault}
            setSearchText={setSearchText}
          />
          </Box>
          <Box flex="1" textAlign="right">
            <HStack>
              <QuestionTypeSelect />
              <LevelSelect />
              <SubjectSelect
                subject={subject}
                selectOutput={(value) => {
                  dispatch(setSubject(value));
                  dispatch(setPageDefault());
                }}
              />
              <Box flex="0">
                <Button
                  rightIcon={<GoPlus />}
                  colorScheme="teal"
                  variant="solid"
                  size="sm"
                  as={RoLink}
                  to={`/${role}/dashboard/import-questions`}
                >
                  Add question
                </Button>
              </Box>
            </HStack>
          </Box>
        </Stack>
        <AdminQuestionTable
          resetCheckBox={resetCheckBox}
          items={data?.questions}
          isLoading={isLoading}
          disableUpdate={false}
        />
        <TablePaginator
          count={data?.count}
          decrementPage={decrementPage}
          incrementPage={incrementPage}
          page={data?.page}
          setPageDefault={setPageDefault}
          setSize={setSize}
          size={size}
          totalPages={data?.totalPage}
        />
      </Box>
    </>
  );
};

export default Questions;
