import { Input } from "@chakra-ui/input";
import { Box, Wrap, WrapItem } from "@chakra-ui/layout";
import { Tag, TagLabel, TagCloseButton } from "@chakra-ui/tag";
import { FC, FormEvent } from "react";

interface TagInputProps {
  tags: string[];
  setTags: (tags: string[]) => void;
}

const TagInput: FC<TagInputProps> = ({ tags, setTags }) => {
  const addTags = (event: FormEvent<EventTarget>): void => {
    let target = event.target as HTMLInputElement;
    let value = target.value;
    if (value !== "") {
      setTags([...tags, value]);
      target.value = "";
    }
  };

  const removeTag = (value: string): void => {
    if (value !== "") {
      setTags(tags.filter((tag) => tag !== value));
    }
  };
  return (
    <>
      <Box width="100%" border="1px solid #ccc" p="2" py="6" borderRadius="xl">
        <Input
          border="none"
          _focus={{ outline: "transparent" }}
          onKeyUp={(event) => (event.key === " " ? addTags(event) : null)}
          placeholder="Enter Tag Name"
        />

        <Wrap spacing={2} w="100%">
          {tags.map((tag) => (
            <WrapItem key={tag}>
              <Tag size="sm" borderRadius="full" variant="solid">
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton onClick={() => removeTag(tag)} />
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box>
    </>
  );
};
export default TagInput;
