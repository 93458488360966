import { Avatar, Box, Container, Divider, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import StaticFooter from "./StaticFooter";
import sohailprofile from "assets/images/sohail-shaikh-photo.jpg";

const SohailShaikh = () => {
    return (
        <>
            <Container py={["24px", "80px"]} maxW="container.md">
                <Box border={'1px'} borderColor={'gray.100'} borderRadius={'15px'}>
                    <Stack direction={'row'} spacing={'0px'} p="4" alignItems={'center'}>
                        <Box>
                            <Avatar size='2xl' name='Sohail Shaikh' src={sohailprofile} />{' '}
                        </Box>
                        <Box pl={'20px'} flex={'1'}>
                            <Text fontSize={'20px'} fontWeight={'bold'} color={'purple'}>Sohail Shaikh</Text>
                            <Text fontWeight={'bold'}>Director, CEO</Text>
                            <Text>B.Com. MBA, DLL, LL.B -II</Text>
                        </Box>
                    </Stack>
                    <Divider />
                    <Stack p="4" spacing={'4'}>
                        <Text fontWeight={'bold'}>HIGHLIGHTS OF QUALIFICATIONS</Text>
                        <UnorderedList pl="16px">
                            <ListItem>24 years of Corporate Experience in the field of management. Highest title achieved Vice President - 3 countries.</ListItem>
                            <ListItem>7 years of experience in Academics teaching management graduates in Pune University &amp; other Mgt. Institutes</ListItem>
                            <ListItem>Responsible for business expansion in new countries</ListItem>
                            <ListItem>Diversified the company into IT collaborating with E-Lock Technology (USA), NIIT &amp; Antra Web – India.</ListItem>
                            <ListItem>Worldwide registration of Brand &amp; Logo for Marina Gulf.</ListItem>
                            <ListItem>Setting up third party marketing agency at PAN India level</ListItem>
                            <ListItem>Certified auditor for auditing ISO processes and also a management representative.</ListItem>
                            <ListItem>Designed and executed training program for senior Japanese executive of NTT-Com., &amp; Toshiba</ListItem>
                            <ListItem>Business visit to USA, Canada, UK, Hong Kong, China, Taiwan, Thailand, Indonesia, Singapore, Jordan, UAE,
                                Bahrain, Kuwait, Saudi, Oman, Qatar.</ListItem>
                        </UnorderedList>
                        <Text fontWeight={'bold'}>International Assignments</Text>
                        <Text>
                            Worked in Canada, CEMTURA Ltd., Responsible for inbound and outbound logistics.<br />
                            Worked for different Retail companies from UAE, KSA and Bahrain
                        </Text>
                        <Text fontWeight={'bold'}>In India</Text>
                        <Text>
                            Sandoz (I) India, Bharat Bijlee India Limited – India, Asian Paints India limited, Metal Box (I) Ltd.
                        </Text>
                        <Text fontWeight={'bold'}>Current Assignments:</Text>
                        <Text>
                            Key Speaker on Management Topic in different Seminars<br />
                            Executive Director with Meezan Management Consultant (Business Consultancy &amp; training)<br />
                            Ex. Member of Academic advisory Board of VIT – Management Institute<br />
                            President - “Concern for People Charitable Trust”<br />
                            Visiting Faculty at Pune University (PUMBA) and other Management Institute
                        </Text>
                    </Stack>
                </Box>
            </Container>
            <StaticFooter />
        </>
    )
}

export default SohailShaikh
