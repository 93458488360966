import { axios } from "./AxiosInstance";
export const getSubjects = async () => {
  try {
    const { data } = await axios.get(`/subject/`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const addSubject = async (name: string) => {
  try {
    const { data } = await axios.post(`/subject/add`, { name });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateSubject = async ({
  name,
  id,
}: {
  name: string;
  id: string;
}) => {
  try {
    const { data } = await axios.patch(`/subject/update`, { name, id });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const deleteSubject = async (id: string) => {
  try {
    const { data } = await axios.delete(`/subject/remove/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
