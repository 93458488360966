import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/react";
import { validatePayment } from "API/paymentApi";
// import { initializePayment, validatePayment } from "API/paymentApi";
import { useHistory } from "react-router";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "app/hook";
import loadRazorPay from "pages/buyProducts/rzrPayScriptLoad";
interface PaymentButtonProps {
  orderId: string;
}

const PaymentButton: FC<PaymentButtonProps> = ({ orderId }) => {
  const { user } = useAppSelector(
    (state) => state.auth
  );
  const [, setLoaded] = useState(false);
  const histroy = useHistory();

  const toast = useToast();

  useEffect(() => {
    loadRazorPay(() => {
      setLoaded(true);
    });
  });

  const showRazorpay = async () => {
    try {
      let options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        order_id: orderId,
        // amount: amount * 100,
        currency: "INR",
        name: user.name,
        contact: `${user.mobile}` || "0000000000",

        // image: "",
        handler: async (response: any) => {
          console.log(response);

          try {
            const validate = await validatePayment({
              razorpay_payment_id: response.razorpay_payment_id,
            });
            console.log(validate);

            toast({
              title: "Payment Successful",
              description: "Your payment has been successful",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            histroy.push(`/${user.role}/dashboard`);
            window.location.reload()
          } catch (error) {
            toast({
              status: "error",
              title: "Something went Wrong",
            });
          }
        },

        // handlePaymentSuccess(response: any) {
        //   console.log(response);
        //   toast({
        //     title: "Payment Successful!",
        //     status: "success",
        //   });

        //   // dispatch(actionTypes.SET_ROLE,value: validate.userData.role);

        //   histroy.push("/");
        // },
        prefill: {
          name: user.name,
          email: user.email,
          contact: `+91 ${user.mobile}` || "",
        },
        // notes: {
        //   address: subscription.order.student.address,
        // },
        theme: {
          color: "#0E93A4",
        },
      };

      let rzp1 = new (window as any).Razorpay(options);

      rzp1.open();

      rzp1.on("payment.failed", function (response: any) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
    } catch (error: any) {
      toast({
        title: error.message,
        status: "error",
      });
    }
  };

  return (
    <Button w="full" colorScheme="red" onClick={showRazorpay}>
      Complete Payment
    </Button>
  );
};

export default PaymentButton;
