import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
interface BatchFilterState {
    page: number;
    size: number;
    searchText: string;
    programId: string | undefined;
    subjectId: string | undefined;
}

// Define the initial state using that type
const initialState: BatchFilterState = {
    page: 1,
    size: 10,
    programId: undefined,
    subjectId: undefined,
    searchText: "",
};

export const batchFilter = createSlice({
    name: "batchFilter",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setPageDefault: (state) => {
            state.page = initialState.page;
        },
        incrementPage: (state) => {
            if (state.page >= 1) {
                state.page++;
            }
        },
        decrementPage: (state) => {
            if (state.page > 1) {
                state.page--;
            }
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },

        setProgramId: (state, action: PayloadAction<string>) => {
            state.programId = action.payload;
        },
        setSubjectId: (state, action: PayloadAction<string>) => {
            state.subjectId = action.payload;
        },
        filterDefaultState: (state) => {
            state.page = 1;
            state.size = 10;
            state.programId = undefined;
            state.subjectId = undefined;
            state.searchText = "";
        },
    },
});

export const {
    filterDefaultState,
    setPageDefault,
    incrementPage,
    decrementPage,
    setProgramId,
    setSize,
    setSearchText,
    setSubjectId,
} = batchFilter.actions;

export default batchFilter.reducer;
