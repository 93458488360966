import { axios } from "./AxiosInstance";


interface IUserLogin {
  email: string;
  password: string;
}

interface IUserRegister {
  name: string;
  role: string;
  email: string;
  password: string;
  mobile: number;
}
export const userLogin = async ({ email, password }: IUserLogin) => {
  try {
    const { data } = await axios.post(`/signin`, {
      email,
      password,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const googleUserLogin = async (response: any) => {
  try {
    const { tokenId } = response;

    const { data } = await axios.post("google-signin", { tokenId });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const fetchUser = async ({
  uid,
  role,
}: {
  uid: string;
  role: RoleType;
}) => {
  try {
    const { data } = await axios.get(`/${role}/${uid}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const verifyCaptcha = async ({
  recaptchaValue
}: {
  recaptchaValue: string;
}) => {
  const SECRET_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY;
  try {
    const { data } = await axios.post('/admin/verify-captcha',{recaptchaValue,SECRET_KEY});
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const userSignUp = async ({
  name,
  role,
  email,
  password,
  mobile,
}: IUserRegister) => {
  try {
    const { data } = await axios.post(`/${role}/signup`, {
      email,
      name,
      password,
      mobile,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const resetPasswordLink = async (email: string) => {
  try {
    const { data } = await axios.post(`/password-reset-link`, { email });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const checkResetToken = async (token: string) => {
  try {
    console.log(token);

    const { data } = await axios.get(`/checkResetToken/${token}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updatePassword = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => {
  try {
    const { data } = await axios.post(`/updatePassword/${token}`, { password });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const sendEmailVerifyToken = async (email: string) => {
  try {
    const { data } = await axios.post(`/send-email-verify-token`, { email });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const emailVerify = async (token: string) => {
  try {
    const { data } = await axios.get(`/email-verify/${token}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateProfile = async ({
  file,
  name,
  mobile,
  email,
  address,
  city,
  state,
  pincode,
  password,
  uid,
  gender,
  dateOfBirth
}: {
  file?: File;
  name?: string;
  mobile?: number;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  pincode?: number;
  password?: string;
  uid?: string;
  gender?: string;
  dateOfBirth?: string;
}) => {
  try {

    const formData = new FormData();
    if (dateOfBirth) formData.append("dateOfBirth", dateOfBirth);
    if (uid) formData.append("uid", uid);
    if (gender) formData.append("gender", gender);
    if (password) formData.append("password", password);
    if (file) formData.append("file", file);
    if (name) formData.append("name", name);
    if (mobile) formData.append("mobile", String(mobile));
    if (email) formData.append("email", email);
    if (address) formData.append("address", address);
    if (city) formData.append("city", city);
    if (state) formData.append("state", state);
    if (pincode) formData.append("pincode", String(pincode));

    const { data } = await axios.patch(`/profile`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};