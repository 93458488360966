import {
  Link,
  Heading,
  Box,
  VStack,
  HStack,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react";
import { Link as RoLink } from "react-router-dom";

const StudentCorrectionParameter = () => {
  //   const [tests, setTests] = useState<ITestShowCase[]>([]);
  //   const { subjectId } = useAppSelector((state) => state.programState);
  //   const dispatch = useAppDispatch();
  //   const [searchTest, setSearchTest] = useState("");
  //   const bgClr = useColorModeValue("red.50", "gray.700");

  //   const { id } = useParams<{ id: string }>();

  //   const {
  //     data: activeProgram,
  //     isLoading,
  //     error,
  //   } = useQuery<IActiveProgram>(["activeProgram", id, subjectId], () =>
  //     getActiveProgram(id, subjectId)
  //   );

  //   useEffect(() => {
  //console.log(activeProgram);
  //     if (activeProgram && subjectId) {
  //       const testIds = activeProgram?.tests.map((t) => t?.test?._id);
  //       const testNotTaken = activeProgram?.program?.tests.filter((test) => {
  //         return subjectId === test.subject.toString() &&
  //           testIds?.includes(test._id)
  //           ? false
  //           : true;
  //       });
  //       const testTaken = activeProgram?.tests
  //         .filter((t) =>
  //           t?.test?.subject.toString() === subjectId ? true : false
  //         )
  //         .map((t) => {
  //           return {
  //             ...t?.test,
  //             attempts: t.testAttempts.length,
  //             userTestId: t._id,
  //           };
  //         });
  //       setTests([...testNotTaken, ...testTaken]);
  //     }
  //   }, [activeProgram, subjectId]);

  //   useErrorToast(error);
  //   const borderClr = useColorModeValue("gray.100", "gray.700");

  return (
    <>
      {/* {( false) ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <></>
      )} */}
      <Heading size="md">
        <Link as={RoLink} to="/student/dashboard" color="teal.500">
          Back
        </Link>
        / LA test
      </Heading>
      <Text color="gray.500">
        Test Level: normal | Subject: Logical Reasoning
      </Text>
      <VStack mt={"25px"} border={"1px solid gray"}>
        <HStack
          w={"100%"}
          bg="green.200"
          justifyContent={"space-between"}
          px={"15px"}
          py={"5px"}
        >
          <HStack>
            <Text>Questions attempted: 26 </Text>
            <Text as="span" mx={2} fontSize="30px" color="red.500">
              &bull;
            </Text>
            <Text>Not attempted: 0</Text>
            <Text as="span" mx={2} fontSize="30px" color="red.500">
              &bull;
            </Text>
            <Text>Correct answers: 23</Text>
            <Text as="span" mx={2} fontSize="30px" color="red.500">
              &bull;
            </Text>
            <Text>Wrong answers: 3</Text>
            <Text as="span" mx={2} fontSize="30px" color="red.500">
              &bull;
            </Text>
            <Text>Time Taken: 75s</Text>
          </HStack>
          <Text as="b">You got total 23 marks</Text>
        </HStack>
        <VStack w={"100%"} px={"15px"} align="start">
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text as="b">1/26: Ques madsaoludfan mnasifhw </Text>
            <Text>1 marks</Text>
          </HStack>
          <Text>Ques madsaoludfan mnasifhw </Text>
          <VStack align="start">
            <Text as="b">Correction Parameter</Text>
            <Box>
              <OrderedList>
                <ListItem>
                  Lorem ipsum dolor sit amet: <Text as="b">No</Text>
                </ListItem>
                <ListItem>
                  Consectetur adipiscing elit: <Text as="b">Yes</Text>
                </ListItem>
                <ListItem>
                  Integer molestie lorem at massa:{" "}
                  <Text as="b">Need Improvement</Text>
                </ListItem>
                <ListItem>
                  Facilisis in pretium nisl aliquet: <Text as="b">Good</Text>
                </ListItem>
              </OrderedList>
            </Box>
          </VStack>
          <VStack
            align="start"
            w="75%"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Text as="b">Remark:</Text>
            <Text style={{ marginTop: "0" }}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Adipisci
              omnis, fugiat perferendis quibusdam nobis laboriosam, provident ut
              maiores neque earum, odio tempora nostrum qui! Aliquid!
            </Text>
          </VStack>
        </VStack>
      </VStack>
    </>
  );
};
export default StudentCorrectionParameter;
