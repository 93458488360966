import { axios } from "./AxiosInstance";

interface IFilterQuestion {
    page?: number;
    size?: number;
    subjectId?: string;
    programId?: string;
    searchText?: string;
}

export const filterBatch = async ({
    page,
    size,
    subjectId,
    programId,
    searchText,
}: IFilterQuestion) => {
    try {
        const { data } = await axios.post(`/batches/filter`, {
            page,
            size,
            programId,
            subjectId,
            searchText,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};


export const getBatchById = async (id: string) => {
    try {
        const { data } = await axios.post(`/batches/get`, {
            batchId: id
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const deleteManyBatches = async (ids: string[]) => {
    try {
        const { data } = await axios.post(`/batches/deleteMany`, {
            ids
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};


export const updateBatch = async ({
    endDate,
    startDate,
    programId,
    name,
    batchId,
    subjectId,
    leadInstructor
}: {
    endDate: Date | string;
    startDate: Date | string;
    programId: string;
    name: string;
    batchId: string;
    subjectId: string;
    leadInstructor: string
}) => {
    try {
        const { data } = await axios.patch(`/batches/update`, {
            endDate,
            startDate,
            programId,
            name,
            batchId,
            subjectId,
            leadInstructor
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const addBatch = async ({
    endDate,
    startDate,
    programId,
    name,
    subjectId,
    leadInstructor
}: {
    endDate: Date | string;
    startDate: Date | string;
    programId: string;
    name: string;
    subjectId: string;
    leadInstructor: string;
}) => {
    try {
        const { data } = await axios.post(`/batches/add`, {
            endDate,
            startDate,
            programId,
            name,
            subjectId,
            leadInstructor
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};
export const assignBatchToStudent = async ({
    studentsIds,
    batchId,
}: {
    studentsIds: string[];
    batchId: string;
}) => {
    try {
        const { data } = await axios.post(`/batches/assign-batch`, {
            studentsIds: studentsIds,
            batchId,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};
export const deleteStudentFromBatch = async ({
    studentsIds,
    batchId,
}: {
    studentsIds: string[];
    batchId: string;
}) => {
    try {
        const { data } = await axios.post(`/batches/remove-students`, {
            studentsIds: studentsIds,
            batchId,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};
