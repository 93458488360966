import { Stack } from "@chakra-ui/layout";
import { updateChapter } from "API/chapterApi";
import { useAppSelector } from "app/hook";
import { Formik, Form } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";

interface UpdateChapterProps {
  chapterName: string;
  chapterId: string;
  onClose: () => void;
}

const UpdateChapter: FC<UpdateChapterProps> = ({
  chapterName,
  chapterId,
  onClose,
}) => {
  const cache = useQueryClient();

  const { program, subjectId } = useAppSelector((state) => state.programState);
  const { isLoading, mutateAsync, error } = useMutation(updateChapter, {
    onSuccess: () => {
      cache.invalidateQueries(["program", program?._id, subjectId]);
      onClose();
    },
  });
  useErrorToast(error);
  return (
    <Formik
      initialValues={{ chapterName: chapterName }}
      onSubmit={(values) => {
        try {
          mutateAsync({
            id: chapterId,
            chapterName: values.chapterName,
          });
        } catch (error) {}
      }}
    >
      <Form>
        <Stack minHeight="200px" justify="space-around">
          <InputControl name="chapterName" label="Chapter Name" />
          <SubmitButton isLoading={isLoading}>Update Chapter</SubmitButton>
        </Stack>
      </Form>
    </Formik>
  );
};
export default UpdateChapter;
