import {
  Center,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  Switch,
  FormLabel,
  FormControl,
  Input,
  Button,
  Textarea,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  Box,
} from "@chakra-ui/react";
import useErrorToast from "hook/error-toast";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "app/hook";
import { DeleteIcon } from "@chakra-ui/icons";

import { useQuery, useMutation, useQueryClient } from "react-query";
// import MCQSReviewAttempt from "./MCQSReviewAttempt";
// import MCQMReviewAttempt from "./MCQMReviewAttempt";
// import TFReviewAttempt from "./TFReviewAttempt";
import {
  getAttemptedTestById,
  submitReview,
  testReviewStarted,
} from "API/attemptedTestApi";
import { format } from "date-fns";
import { useState } from "react";

const ReviewAttempt = () => {
  const { testId } = useParams<{ testId: string }>();
  const history = useHistory();
  const {
    isLoading,
    data: attemptedTest,
    error,
  } = useQuery<IAttemptedTest>(["attemptedTest", testId], () =>
    getAttemptedTestById({ id: testId })
  );

  // console.log(attemptedTest);

  const checkedQuestion = attemptedTest?.questionResult;
  const queryClient = useQueryClient();

  const { user: staff }: { user: IStaff } = useAppSelector(
    (state) => state.auth
  );
  const mutation = useMutation(testReviewStarted, {
    onSuccess: () => {
      queryClient.invalidateQueries("attemptedTest");
    },
  });
  const submitMutation = useMutation(submitReview, {
    onSuccess: () => {
      queryClient.invalidateQueries("attemptedTest");
      history.goBack();
    },
  });
  useErrorToast(mutation.error);
  useErrorToast(error);

  if (isLoading)
    return (
      <Center>
        <Spinner />
      </Center>
    );
  return (
    <Stack>
      <Flex justify="space-between">
        <Stack>
          <Heading fontSize="xl">
            {" "}
            {format(
              new Date(attemptedTest?.testDate ?? ""),
              "MMM dd, yyyy hh:mm a"
            )}
            , {attemptedTest?.test?.name}
          </Heading>
          <Text>
            Test level : {attemptedTest?.test.level} | Subject :{" "}
            {attemptedTest?.test.subject?.name}
          </Text>
        </Stack>
      </Flex>

      <Stack spacing={0}>
        <Stack>
          <Flex
            justify="space-between"
            border="1px solid #ccc"
            padding={4}
            borderTopRadius="xl"
          >
            <Stack>
              <Text>
                Questions attempted:{attemptedTest?.attemptedQuestion} | Not
                Attempted : {attemptedTest?.notAttemptedQuestion} | Time Taken:
                {attemptedTest?.timeTaken}
              </Text>
            </Stack>
            <Stack>
              {attemptedTest?.isResultPending ? (
                <FormControl display="flex" alignItems="center">
                  <Switch
                    id="email-alerts"
                    defaultChecked={attemptedTest.isReviewing}
                    isDisabled={attemptedTest.isReviewing}
                    onChange={() =>
                      mutation.mutate({ id: testId, staffId: staff._id })
                    }
                  />
                  <FormLabel
                    htmlFor="start-review"
                    mb="0"
                    disabled={attemptedTest.isReviewing}
                    ml="15px"
                    color={attemptedTest.isReviewing ? "red" : "teal.500"}
                  >
                    {attemptedTest.isReviewing ? "In review" : "Start review"}
                  </FormLabel>
                </FormControl>
              ) : (
                <>
                  <Heading fontSize="xl">
                    You got total {attemptedTest?.correctAnswer} marks
                  </Heading>
                </>
              )}
              <Heading fontSize="md">Reviewed By: {staff?.name}</Heading>
            </Stack>
          </Flex>
        </Stack>

        {checkedQuestion?.map((question, index) => {
          if (question.type.toLowerCase() === "la" && question.attempted)
            return (
              <QuestionReviewInput
                question={question}
                key={question.id}
                checkedQuestion={checkedQuestion}
                index={index}
              />
            );
          return null;
        })}

        <Stack
          border="1px solid #ccc"
          p="4"
          direction={["column", "row"]}
          borderTop="none"
          borderBottomRadius={"xl"}
        >
          <Button
            colorScheme="gray"
            size="md"
            isDisabled={!attemptedTest?.isReviewing}
            onClick={() => {
              submitMutation.mutate({
                isDraft: true,
                id: attemptedTest?._id ?? "",
                questions: checkedQuestion,
                staffName: staff.name,
              });
            }}
          >
            Save as draft
          </Button>
          <Button
            colorScheme="teal"
            size="md"
            onClick={() => {
              submitMutation.mutate({
                isDraft: false,
                id: attemptedTest?._id ?? "",
                questions: checkedQuestion,
                staffName: staff.name,
              });
            }}
            isLoading={submitMutation.isLoading}
            isDisabled={!attemptedTest?.isReviewing}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ReviewAttempt;

interface Parameter {
  parameter: string;
  options: string[];
  deletable: Boolean;
}

const QuestionReviewInput = ({
  question,
  checkedQuestion,
  index,
}: {
  question: any;
  index: number;
  checkedQuestion: any;
}) => {
  const initialParameters: Parameter[] = [
    {
      parameter: "Undestanding of the Question",
      options: ["Yes", "No"],
      deletable: false,
    },
    {
      parameter: "Justice to the Question",
      options: ["Yes", "No"],
      deletable: false,
    },
    {
      parameter: "Use of right Vocabulary",
      options: ["Yes", "No", "Need Improvement"],
      deletable: false,
    },
    {
      parameter: "Logically & Sequentially written",
      options: ["Yes", "No", "Need Improvement"],
      deletable: false,
    },
    {
      parameter: "Conclusion",
      options: ["Yes", "No", "Need Improvement"],
      deletable: false,
    },
  ];

  const existingKeys = Object.keys(
    question.correctionParameterJustification || {}
  );

  const missingKeys = existingKeys.filter((key) => {
    return !initialParameters.some((param) => param.parameter === key);
  });
  missingKeys.forEach((key) =>
    initialParameters.push({
      parameter: key,
      options: ["Yes", "No"],
      deletable: true,
    })
  );
  const [correctionParameter, setCorrectionParameter] =
    useState(initialParameters);
  const [newParameter, setNewParameter] = useState<string>("");
  const [newParameterValue, setNewParameterValue] = useState("");
  const [justificationAnswer, setJustificationAnswer] = useState(
    question.remark ?? ""
  );
  console.log(question);

  const [marks, setMarks] = useState(question.marks);
  if (marks > 0) {
    question.marks = marks;
    question.isCorrect = true;
  }
  question.remark = justificationAnswer;

  const [
    correctionParameterJustification,
    setCorrectionParameterJustification,
  ] = useState<{ [key: string]: string }>(
    question.correctionParameterJustification ?? {}
  );

  const handleAddParameter = () => {
    if (newParameter.trim() !== "") {
      setCorrectionParameterJustification((prevJustifications) => ({
        ...prevJustifications,
        [newParameter]: newParameterValue,
      }));

      setCorrectionParameter((prev) => [
        ...prev,
        {
          parameter: newParameter,
          options: ["Yes", "No"],
          deletable: true,
        },
      ]);

      setNewParameter("");
      setNewParameterValue("");
    }
  };

  const handleRadioChange = (parameter: string, selectedValue: string) => {
    setCorrectionParameterJustification((prevJustifications) => ({
      ...prevJustifications,
      [parameter]: selectedValue,
    }));
  };
  question.correctionParameterJustification = correctionParameterJustification;

  const [isVisible, setIsVisible] = useState(false);
  const handleDeleteParameter = (parameter: any) => {
    const parameterToDelete = correctionParameter.find(
      (param) => param.parameter === parameter
    );
    console.log(parameterToDelete);
    if (parameterToDelete && parameterToDelete.deletable) {
      const updatedCorrectionParameter = {
        ...correctionParameterJustification,
      };
      delete updatedCorrectionParameter[parameter];
      setCorrectionParameterJustification(updatedCorrectionParameter);

      setCorrectionParameter((prev) =>
        prev.filter((param) => param.parameter !== parameter)
      );
    }
  };

  return (
    <Stack
      key={question.id}
      border="1px solid #ccc"
      p="4"
      borderTop="none"
      borderBottomRadius={"0"}
    >
      <Flex justify="space-between">
        <Heading fontSize="lg">
          {index + 1}/{checkedQuestion.length} :{question.question}
        </Heading>
        <Text>{question.positiveMarks ?? 0} marks</Text>
      </Flex>
      <Text fontWeight="bold" as="span">
        Your answer: {question.laSubmittedAnswer}
      </Text>

      <Stack>
        {!question.attempted && <Text color="red.600">Not attempted</Text>}
        <VStack>
          <form style={{ width: "100%" }}>
            <HStack w={"100%"} justifyContent={"space-between"}>
              <VStack align="start" w={"60%"}>
                <Text as="b">Correction Parameter</Text>
                <Box w={"100%"}>
                  {correctionParameter.map((item, index) => (
                    <HStack key={index} justifyContent={"space-between"}>
                      <Text>{item.parameter}</Text>
                      <HStack w={"50%"} gridGap={"100"}>
                        <RadioGroup
                          value={
                            correctionParameterJustification[item.parameter] ||
                            ""
                          }
                        >
                          <Stack direction="row">
                            {item.options.map((option, ind) => (
                              <Radio
                                key={ind}
                                name={index.toString()}
                                value={option}
                                onChange={(e) =>
                                  handleRadioChange(
                                    item.parameter,
                                    e.target.value
                                  )
                                }
                              >
                                {option}
                              </Radio>
                            ))}
                          </Stack>
                        </RadioGroup>
                        {item.deletable && (
                          <DeleteIcon
                            color={"red"}
                            fontSize={"20px"}
                            cursor={"pointer"}
                            onClick={() =>
                              handleDeleteParameter(item.parameter)
                            }
                          />
                        )}
                      </HStack>
                    </HStack>
                  ))}
                </Box>
              </VStack>
              <VStack
                w={"40%"}
                display={"flex"}
                align={"start"}
                alignItems={"flex-start "}
              >
                <Text as={"b"}>Remark</Text>
                <Textarea
                  value={justificationAnswer}
                  onChange={(e) => {
                    setJustificationAnswer(e.target.value);
                  }}
                  name="answerJustification"
                  style={{ minHeight: "100px" }}
                />
              </VStack>
            </HStack>

            <HStack my={"20px"} justifyContent={"space-between"}>
              <VStack w={"60%"} align={"start"}>
                <Text
                  as={"b"}
                  color={"teal"}
                  onClick={() => setIsVisible(true)}
                  cursor={"pointer"}
                >
                  Add more parameter
                </Text>
                {isVisible && (
                  <HStack w={"100%"}>
                    <Input
                      type="text"
                      value={newParameter}
                      onChange={(e) => {
                        setNewParameter(e.target.value);
                      }}
                    />
                    <RadioGroup w={"100%"} value={newParameterValue}>
                      <Stack direction="row">
                        {/* {["Yes", "No"].map((option) => (
                          <Radio
                            key={option}
                            name={"additional"}
                            value={option}
                            onChange={(e) => {
                              setNewParameterValue(e.target.value);
                            }}
                          >
                            {option}
                          </Radio>
                        ))} */}
                        <Button
                          colorScheme="whatsapp"
                          size="xs"
                          onClick={handleAddParameter}
                        >
                          Add
                        </Button>
                        {/* <DeleteIcon
                          color={"red"}
                          fontSize={"20px"}
                          cursor={"pointer"}
                          onClick={() => {
                            setNewParameter("");
                            setNewParameterValue("");
                          }}
                        /> */}
                      </Stack>
                    </RadioGroup>
                  </HStack>
                )}
              </VStack>
              <VStack w={"40%"} align={"start"}>
                <Text as={"b"}>Marks for answer</Text>
                <Input
                  value={marks}
                  name="marks"
                  variant="filled"
                  min={0}
                  width={"100%"}
                  max={question.positiveMarks}
                  type="number"
                  onChange={(e) => {
                    setMarks(
                      parseInt(e.target.value) > question.positiveMarks
                        ? question.positiveMarks
                        : parseInt(e.target.value)
                    );
                  }}
                />
              </VStack>
            </HStack>
          </form>
        </VStack>
        {/* <Heading size="sm">Marks for answer</Heading>
        <form>
          <Input
            value={marks}
            name="marks"
            variant="filled"
            size="md"
            min={0}
            width={["100%", "60%"]}
            max={question.positiveMarks}
            type="number"
            onChange={(e) => {
              setMarks(
                parseInt(e.target.value) > question.positiveMarks
                  ? question.positiveMarks
                  : parseInt(e.target.value)
              );
            }}
          />
          <Heading size="sm">Justification</Heading>
          <Textarea
            value={justificationAnswer}
            onChange={(e) => {
              setJustificationAnswer(e.target.value);
            }}
            name="answerJustification"
            variant="filled"
            size="md"
            width={["100%", "60%"]}
          />
        </form> */}
      </Stack>
    </Stack>
  );
};
