import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email required!"),
  password: yup
    .string()
    .min(4, "password is too sort")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password required!"),
});

export const signupValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email required!"),
  password: yup
    .string()
    .min(4, "password is too sort")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  name: yup.string().required("Name required!"),
  mobile: yup
    .number()
    .min(1000000000, "Number not valid!")
    .max(9999999999, "Number not valid!")
    .required("Mobile required!"),
  // licences: yup.number().required("Licences required!"),
  // schoolName: yup.string().required("School Name required!"),
});

export const resetPasswordValidationSchema = yup.object({
  password: yup
    .string()
    .min(4, "password is too sort")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

// const SUPPORTED_FORMATS = ["csv"];

export const importQuestionValidationSchema = yup.object({
  subject: yup.string().required("Subject required!"),
  file: yup
    .mixed()
    .required(" CSV File is required!")
    .test(
      "fileSize",
      "File too large!",
      (value) => value === null || (value && value.size <= 1024 * 1024 * 10)
    )
    .test(
      "fileFormat",
      "Please Upload CSV file type!",
      (value) => {
        console.log(value);

        return value === null || (value && value.name.includes(".csv"));
        // return SUPPORTED_FORMATS.includes(value.name.split(".")[1]);
      }
      // (value) => value === null || (value && value.type.includes("csv"))
    ),
  questionType: yup.string().required("Question Type required!"),
});

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const UploadFileSchema = yup.object().shape({
  file: yup.mixed().test("fileSize", "The file is too large", (value) => {
    if (!value?.length) return true // attachment is optional
    return value[0].size <= 10485760
  }).test('format',
    'Only the following formats are accepted: .jpeg, .jpg and .png.', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
});
