import Login from "pages/auth/Login/Login";

import Signup from "pages/auth/Signup/Signup";
import ResetPassword from "pages/auth/ResetPassword/ResetPassword";
import EmailVerify from "pages/auth/EmailVerify";
import EmailSentPage from "pages/auth/EmailSentPage/EmailSentPage";
import HomePage from "pages/static/HomePage";
import AboutUs from "pages/static/AboutUs";
import ContactUs from "pages/static/ContactUs";
import PrivacyPolicy from "pages/static/PrivacyPolicy";
import TermsAndConditions from "pages/static/TermsAndConditions";
import ProgramL1 from "pages/static/ProgramL1";
import ProgramClat from "pages/static/ProgramClat";
import ProgramL2 from "pages/static/ProgramL2";
import ProgramL3 from "pages/static/ProgramL3";
import SohailShaikh from "pages/static/SohailShaikh";
import Takidar from "pages/static/Takidar";
import Anindita from "pages/static/Anindita";
import Refund from "pages/static/Refund";
import ProgramCivilServices from "pages/static/ProgramCivilServices";
import ProgramNIOS from "pages/static/ProgramNIOS";
import ProgramNIOS_01 from "pages/static/ProgramNIOS_01";
export const unProtectedRoutes = [
  {
    type: ["notProtected"],
    path: "/",
    component: HomePage,
    exact: true,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/about-us",
    component: AboutUs,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/about-us-director-01",
    component: SohailShaikh,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/about-us-director-02",
    component: Takidar,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/about-us-project-director-01",
    component: Anindita,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/contact-us",
    component: ContactUs,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/privacy-policy",
    component: PrivacyPolicy,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/refund",
    component: Refund,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/graduation-program-L-1",
    component: ProgramL1,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/clat-program",
    component: ProgramClat,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/graduation-program-L-2",
    component: ProgramL2,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/graduation-program-L-3",
    component: ProgramL3,
    exact: false,
    protect: false,
  },

  {
    type: ["notProtected"],
    path: "/login",
    component: Login,
    exact: true,
    protect: false,
  },

  {
    type: ["notProtected"],
    path: "/signup",
    component: Signup,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/civil-services-level-1",
    component: ProgramCivilServices,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/nios",
    component: ProgramNIOS,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/nios-1",
    component: ProgramNIOS_01,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/reset-link/:token",
    component: ResetPassword,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/email-verify/:token",
    component: EmailVerify,
    exact: false,
    protect: false,
  },
  {
    type: ["notProtected"],
    path: "/email-sent/:email",
    component: EmailSentPage,
    exact: false,
    protect: false,
  },
];
