import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
interface UserFilterState {
  page: number;
  size: number;
  searchText: string;
}

// Define the initial state using that type
const initialState: UserFilterState = {
  page: 1,
  size: 20,
  searchText: "",
};

export const userFilterSlice = createSlice({
  name: "userFilter",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPageDefault: (state) => {
      state.page = initialState.page;
    },
    incrementPage: (state) => {
      if (state.page >= 1) {
        state.page++;
      }
    },
    decrementPage: (state) => {
      if (state.page > 1) {
        state.page--;
      }
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },

    defaultState: (state) => {
      state = initialState;
    },
  },
});

export const {
  defaultState,
  setPageDefault,
  incrementPage,
  decrementPage,
  setSize,
  setSearchText,
} = userFilterSlice.actions;

export default userFilterSlice.reducer;
