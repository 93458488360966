import { Center, Spinner } from "@chakra-ui/react";
import { FC } from "react";

interface LoadingSpinnerProps {
  isFull?: boolean;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ isFull = false }) => {
  return (
    <Center h={isFull ? "100vh" : "auto"}>
      <Spinner />
    </Center>
  );
};
export default LoadingSpinner;
