import { axios } from "./AxiosInstance";

export const addProductsToCart = async ({
  productId,
  quantity,
}: {
  productId: string;
  quantity: number;
}) => {
  try {
    const { data } = await axios.post(`/cart/add`, { productId, quantity });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const removeProductFromCart = async ({
  productId,
}: {
  productId: string;
}) => {
  try {
    const { data } = await axios.delete(`/cart/${productId}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateCartItem = async ({
  productId,
  quantity,
}: {
  productId: string;
  quantity: number;
}) => {
  try {
    const { data } = await axios.patch(`/cart/update`, { productId, quantity });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
