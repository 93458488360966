import { axios } from "./AxiosInstance";

export const getSchoolById = async (id: string) => {
  try {
    const { data } = await axios.get(`/school/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getSchools = async ({
  searchText,
  page,
  size,
}: {
  searchText?: string | undefined;
  page?: number;
  size?: number;
}) => {
  try {
    const { data } = await axios.post(`/school`, {
      searchText,
      page,
      size,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getAllSchools = async () => {
  try {
    const { data } = await axios.post(`/school/all`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getSchoolsByProgramId = async ({
  programId
}: {
  programId: string | undefined;
}) => {
  try {
    const { data } = await axios.post(`/getSchoolsByProgramId`, {
      programId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const addSchool = async ({
  name,
  email,
  mobile,
  address,
  state,
  city,
  password,
  pincode,
}: {
  name: string;
  email: string;
  password: string;
  mobile: number;
  address: string;
  city: string;
  state: string;
  pincode: number;
}) => {
  try {
    const { data } = await axios.post(`/school/add`, {
      name,
      email,
      mobile,
      address,
      state,
      city,
      password,
      pincode,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateSchool = async ({
  userId,
  name,
  email,
  mobile,
  address,
  state,
  city,
  password,
  pincode,
}: {
  userId: string;
  name: string;
  email: string;
  password: string;
  mobile: number;
  address: string;
  city: string;
  state: string;
  pincode: number;
}) => {
  try {
    const updateData = {
      userId,
      name,
      email,
      mobile,
      address,
      state,
      city,
      password: password.length > 0 ? password : undefined,
      pincode,
    };

    const { data } = await axios.patch(`/school/update`, updateData);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
