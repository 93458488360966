import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

interface ModalProps {
  open: boolean;
  onClose: any;
  children: JSX.Element;
  modalSize?: string;
}
const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  modalSize = "md",
}) => {
  return (
    <ChakraModal isOpen={open} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Modal Title</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
