import { Box, VStack, Badge, Flex } from "@chakra-ui/layout";
import {
  Table as CTable,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Spinner,
  Td,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { GoKebabVertical } from "react-icons/go";
// import { useState } from "react";
import { Link as RoLink } from "react-router-dom";
import { useAppSelector } from "app/hook";
import DeleteTableItem from "./DeleteTableItem";
import { FcFinePrint } from "react-icons/fc";
import { format } from "date-fns";

interface QuestionTableProps {
  isLoading: boolean;
  items: IBatch[];
  resetCheckBox: boolean;
  disableUpdate: boolean;
}

const QuestionTable: FC<QuestionTableProps> = ({
  isLoading,
  items: batchItems,
  resetCheckBox,
  disableUpdate,
}) => {
  const { role } = useAppSelector((state) => state.auth);
  const itemsCount = batchItems?.length;
  const [checkedItems, setCheckedItems] = useState(
    new Array(itemsCount).fill(false)
  );

  let items: IBatch[] = [];
  batchItems?.map(item => { 
    if ((Number(new Date(item?.startDate)) < Date.now() && Number(new Date(item?.endDate)) > Date.now()) && (Number(new Date(item?.endDate)) - Date.now() <= 30 * 24 * 60 * 60 * 1000)) 
    { 
      items.push(item) 
    } 
    return item;
  })
  batchItems?.map(item => { 
    if ((Number(new Date(item?.startDate)) < Date.now() && Number(new Date(item?.endDate)) > Date.now()) && (Number(new Date(item?.endDate)) - Date.now() > 30 * 24 * 60 * 60 * 1000)) 
    { 
      items.push(item) 
    } 
    return item;
  })
  batchItems?.map(item => { 
    if (Number(new Date(item?.startDate)) > Date.now() && Number(new Date(item?.endDate)) > Date.now()) 
    { 
      items.push(item) 
    } 
    return item;
  })
  batchItems?.map(item => { 
    if (Number(new Date(item?.startDate)) < Date.now() && Number(new Date(item?.endDate)) < Date.now()) 
    { 
      items.push(item) 
    } 
    return item;
  })

  const itemsId = items
    ?.filter((q, i) => checkedItems[i] === true)
    .map((q) => q._id);

  useEffect(() => {

    if (resetCheckBox) {
      setCheckedItems(new Array(itemsCount).fill(false));
    }
  }, [resetCheckBox, itemsCount]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  
  return (
    <>
      {checkedItems.includes(true) && (
        <DeleteTableItem
          itemIds={itemsId as string[]}
          itemsLength={itemsCount}
          setCheckedItems={setCheckedItems}
        />
      )}
      <Box overflow="hidden">
        <Box overflowX="auto">
          {isLoading ? (
            <Box width="100%" height="100%">
              <Spinner />
            </Box>
          ) : itemsCount === 0 ? (
            <Flex w="100%" minH="50vh" align="center" justify="center">
              <VStack>
                <Icon as={FcFinePrint} w={16} h={16} />
                <Text>No Batches Found!</Text>
              </VStack>
            </Flex>
          ) : (
            <>
              <CTable variant="striped">
                <Thead>
                  <Tr>
                    {role === "admin" ? (
                      <Th maxW="30px" display="flex">
                        <Checkbox
                          colorScheme="teal"
                          isChecked={allChecked}
                          isIndeterminate={isIndeterminate}
                          onChange={(e) =>
                            setCheckedItems(
                              new Array(itemsCount).fill(e.target.checked)
                            )
                          }
                        />{" "}
                      </Th>
                    ) : null}
                    <Th>Name</Th>
                    {/* <Th>Lead Instructor</Th> */}
                    <Th>Start Date</Th>
                    <Th>End Date</Th>
                    <Th>Program</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  <>
                    {items?.map((item, i) => (
                      <Tr key={item._id}>
                        {role === "admin" ? (
                          <Td maxW="30px">
                            <Checkbox
                              colorScheme="teal"
                              isChecked={checkedItems[i]}
                              onChange={(e) => {
                                const checkItems = new Array(itemsCount)
                                  .fill(checkedItems[i])
                                  .map((_, checkI) => {
                                    if (checkI === i) {
                                      return e.target.checked;
                                    }
                                    return checkedItems[checkI];
                                  });
                                setCheckedItems(checkItems);
                              }}
                            />
                          </Td>
                        ) : null}
                        <Td maxW="400px">{item?.name}</Td>
                        {/* <Td textTransform="capitalize">
                          {item?.leadInstructor}
                        </Td> */}
                        <Td>
                          <Badge colorScheme="green">
                            {format(new Date(item?.startDate), "dd-MM-yyyy")}
                          </Badge>
                        </Td>
                        <Td>
                          <Badge colorScheme="red">
                            {format(new Date(item?.endDate), "dd-MM-yyyy")}
                          </Badge>
                        </Td>
                        <Td textTransform="capitalize">{item?.program.name}</Td>

                        {/* <Td textTransform="capitalize">{item?.program.status}</Td> */}
                        <Td>

                          {(Number(new Date(item?.startDate)) < Date.now() && Number(new Date(item?.endDate)) > Date.now()) ? (Number(new Date(item?.endDate)) - Date.now() <= 30 * 24 * 60 * 60 * 1000) ? <Badge
                            colorScheme="yellow"
                          >
                            Ending soon
                          </Badge> : <Badge
                            colorScheme="green"
                          >
                            Active
                          </Badge> : ""}
                          {Number(new Date(item?.startDate)) < Date.now() && Number(new Date(item?.endDate)) < Date.now() && <Badge
                            colorScheme="red"
                          >
                            Ended
                          </Badge>}

                          {Number(new Date(item?.startDate)) > Date.now() && Number(new Date(item?.endDate)) > Date.now() && <Badge
                            colorScheme="blue"
                          >
                            Upcoming
                          </Badge>}
                          {/* <Badge colorScheme="green">
                            Active
                          </Badge> */}
                        </Td>


                        {!disableUpdate && (
                          <Td>
                            <Menu closeOnBlur={true}>
                              <MenuButton
                                as={IconButton}
                                icon={<GoKebabVertical />}
                                variant="ghost"
                              ></MenuButton>
                              <MenuList>
                                {Number(new Date(item?.endDate)) > Date.now() && <MenuItem
                                  as={RoLink}
                                  to={`/${role}/dashboard/batches/edit/${item._id}/`}
                                >
                                  Edit
                                </MenuItem>}
                                <MenuItem
                                  as={RoLink}
                                  to={`/${role}/dashboard/batches/edit/${item._id}/assign-batch`}
                                >
                                  Assign Batch Students
                                </MenuItem>
                                {Number(new Date(item?.endDate)) > Date.now() && <MenuItem
                                  as={RoLink}
                                  to={`/${role}/dashboard/batches/edit/${item._id}/update-program`}
                                >
                                  Change program end date
                                </MenuItem>}
                                {/* <MenuItem>Delete</MenuItem> */}
                              </MenuList>
                            </Menu>
                          </Td>
                        )}
                      </Tr>
                    ))}
                  </>
                </Tbody>
              </CTable>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default QuestionTable;
