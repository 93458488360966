import { IconButton } from "@chakra-ui/button";
import { Badge, Box, Flex, Heading, HStack, Stack } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { removeActiveProgram } from "API/activeProgramApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { format } from "date-fns";
import { useDisclosure } from "@chakra-ui/hooks";
import UpdateActiveProgramModal from "./UpdateActiveProgramModal";
interface ActiveProgramProps {
  activeProgram: IActiveProgram;
  studentId: string;
}

const ActiveProgram: FC<ActiveProgramProps> = ({
  activeProgram,
  studentId,
}) => {
  const { isOpen, onClose } = useDisclosure();
  const toast = useToast();
  const cache = useQueryClient();
  const { error, isLoading, mutateAsync } = useMutation(
    "removeActiveProgram",
    removeActiveProgram,
    {
      onSuccess: () => {
        cache.invalidateQueries(["student", studentId]);
        toast({
          title: "Active Program Removed",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  useErrorToast(error);

  return (
    <>
      <Flex
        p="4"
        justify="space-between"
        align="center"
        borderBottom="1px"
        borderColor="gray.200"
        mt="0"
      >
        <Box>
          <Heading
            as="h5"
            size="sm"
            mb="2"
            color={"teal"}
            textTransform={"uppercase"}
          >
            {activeProgram?.program?.name}
          </Heading>
          <Stack direction={["column", "row"]} spacing="0px">
            <Badge colorScheme="green">
              Start -{" "}
              {format(new Date(activeProgram?.createdAt), "MMM dd, yyyy")}
            </Badge>
            <Badge colorScheme="red">
              Exp - {format(new Date(activeProgram?.expiredAt), "MMM dd, yyyy")}
            </Badge>
          </Stack>
        </Box>
        <HStack>
          {/* <IconButton
              isLoading={isLoading}
              icon={<FaEdit />}
              aria-label="edot"
              onClick={onOpen}
            /> */}
          <IconButton
            isLoading={isLoading}
            icon={<FaTrash />}
            aria-label="remove"
            onClick={async () => {
              try {
                await mutateAsync({
                  programId: activeProgram?._id,
                  studentId: studentId,
                });
              } catch (error) {
                console.log(error);
              }
            }}
          />
        </HStack>
      </Flex>
      <UpdateActiveProgramModal
        acitveProgram={activeProgram}
        onClose={onClose}
        isOpen={isOpen}
      />

      {/* <ListItem>
        <Flex justify="space-between" align="center">
          <Stack>
            <Text> {activeProgram?.program?.name}</Text>
            <HStack>
              <Badge colorScheme="green">
                Start -{" "}
                {format(new Date(activeProgram?.createdAt), "MMM dd, yyyy")}
              </Badge>
              <Badge colorScheme="red">
                Exp -{" "}
                {format(new Date(activeProgram?.expiredAt), "MMM dd, yyyy")}
              </Badge>
            </HStack>
          </Stack>
          <HStack>
            <IconButton
              isLoading={isLoading}
              icon={<FaEdit />}
              aria-label="edot"
              onClick={onOpen}
            />
            <IconButton
              isLoading={isLoading}
              icon={<FaTrash />}
              aria-label="remove"
              onClick={async () => {
                try {
                  await mutateAsync({
                    programId: activeProgram?._id,
                    studentId: studentId,
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          </HStack>
        </Flex>
      </ListItem> */}
    </>
  );
};
export default ActiveProgram;
