import { HStack, Button } from "@chakra-ui/react";
import { FC } from "react";

interface PrevAndNextButtonProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const PrevAndNextButton: FC<PrevAndNextButtonProps> = ({ setFieldValue }) => {
  return (
    <HStack my="4">
      <Button type="submit" onClick={() => setFieldValue("isPrev", true)}>
        Prev
      </Button>
      <Button
        colorScheme={"teal"}
        type="submit"
        onClick={() => {
          setFieldValue("isPrev", false);
        }}
      >
        Next
      </Button>
    </HStack>
  );
};
export default PrevAndNextButton;
