import {
  Stack,
  Link,
  Heading,
  Divider,
  Button,
  Text,
  Spinner,
  Badge,
  Grid,
  Box,
  Select,
  useColorModeValue,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
} from "@chakra-ui/react";
import { getListofAttemptedTestByProgramAndSchool } from "API/attemptedTestApi";
import { format } from "date-fns";
import useErrorToast from "hook/error-toast";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Link as RoLink } from "react-router-dom";
import { setProgram } from "app/features/program/programSlice";
import { FaSearch } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "app/hook";
import { getProgramById } from "API/programApi";

interface StaffTestsProps {}

const StaffTests: FC<StaffTestsProps> = () => {
  const borderClr = useColorModeValue("gray.100", "gray.700");
  const bgClr = useColorModeValue("red.50", "gray.700");
  const dispatch = useAppDispatch();
  const { user: staff }: { user: IStaff } = useAppSelector(
    (state) => state.auth
  );

  const { subjectId } = useAppSelector((state) => state.programState);
  const [searchTest, setSearchTest] = useState("");

  const { programId, schoolId } =
    useParams<{ programId: string; schoolId: string }>();
  const { data: programData } = useQuery<IProgram>(
    ["program", programId, subjectId],
    () => getProgramById(programId, subjectId)
  );
  const { data, isLoading, error } = useQuery<IAttemptedTest[]>(
    "attemptedTests",
    () => getListofAttemptedTestByProgramAndSchool({ programId, schoolId })
  );

  useErrorToast(error);

  return (
    <>
      {isLoading ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <>
          <Heading size="md">
            <Link as={RoLink} to="/Staff/dashboard" color="teal.500">
              Dashboard
            </Link>{" "}
            / Review Tests (program name)
          </Heading>
          <Box
            my="20px"
            border="1px"
            width="100%"
            borderColor={borderClr}
            borderRadius="md"
          >
            <Stack w="100%" backgroundColor={bgClr} padding="20px">
              <Select
                placeholder="Select Subject"
                rounded="md"
                textTransform="capitalize"
                backgroundColor="white"
                value={subjectId}
                onChange={(e: any) => {
                  console.log("slected");

                  dispatch(
                    setProgram({
                      program: programData,
                      subjectId: e.target.value,
                    })
                  );
                }}
              >
                {programData?.subjects?.map((e: ISubject, index) => {
                  if (!subjectId && index === 0) {
                    dispatch(
                      setProgram({
                        program: programData,
                        subjectId: e._id,
                      })
                    );
                  }
                  return (
                    <option key={e?.name} value={e._id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
            </Stack>
            {subjectId && (
              <Stack w="100%" padding="20px">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaSearch />}
                  />
                  <Input
                    type="tel"
                    onChange={(e) => {
                      setSearchTest(e.target.value.trim());
                    }}
                    placeholder="Search Student......"
                  />
                </InputGroup>
                <Divider my="4" />
                <Stack my={4}>
                  {data
                    ?.filter((attempt) =>
                      attempt.student.name?.includes(searchTest)
                    )
                    .map((attempt: IAttemptedTest) => {
                      if (attempt.isResultPending)
                        return (
                          <>
                            <Stack direction={["column", "row"]}>
                              <Stack my={5}>
                                <Text fontWeight="bold">
                                  {format(
                                    new Date(attempt?.testDate),
                                    "MMM dd, yyyy hh:mm a"
                                  )}
                                  <Badge
                                    ml="1"
                                    colorScheme={
                                      attempt?.test?.level === "easy"
                                        ? "blue"
                                        : attempt?.test?.level === "normal"
                                        ? "purple"
                                        : attempt?.test?.level === "difficult"
                                        ? "orange"
                                        : ""
                                    }
                                  >
                                    {attempt?.test?.level}
                                  </Badge>

                                  {staff._id === attempt.staff ? (
                                    <Badge>Draft</Badge>
                                  ) : null}
                                </Text>
                                <Stack direction={["column", "row"]}>
                                  <Text as="span">
                                    Student Name: {attempt.student.name}|
                                    Subject : {attempt.test.subject.name}
                                  </Text>
                                </Stack>
                              </Stack>
                              <Spacer />

                              <RoLink
                                to={`/staff/dashboard/review-attempt/${attempt._id}`}
                              >
                                <Button
                                  colorScheme="teal"
                                  disabled={
                                    attempt.isReviewing
                                      ? staff._id === attempt.staff
                                        ? false
                                        : true
                                      : false
                                  }
                                  width={["100%", "auto"]}
                                >
                                  {attempt.isReviewing
                                    ? staff._id === attempt.staff
                                      ? "Review"
                                      : "Reviewing"
                                    : "Review"}
                                </Button>
                              </RoLink>
                            </Stack>
                            <Divider colorScheme="gray.900" />
                          </>
                        );
                      return null;
                    })}
                </Stack>
              </Stack>
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default StaffTests;
