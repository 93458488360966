import { Box, Flex, Heading, Stack } from "@chakra-ui/layout";
//import { useToast } from "@chakra-ui/toast";
import { sendEmailVerifyToken } from "API/authApi";
import { updateEmail } from "API/sharedApi";
// import { on } from "events";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";

import { FC, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import * as yup from "yup";

interface EditEmailProps {
  onClose: () => void;
  oldEmail: string;
  password: string;
}
const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const EditEmail: FC<EditEmailProps> = ({ onClose, oldEmail, password }) => {
  //const toast = useToast();
  const { isLoading, error, mutateAsync } = useMutation(
    updateEmail,
    {
      onSuccess: async () => {
        try {
          if (email) {
            await tokenMutateAsync(email);
          }
        } catch (error) {
          console.log(error);
        }
        onClose();
      },
    }
  );

  useErrorToast(error);
  const history = useHistory();
  const [email, setEmail] = useState("");

  const {
    //isLoading: tokenLoading,
    //error: tokenError,
    mutateAsync: tokenMutateAsync,
  } = useMutation(sendEmailVerifyToken, {
    onSuccess: () => {
      history.push(`/email-sent/${email}`, { password: password });
    },
  });

  return (
    <Box mt="8">
      <Heading textAlign="center" mb="8" fontSize="2xl" color="main.400">
        Update Email
      </Heading>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values) => {
          try {
            setEmail(values.email);
            await mutateAsync({
              email: values.email,
              oldEmail: oldEmail,
              password: password,
            });
          } catch (error) {}
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Stack>
            <InputControl
              name="email"
              label="Email:"
              inputProps={{
                placeholder: "Enter Email",
                type: "email",
                focusBorderColor: "blue.400",
              }}
            />
            <Flex justify="flex-end">
              <SubmitButton mt="4" isLoading={isLoading} colorScheme="blue">
                Send Link
              </SubmitButton>
            </Flex>
          </Stack>
        </Form>
      </Formik>
    </Box>
  );
};
export default EditEmail;
