import { Box, HStack, Badge, Flex } from "@chakra-ui/layout";
import {
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Spinner,
  Td,
  Text,
  Button,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { Tag } from "@chakra-ui/tag";
import { FC, useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
// import { useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import useErrorToast from "hook/error-toast";
import { useToast } from "@chakra-ui/toast";
import { useAppSelector } from "app/hook";
import { addQuestionToTopic } from "API/topicApi";

interface QuestionAdderTableProps {
  isLoading: boolean;
  filterQuestion: IFilterQuestions;
  resetCheckBox: boolean;
  topicId: string;
  onClose: () => void;
}

const QuestionAdderTable: FC<QuestionAdderTableProps> = ({
  isLoading,
  filterQuestion,
  resetCheckBox,
  onClose,
  topicId,
}) => {
  const { program, subjectId } = useAppSelector((state) => state.programState);

  const toast = useToast();
  const cache = useQueryClient();
  const [checkedItems, setCheckedItems] = useState(
    new Array(filterQuestion?.questions.length).fill(false)
  );

  const questionIds = filterQuestion?.questions
    .filter((q, i) => checkedItems[i] === true)
    .map((q) => q._id);

  const {
    isLoading: addQuestionLoading,
    error: addQuestionError,
    mutateAsync: addQuestionMutateAsync,
  } = useMutation(addQuestionToTopic, {
    onSuccess: () => {
      toast({ title: "Added  Question successfully", status: "success" });
      cache.invalidateQueries(["program", program?._id, subjectId]);
      setCheckedItems(new Array(filterQuestion?.questions.length).fill(false));
      // onClose();
    },
  });

  useEffect(() => {
    if (resetCheckBox) {
      setCheckedItems(new Array(filterQuestion?.questions.length).fill(false));
    }
  }, [resetCheckBox, filterQuestion]);
  useErrorToast(addQuestionError);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  return (
    <Box>
      {checkedItems.includes(true) && (
        <Box bg="teal" pos="absolute" top="0" left="0" w="100%" p="3">
          <Button
            leftIcon={<GoPlus />}
            variant="solid"
            isLoading={addQuestionLoading}
            icon={<GoPlus />}
            aria-label="Add Button"
            onClick={async () => {
              try {
                await addQuestionMutateAsync({
                  topicId,
                  questions: questionIds,
                });
              } catch (error) {}
            }}
          >
            Add more questions
          </Button>
        </Box>
      )}
      <Box overflow="hidden">
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr bg={useColorModeValue("gray.50", "gray.700")}>
                <Th maxW="30px">
                  {/* {checkedItems.includes(true) && (
                  <>
                    <IconButton
                      alignSelf="center"
                      mb="2"
                      isLoading={addQuestionLoading}
                      icon={<GoPlus />}
                      aria-label="Add Button"
                      onClick={async () => {
                        try {
                          await addQuestionMutateAsync({
                            topicId,
                            questions: questionIds,
                          });
                        } catch (error) {}
                      }}
                    />
                  </>
                )} */}
                  <Checkbox
                    alignSelf="center"
                    colorScheme="teal"
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={(e) =>
                      setCheckedItems(
                        new Array(filterQuestion?.questions.length).fill(
                          e.target.checked
                        )
                      )
                    }
                  />
                </Th>
                <Th>Question</Th>
                {/* <Th>Subject</Th> */}
                <Th>Type</Th>
                <Th>Level</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Flex
                  as="tr"
                  width="100%"
                  height="100%"
                  justify="center"
                  align="center"
                >
                  <Spinner as="td" />
                </Flex>
              ) : filterQuestion?.questions.length === 0 ? (
                <Flex
                  as="tr"
                  width="100%"
                  height="100%"
                  justify="center"
                  align="center"
                >
                  <Text as="td">No Questions Found!</Text>
                </Flex>
              ) : (
                <>
                  {filterQuestion?.questions.map((question, i) => (
                    <Tr key={question._id}>
                      <Td>
                        <Checkbox
                          alignSelf="center"
                          colorScheme="teal"
                          isChecked={checkedItems[i]}
                          onChange={(e) => {
                            const checkItems = new Array(
                              filterQuestion?.questions.length
                            )
                              .fill(checkedItems[i])
                              .map((_, checkI) => {
                                if (checkI === i) {
                                  return e.target.checked;
                                }
                                return checkedItems[checkI];
                              });
                            setCheckedItems(checkItems);
                          }}
                        />
                      </Td>
                      <Td maxW="400px">
                        {question?.question}
                        <HStack spacing={2} mt="10px">
                          {question?.tags.map((tag) => (
                            <Tag size="sm" key={tag} colorScheme="cyan">
                              {tag}
                            </Tag>
                          ))}
                        </HStack>
                      </Td>
                      {/* <Td textTransform="capitalize">{question?.subjectName}</Td> */}
                      <Td>{question?.type}</Td>
                      <Td>
                        <Badge colorScheme="green">{question?.level}</Badge>
                      </Td>
                    </Tr>
                  ))}
                </>
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};
export default QuestionAdderTable;
