import {
  Spinner,
  Button,
  useColorModeValue,
  Link,
  Grid,
  Heading,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";
import { getActiveProgram } from "API/activeProgramApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link as RoLink } from "react-router-dom";
import { useAppSelector } from "app/hook";
import { format } from "date-fns";
interface StudentProgramProps {}

const StudentProgram: FC<StudentProgramProps> = () => {
  const borderClr = useColorModeValue("gray.100", "gray.700");
  const { subjectId } = useAppSelector((state) => state.programState);

  const { id } = useParams<{ id: string }>();
  const {
    data: activeProgram,
    isLoading,
    error,
  } = useQuery<IActiveProgram>(["activeProgram", id, subjectId], () =>
    getActiveProgram(id, subjectId)
  );

  useErrorToast(error);

  return (
    <>
      {isLoading ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <>
          <Heading size="md">
            <Link as={RoLink} to="/student/dashboard" color="teal.500">
              Dashboard
            </Link>{" "}
            / Program Details
          </Heading>
          <Stack direction={["column-reverse", "row"]} spacing="20px" my="20px">
            <Box
              border="1px"
              width="100%"
              borderColor={borderClr}
              borderRadius="md"
              padding={"4"}
            >
              {activeProgram && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeProgram.program.details,
                  }}
                ></div>
              )}
            </Box>
            <Box
              my="20px"
              width={["100%", "48%"]}
              border="1px"
              borderColor={borderClr}
              borderRadius="md"
              padding={["4", "40px"]}
            >
              <Stack spacing="20px" mb="5px">
                <Heading size="md">
                  Program {activeProgram?.program.name}
                </Heading>
                <Stack direction={["column", "row"]}>
                  <Text as="span">
                    Start Date:{" "}
                    {format(
                      new Date(activeProgram?.createdAt ?? ""),
                      "dd-MM-yyyy"
                    )}
                    ,
                  </Text>
                  <Text as="span">
                    End Date:{" "}
                    {format(
                      new Date(activeProgram?.expiredAt ?? ""),
                      "dd-MM-yyyy"
                    )}
                  </Text>
                </Stack>
                {/* <Text size="sm">
                  The detailed Maharashtra SSC board syllabus 10th for Maths is
                  tabulated below. Go through the same and prepare for the exam
                  in a planned way. The detailed Maharashtra SSC board syllabus
                  10th for Maths is tabulated below. Go through the same and
                  prepare for the exam in a planned way.
                </Text> */}
                {activeProgram?.program?.tests?.length !== 0 && (
                  <>
                    {/* <Text as="span" fontWeight="bold">
                      No. of Tests: {activeProgram?.program?.tests?.length}
                    </Text> */}
                    <Button
                      colorScheme="teal"
                      variant="solid"
                      as={RoLink}
                      to={`/student/dashboard/programs/${activeProgram?._id}/viewalltests`}
                    >
                      View all tests
                      {/* {console.log(activeProgram)} */}
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
        </>
      )}
    </>
  );
};
export default StudentProgram;
