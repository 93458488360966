import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles

const RichTextEditorModal = ({ isOpen, onClose, onSave, data }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (data) {
      setText(data);
    }
  }, [data]);

  const handleSave = () => {
    onSave(text);
    setText(null);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setText(null);
        onClose();
      }}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent maxWidth="75vw" height="75vh">
        <ModalHeader>Edit Your Answer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReactQuill
            value={text ? text : ""}
            onChange={setText}
            style={{
              height: "90%",
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setText(null);
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RichTextEditorModal;
