import { Button, Box } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { deleteManyPerformances } from "API/performanceApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { GoTrashcan } from "react-icons/go";
import { useMutation, useQueryClient } from "react-query";

interface DeleteTableItemProps {
  itemIds: string[];
  itemsLength: number;
  setCheckedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

const DeleteTableItem: FC<DeleteTableItemProps> = ({
  itemIds,
  itemsLength,
  setCheckedItems,
}) => {
  const toast = useToast();
  const cache = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(
    deleteManyPerformances,
    {
      onSuccess: () => {
        toast({
          title: "Performances deleted successfully",
          status: "success",
        });
        cache.invalidateQueries("performances");
        setCheckedItems(new Array(itemsLength).fill(false));
      },
    }
  );

  useErrorToast(error);
  return (
    <Box bg="tomato" pos="absolute" top="0" left="0" w="100%" p="3">
      <Button
        leftIcon={<GoTrashcan />}
        variant="solid"
        isLoading={isLoading}
        aria-label="delete Button"
        onClick={async () => {
          try {
            await mutateAsync(itemIds);
          } catch (error) {}
        }}
      >
        Delete
      </Button>
    </Box>
  );
};
export default DeleteTableItem;
