import { Stack, Flex, Grid, Text } from "@chakra-ui/react";
import { FC } from "react";

interface TFResultProps {
  question: IAnsweredTFQuestion;
}

const TFResult: FC<TFResultProps> = ({ question }) => {
  return (
    <>
      <Stack>
        {question.attempted && question.isCorrect ? (
          <Text color="green">Correct</Text>
        ) : (
          <Text color="red">Incorrect</Text>
        )}
        {["T", "F"].map((answer) => (
          <Flex key={answer} name={`tFAnswer`} align="center">
            <Grid
              placeItems="center"
              boxSize={"50px"}
              borderRadius="xl"
              flexShrink="0"
              border={
                question.isCorrect
                  ? "none"
                  : question.tfSubmittedAnswer === answer
                  ? "none"
                  : "none"
              }
              bg={
                question.attempted
                  ? question.tfSubmittedAnswer === answer
                    ? question.isCorrect
                      ? "green.500"
                      : "red.400"
                    : question.isCorrect
                    ? "gray.200"
                    : "green.500"
                  : "gray.200"
              }
              // color={question.submittedAnswer === true ? "white" : "black"}
            >
              {answer}
            </Grid>{" "}
            <Text ml={4}> {answer === "T" ? "True" : "False"}</Text>
          </Flex>
        ))}
      </Stack>
    </>
  );
};
export default TFResult;
