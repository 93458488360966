import * as yup from "yup";

export const addPerformanceValidationSchema = yup.object().shape({
  name: yup.string().required("name required!"),
  basic_objective_academics: yup
    .string()
    .required("basic objective academics required!"),
  career_objectives_1: yup.string().required("career objectives 1 required!"),
  career_objectives_2: yup.string().required("career objectives 2 required!"),
  programId: yup.string().required("program required!"),
});

export const updatePerformanceValidationSchema = yup.object().shape({
  name: yup.string().required("name required!"),
  basic_objective_academics: yup
    .string()
    .required("basic objective academics required!"),
  career_objectives_1: yup.string().required("career objectives 1 required!"),
  career_objectives_2: yup.string().required("career objectives 2 required!"),
  programId: yup.string().required("program required!"),
});
