import { IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { useToast } from "@chakra-ui/toast";
import { updateTest } from "API/testApi";
import { useAppSelector } from "app/hook";
import Modal from "components/UI/Modal";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { FaEdit } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";

interface UpdateTestProps {
  test: ITest;
}

const UpdateTest: FC<UpdateTestProps> = ({ test }) => {
  const { subjectId, program } = useAppSelector((state) => state.programState);
  const cache = useQueryClient();
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading, error, mutateAsync } = useMutation(updateTest, {
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Test Updated Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      cache.invalidateQueries(["program", program?._id, subjectId]);
    },
  });

  useErrorToast(error);
  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Formik
          initialValues={{ testName: test.name }}
          onSubmit={async (values) => {
            try {
              await mutateAsync({
                testId: test._id,
                testName: values.testName,
              });
            } catch (error) {}
          }}
        >
          <Form>
            <InputControl
              name="testName"
              label="Test Name"
              inputProps={{ placeholder: "Enter Test Name:" }}
            />
            <SubmitButton isLoading={isLoading}> Update Test</SubmitButton>
          </Form>
        </Formik>
      </Modal>
      <IconButton
        mr="4"
        isLoading={isLoading}
        onClick={onOpen}
        size="sm"
        aria-label="remove"
        icon={<FaEdit />}
      />{" "}
    </>
  );
};
export default UpdateTest;
