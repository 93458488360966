import {
  Button,
  Divider,
  Heading,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import AboutSelf from "./AboutSelf";
import AreaOfExpertise from "./AreaOfExpertise";
import CurrentAndGeneralInfo from "./CurrentAndGeneralInfo";
import ConcludingInterview from "./ConcludingInterview";
import { useAppSelector } from "app/hook";
import { useEffect, useState } from "react";

const Personalinterview = () => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  let { role, user: currentUser } = useAppSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    if (role === "student") {
      setUser(currentUser);
      setLoading(false);
    } else {
      setUser(location.state?.item);
      setLoading(false);
    }
  }, [role, currentUser, location.state]);

  const handleEditButtonClick = () => {
    history.push({
      pathname: "/edit/interview",
      state: { item: user },
    });
  };

  const hasInterviewAccess = user?.activePrograms?.some((program) =>
    program.purchasedProduct?.product?.access?.includes("interview")
  );

  if (loading) {
    return (
      <HStack justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </HStack>
    );
  }

  return (
    <>
      {!hasInterviewAccess ? (
        <HStack placeItems="center">
          <Heading color={"red"}>No Data Available</Heading>
        </HStack>
      ) : (
        <VStack>
          <HStack justifyContent={"space-between"} w={"100%"}>
            <Text textTransform="uppercase" as={"b"} fontSize={"20px"}>
              preparing for personal interview
            </Text>
            {/* {role === "student" && ( */}
            <Button
              colorScheme="teal"
              paddingX={"40px"}
              paddingY={"15px"}
              size="xs"
              onClick={handleEditButtonClick}
            >
              <Text fontSize={"18px"}>Edit</Text>
            </Button>
            {/* )} */}
          </HStack>
          <VStack
            paddingY={"15px"}
            w={"full"}
            border="1px"
            borderColor="gray.400"
            borderRadius={"8px"}
          >
            {/* //About Self */}
            <AboutSelf user={user} />

            <Divider />

            {/* AREA OF EXPERTISE  */}
            <AreaOfExpertise user={user} />

            <Divider />
            {/* CURRENT AND GENERAL INFORMATION  */}
            <CurrentAndGeneralInfo user={user} />

            <Divider />
            {/* CONCLUDING INTERVIEW  */}
            <ConcludingInterview user={user} />
          </VStack>
        </VStack>
      )}
    </>
  );
};

export default Personalinterview;
