import { useAppSelector } from "app/hook";
import { FC } from "react";
import {
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import AddChapter from "./Chapter/AddChapter";
import Chapter from "./Chapter/Chapter";

interface ChaptersProps { }

const Chapters: FC<ChaptersProps> = () => {
  const { program, subjectId } = useAppSelector((state) => state.programState);
  return (
    <>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderTopRadius="md"
        pos="relative"
      >

        {program?.chapters?.map((chapter: IChapter) => (
          <Chapter chapter={chapter} key={chapter._id} />
        ))}
          {subjectId && <AddChapter />}
        </Box>
    </>
  );
};
export default Chapters;
