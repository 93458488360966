import React, { FC } from "react";
import { Redirect, Route } from "react-router";

import { useCookies } from "react-cookie";

interface ProtectedRouteProps {
  children: React.ReactNode;
  type: string[];
  path: string;
  exact?: boolean;
}

const ProtectedRoute: FC<ProtectedRouteProps> = (
  { children, type, path, exact },
  props
) => {
  // const { role, token } = useAppSelector((state) => state.auth);
  const [cookies, ,] = useCookies(["jwt"]);
  const jwt = cookies.jwt;

  return (
    <>
      <Route
        exact={exact}
        path={path}
        {...props}
        render={() => {
          if (type.includes("notProtected")) {
            return children;
          } else if (jwt?.token && type.includes(jwt?.role as string)) {
            return children;
          } else {
            return <Redirect to="/login" />;
          }
        }}
      />
    </>
  );
};
export default ProtectedRoute;
