import {
  Flex,
  Grid,
  Heading,
  Stack,
  Center,
  GridItem,
} from "@chakra-ui/layout";
import { Button, Input, Container, Text } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { updateProfile } from "API/authApi";
import { Form, Formik, ErrorMessage } from "formik";
import {
  InputControl,
  SubmitButton,
  SelectControl,
  TextareaControl,
} from "formik-chakra-ui";
import { GoCloudUpload } from "react-icons/go";
import useErrorToast from "hook/error-toast";
import { useMutation, useQuery } from "react-query";
import { format } from "date-fns";
import { UploadFileSchema } from "validation";
import { useRef } from "react";
import { setUser } from "app/features/auth/authSlice";
import { getStudentById } from "API/studentApi";
import { getAdminByID } from "API/adminApi";
import { useAppSelector } from "app/hook";
import { Spinner } from "@chakra-ui/spinner";
import { getStaffById } from "API/staffApi";
import { getSchoolById } from "API/schoolApi";

const MyProfile = () => {
  const toast = useToast();
  const { role, user } = useAppSelector((state) => state.auth);
  const userId = user?._id;
  const {
    data: studentData,
    isLoading: studentLoading,
    error: studentError,
  } = useQuery<IStudent>(
    ["student", user?._id],
    () => getStudentById(user?._id),
    {
      enabled: !!userId && role === "student",
    }
  );

  const { isLoading: adminLoading, error: adminError } = useQuery<IStudent>(
    ["admin", user?._id],
    () => getAdminByID(user?._id),
    {
      enabled: !!userId && role === "admin",
    }
  );
  const { isLoading: staffLoading, error: staffError } = useQuery<IStaff>(
    ["staff", user?._id],
    () => getStaffById(user?._id),
    {
      enabled: !!userId && role === "staff",
    }
  );

  const {
    data: schoolData,
    isLoading: schoolLoading,
    error: schoolError,
  } = useQuery<ISchool>(["school", user?._id], () => getSchoolById(user?._id), {
    enabled: !!userId && role === "school",
  });

  const {
    isLoading,
    error: passwordError,
    data,
    mutateAsync,
  } = useMutation(updateProfile, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Profile Updated",
        description: `Your profile has been updated`,
      });
      setUser(data);
      window.location.reload();
    },
  });
  useErrorToast(passwordError);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      {(role === "student" && studentLoading) ||
        (role === "admin" && adminLoading) ||
        (role === "school" && schoolLoading) ||
        (role === "staff" && staffLoading) ? (
        <Center>
          <Spinner />
        </Center>
      ) : (role === "student" && studentError) ||
        (role === "admin" && adminError) ||
        (role === "school" && schoolError) ||
        (role === "staff" && staffError) ? (
        <Container maxW="1300px">
          <Stack align="center" justify="center" height="100vh">
            <Heading fontSize="xl" color="red.400">
              Profile Error ! Please try again!
            </Heading>
          </Stack>
        </Container>
      ) : (
        <Formik
          initialValues={{
            file: undefined,
            name: user?.name,
            email: user?.email,
            mobile: user?.mobile,
            ...(role === "student" && {
              dateOfBirth: format(
                new Date(studentData?.dateOfBirth ?? new Date().getDate()),
                "yyyy-MM-dd"
              ),
              uid: studentData?.uid,
              gender: studentData?.gender,
            }),
            ...(role === "school" && {
              address: schoolData?.contact.address,
              city: schoolData?.contact.city,
              state: schoolData?.contact.state,
              pincode: schoolData?.contact.pincode,
            }),
          }}
          validationSchema={UploadFileSchema}
          onSubmit={async (values) => {
            try {
              await mutateAsync({
                file: values.file ?? undefined,
                name: values.name,
                email: values.email,
                mobile: values.mobile,
                ...(role === "school" && {
                  address: values.address,
                  city: values.city,
                  state: values.state,
                  pincode: values.pincode,
                }),
                ...(role === "student" && {
                  uid: values.uid,
                  gender: values.gender,
                  dateOfBirth: values.dateOfBirth,
                }),
              });
            } catch (error) { }
          }}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <Grid gridColumn={{ base: 1, sm: 2 }} gridGap="4">
                <GridItem colSpan={{ base: 1, sm: 2 }}>
                  <Heading fontSize="xl">Update Profile</Heading>
                </GridItem>
                <GridItem>
                  <InputControl
                    name="name"
                    inputProps={{ placeholder: "Enter Name" }}
                    label="Name"
                  />
                </GridItem>
                <GridItem>
                  <InputControl
                    name="email"
                    inputProps={{
                      placeholder: "Enter Email",
                      type: "email",
                    }}
                    label="Email"
                  />
                </GridItem>
                <GridItem>
                  <InputControl
                    name="mobile"
                    inputProps={{
                      placeholder: "Enter Mobile No.",
                      type: "number",
                    }}
                    label="Mobile No."
                  />
                </GridItem>

                {role === "student" && (
                  <>
                    <GridItem>
                      <InputControl
                        name="uid"
                        inputProps={{
                          placeholder: "Enter Enrollment ID",
                        }}
                        label="Enrollment ID"
                      />
                    </GridItem>
                    <GridItem>
                      <InputControl
                        name="dateOfBirth"
                        inputProps={{
                          placeholder: "Enter Date of Birth...",
                          type: "date",
                        }}
                        label="Date of Birth"
                      />
                    </GridItem>
                    <GridItem>
                      <SelectControl
                        label="Gender"
                        name="gender"
                        selectProps={{ placeholder: "Select Gender" }}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </SelectControl>
                    </GridItem>
                  </>
                )}
                {role === "school" && (
                  <>
                    <GridItem colSpan={{ base: 1, sm: 2 }}>
                      <TextareaControl
                        name="address"
                        textareaProps={{
                          placeholder: "Enter Address",
                        }}
                        label="Address"
                      />
                    </GridItem>
                    <GridItem>
                      <InputControl
                        name="city"
                        inputProps={{ placeholder: "Enter City" }}
                        label="City"
                      />
                    </GridItem>
                    <GridItem>
                      <SelectControl name="state" label="State">
                        <option value="">Select State</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </SelectControl>
                    </GridItem>
                    <GridItem>
                      <InputControl
                        name="pincode"
                        label="Pincode"
                        inputProps={{ placeholder: "Enter Pincode" }}
                      />
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <Input
                    ref={fileInputRef}
                    name="file"
                    onChange={(event: any) => {
                      setFieldValue("file", event.target.files[0]);
                    }}
                    type="file"
                    hidden
                  />

                  {values.file && (
                    <>
                    <Button
                      onClick={() => fileInputRef.current?.click()}
                      variant="outline"
                      colorScheme="teal"
                      mb="4"
                    >
                      File - {(values as any).file.name}
                    </Button>
                     <Text fontSize={'12px'} color={'red'}>First click update button and refrash your page</Text>
                    </>
                    
                  )}
                  {!values.file && (
                    <>
                    <Button
                      leftIcon={<GoCloudUpload />}
                      colorScheme="teal"
                      variant="solid"
                      mb="4"
                      width={["55%", "35%"]}
                      onClick={() => fileInputRef.current?.click()}
                    >
                      Choose Profile Image
                    </Button>
                    <Text fontSize={'12px'} color={'red'}>Choose jpg, jpeg, png file</Text>
                    <Text fontSize={'12px'} color={'red'}>Less than 1MB and 200x200 is recommended</Text>
                    </>
                  )}
                  <Text
                    textAlign="start"
                    mb="4"
                    color="red"
                    fontWeight="400"
                    fontSize="sm"
                  >
                    <ErrorMessage name="file" />
                  </Text>
                </GridItem>

                <Flex justify="flex-end">
                  <SubmitButton
                    isLoading={isLoading}
                    mt="4"
                    type="submit"
                    colorScheme="green"
                    width="full"
                  >
                    Update
                  </SubmitButton>
                </Flex>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default MyProfile;
