import { Stack, Flex, Grid, Text } from "@chakra-ui/react";
import { FC } from "react";

interface MCQMResultProps {
  question: IAnsweredMCQMQuestion;
}

const MCQMResult: FC<MCQMResultProps> = ({ question }) => {
  return (
    <>
      <Stack>
        {question.attempted && question.isCorrect ? (
          <Text color="green">Correct</Text>
        ) : (
          <Text color="red">Incorrect</Text>
        )}
        {question.mcqMOptions.map((option, index) => (
          <Flex key={index} align="center">
            <Grid
              placeItems="center"
              boxSize={"50px"}
              borderRadius="xl"
              flexShrink="0"
              border={
                question.isCorrect
                  ? "none"
                  : question.mcqmSubmittedAnswer?.includes(index)
                  ? "1px solid red"
                  : "none"
              }
              bg={
                question.attempted
                  ? question.isCorrect
                    ? question.mcqmSubmittedAnswer?.includes(index)
                      ? "green.500"
                      : "gray.200"
                    : question.mcqMAnswer?.includes(index)
                    ? "green.500"
                    : "gray.200"
                  : question.mcqMAnswer?.includes(index)
                  ? "green.500"
                  : "gray.200"
              }
            >
              {String.fromCharCode(65 + index)}
            </Grid>{" "}
            <Text ml="4">{option}</Text>
          </Flex>
        ))}
      </Stack>{" "}
    </>
  );
};
export default MCQMResult;
