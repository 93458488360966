import { Heading, Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { setQuestionAnswer } from "app/features/question/questionSlice";
import { useAppDispatch } from "app/hook";
import { Formik, Form } from "formik";
import { FC } from "react";
import PrevAndNextButton from "./PrevAndNextButton";

interface TFQuestionProps {
  question: IAnsweredTFQuestion;
  prevQuestionHandler: () => void;
  nextQuestionHandler: () => void;
  index: number;
  isLastQuestion: boolean;
  setIsLastQuestion: React.Dispatch<React.SetStateAction<boolean>>;
}

const TFQuestion: FC<TFQuestionProps> = ({
  question,
  prevQuestionHandler,
  nextQuestionHandler,
  index,
  isLastQuestion,
  setIsLastQuestion,
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Heading fontSize="md" fontWeight="semibold">
        Choose one correct answer
      </Heading>
      <Formik
        enableReinitialize
        initialValues={{
          tFAnswer: question?.tfSubmittedAnswer ?? "",
          isPrev: false,
        }}
        onSubmit={(values) => {
          const tfAnswer = question.tFAnswer ? "T" : "F";
          if (values.tFAnswer === tfAnswer) {
            dispatch(
              setQuestionAnswer({
                index,
                isCorrect: true,
                tfSubmittedAnswer: values.tFAnswer === "T" ? "T" : "F",
                marks: question.positiveMarks,
                attempted: true,
              })
            );
          } else if (values.tFAnswer === "") {
            dispatch(
              setQuestionAnswer({
                index,
                attempted: false,
              })
            );
          } else {
            dispatch(
              setQuestionAnswer({
                index,
                isCorrect: false,
                tfSubmittedAnswer: values.tFAnswer === "F" ? "F" : "T",
                marks: question.negativeMarks,
                attempted: true,
              })
            );
          }
          values.isPrev ? prevQuestionHandler() : nextQuestionHandler();
          if (isLastQuestion) {
            setIsLastQuestion(true);
          }
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Stack>
              {["T", "F"].map((answer) => (
                <Flex
                  key={answer}
                  name={`tFAnswer`}
                  align="center"
                  onClick={() =>
                    setFieldValue("tFAnswer", answer === "T" ? "T" : "F")
                  }
                >
                  <Grid
                    placeItems="center"
                    boxSize={"50px"}
                    borderRadius="xl"
                    flexShrink="0"
                    bg={values.tFAnswer === answer ? "teal.400" : "gray.200"}
                    color={values.tFAnswer === answer ? "white" : "black"}
                  >
                    {answer}
                  </Grid>{" "}
                  <Text ml={4}> {answer === "T" ? "True" : "False"}</Text>
                </Flex>
              ))}
            </Stack>
            <PrevAndNextButton setFieldValue={setFieldValue} />
          </Form>
        )}
      </Formik>
    </>
  );
};
export default TFQuestion;
