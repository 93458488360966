import { HStack, Heading, Text, Box, Stack } from "@chakra-ui/layout";
import { getStudentById } from "API/studentApi";
import useErrorToast from "hook/error-toast";
import { Link as RoLink } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getPerformancesByProgramId } from "API/performanceApi";
import { Button, useDisclosure } from "@chakra-ui/react";
import {
  Select,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  InputGroup,
  Input,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Textarea,
} from "@chakra-ui/react";
import {
  addPerformanceReport,
  getPerformanceReportByStudentIdAndPerformanceId,
  updatePerformanceReport,
} from "API/performanceReportApi";
import { useMutation, useQueryClient } from "react-query";

import { useToast } from "@chakra-ui/toast";
import PerformanceReport from "./PerformanceReport";
import { Form, Formik } from "formik";
import { SubmitButton } from "formik-chakra-ui";
import { useAppSelector } from "app/hook";

interface PerformanceReportsProps {}

const PerformanceReports: FC<PerformanceReportsProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { user, role } = useAppSelector((state) => state.auth);
  const cache = useQueryClient();
  const { error: addError, mutateAsync: addMutate } = useMutation(
    "addPerformanceReport",
    addPerformanceReport,
    {
      onSuccess: () => {
        toast({
          title: "Performance Report Added",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        cache.invalidateQueries("performanceReports");
        // history.push(redirectTo);
      },
    }
  );

  useErrorToast(addError);

  const { error: updateError, mutateAsync: updateMutate } = useMutation(
    "updatePerformanceReport",
    updatePerformanceReport,
    {
      onSuccess: () => {
        toast({
          title: "Performance Report Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        cache.invalidateQueries("performanceReports");

        // history.push(redirectTo);
      },
    }
  );
  useErrorToast(updateError);

  const [performanceReportId, setPerformanceReportId] = useState("");
  const [selectedPerformance, setSelectedPerformance] = useState<any>();
  const [state, setState] = useState<any>({
    parameters_result: [],
    expectedBehavior: {
      knowledge: {
        fundamental_of_subjects: 0,
        voculabry_of_all_subjects: 0,
        current_information_of_different_subjects: 0,
      },
      skills: {
        ability_to_present_logically_seq: 0,
        writing_skills: 0,
        verbal_skills: 0,
        ability_to_debate_and_discuss: 0,
      },
      attitude: {
        to_excel: 0,
        to_get_feedback: 0,
        getting_along_with_people: 0,
        to_work_in_group_team: 0,
      },
      moralValue: {
        respectful: 0,
        honest: 0,
        timeliness: 0,
      },
    },
    careerRemark: "",
    academicsRemark: "",
    year: 0,
  });

  const { id } = useParams<{ id: string }>();

  const studentId = role === "student" ? user?._id : id;
  const {
    data: student,

    error: errorStudent,
  } = useQuery<IStudent>(
    ["student", studentId],
    () => getStudentById(studentId),
    {
      enabled: !!studentId,
    }
  );

  const {
    data: performanceReport,

    error: errorPerformanceReport,
  } = useQuery(["performanceReport", id, selectedPerformance?._id], () =>
    getPerformanceReportByStudentIdAndPerformanceId({
      studentId: id,
      performanceId: selectedPerformance?._id,
    })
  );

  useErrorToast(errorStudent);

  const activeProgram = student?.activePrograms?.filter((activeProgram) => {
    return activeProgram.purchasedProduct?.product.access.includes(
      "report" as ProductType.report
    );
  })[0];

  const activeProgramId = activeProgram?.program?._id;

  const { data: performanceReports } = useQuery(
    ["performanceReports", activeProgramId],
    () =>
      getPerformancesByProgramId({
        programId: activeProgramId ?? "",
      }),
    {
      enabled: !!activeProgramId,
    }
  );

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (selectedPerformance) {
      if (
        state &&
        state.parameters_result &&
        state.parameters_result.length === 0
      ) {
        setState({
          ...state,
          parameters_result: selectedPerformance.parameters,
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedPerformance]);

  useErrorToast(errorPerformanceReport);

  useEffect(() => {
    if (performanceReport && performanceReport.length > 0) {
      console.log(state.parameters_result);
      console.log(performanceReport[0].parameters_result);
      setIsUpdate(true);
      setPerformanceReportId(performanceReport[0]._id);
      setState({
        expectedBehavior: performanceReport[0].expectedBehavior,
        academicsRemark: performanceReport[0].academicsRemark,
        careerRemark: performanceReport[0].careerRemark,
        year: performanceReport[0].year,
        parameters_result: performanceReport[0].parameters_result,
      });
    }
    // eslint-disable-next-line
  }, [performanceReport]);

  const [isDraft, setIsDraft] = useState(false);

  const handleClick = (obj: any) => {
    setSelectedPerformance(obj);
    onOpen();
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottom={"1px"}>
            <HStack spacing={"20px"}>
              <Heading size="md">Student name: {student?.name}</Heading>{" "}
              <Heading size="md">
                Report name: {selectedPerformance?.name}
              </Heading>
            </HStack>
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            {" "}
            {selectedPerformance?.program.subjects?.map((subj: any) => (
              <Box
                p="4"
                key={subj._id}
                borderBottom="1px"
                borderColor="gray.200"
              >
                <HStack spacing={"10px"}>
                  <Heading
                    w={"100px"}
                    fontSize="sm"
                    textTransform={"capitalize"}
                  >
                    {subj?.name}
                  </Heading>
                  <HStack spacing={"10px"} overflowX={"auto"}>
                    {state.parameters_result?.map(
                      (parameter: any, index: number) =>
                        parameter.subject === subj?._id ? (
                          <Stack key={index} w={"120px"}>
                            <Text
                              key={parameter._id}
                              as="span"
                              textTransform="capitalize"
                              fontSize="sm"
                            >
                              {parameter?.name}
                            </Text>
                            <InputGroup size="xs">
                              <Input
                                pr="2rem"
                                type="number"
                                placeholder="Marks"
                                value={parameter?.marks_obtained}
                                onChange={(e) => {
                                  const updatedParameterResult =
                                    state.parameters_result.map(
                                      (parameterResult: any) =>
                                        parameterResult._id === parameter._id
                                          ? {
                                              ...parameterResult,
                                              marks_obtained: e.target.value,
                                            }
                                          : parameterResult
                                    );

                                  setState({
                                    ...state,
                                    parameters_result: updatedParameterResult,
                                  });
                                }}
                              />
                              <InputRightElement width="2rem">
                                <Text size="sm" color={"blue"}>
                                  / {parameter?.marks}
                                </Text>
                              </InputRightElement>
                            </InputGroup>
                            <Popover placement="right">
                              <PopoverTrigger>
                                <Button
                                  size="xs"
                                  disabled={
                                    parameter?.lenovo?.filter((elem: any) =>
                                      elem !== "" ? true : false
                                    ).length === 0
                                  }
                                >
                                  Standards
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverHeader>
                                  Qualitative standards
                                </PopoverHeader>
                                <PopoverCloseButton />
                                <PopoverBody>
                                  {parameter?.lenovo?.map(
                                    (lenovo: any, index: number) =>
                                      lenovo !== "" && (
                                        <HStack key={index}>
                                          <Text w={"70%"} fontSize={"xs"}>
                                            {typeof lenovo === "string"
                                              ? lenovo
                                              : lenovo?.name}
                                          </Text>

                                          <Select
                                            placeholder="Select option"
                                            w={"30%"}
                                            size={"xs"}
                                            onChange={(e) => {
                                              const { value } = e.target;
                                              const updatedParameterResult =
                                                state.parameters_result.map(
                                                  (parameterResult: any) => {
                                                    if (
                                                      parameterResult._id !==
                                                      parameter._id
                                                    ) {
                                                      return parameterResult;
                                                    } else {
                                                      const updatedLenovo =
                                                        parameterResult.lenovo.map(
                                                          (newLenovo: any) => {
                                                            if (
                                                              newLenovo ===
                                                              lenovo
                                                            ) {
                                                              return {
                                                                name:
                                                                  typeof newLenovo ===
                                                                  "string"
                                                                    ? newLenovo
                                                                    : newLenovo &&
                                                                      newLenovo.name,
                                                                rating: value,
                                                              };
                                                            } else {
                                                              return newLenovo;
                                                            }
                                                          }
                                                        );
                                                      return {
                                                        ...parameterResult,
                                                        lenovo: updatedLenovo,
                                                      };
                                                    }
                                                  }
                                                );

                                              setState({
                                                ...state,
                                                parameters_result:
                                                  updatedParameterResult,
                                              });
                                            }}
                                            value={lenovo?.rating}
                                          >
                                            <option value="Poor">Poor</option>
                                            <option value="Satisfactory">
                                              Satisfactory
                                            </option>
                                            <option value="Good">Good</option>
                                            <option value="Very good">
                                              Very good
                                            </option>
                                            <option value="Excellent">
                                              Excellent
                                            </option>
                                          </Select>
                                        </HStack>
                                      )
                                  )}
                                  <PopoverTrigger>
                                    <Button colorScheme={"teal"} size={"sm"}>
                                      Done
                                    </Button>
                                  </PopoverTrigger>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Stack>
                        ) : null
                    )}
                  </HStack>
                </HStack>
              </Box>
            ))}
            <Heading size="sm" px={4} py={3} w="100%" bgColor={"gray.100"}>
              Expected Behaviour
            </Heading>
            <HStack
              spacing={"30px"}
              alignItems={"flex-start"}
              borderBottom="1px"
              borderColor="gray.200"
              py={"20px"}
            >
              <Stack spacing={"10px"} direction={"column"} w={"25%"}>
                <HStack spacing={"10px"}>
                  <Heading size="xs" w={"80%"}>
                    Knowledge
                  </Heading>
                  <Heading size="xs" w={"20%"} textAlign={"right"}>
                    Marks
                  </Heading>
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Fundamental of subjects
                  </Text>
                  <Input
                    type="number"
                    placeholder="Marks"
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    value={
                      state.expectedBehavior.knowledge.fundamental_of_subjects
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          knowledge: {
                            ...state.expectedBehavior.knowledge,
                            fundamental_of_subjects: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Voculabry of all subjects
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={
                      state.expectedBehavior.knowledge.voculabry_of_all_subjects
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          knowledge: {
                            ...state.expectedBehavior.knowledge,
                            voculabry_of_all_subjects: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize={"sm"} w={"80%"}>
                    Current information of different subjects
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={
                      state.expectedBehavior.knowledge
                        .current_information_of_different_subjects
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          knowledge: {
                            ...state.expectedBehavior.knowledge,
                            current_information_of_different_subjects:
                              e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
              </Stack>

              {/* <HStack spacing={'20px'}>
                <Box>
                  <Heading size="sm">Knowledge</Heading>
                  <Text fontSize="sm">Fundamental of subjects</Text>
                  <Text fontSize="sm">Voculabry of all subjects</Text>
                  <Text fontSize="sm">
                    Current information of different subjects
                  </Text>
                </Box>
                <Stack>
                  <Heading size="sm">Marks</Heading>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={
                        state.expectedBehavior.knowledge.fundamental_of_subjects
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            knowledge: {
                              ...state.expectedBehavior.knowledge,
                              fundamental_of_subjects: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={
                        state.expectedBehavior.knowledge
                          .voculabry_of_all_subjects
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            knowledge: {
                              ...state.expectedBehavior.knowledge,
                              voculabry_of_all_subjects: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={
                        state.expectedBehavior.knowledge
                          .current_information_of_different_subjects
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            knowledge: {
                              ...state.expectedBehavior.knowledge,
                              current_information_of_different_subjects:
                                e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                </Stack>
              </HStack> */}
              <Stack spacing={"10px"} direction={"column"} w={"25%"}>
                <HStack spacing={"10px"}>
                  <Heading size="xs" w={"80%"}>
                    Skills
                  </Heading>
                  <Heading size="xs" w={"20%"} textAlign={"right"}>
                    Marks
                  </Heading>
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Ability to present logically & seq
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={
                      state.expectedBehavior.skills
                        .ability_to_present_logically_seq
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          skills: {
                            ...state.expectedBehavior.skills,
                            ability_to_present_logically_seq: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Writting skills
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={state.expectedBehavior.skills.writing_skills}
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          skills: {
                            ...state.expectedBehavior.skills,
                            writing_skills: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Verbal skills
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={state.expectedBehavior.skills.verbal_skills}
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          skills: {
                            ...state.expectedBehavior.skills,
                            verbal_skills: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Ability to debate and discuss
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={
                      state.expectedBehavior.skills
                        .ability_to_debate_and_discuss
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          skills: {
                            ...state.expectedBehavior.skills,
                            ability_to_debate_and_discuss: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
              </Stack>

              {/* <HStack spacing={20}>
                <Stack>
                  <Heading size="sm">Skills</Heading>
                  <Text fontSize="sm">Ability to present logically & seq</Text>
                  <Text fontSize="sm">Writting skills</Text>
                  <Text fontSize="sm">Verbal skills</Text>
                  <Text fontSize="sm">Ability to debate and discuss</Text>
                </Stack>
                <Stack>
                  <Heading size="sm">Marks</Heading>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={
                        state.expectedBehavior.skills
                          .ability_to_present_logically_seq
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            skills: {
                              ...state.expectedBehavior.skills,
                              ability_to_present_logically_seq: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={state.expectedBehavior.skills.writing_skills}
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            skills: {
                              ...state.expectedBehavior.skills,
                              writing_skills: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={state.expectedBehavior.skills.verbal_skills}
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            skills: {
                              ...state.expectedBehavior.skills,
                              verbal_skills: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={
                        state.expectedBehavior.skills
                          .ability_to_debate_and_discuss
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            skills: {
                              ...state.expectedBehavior.skills,
                              ability_to_debate_and_discuss: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                </Stack>
              </HStack> */}
              <Stack spacing={"10px"} direction={"column"} w={"25%"}>
                <HStack spacing={"10px"}>
                  <Heading size="xs" w={"80%"}>
                    Attitude
                  </Heading>
                  <Heading size="xs" w={"20%"} textAlign={"right"}>
                    Marks
                  </Heading>
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    To excel
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={state.expectedBehavior.attitude.to_excel}
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          attitude: {
                            ...state.expectedBehavior.attitude,
                            to_excel: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    To get feedback
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={state.expectedBehavior.attitude.to_get_feedback}
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          attitude: {
                            ...state.expectedBehavior.attitude,
                            to_get_feedback: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Getting along with people
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={
                      state.expectedBehavior.attitude.getting_along_with_people
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          attitude: {
                            ...state.expectedBehavior.attitude,
                            getting_along_with_people: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    To work in group/ team
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={
                      state.expectedBehavior.attitude.to_work_in_group_team
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          attitude: {
                            ...state.expectedBehavior.attitude,
                            to_work_in_group_team: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
              </Stack>

              {/* <HStack spacing={20}>
                <Stack>
                  <Heading size="sm">Attitude</Heading>
                  <Text fontSize="sm">To excel</Text>
                  <Text fontSize="sm">To get feedback</Text>
                  <Text fontSize="sm">Getting along with people</Text>
                  <Text fontSize="sm">To work in group/ team</Text>
                </Stack>
                <Stack>
                  <Heading size="sm">Marks</Heading>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={state.expectedBehavior.attitude.to_excel}
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            attitude: {
                              ...state.expectedBehavior.attitude,
                              to_excel: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={state.expectedBehavior.attitude.to_get_feedback}
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            attitude: {
                              ...state.expectedBehavior.attitude,
                              to_get_feedback: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={
                        state.expectedBehavior.attitude
                          .getting_along_with_people
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            attitude: {
                              ...state.expectedBehavior.attitude,
                              getting_along_with_people: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={
                        state.expectedBehavior.attitude.to_work_in_group_team
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            attitude: {
                              ...state.expectedBehavior.attitude,
                              to_work_in_group_team: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                </Stack>
              </HStack> */}
              <Stack spacing={"10px"} direction={"column"} w={"25%"}>
                <HStack spacing={"10px"}>
                  <Heading size="xs" w={"80%"}>
                    Moral Value
                  </Heading>
                  <Heading size="xs" w={"20%"} textAlign={"right"}>
                    Marks
                  </Heading>
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Respectful
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={state.expectedBehavior.moralValue.respectful}
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          moralValue: {
                            ...state.expectedBehavior.moralValue,
                            respectful: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Honest
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={state.expectedBehavior.moralValue.honest}
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          moralValue: {
                            ...state.expectedBehavior.moralValue,
                            honest: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
                <HStack spacing={"10px"}>
                  <Text fontSize="sm" w={"80%"}>
                    Timeliness
                  </Text>
                  <Input
                    size="xs"
                    w={"20%"}
                    textAlign={"center"}
                    type="number"
                    placeholder="Marks"
                    value={state.expectedBehavior.moralValue.timeliness}
                    onChange={(e) => {
                      setState({
                        ...state,
                        expectedBehavior: {
                          ...state.expectedBehavior,
                          moralValue: {
                            ...state.expectedBehavior.moralValue,
                            timeliness: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </HStack>
              </Stack>

              {/* <HStack spacing={20}>
                <Stack>
                  <Heading size="sm">Moral Value</Heading>
                  <Text fontSize="sm">Respectful</Text>
                  <Text fontSize="sm">Honest</Text>

                  <Text fontSize="sm">Timeliness</Text>
                </Stack>
                <Stack>
                  <Heading size="sm">Marks</Heading>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={state.expectedBehavior.moralValue.respectful}
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            moralValue: {
                              ...state.expectedBehavior.moralValue,
                              respectful: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={state.expectedBehavior.moralValue.honest}
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            moralValue: {
                              ...state.expectedBehavior.moralValue,
                              honest: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="sm">
                    <Input
                      pr="1.5rem"
                      type="number"
                      placeholder="Marks"
                      value={state.expectedBehavior.moralValue.timeliness}
                      onChange={(e) => {
                        setState({
                          ...state,
                          expectedBehavior: {
                            ...state.expectedBehavior,
                            moralValue: {
                              ...state.expectedBehavior.moralValue,
                              timeliness: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                    <InputRightElement width="1.5rem">
                      <Heading size="sm">10</Heading>
                    </InputRightElement>
                  </InputGroup>
                </Stack>
              </HStack> */}
            </HStack>
            <HStack spacing={"20px"} mt={"30px"} mb={"20px"}>
              <Box w={"50%"}>
                <Heading size="sm" mb={"10px"}>
                  Academics remark
                </Heading>
                <Textarea
                  value={state.academicsRemark}
                  onChange={(e) =>
                    setState({
                      ...state,
                      academicsRemark: e.target.value,
                    })
                  }
                  // placeholder='Here is a sample placeholder'
                  size="lg"
                />
              </Box>
              <Box w={"50%"}>
                <Heading size="sm" mb={"10px"}>
                  Career remark
                </Heading>
                <Textarea
                  value={state.careerRemark}
                  onChange={(e) =>
                    setState({
                      ...state,
                      careerRemark: e.target.value,
                    })
                  }
                  // placeholder='Here is a sample placeholder'
                  size="lg"
                />
              </Box>
            </HStack>
            <Box>
              <Heading size="sm" mb={"10px"}>
                Year
              </Heading>{" "}
              <Input
                pr="1.5rem"
                type="number"
                value={state.year}
                onChange={(e) =>
                  setState({
                    ...state,
                    year: e.target.value,
                  })
                }
              />
            </Box>
            <HStack mt="12">
              <Formik
                initialValues={{}}
                onSubmit={async (values) => {
                  const updatedParameterResult = state.parameters_result.map(
                    (data: any) => {
                      // eslint-disable-next-line
                      const updatedLenovo = data.lenovo.map(
                        (newlenovo: any) => {
                          if (newlenovo && !newlenovo.rating) {
                            return {
                              name: newlenovo,
                              rating: "",
                            };
                          } else {
                            return {
                              name: newlenovo.name,
                              rating: newlenovo.rating,
                            };
                          }
                        }
                      );
                      return {
                        ...data,
                        lenovo: updatedLenovo,
                      };
                    }
                  );

                  if (!isUpdate) {
                    await addMutate({
                      ...state,
                      performanceId: selectedPerformance?._id,
                      studentId: id,
                      schoolId: student?.school,
                      isDraft,
                      parameters_result: updatedParameterResult,
                    });
                  } else {
                    await updateMutate({
                      ...state,
                      id: performanceReportId,
                      performanceId: selectedPerformance?._id,
                      studentId: id,
                      schoolId: student?.school,
                      isDraft,
                      parameters_result: updatedParameterResult,
                    });
                  }
                }}
              >
                <Form>
                  <SubmitButton
                    colorScheme="teal"
                    onClick={() => {
                      setIsDraft(true);
                    }}
                  >
                    Save as Draft
                  </SubmitButton>
                </Form>
              </Formik>
              <Formik
                initialValues={{}}
                onSubmit={async (values) => {
                  const updatedParameterResult = state.parameters_result.map(
                    (data: any) => {
                      // eslint-disable-next-line
                      const updatedLenovo = data.lenovo
                        .filter((elem: any) => (elem === "" ? false : true))
                        .map((newlenovo: any) => {
                          if (newlenovo && !newlenovo.rating) {
                            return {
                              name: newlenovo,
                              rating: "",
                            };
                          } else {
                            return {
                              name: newlenovo.name,
                              rating: newlenovo.rating,
                            };
                          }
                        });
                      return {
                        ...data,
                        lenovo: updatedLenovo,
                      };
                    }
                  );

                  if (!isUpdate) {
                    await addMutate({
                      ...state,
                      performanceId: selectedPerformance?._id,
                      studentId: id,
                      schoolId: student?.school,
                      isDraft,
                      parameters_result: updatedParameterResult,
                    });
                  } else {
                    await updateMutate({
                      ...state,
                      id: performanceReportId,
                      performanceId: selectedPerformance?._id,
                      studentId: id,
                      schoolId: student?.school,
                      isDraft,
                      parameters_result: updatedParameterResult,
                    });
                  }
                }}
              >
                <Form>
                  <SubmitButton
                    colorScheme="teal"
                    onClick={() => {
                      setIsDraft(false);
                    }}
                  >
                    Publish
                  </SubmitButton>
                </Form>
              </Formik>
            </HStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Heading size="md">
        <Heading
          color="teal.500"
          as={RoLink}
          size="md"
          to={"/school/dashboard/users/student"}
        >
          {student?.name}
        </Heading>{" "}
        / Performance Reports ({activeProgram?.program?.name})
      </Heading>
      <Box
        w={"100%"}
        border="1px"
        borderColor="gray.200"
        borderRadius={"4px"}
        mt={"15px"}
      >
        {performanceReports?.map((performanceReport: any) =>
          (role === "student" && performanceReport?.isDraft === false) ||
          role !== "student" ? (
            <PerformanceReport
              key={performanceReport._id}
              performanceReport={performanceReport}
              handleClick={handleClick}
              student={student}
              isUpdateDisabled={user.role === "student" ? true : false}
            />
          ) : null
        )}
        {user.role === "student" &&
          performanceReports &&
          performanceReports.filter((performanceReport: any) => {
            return performanceReport.isDraft === false;
          })?.length === 0 && (
            <Heading size="md">Your result is not publish yet</Heading>
          )}
      </Box>
    </>
  );
};

export default PerformanceReports;
