import { HStack, Heading, Text, Box, Stack } from "@chakra-ui/layout";
import useErrorToast from "hook/error-toast";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Image,
} from "@chakra-ui/react";
import { useAppSelector } from "app/hook";
import { getPerformanceReportByStudentIdAndPerformanceId } from "API/performanceReportApi";
import { useParams, Link as RoLink } from "react-router-dom";

interface PerformanceReportsViewProps {}

const PerformanceReportsView: FC<PerformanceReportsViewProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { idPerformance } = useParams<{ idPerformance: string }>();
  const { user, role } = useAppSelector((state) => state.auth);

  const [state, setState] = useState<any>({
    parameters_result: [],
    expectedBehavior: {
      knowledge: {
        fundamental_of_subjects: 0,
        voculabry_of_all_subjects: 0,
        current_information_of_different_subjects: 0,
      },
      skills: {
        ability_to_present_logically_seq: 0,
        writing_skills: 0,
        verbal_skills: 0,
        ability_to_debate_and_discuss: 0,
      },
      attitude: {
        to_excel: 0,
        to_get_feedback: 0,
        getting_along_with_people: 0,
        to_work_in_group_team: 0,
      },
      moralValue: {
        respectful: 0,
        honest: 0,
        timeliness: 0,
      },
    },
    careerRemark: "",
    academicsRemark: "",
    year: 0,
  });

  const { data: performanceReport, error: errorPerformanceReport } = useQuery(
    ["performanceReport", role === "student" ? user._id : id, idPerformance],
    () =>
      getPerformanceReportByStudentIdAndPerformanceId({
        studentId: role === "student" ? user._id : id,
        performanceId: idPerformance,
      })
  );

  useErrorToast(errorPerformanceReport);

  const [performanceReportSingle, setPerformanceReport] = useState<any>();

  useEffect(() => {
    if (performanceReport && performanceReport.length > 0) {
      setPerformanceReport(performanceReport[0]);
      setState({
        expectedBehavior: performanceReport[0].expectedBehavior,
        academicsRemark: performanceReport[0].academicsRemark,
        careerRemark: performanceReport[0].careerRemark,
        year: performanceReport[0].year,
        parameters_result: performanceReport[0].parameters_result,
      });
    }
  }, [performanceReport]);

  const totalOfAttitude = Object.values(state.expectedBehavior.attitude).reduce(
    (a, b) => Number(a) + Number(b)
  ); // get total of attitude

  const totalOfMoralValue = Object.values(
    state.expectedBehavior.moralValue
  ).reduce((a, b) => Number(a) + Number(b)); // get total of moral value

  const totalOfSkills = Object.values(state.expectedBehavior.skills).reduce(
    (a, b) => Number(a) + Number(b)
  ); // get total of skills

  const totalOfKnowledge = Object.values(
    state.expectedBehavior.knowledge
  ).reduce((a, b) => Number(a) + Number(b)); // get total of knowledge

  const totalOfExpectedBehavior =
    Number(totalOfSkills) +
    Number(totalOfKnowledge) +
    Number(totalOfAttitude) +
    Number(totalOfMoralValue);

  const totalOfAcademics = state.parameters_result.reduce(
    (a: any, b: any) => Number(a) + Number(b.marks_obtained),
    0
  );

  const to =
    role === "student"
      ? "/student/dashboard/performance-reports"
      : `/school/dashboard/users/student/edit/${id}/performance-reports`;
  return performanceReportSingle?.isDraft === false ? (
    <>
      <Heading size="md" mb={"20px"}>
        <Heading as={RoLink} to={to} size="md" color={"teal"}>
          Back
        </Heading>{" "}
        / {performanceReportSingle?.performance.name}
      </Heading>
      <Box border="1px" borderColor="gray.200" borderRadius={"4px"}>
        <Stack
          direction={["column", "row"]}
          spacing="30px"
          borderBottom={"1px"}
          borderColor="gray.200"
          p={"15px"}
        >
          <Box flexShrink={"0"}>
            <Image
              src={
                process.env.NODE_ENV === "production"
                  ? performanceReportSingle?.school?.avatar
                  : process.env.REACT_APP_API_URL?.split("/api")[0] +
                    performanceReportSingle?.school?.avatar
              }
              alt="performance report"
              style={{
                width: 80,
                height: 80,
              }}
            />
          </Box>
          <Box flexGrow={"1"}>
            <Heading size="md" mb={"10px"}>
              {performanceReportSingle.school.name}
            </Heading>
            <Stack direction={["column", "row"]} spacing={"30px"}>
              <Box w={["100%", "40%"]}>
                <Text fontSize={"sm"}>
                  Summary of students Performance for the year:{" "}
                  <Text as={"span"} fontWeight={"bold"}>
                    {" "}
                    {performanceReportSingle.year}
                  </Text>
                </Text>
                <Text fontSize={"sm"}>
                  Student name:
                  <Text as={"span"} fontWeight={"bold"}>
                    {" "}
                    {performanceReportSingle.student.name}
                  </Text>
                </Text>

                <Text fontSize={"sm"}>
                  Unique No:
                  <Text as={"span"} fontWeight={"bold"}>
                    {" "}
                    {performanceReportSingle.student.uid}
                  </Text>
                </Text>
              </Box>
              <Box w={["100%", "60%"]}>
                <Text fontSize={"sm"}>
                  Basic Objective Academics:
                  <Text as={"span"} fontWeight={"bold"}>
                    {" "}
                    {
                      performanceReportSingle.performance
                        .basic_objective_academics
                    }
                  </Text>
                </Text>
                <Text fontSize={"sm"}>
                  Career Objectives 1:
                  <Text as={"span"} fontWeight={"bold"}>
                    {" "}
                    {performanceReportSingle.performance.career_objectives_1}
                  </Text>
                </Text>
                <Text fontSize={"sm"}>
                  Career Objectives 2:
                  <Text as={"span"} fontWeight={"bold"}>
                    {" "}
                    {performanceReportSingle.performance.career_objectives_2}
                  </Text>
                </Text>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Box w={"100%"} overflowX={"auto"}>
          {performanceReportSingle?.performance?.program?.subjects?.map(
            (subj: any) => (
              <Box
                p="4"
                key={subj._id}
                borderBottom="1px"
                borderColor="gray.200"
              >
                <Box w={"100%"}>
                  <HStack spacing={"10px"}>
                    <Heading
                      w={"100px"}
                      flexShrink={"0"}
                      fontSize="sm"
                      textTransform={"capitalize"}
                    >
                      {subj?.name}
                    </Heading>
                    <HStack spacing={"10px"} alignItems={"end"}>
                      {state.parameters_result?.map(
                        (parameter: any, index: number) =>
                          parameter.subject === subj?._id ? (
                            <Stack key={index} w={"120px"}>
                              <Text
                                key={parameter._id}
                                textTransform="capitalize"
                                fontSize="sm"
                              >
                                {parameter?.name}
                              </Text>

                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    bg={
                                      parameter && parameter.marks_obtained >= 7
                                        ? "green.300"
                                        : parameter.marks_obtained > 4
                                        ? "blue.200"
                                        : "yellow.200"
                                    }
                                  >
                                    {parameter?.marks_obtained}/
                                    {parameter?.marks}
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverHeader>
                                    Qualitative standards
                                  </PopoverHeader>
                                  <PopoverBody>
                                    {parameter?.lenovo?.map(
                                      (lenovo: any, index: number) => (
                                        <HStack key={index}>
                                          <Text fontSize="sm">
                                            {lenovo?.name || lenovo}
                                          </Text>
                                          <Text
                                            fontSize="sm"
                                            fontWeight={"bold"}
                                          >
                                            {lenovo?.rating}
                                          </Text>
                                        </HStack>
                                      )
                                    )}
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            </Stack>
                          ) : null
                      )}
                      <Stack w={"120px"}>
                        <Text fontSize="sm">Avg Marks</Text>
                        <Button size={"xs"}>
                          {state.parameters_result
                            .filter((parameter: any) => {
                              return parameter.subject === subj._id;
                            })
                            .reduce(
                              (a: any, b: any) => +a + +b.marks_obtained,
                              0
                            ) /
                            state.parameters_result.filter((parameter: any) => {
                              return parameter.subject === subj._id;
                            }).length}
                          /
                          {state.parameters_result
                            .filter((parameter: any) => {
                              return parameter.subject === subj._id;
                            })
                            .reduce((a: any, b: any) => +a + +b.marks, 0) /
                            state.parameters_result.filter((parameter: any) => {
                              return parameter.subject === subj._id;
                            }).length}
                        </Button>
                      </Stack>
                    </HStack>
                  </HStack>
                </Box>
              </Box>
            )
          )}
        </Box>
        <Heading size="sm" px={4} py={3} w="100%" bgColor={"gray.100"}>
          Expected Behaviour
        </Heading>
        <Stack
          direction={["column", "row"]}
          spacing={"30px"}
          alignItems={"flex-start"}
          borderBottom="1px"
          borderColor="gray.200"
          py={"20px"}
          px={"15px"}
        >
          <Stack spacing={"10px"} direction={"column"} w={["100%", "25%"]}>
            <HStack spacing={"10px"}>
              <Heading size="xs" w={"80%"}>
                Knowledge
              </Heading>
              <Heading size="xs" w={"20%"} textAlign={"center"}>
                Marks
              </Heading>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Fundamental of subjects
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.knowledge?.fundamental_of_subjects}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Voculabry of all subjects
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.knowledge?.voculabry_of_all_subjects}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Current information of different subjects
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {
                  state.expectedBehavior?.knowledge
                    ?.current_information_of_different_subjects
                }
              </Text>
            </HStack>
          </Stack>
          {/* <HStack spacing={20}>
            <Stack>
              <Heading size="sm">Knowledge</Heading>
              <Text fontSize="sm">Fundamental of subjects</Text>
              <Text fontSize="sm">Voculabry of all subjects</Text>
              <Text fontSize="sm">Current information of different subjects</Text>
            </Stack>
            <Stack>
              <Heading size="sm">Marks</Heading>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.knowledge?.fundamental_of_subjects}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.knowledge?.voculabry_of_all_subjects}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {
                  state.expectedBehavior?.knowledge
                    ?.current_information_of_different_subjects
                }
              </Text>
            </Stack>
          </HStack> */}

          <Stack spacing={"10px"} direction={"column"} w={["100%", "25%"]}>
            <HStack spacing={"10px"}>
              <Heading size="xs" w={"80%"}>
                Skills
              </Heading>
              <Heading size="xs" w={"20%"} textAlign={"center"}>
                Marks
              </Heading>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Ability to present logically & seq
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {
                  state.expectedBehavior?.skills
                    ?.ability_to_present_logically_seq
                }
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Writting skills
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.skills?.writing_skills}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Verbal skills
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.skills?.verbal_skills}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Ability to debate and discuss
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.skills?.ability_to_debate_and_discuss}
              </Text>
            </HStack>
          </Stack>

          {/* <HStack spacing={20}>
            <Stack>
              <Heading size="sm">Skills</Heading>
              <Text fontSize="sm">Ability to present logically & seq</Text>
              <Text fontSize="sm">Writting skills</Text>
              <Text fontSize="sm">Verbal skills</Text>
              <Text fontSize="sm">Ability to debate and discuss</Text>
            </Stack>
            <Stack>
              <Heading size="sm">Marks</Heading>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.skills?.ability_to_present_logically_seq}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.skills?.writing_skills}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.skills?.verbal_skills}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.skills?.ability_to_debate_and_discuss}
              </Text>
            </Stack>
          </HStack> */}

          <Stack spacing={"10px"} direction={"column"} w={["100%", "25%"]}>
            <HStack spacing={"10px"}>
              <Heading size="xs" w={"80%"}>
                Attitude
              </Heading>
              <Heading size="xs" w={"20%"} textAlign={"center"}>
                Marks
              </Heading>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                To excel
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.attitude?.to_excel}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                To get feedback
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.attitude?.to_get_feedback}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Getting along with people
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.attitude?.getting_along_with_people}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                To work in group/ team
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.attitude?.to_work_in_group_team}
              </Text>
            </HStack>
          </Stack>
          {/* <HStack spacing={20}>
            <Stack>
              <Heading size="sm">Attitude</Heading>
              <Text fontSize="sm">To excel</Text>
              <Text fontSize="sm">To get feedback</Text>
              <Text fontSize="sm">Getting along with people</Text>
              <Text fontSize="sm">To work in group/ team</Text>
            </Stack>
            <Stack>
              <Heading size="sm">Marks</Heading>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.attitude?.to_excel}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.attitude?.to_get_feedback}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.attitude?.getting_along_with_people}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.attitude?.to_work_in_group_team}
              </Text>
            </Stack>
          </HStack> */}

          <Stack spacing={"10px"} direction={"column"} w={["100%", "25%"]}>
            <HStack spacing={"10px"}>
              <Heading size="xs" w={"80%"}>
                Moral Value
              </Heading>
              <Heading size="xs" w={"20%"} textAlign={"center"}>
                Marks
              </Heading>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Respectful
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.moralValue?.respectful}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Honest
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.moralValue?.honest}
              </Text>
            </HStack>
            <HStack spacing={"10px"}>
              <Text fontSize="sm" w={"80%"}>
                Timeliness
              </Text>
              <Text
                fontSize="sm"
                w={"20%"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {state.expectedBehavior?.moralValue?.timeliness}
              </Text>
            </HStack>
          </Stack>
          {/* <HStack spacing={20}>
            <Stack>
              <Heading size="sm">Moral Value</Heading>
              <Text fontSize="sm">Respectful</Text>
              <Text fontSize="sm">Honest</Text>

              <Text fontSize="sm">Timeliness</Text>
            </Stack>
            <Stack>
              <Heading size="sm">Marks</Heading>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.moralValue?.respectful}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.moralValue?.honest}
              </Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {state.expectedBehavior?.moralValue?.timeliness}
              </Text>
            </Stack>
          </HStack> */}
        </Stack>
        <Heading size="sm" px={4} py={3} w="100%" bgColor={"gray.100"}>
          Academics Marks: {totalOfAcademics} + Behavioural Marks:{" "}
          {totalOfExpectedBehavior} = Total Marks:{" "}
          {totalOfAcademics + totalOfExpectedBehavior}
        </Heading>
        <Stack
          spacing={"30px"}
          px={"15px"}
          py={"20px"}
          direction={["column", "row"]}
        >
          <Box w={["100%", "50%"]}>
            <Heading size="sm" mb={"10px"}>
              Academics remark
            </Heading>
            <Text fontSize={"sm"}>{state.academicsRemark}</Text>
          </Box>
          <Box w={["100%", "50%"]}>
            <Heading size="sm" mb={"10px"}>
              Career remark
            </Heading>
            <Text fontSize={"sm"}>{state.careerRemark}</Text>
          </Box>
        </Stack>
      </Box>
    </>
  ) : (
    <Heading size="md">Report is not publish yet</Heading>
  );
};

export default PerformanceReportsView;
