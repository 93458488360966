import {
  Button,
  Center,
  Grid,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import useErrorToast from "hook/error-toast";
import { HiBadgeCheck } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import { appendZerotoString } from "utils/functions";
import { useQuery } from "react-query";
import { getAttemptedTestById } from "API/attemptedTestApi";
import { intervalToDuration } from "date-fns";

const TestResult = () => {
  const { testId } = useParams<{ testId: string }>();
  const {
    isLoading,
    data: attemptedTest,
    error,
  } = useQuery<IAttemptedTest>(["attemptedTest", testId], () =>
    getAttemptedTestById({ id: testId })
  );
  useErrorToast(error);
  const { minutes, seconds, hours } = intervalToDuration({
    start: 0,
    end: attemptedTest?.timeTaken ? attemptedTest?.timeTaken * 1000 : 0,
  });
  if (isLoading)
    return (
      <Center>
        <Spinner />
      </Center>
    );
  return (
    <Grid placeItems="center" height="90vh">
      <VStack p="4" border="1px solid #ccc" px="16" borderRadius="xl">
        <HiBadgeCheck fontSize={100} color="green" />
        <Heading fontSize="xl" color="green">
          You have submitted test successfully
        </Heading>
        <Heading fontSize="lg" color="orange">
          You got {attemptedTest?.totalMarks} Marks
        </Heading>
        <Text>Attempted Question:{attemptedTest?.attemptedQuestion}</Text>
        <Text>
          Not Attempted Question:{attemptedTest?.notAttemptedQuestion}
        </Text>
        <Text>Correct Answer:{attemptedTest?.correctAnswer}</Text>
        <Text>Wrong Answer:{attemptedTest?.wrongAnswer}</Text>
        <Text>
          Time Taken:
          {`${appendZerotoString(hours!)}h ${appendZerotoString(
            minutes!
          )}m ${appendZerotoString(seconds!)}s`}
        </Text>
        <Button
          as={Link}
          to={`/student/dashboard/test-review/${testId}`}
          replace
        >
          Review Test
        </Button>
      </VStack>
    </Grid>
  );
};

export default TestResult;
