import { extendTheme } from '@chakra-ui/react';

// 2. Extend the theme to include custom colors, fonts, etc
export const colors = {
  main: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },
};

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },

  colors,
  styles: {
    global: {
      html: {
        height: '100%',
        fontSize: { base: '85%', md: '95%', lg: '100%' },
      },
      body: {
        minH: '100vh',
        position: 'relative',
      },
      ul: { listStyleType: 'none' },
      a: { cursor: 'pointer' },
      // input: { focusBorderColor: "main.m" },
    },
  },
});
