import { Box, HStack, Badge, Flex } from "@chakra-ui/layout";
import {
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Text,
  Button,
} from "@chakra-ui/react";
import { Tag } from "@chakra-ui/tag";
import { FC, useState } from "react";
import { GoTrashcan } from "react-icons/go";
// import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import useErrorToast from "hook/error-toast";
import { useToast } from "@chakra-ui/toast";
import { useAppSelector } from "app/hook";
import { deleteTopicQuestionById } from "API/topicApi";

interface ProgramQuestionTableProps {
  questions: IQuestion[];
  topicId: string;
}

const ProgramQuestionTable: FC<ProgramQuestionTableProps> = ({
  questions,
  topicId,
}) => {
  const { program, subjectId } = useAppSelector((state) => state.programState);

  const toast = useToast();
  const cache = useQueryClient();
  const [checkedItems, setCheckedItems] = useState(
    new Array(questions.length).fill(false)
  );

  const questionIds = questions
    .filter((q, i) => checkedItems[i] === true)
    .map((q) => q._id);

  const {
    isLoading: deleteQuestionLoading,
    error: deleteQuestionError,
    mutateAsync: deleteQuestionMutateAsync,
  } = useMutation(deleteTopicQuestionById, {
    onSuccess: () => {
      toast({ title: "Question deleted successfully", status: "success" });
      cache.invalidateQueries(["program", program?._id, subjectId]);
      setCheckedItems(new Array(questions.length).fill(false));
    },
  });

  useErrorToast(deleteQuestionError);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  return (
    <Box>
      {/* <Button leftIcon={<GoTrashcan />} variant="solid"
        isLoading={isLoading}
        aria-label="delete Button"
        onClick={async () => {
          try {
            await mutateAsync(itemIds);
          } catch (error) { }
        }}
      >
        Delete
      </Button> */}
      {checkedItems.includes(true) && (
        <Box bg="tomato" pos="absolute" top="0" left="0" w="100%" p="3">
          <Button
            leftIcon={<GoTrashcan />}
            variant="solid"
            isLoading={deleteQuestionLoading}
            aria-label="delete Button"
            onClick={async () => {
              try {
                await deleteQuestionMutateAsync({
                  ids: questionIds,
                  topicId,
                });
              } catch (error) {}
            }}
          >
            Remove
          </Button>
        </Box>
      )}
      {questions.length === 0 ? (
        <Flex width="100%" height="100%" justify="center" align="center" py="5">
          <Text>No Questions Found!</Text>
        </Flex>
      ) : (
        <>
          <Box overflow="hidden">
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr bg="gray.50" height="80px">
                    <Th maxW="30px">
                      <Checkbox
                        colorScheme="teal"
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) =>
                          setCheckedItems(
                            new Array(questions.length).fill(e.target.checked)
                          )
                        }
                      />
                    </Th>
                    <Th>Question</Th>
                    {/* <Th>Subject</Th> */}
                    <Th>Type</Th>
                    <Th>Level</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {questions.map((question, i) => (
                    <Tr key={question._id}>
                      <Td maxW="30px">
                        <Checkbox
                          colorScheme="teal"
                          isChecked={checkedItems[i]}
                          onChange={(e) => {
                            const checkItems = new Array(questions.length)
                              .fill(checkedItems[i])
                              .map((_, checkI) => {
                                if (checkI === i) {
                                  return e.target.checked;
                                }
                                return checkedItems[checkI];
                              });
                            setCheckedItems(checkItems);
                          }}
                        />
                      </Td>
                      <Td maxW="400px">
                        {question?.question}
                        <HStack spacing={2} mt="10px">
                          {question?.tags.map((tag) => (
                            <Tag size="sm" key={tag} colorScheme="cyan">
                              {tag}
                            </Tag>
                          ))}
                        </HStack>
                      </Td>
                      {/* <Td textTransform="capitalize">{question?.subjectName}</Td> */}
                      <Td>{question?.type}</Td>
                      <Td>
                        <Badge colorScheme="green">{question?.level}</Badge>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default ProgramQuestionTable;
