import { Box, VStack, Flex } from "@chakra-ui/layout";
import {
  Table as CTable,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Spinner,
  Td,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { GoKebabVertical } from "react-icons/go";
// import { useState } from "react";
import { Link as RoLink } from "react-router-dom";
import { useAppSelector } from "app/hook";
import DeleteTableItem from "./DeleteTableItem";
import { FcFinePrint } from "react-icons/fc";
// import { format } from "date-fns";

interface PerformancesTableProps {
  isLoading: boolean;
  items: IPerformance[];
  resetCheckBox: boolean;
  disableUpdate: boolean;
}

const PerformancesTable: FC<PerformancesTableProps> = ({
  isLoading,
  items,
  resetCheckBox,
  disableUpdate,
}) => {
  const { role } = useAppSelector((state) => state.auth);
  const itemsCount = items?.length;
  const [checkedItems, setCheckedItems] = useState(
    new Array(itemsCount).fill(false)
  );

  const itemsId = items
    ?.filter((q, i) => checkedItems[i] === true)
    .map((q) => q._id);

  useEffect(() => {
    if (resetCheckBox) {
      setCheckedItems(new Array(itemsCount).fill(false));
    }
  }, [resetCheckBox, itemsCount]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  return (
    <>
      {checkedItems.includes(true) && (
        <DeleteTableItem
          itemIds={itemsId as string[]}
          itemsLength={itemsCount}
          setCheckedItems={setCheckedItems}
        />
      )}
      <Box overflow="hidden">
        <Box overflowX="auto">
          {isLoading ? (
            <Box width="100%" height="100%">
              <Spinner />
            </Box>
          ) : itemsCount === 0 ? (
            <Flex w="100%" minH="50vh" align="center" justify="center">
              <VStack>
                <Icon as={FcFinePrint} w={16} h={16} />
                <Text>No Performances Found!</Text>
              </VStack>
            </Flex>
          ) : (
            <>
              <CTable variant="striped">
                <Thead>
                  <Tr>
                    <Th maxW="30px" display="flex">
                      <Checkbox
                        colorScheme="teal"
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) =>
                          setCheckedItems(
                            new Array(itemsCount).fill(e.target.checked)
                          )
                        }
                      />
                    </Th>
                    <Th>Name</Th>
                    <Th>Program</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  <>
                    {items?.map((item, i) => (
                      <Tr key={item._id}>
                        <Td maxW="30px">
                          <Checkbox
                            colorScheme="teal"
                            isChecked={checkedItems[i]}
                            onChange={(e) => {
                              const checkItems = new Array(itemsCount)
                                .fill(checkedItems[i])
                                .map((_, checkI) => {
                                  if (checkI === i) {
                                    return e.target.checked;
                                  }
                                  return checkedItems[checkI];
                                });
                              setCheckedItems(checkItems);
                            }}
                          />
                        </Td>
                        <Td maxW="400px">{item?.name}</Td>
                        <Td textTransform="capitalize">
                          {item?.program?.name}
                        </Td>

                        {!disableUpdate && (
                          <Td>
                            <Menu closeOnBlur={true}>
                              <MenuButton
                                as={IconButton}
                                icon={<GoKebabVertical />}
                                variant="ghost"
                              ></MenuButton>
                              <MenuList>
                                <MenuItem
                                  as={RoLink}
                                  to={`/${role}/dashboard/performances/edit/${item._id}/`}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  as={RoLink}
                                  to={`/${role}/dashboard/performances/edit/${item._id}/structure`}
                                >
                                  Structure
                                </MenuItem>
                                {/* <MenuItem>Delete</MenuItem> */}
                              </MenuList>
                            </Menu>
                          </Td>
                        )}
                      </Tr>
                    ))}
                  </>
                </Tbody>
              </CTable>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default PerformancesTable;
