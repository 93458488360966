import { useAppSelector } from "app/hook";
import AssignProgramToStudent from "pages/dashboard/shared/Users/Students/AssignProgramToStudent/AssignProgramToStudent";
import { FC } from "react";

interface AssignProgramProps {}

const AssignProgram: FC<AssignProgramProps> = () => {
  const { user: school }: { user: ISchool } = useAppSelector(
    (state) => state.auth
  );
  return <AssignProgramToStudent school={school} />;
};
export default AssignProgram;
