import { Box, Flex, Heading, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { resetPasswordLink } from "API/authApi";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";

import { FC } from "react";
import { useMutation } from "react-query";
import * as yup from "yup";

interface ResetPasswordLinkProps {
  onClose: () => void;
}
const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const ResetPasswordLink: FC<ResetPasswordLinkProps> = ({ onClose }) => {
  const toast = useToast();
  const { data, isLoading, error, isSuccess, mutateAsync } =
    useMutation(resetPasswordLink);

  useErrorToast(error);

  if (isSuccess) {
    onClose();
    toast({
      status: "success",
      title: "Password reset link sent",
      description: `Check Email:  ${data.email}`,
    });
  }
  return (
    <Box mt="8">
      <Heading textAlign="center" mb="8" fontSize="2xl" color="main.400">
        Reset Password
      </Heading>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values) => {
          try {
            await mutateAsync(values.email);
          } catch (error) {}
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Stack>
            <InputControl
              name="email"
              label="Email:"
              inputProps={{
                placeholder: "Enter Email",
                type: "email",
                focusBorderColor: "blue.400",
              }}
            />
            <Flex justify="flex-end">
              <SubmitButton mt="4" isLoading={isLoading} colorScheme="blue">
                Send Link
              </SubmitButton>
            </Flex>
          </Stack>
        </Form>
      </Formik>
    </Box>
  );
};
export default ResetPasswordLink;
