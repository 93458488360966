import {
  Badge,
  Button,
  Checkbox,
  Flex,
  Heading,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  assignPurchasedProductToStudent,
  getPurchasedProductById,
} from "API/purchasedProdcutsApi";
import { getAllStudents } from "API/studentApi";
import { useAppSelector } from "app/hook";
import LoadingSpinner from "components/dumb/LoadingSpinner";
import useErrorToast from "hook/error-toast";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

interface AssignPurchasedProductProps {}

const AssignPurchasedProduct: FC<AssignPurchasedProductProps> = () => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const cache = useQueryClient();
  const {
    data: purchasedProduct,
    isLoading: purchasedProductLoading,
    error: errorPurchasedProduct,
  } = useQuery<IPurchasedProduct>(["purchasedProduct", id], () =>
    getPurchasedProductById(id)
  );
  const { uid } = useAppSelector((state) => state.auth);

  const {
    data: students,
    isLoading,
    error,
  } = useQuery<IStudent[]>("students", () => getAllStudents(uid!), {
    enabled: !!uid,
  });

  const [checkedItems, setCheckedItems] = useState(
    new Array(students?.length).fill(false)
  );

  const itemsId =
    students?.filter((q, i) => checkedItems[i] === true).map((q) => q._id) ??
    [];

  const mutateAssignStudents = useMutation(assignPurchasedProductToStudent, {
    onSuccess: () => {
      cache.invalidateQueries(["purchasedProduct", id]);
      cache.invalidateQueries("students");
      toast({
        title: "Success",
        description: "Students assigned successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  //   useEffect(() => {
  //     if (resetCheckBox) {
  //       setCheckedItems(new Array(itemsCount).fill(false));
  //     }
  //   }, [resetCheckBox, itemsCount]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  useErrorToast(error || errorPurchasedProduct || mutateAssignStudents.error);
  if (isLoading || purchasedProductLoading) return <LoadingSpinner isFull />;

  const assignStudents = async () => {
    await mutateAssignStudents.mutate({
      purchasedProductId: id,
      studentsIds: itemsId,
    });
  };

  return (
    <>
      {purchasedProduct && (
        <>
          <Stack>
            <Heading size={"md"}>Assign Purchased Product</Heading>

            <Stack spacing="0">
              <Flex
                p={4}
                borderTopRadius="xl"
                bg="pink"
                justify="space-between"
              >
                <Text>{purchasedProduct.product.name}</Text>
                <Tag> Licences Left {purchasedProduct.quantity}</Tag>
              </Flex>
              {itemsId && itemsId.length > 0 && (
                <Flex bg="green" p={2}>
                  <Button
                    size="sm"
                    isLoading={mutateAssignStudents.isLoading}
                    onClick={assignStudents}
                  >
                    Assign selected program to selected students
                  </Button>
                </Flex>
              )}
              <Table border="0.5px solid #ccc" borderTop="none">
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox
                        colorScheme="teal"
                        borderColor="black"
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) =>
                          setCheckedItems(
                            new Array(students?.length).fill(e.target.checked)
                          )
                        }
                      />
                    </Th>
                    <Th>Student Name</Th>
                    {/* <Th>Intrested Program</Th> */}
                    <Th>Email</Th>
                    <Th>Mobile</Th>
                    <Th>School</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {students?.map((student, i) => (
                    <Tr key={student._id}>
                      <Td>
                        <Flex align="center">
                          <Checkbox
                            colorScheme="teal"
                            borderColor="black"
                            isChecked={checkedItems[i]}
                            onChange={(e) => {
                              const checkItems = new Array(students?.length)
                                .fill(checkedItems[i])
                                .map((_, checkI) => {
                                  if (checkI === i) {
                                    return e.target.checked;
                                  }
                                  return checkedItems[checkI];
                                });
                              setCheckedItems(checkItems);
                            }}
                          />
                          {purchasedProduct.assignedStudents.map(
                            (assignedStudent) => {
                              if (assignedStudent.student === student._id) {
                                return (
                                  <Badge
                                    colorScheme="green"
                                    ml={2}
                                    key={student._id}
                                  >
                                    Assigned
                                  </Badge>
                                );
                              }
                              return null;
                            }
                          )}
                        </Flex>
                      </Td>

                      <Td>{student.name}</Td>
                      {/* <Td>{student.interestedProgram}</Td> */}
                      <Td>{student.email}</Td>
                      <Td>{student.mobile}</Td>
                      <Td>{student.school.name}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};
export default AssignPurchasedProduct;
