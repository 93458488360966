import {
  Center,
  Heading,
  Spinner,
  Stack,
  VStack,
  HStack,
  Text,
  Box,
  Grid,
  Divider,
} from "@chakra-ui/react";

import { getSchoolById } from "API/schoolApi";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { BiUser } from "react-icons/bi";
import { Link } from "react-router-dom";
import useErrorToast from "hook/error-toast";
import PurchasedProduct from "./PurchasedProduct";
const ViewSchool = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useQuery<ISchool>(
    ["school", id],
    () => getSchoolById(id),
    {}
  );

  useErrorToast(error);

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <Stack spacing={4}>
            <Heading fontSize="xl">School Details</Heading>
            <VStack align="flex-start" spacing={2}>
              <TextHorizontal label="Name" text={data?.name} />
              <TextHorizontal label="Email" text={data?.email} />
              <TextHorizontal label="mobile" text={data?.mobile.toString()} />
              <TextHorizontal label="Address" text={data?.contact?.address} />
              <TextHorizontal label="City" text={data?.contact?.city} />
              <TextHorizontal label="State" text={data?.contact?.state} />
              <TextHorizontal
                label="Pincode"
                text={data?.contact?.pincode.toString()}
              />
            </VStack>
            <Heading fontSize="xl">Users</Heading>
            <Divider />
            <Grid
              gridGap={4}
              gridTemplateColumns={`repeat(auto-fit,minmax(200px,1fr))`}
            >
              <UserCard
                link={`/admin/dashboard/users/${id}/students`}
                icon={<BiUser />}
                text="Students"
              />
            </Grid>
            {data?.purchasedProducts?.map((purchasedProduct) => (
              <Stack
                p="4"
                key={purchasedProduct._id}
                justify="space-between"
                borderBottom="1px"
                borderColor="gray.200"
                spacing={"0px"}
                direction={["column", "row"]}
                alignItems={"center"}
              >
                <PurchasedProduct PurchasedProduct={purchasedProduct} />
              </Stack>
            ))}
          </Stack>
        </>
      )}
    </>
  );
};

export default ViewSchool;

const TextHorizontal = ({
  label,
  text,
}: {
  label: string;
  text: string | undefined;
}) => {
  return (
    <HStack>
      <Text fontWeight="semibold">{label}</Text>
      <Text> {text}</Text>
    </HStack>
  );
};

const UserCard = ({
  icon,
  text,
  link,
}: {
  icon: any;
  text: string;
  link: string;
}) => {
  return (
    <HStack
      as={Link}
      to={link}
      justify="center"
      align="center"
      boxShadow="xl"
      py="6"
      px="6"
      borderRadius="xl"
    >
      <Box fontSize="3xl">{icon}</Box>
      <Heading fontSize="xl">{text}</Heading>
    </HStack>
  );
};
