import * as yup from "yup";

export const addBatchValidationSchema = yup.object().shape({
  name: yup.string().required("name required!"),
  programId: yup.string().optional(),
  leadInstructor: yup.string().optional(),
  subjectId: yup.string().optional(),
  endDate: yup.date().optional(),
  startDate: yup.date().optional(),
});

export const updateBatchValidationSchema = yup.object().shape({
  name: yup.string().required("name required!"),
  programId: yup.string().optional(),
  subjectId: yup.string().optional(),
  leadInstructor: yup.string().optional(),
  endDate: yup.date().optional(),
  startDate: yup.date().optional(),
});
