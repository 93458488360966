import { Flex, Grid, Heading, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Button } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { checkResetToken, updatePassword } from "API/authApi";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams, Link } from "react-router-dom";
import { resetPasswordValidationSchema } from "validation";
import { Container } from "@chakra-ui/react";

const ResetPassword = () => {
  const { token }: { token: string } = useParams();

  const toast = useToast();
  const history = useHistory();
  const {
    isLoading,
    error: passwordError,
    mutateAsync,
  } = useMutation(updatePassword, {
    onSuccess: () => {
      history.replace("/login");
      toast({
        status: "success",
        title: "Password Changed",
        description: `Now Login with new password`,
      });
    },
  });
  useErrorToast(passwordError);
  const { error, isLoading: checkLoading } = useQuery("password-token", () =>
    checkResetToken(token)
  );

  // useErrorToast(error);

  return (
    <>
      {checkLoading ? (
        <Spinner />
      ) : error ? (
        <Container maxW="1300px">
          <Stack align="center" justify="center" height="100vh">
            <Heading fontSize="xl" color="blue.400">
              Forgot Password Link Expired ! Kindly Generate New Link
            </Heading>
            <Button as={Link} to="/login" variant="outline" colorScheme="blue">
              Go Back
            </Button>
          </Stack>
        </Container>
      ) : (
        <Grid placeItems="center" height="100vh">
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            onSubmit={async (values) => {
              try {
                await mutateAsync({ token, password: values.password });
              } catch (error) {}
            }}
            validationSchema={resetPasswordValidationSchema}
          >
            <Form>
              <Stack width="300px" height="350px" boxShadow="xl" p="4" pt="8">
                <Heading color="main.500" fontSize="2xl">
                  Change Password
                </Heading>
                <InputControl
                  label="New Password:"
                  name="password"
                  inputProps={{
                    placeholder: "Enter New Password",
                    type: "password",
                    focusBorderColor: "main.400",
                    // mb: "8",
                  }}
                />
                <InputControl
                  label="Confirm Password:"
                  name="confirmPassword"
                  inputProps={{
                    placeholder: "Enter New Password",
                    type: "password",
                    focusBorderColor: "main.400",
                    // mb: "8",
                  }}
                />
                <Flex justify="flex-end">
                  <SubmitButton
                    isLoading={isLoading}
                    mt="4"
                    type="submit"
                    colorScheme="green"
                  >
                    Update
                  </SubmitButton>
                </Flex>
              </Stack>
            </Form>
          </Formik>
        </Grid>
      )}
    </>
  );
};
export default ResetPassword;
