import { Center, Grid, GridItem, Heading } from "@chakra-ui/layout";
import { Flex, Spinner } from "@chakra-ui/react";
import { addBatch, getBatchById, updateBatch } from "API/batchApi";
import { Formik, Form } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { FC } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addBatchValidationSchema,
  updateBatchValidationSchema,
} from "validation/batchValidation";
import { useToast } from "@chakra-ui/toast";
import useErrorToast from "hook/error-toast";
import { useHistory, useParams } from "react-router";
import { getAllPrograms } from "API/programApi";
import { format } from "date-fns";

interface AddAndUpdateBatchProps {
  isUpdate?: boolean;
}

const AddAndUpdateBatch: FC<AddAndUpdateBatchProps> = ({
  isUpdate = false,
}) => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const {
    data,
    isLoading: isLoadingBatch,
    error: errorBatch,
  } = useQuery<IBatch>(["batch", id], () => getBatchById(id), {
    enabled: isUpdate,
  });
  // const { data: subjects } = useQuery<ISubject[]>("subjects", getSubjects);

  const {
    error: updateError,
    isLoading: updateIsLoading,
    mutateAsync: updateMutate,
  } = useMutation("updateBatch", updateBatch, {
    onSuccess: () => {
      toast({
        title: "Batch Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/dashboard/batches");
    },
  });
  const {
    error: addError,
    isLoading: addIsLoading,
    mutateAsync: addMutate,
  } = useMutation("addBatch", addBatch, {
    onSuccess: () => {
      toast({
        title: "Batch Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/dashboard/batches");
    },
  });
  useErrorToast(updateError);
  useErrorToast(errorBatch);
  useErrorToast(addError);

  const { data: allprograms } = useQuery<IProgram[]>(
    "allprograms",
    getAllPrograms
  );

  return (
    <>
      {isLoadingBatch ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Formik
          validationSchema={
            isUpdate ? updateBatchValidationSchema : addBatchValidationSchema
          }
          initialValues={
            isUpdate
              ? {
                name: data?.name ?? "",
                endDate: format(
                  new Date(data?.endDate ?? new Date().getDate()),
                  "yyyy-MM-dd"
                ),
                startDate: format(
                  new Date(data?.startDate ?? new Date().getDate()),
                  "yyyy-MM-dd"
                ),
                programId: data?.program._id ?? "",
                subjectId: "not assigned",
                leadInstructor: "not assigned",
              }
              : {
                name: "",
                endDate: "",
                startDate: "",
                leadInstructor: "not assigned",
                subjectId: "not assigned",
                programId: "",
              }
          }
          onSubmit={async (values) => {
            if (isUpdate) {
              try {
                await updateMutate({
                  batchId: id,
                  ...values,
                });
              } catch (error) { }
            } else {
              try {
                await addMutate({
                  ...values,
                });
              } catch (error) { }
            }
          }}
        >
          <Form>
            <Grid gridColumn={{ base: 1, sm: 2 }} gridGap="4">
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <Heading fontSize="xl">
                  {isUpdate ? "Update" : "Add"} Batch
                </Heading>
              </GridItem>

              <GridItem>
                <InputControl
                  name="name"
                  inputProps={{ placeholder: "Enter Batch Name" }}
                  label="Batch Name"
                />
              </GridItem>
              {/* <GridItem>
                <InputControl
                  name="leadInstructor"
                  inputProps={{
                    placeholder: "Enter Lead Instructor Name",
                  }}
                  label="Lead Instructor Name"
                />
              </GridItem> */}
              <GridItem>
                <InputControl
                  name="startDate"
                  inputProps={{
                    placeholder: "Enter Start Date",
                    type: "date",
                  }}
                  label="Start Date"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="endDate"
                  inputProps={{
                    placeholder: "Enter End Date",
                    type: "date",
                  }}
                  label="End Date"
                />
              </GridItem>

              <SelectControl defaultValue="name" name="programId" label="Program Id">
                <option value={data?.program ? data?.program._id : ''}>
                  {data?.program ? data?.program.name : "Select Program"}
                </option>

                {allprograms?.map((act) => {
                  if (act.name !== data?.program.name.toString())
                    return <option key={act?._id} value={act?._id} >{act?.name}</option>
                  return null;
                })}
              </SelectControl>

              <Flex align="flex-end">
                <SubmitButton
                  isLoading={addIsLoading || updateIsLoading}
                  width="full"
                >
                  Submit
                </SubmitButton>
              </Flex>
            </Grid>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default AddAndUpdateBatch;
