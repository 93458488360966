import {
  SchoolQuestions,
  SchoolUpdateQuestion,
  SchoolImportQuestions,
  SchoolTestSection,
  SchoolTestProgram,
} from "pages/dashboard/SchoolDashboard";
import SchoolPrograms from "pages/dashboard/SchoolDashboard/SchoolQuestionSection/SchoolPrograms/SchoolPrograms";
import SchoolProgramUpdate from "pages/dashboard/SchoolDashboard/SchoolQuestionSection/SchoolProgramUpdate/SchoolProgramUpdate";
import StaffDashboard from "pages/dashboard/StaffDashboard/StaffDashboard";
import StaffTests from "pages/dashboard/StaffDashboard/StaffTests/StaffTests";
import StaffProgram from "pages/dashboard/StaffDashboard/StaffProgram/StaffProgram";
import ReviewAttempt from "pages/dashboard/StaffDashboard/ReviewAttempt/ReviewAttempt";
import StaffStudents from "pages/dashboard/StaffDashboard/StaffStudents/StaffStudents";
import StaffProgramRecordedVideos from "pages/dashboard/StaffDashboard/StaffProgramRecordedVideos/StaffProgramRecordedVideos";
import Reports from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/Reports";
import PerformanceReports from "pages/dashboard/shared/Users/Students/PerformanceReports";
import PerformanceReportView from "pages/dashboard/shared/Users/Students/PerformanceReportView";
import CorrectionParameter from "pages/dashboard/StaffDashboard/CorrectionParameter/CorrectionParameter";
import CareerPlanView from "pages/dashboard/StudentDashboard/CareerPlan/CareerPlanView";

export const staffRoutes = [
  {
    type: ["staff"],
    path: "/staff/student/career-plan",
    component: CareerPlanView,
    exact: true,
    protect: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/correction-parameter",
    component: CorrectionParameter,
    exact: true,
    protect: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard",
    component: StaffDashboard,
    exact: true,
    protect: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/users/students",
    component: StaffStudents,
    exact: true,
    protect: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/questions",
    component: SchoolQuestions,
    protect: true,
    exact: false,
  },

  {
    type: ["staff"],
    path: "/staff/dashboard/users/student/edit/:id/reports",
    component: Reports,
    protect: true,
    exact: false,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/users/student/edit/:id/performance-reports/:idPerformance/view",
    component: PerformanceReportView,
    protect: true,
    exact: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/users/student/edit/:id/performance-reports",
    component: PerformanceReports,
    protect: true,
    exact: false,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/update-question/:type/:id",
    component: SchoolUpdateQuestion,
    protect: true,
    exact: false,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/import-questions",
    component: SchoolImportQuestions,
    protect: true,
    exact: false,
  },

  {
    type: ["staff"],
    path: "/staff/dashboard/programs",
    component: SchoolPrograms,
    exact: true,
    protect: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/programs/:id/view",
    component: StaffProgram,
    exact: false,
    protect: true,
  },

  {
    type: ["staff"],
    path: "/staff/dashboard/programs/:id/recordedvideos",
    component: StaffProgramRecordedVideos,
    exact: false,
    protect: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/programs/:id",
    component: SchoolProgramUpdate,
    exact: false,
    protect: true,
  },

  {
    type: ["staff"],
    path: "/staff/dashboard/test-manager",
    component: SchoolTestSection,
    protect: true,
    exact: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/review-attempt/:testId",
    component: ReviewAttempt,
    protect: true,
    exact: true,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/test-manager/:id",
    component: SchoolTestProgram,
    protect: true,
    exact: false,
  },
  {
    type: ["staff"],
    path: "/staff/dashboard/program/:programId/school/:schoolId",
    component: StaffTests,
    protect: true,
    exact: false,
  },
];
