import { useState } from "react";
import {
  Heading,
  Box,
  Button,
  useColorModeValue,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { GoPlus } from "react-icons/go";
import { Link as RoLink } from "react-router-dom";
import { filterBatch } from "API/batchApi";
import { useQuery } from "react-query";
import useErrorToast from "hook/error-toast";
import AdminQuestionTable from "./BatchesTable/BatchesTable";
import LevelSelect from "./LevelSelect";
import {  useAppDispatch, useAppSelector } from "app/hook";
import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
} from "app/features/batchFilter/batchFilter";
import TableSearch from "components/dumb/Table/TableSearch";
import TablePaginator from "components/dumb/Table/TablePaginator";
import { setStatus } from "app/features/batchStatus/batchStatus";

const Batches = ({ fromStudents = false }) => {
  const [resetCheckBox, setResetCheckBox] = useState(false);
  const { role, uid } = useAppSelector((state) => state.auth);
  const { programId, size, page, subjectId, searchText } = useAppSelector(
    (state) => state.batchFilter
  );
  const dispatch = useAppDispatch();

  const { data, isLoading, error } = useQuery(
    ["batches", page, size, programId, subjectId, searchText],
    () => {
      setResetCheckBox(true);
      return filterBatch({
        page,
        size,
        programId,
        subjectId,
        searchText,
      });
    },
    { onSettled: () => setResetCheckBox(false), keepPreviousData: true }
  );

  useErrorToast(error);
  dispatch(setStatus("ALL"))

  return (
    <>
      <Heading size="md">All Batches</Heading>

      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderTopRadius="md"
        pos="relative"
      >
        <Stack w="100%" direction={["column", "row"]} p="15px">
          <Box w={"50%"}>
            <TableSearch
              searchText={searchText}
              setPageDefault={setPageDefault}
              setSearchText={setSearchText}
              searchLabel="Search by batch name"
            />
          </Box>
          
          <Box flex="1" textAlign="right">
            <HStack>
              {/* <QuestionTypeSelect /> */}
              <LevelSelect />
              {/* <SubjectSelect
                subject={subjectId}
                selectOutput={(value) => {
                  dispatch(setSubjectId(value));
                  dispatch(setPageDefault());
                }}
              /> */}
              {!fromStudents && (
                <Box flex="0">
                  <Button
                    rightIcon={<GoPlus />}
                    colorScheme="teal"
                    variant="solid"
                    size="sm"
                    as={RoLink}
                    to={`/${role}/dashboard/batches/add`}
                  >
                    Add Batch
                  </Button>
                </Box>
              )}
            </HStack>
          </Box> 
        </Stack>
        <AdminQuestionTable
          resetCheckBox={resetCheckBox}
          items={
            fromStudents
              ? data?.batches.filter((batch: IBatch) => {
                  return batch.assignedStudents.includes(uid);
                })
              : data?.batches
          }
          isLoading={isLoading}
          disableUpdate={fromStudents}
        />
        <TablePaginator
          count={data?.count}
          decrementPage={decrementPage}
          incrementPage={incrementPage}
          page={data?.page}
          setPageDefault={setPageDefault}
          setSize={setSize}
          size={size}
          totalPages={data?.totalPage}
        />
      </Box>
    </>
  );
};

export default Batches;
