import { FC } from "react";

import {
  Stack,
  Heading,
  Box,
  useColorModeValue,
  Text,
  Flex,
  Divider,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/hook";

interface StaffDashboardProps {}

const StaffDashboard: FC<StaffDashboardProps> = () => {
  const { user: staff }: { user: IStaff } = useAppSelector(
    (state) => state.auth
  );

  return (
    <>
      <Heading size="md">Dashboard</Heading>
      <Box
        my="20px"
        border="1px"
        padding="20px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderTopRadius="md"
      >
        <>
          <Heading size="sm" color={"gray.400"} fontWeight="bold">
            PROGRAMS
          </Heading>
          <Divider my={4} colorScheme="gray.700" />
          <Stack spacing="0px">
            {staff?.interestedPrograms
              ?.map((program: any) => (
                <Flex
                  my="20px"
                  py="20px"
                  key={program._id}
                  justify="space-between"
                  align="center"
                  borderBottom="1px"
                  borderColor="gray.200"
                  mt="0"
                >
                  <Box>
                    <Heading as="h5" size="sm" mb="2">
                      {program.name}
                    </Heading>

                    <Stack direction={["column", "row"]} spacing="20px">
                      <Box>
                        <Text as="span">Duration: </Text>6 months
                      </Box>
                      <Box>
                        <Text as="span">Total tests: </Text>100
                      </Box>
                    </Stack>
                  </Box>

                  <Stack direction={["column", "row"]}>
                    <Button
                      colorScheme="teal"
                      variant="link"
                      as={Link}
                      to={`/staff/dashboard/programs/${program._id}/view`}
                    >
                      Program Details
                    </Button>
                    <Button
                      colorScheme="teal"
                      variant="link"
                      as={Link}
                      to={`/staff/dashboard/programs/${program._id}/recordedvideos`}
                    >
                      Play videos
                    </Button>

                    <Button
                      colorScheme="teal"
                      as={Link}
                      width={["100%", "auto"]}
                      to={`/staff/dashboard/program/${program._id}/school/${staff?.school._id}`}
                    >
                      Review tests
                    </Button>
                  </Stack>
                </Flex>
              ))
              .reverse()}
          </Stack>
        </>
      </Box>
    </>
  );
};

export default StaffDashboard;
