import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Container,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link as RoLink } from "react-router-dom";
// import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { useAuth } from "hook/auth-hooks";
import { useAppSelector } from "app/hook";
import { useMediaQuery } from "@chakra-ui/react";

export default function RegularNavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const { logout } = useAuth();
  const { user, uid, role } = useAppSelector((state) => state.auth);

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.600", "white")}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
    >
      <Container maxW="container.xl">
        <Flex minH={"60px"} py={{ base: 2 }} align={"center"}>
          <Flex
            flex={{ base: 0, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              fontWeight="bold"
              color={useColorModeValue("gray.800", "white")}
              fontSize={["13px", "18px"]}
            >
              Knowledge Park Training Academy
            </Text>
            <Flex
              display={{ base: "none", lg: "flex" }}
              ml={10}
              justify={{ base: "end", md: "end" }}
            ></Flex>
          </Flex>

          <Stack
            flex={{ base: 0, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            {/* <ColorModeSwitcher /> */}

            <>
              <Stack direction={"row"} spacing={6} d={["none", "flex"]}>
                <Button as={RoLink} to="/" colorScheme="teal" variant="link">
                  Home
                </Button>
                <Button
                  as={RoLink}
                  to="/about-us"
                  colorScheme="teal"
                  variant="link"
                >
                  About Us
                </Button>
                <Button
                  as={RoLink}
                  to="/contact-us"
                  colorScheme="teal"
                  variant="link"
                >
                  Contact Us
                </Button>
              </Stack>

              {!uid && (
                <>
                  {" "}
                  <Button colorScheme="teal" as={RoLink} to="/Login">
                    Login
                  </Button>
                  {isLargerThan768 && (
                    <Button colorScheme="teal" as={RoLink} to="/signup">
                      Sign up
                    </Button>
                  )}
                </>
              )}
            </>
          </Stack>
          {uid && (
            <Box ml={4}>
              {" "}
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  _focus={{
                    boxShadow: "none",
                  }}
                  minW={0}
                >
                  <Avatar
                    size="md"
                    src={
                      process.env.NODE_ENV === "production"
                        ? user?.avatar
                        : process.env.REACT_APP_API_URL?.split("/api")[0] +
                          user?.avatar
                    }
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem textTransform={"capitalize"}>
                    {role === "student" ? user?.name : "User"} -
                    {role === "admin"
                      ? "Admin"
                      : role === "school"
                      ? "Institution"
                      : role === "student"
                      ? "Student"
                      : role === "staff"
                      ? "Teacher"
                      : ""}
                  </MenuItem>
                  {role === "student" && (
                    <MenuItem
                      _disabled={{
                        color: "black",
                        cursor: "not-allowed",
                      }}
                      isDisabled
                    >
                      {user?.school.name}
                    </MenuItem>
                  )}
                  <MenuItem as={RoLink} to={`/${role}/dashboard`}>
                    Dashboard
                  </MenuItem>
                  <MenuItem as={RoLink} to="/MyProfile">
                    My Profile
                  </MenuItem>
                  <MenuItem as={RoLink} to="/Settings">
                    Settings
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
        </Flex>
      </Container>
      <Collapse in={isOpen} animateOpacity>
        <Stack
          direction={"column"}
          spacing={6}
          d={["flex", "none"]}
          alignItems={"left"}
          justifyContent={"left"}
        >
          <Button as={RoLink} to="/" colorScheme="teal" variant="link">
            Home
          </Button>
          <Button as={RoLink} to="/about-us" colorScheme="teal" variant="link">
            About Us
          </Button>
          <Button
            as={RoLink}
            to="/contact-us"
            colorScheme="teal"
            variant="link"
          >
            Contact Us
          </Button>
        </Stack>
      </Collapse>
    </Box>
  );
}
