import {
  SchoolImportQuestions,
  SchoolQuestions,
  SchoolTestProgram,
  SchoolTestSection,
  SchoolUpdateQuestion,
  SchoolDashboard,
} from "pages/dashboard/SchoolDashboard";
import AssignPurchasedProduct from "pages/dashboard/SchoolDashboard/Dashboard/SchoolPurchasedProduct/AssignPurchasedProduct";
import SchoolPrograms from "pages/dashboard/SchoolDashboard/SchoolQuestionSection/SchoolPrograms/SchoolPrograms";
import SchoolProgramUpdate from "pages/dashboard/SchoolDashboard/SchoolQuestionSection/SchoolProgramUpdate/SchoolProgramUpdate";
import AddStudents from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/AddStudent";
import AssignProgram from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/AssignProgram";
import Reports from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/Reports";
import SchoolStudents from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/SchoolStudents";
import UpdateStudent from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/UpdateStudent";
import StudentTest from "pages/dashboard/SchoolDashboard/StudentTest/StudentTest"
import PerformanceReports from "pages/dashboard/shared/Users/Students/PerformanceReports";
import PerformanceReportView from "pages/dashboard/shared/Users/Students/PerformanceReportView";
import TestResultDetails from "pages/dashboard/StudentDashboard/TestResultPreview/TestResultPreview";

export const schoolRoutes = [
  {
    type: ["school"],
    path: "/school/dashboard",
    component: SchoolDashboard,
    exact: true,
    protect: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/questions",
    component: SchoolQuestions,
    protect: true,
    exact: false,
  },
  {
    type: ["school"],
    path: "/school/dashboard/test/:testId/:retest",
    component: StudentTest,
    exact: false,
    protect: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/update-question/:type/:id",
    component: SchoolUpdateQuestion,
    protect: true,
    exact: false,
  },
  {
    type: ["school"],
    path: "/school/dashboard/test-review/:testId",
    component: TestResultDetails,
    exact: true,
    protect: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/import-questions",
    component: SchoolImportQuestions,
    protect: true,
    exact: false,
  },

  {
    type: ["school"],
    path: "/school/dashboard/programs",
    component: SchoolPrograms,
    exact: true,
    protect: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/programs/:id",
    component: SchoolProgramUpdate,
    exact: false,
    protect: true,
  },

  {
    type: ["school"],
    path: "/school/dashboard/test-manager",
    component: SchoolTestSection,
    protect: true,
    exact: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/test-manager/:id",
    component: SchoolTestProgram,
    protect: true,
    exact: false,
  },
  {
    type: ["school"],
    path: "/school/dashboard/users/student",
    component: SchoolStudents,
    protect: true,
    exact: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/users/student/add",
    component: AddStudents,
    protect: true,
    exact: false,
  },
  {
    type: ["school"],
    path: "/school/dashboard/users/student/edit/:id",
    component: UpdateStudent,
    protect: true,
    exact: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/users/student/edit/:id/performance-reports/:idPerformance/view",
    component: PerformanceReportView,
    protect: true,
    exact: true,
  },
  {
    type: ["school"],
    path: "/school/dashboard/users/student/edit/:id/performance-reports",
    component: PerformanceReports,
    protect: true,
    exact: false,
  },

  {
    type: ["school"],
    path: "/school/dashboard/users/student/edit/:id/aasign-program",
    component: AssignProgram,
    protect: true,
    exact: false,
  },
  {
    type: ["school"],
    path: "/school/dashboard/users/student/edit/:id/reports",
    component: Reports,
    protect: true,
    exact: false,
  },
  {
    type: ["school"],
    path: "/school/dashboard/assign-product/:id",
    component: AssignPurchasedProduct,
    protect: true,
    exact: false,
  },
  // {
  //   type: ["school"],
  //   path: "/school/dashboard/users/staff/view/:id",
  //   component: ViewSchool,
  //   protect: true,
  //   exact: false,
  // },
];
