import { useDisclosure } from "@chakra-ui/hooks";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  EditIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { Flex, Stack, Text, Badge, Box } from "@chakra-ui/layout";
import { FC, useRef } from "react";

import { Button, IconButton } from "@chakra-ui/button";
import Modal from "components/UI/Modal";
import QuestionAdder from "./QuestionAdder";
import ProgramQuestionTable from "./ProgramQuestionTable";

import UpdateTopic from "./UpdateTopic";
import { useAppSelector } from "app/hook";
import { useMutation, useQueryClient } from "react-query";
import { deleteTopicById } from "API/topicApi";
import useErrorToast from "hook/error-toast";
import {
  Heading,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

interface TopicProps {
  topic: ITopic;
  subjectName: string | undefined;
}

const Topic: FC<TopicProps> = ({ topic, subjectName }) => {
  const { isOpen, onToggle } = useDisclosure();
  const btnRef = useRef(null);
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const {
    isOpen: isModalUpdateOpen,
    onOpen: onModalUpdateOpen,
    onClose: onModalUpdateClose,
  } = useDisclosure();

  const cache = useQueryClient();

  const { program, subjectId } = useAppSelector((state) => state.programState);
  const { isLoading, mutateAsync, error } = useMutation(deleteTopicById, {
    onSuccess: () => {
      cache.invalidateQueries(["program", program?._id, subjectId]);
    },
  });
  useErrorToast(error);
  return (
    <>
      {
        // <Modal modalSize="3xl" onClose={onModalClose} open={isModalOpen}>
        //   <QuestionAdder
        //     onClose={onModalClose}
        //     topicId={topic._id}
        //     subject={subjectName ?? ""}
        //   />
        // </Modal>
        <Drawer
          onClose={onModalClose}
          isOpen={isModalOpen}
          size="xl"
          placement="right"
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>All questions</DrawerHeader>

            <DrawerBody>
              {program && (
                <QuestionAdder
                  onClose={onModalClose}
                  topicId={topic._id}
                  subjectId={subjectId ?? ""}
                  programId={program._id}
                />
              )}
            </DrawerBody>

            {/* <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onModalClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button>
          </DrawerFooter> */}
          </DrawerContent>
        </Drawer>
      }
      {
        <Modal
          modalSize="md"
          onClose={onModalUpdateClose}
          open={isModalUpdateOpen}
        >
          <UpdateTopic
            topicId={topic._id}
            onClose={onModalUpdateClose}
            topicName={topic.name}
            topicVideoLink={topic.videolink}
          />
        </Modal>
      }
      <Box borderBottom="1px" borderColor="gray.200">
        <Flex
          aling="center"
          p="4"
          key={topic._id}
          alignItems="center"
          justify="space-between"
        >
          <Stack width="100%">
            <Flex
              onClick={() => {
                onToggle();
              }}
              justify="space-between"
              alignItems="center"
            >
              <Text>
                <Badge variant="solid" colorScheme="purple">
                  Topic
                </Badge>{" "}
                {topic?.name}
              </Text>
              {isOpen ? (
                <ChevronRightIcon color="teal.400" fontSize="2xl" />
              ) : (
                <ChevronDownIcon color="teal.400" fontSize="2xl" />
              )}
            </Flex>
          </Stack>

          <IconButton
            aria-label="open modal"
            onClick={onModalUpdateOpen}
            ml="4"
            icon={<EditIcon />}
          />
          <IconButton
            aria-label="delete button"
            isLoading={isLoading}
            onClick={async () => {
              try {
                mutateAsync(topic._id);
              } catch (error) {}
            }}
            ml="4"
            icon={<DeleteIcon />}
          />
        </Flex>

        {isOpen && (
          <Box pos="relative">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              px="4"
              py="3"
              bg="purple.100"
            >
              <Heading as="h5" size="sm">
                Questions
              </Heading>
              <Button onClick={onModalOpen}>Add Questions</Button>
            </Flex>
            <Box overflowY="auto" maxH="300">
              <ProgramQuestionTable
                topicId={topic._id}
                questions={topic.questions}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default Topic;
