import {
  Text,
  Button,
  Box,
  DrawerBody,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Stack,
  DrawerFooter,
  Center,
  Tag,
} from "@chakra-ui/react";
import { removeProductFromCart, updateCartItem } from "API/cartApi";
import { initPayment } from "API/paymentApi";
import { useAppSelector } from "app/hook";
import useErrorToast from "hook/error-toast";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
const Cart = () => {
  const { user }: { user: IStudent | ISchool } = useAppSelector(
    (state) => state.auth
  );

  const history = useHistory();
  const mutationPayment = useMutation(initPayment, {
    onSuccess: (data) => {
      history.push(`/buy-products/checkout/${data.payment._id}`);
    },
  });
  const cart = user?.cart;
  return (
    <>
      <DrawerBody>
        {cart?.length < 1 ? (
          <Center>Add Product To Cart</Center>
        ) : (
          cart?.map((cart) => (
            <CartItem
              quantity={cart.quantity}
              key={cart.productId._id}
              product={cart.productId}
            />
          ))
        )}
      </DrawerBody>

      <DrawerFooter>
        {cart.length > 0 && (
          <Button
            w={["100%", "auto"]}
            size={"lg"}
            colorScheme="teal"
            onClick={() => {
              mutationPayment.mutate();
            }}
            isLoading={mutationPayment.isLoading}
          >
            Checkout
          </Button>
        )}
      </DrawerFooter>
    </>
  );
};

export default Cart;

export const CartItem = ({
  product,
  quantity: serverQuantity,
}: {
  product: IProduct;
  quantity: number;
}) => {
  // product.quantity = quantity;
  const [quantity, setQuantity] = useState(serverQuantity);
  const cache = useQueryClient();
  const { role } = useAppSelector((state) => state.auth);

  const mutateUpdateCart = useMutation(updateCartItem, {
    onSuccess: () => {
      cache.invalidateQueries("user");
    },
  });

  const mutateRemoveCart = useMutation(removeProductFromCart, {
    onSuccess: () => {
      cache.invalidateQueries("user");
    },
  });
  useErrorToast(mutateUpdateCart.error);
  useErrorToast(mutateRemoveCart.error);
  return (
    <HStack
      key={product._id}
      mb={"10px"}
      borderBottom={"1px"}
      borderColor={"gray.100"}
      pb={"10px"}
    >
      <Stack direction={["column", "row"]} spacing={"5px"} w={["70%", "80%"]}>
        <Box w={["100%", "75%"]}>
          <Text fontWeight={"bold"}>{product.name}</Text>
          <Text>Rs.{product.price}/user</Text>
          <Box>
            {role === "school" && (
              <Tag colorScheme="red">
                Quantity : <b> {serverQuantity}</b>
              </Tag>
            )}
          </Box>
          <Button
            isLoading={mutateRemoveCart.isLoading}
            onClick={() => {
              mutateRemoveCart.mutate({
                productId: product._id,
              });
            }}
            colorScheme="orange"
            variant="link"
          >
            Remove
          </Button>
        </Box>

        <Box>
          {role === "school" && (
            <Stack>
              <FormControl>
                <FormLabel htmlFor="quantity">
                  QTY.
                  {product.minQ > 0 && (
                    <Text as="span" fontSize={"12px"}>
                      (Min {product.minQ})
                    </Text>
                  )}
                </FormLabel>
                <NumberInput
                  min={product.minQ}
                  size="sm"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(parseInt(e));
                  }}
                >
                  <NumberInputField id="quantity" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>{" "}
              <Button
                isLoading={mutateUpdateCart.isLoading}
                colorScheme="blue"
                size="xs"
                onClick={() => {
                  mutateUpdateCart.mutate({
                    productId: product._id,
                    quantity: quantity,
                  });
                }}
              >
                Update Quantity
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>
      <Box w={["30%", "20%"]} textAlign={"right"}>
        <Text fontWeight={"bold"} fontSize={"16px"} color={"teal"}>
          Rs.{product.price * quantity}
        </Text>
      </Box>
    </HStack>
  );
};
