import { useAppSelector } from "app/hook";
import { FC } from "react";
import AddTopic from "./Topic/AddTopic";
import Topic from "./Topic/Topic";

interface TopicsProps {
  topics: ITopic[];
  chapterId: string;
}

const Topics: FC<TopicsProps> = ({ topics, chapterId }) => {
  const { program, subjectId } = useAppSelector((state) => state.programState);

  const subjectName = program?.subjects?.find(
    (e: ISubject) => e._id === subjectId
  )?.name;

  return (
    <>
      {topics.map((topic: ITopic) => (
        <Topic subjectName={subjectName} topic={topic} key={topic._id} />
      ))}
        <AddTopic chapterId={chapterId} program={program} subjectId={subjectId} />
    </>
  );
};
export default Topics;
