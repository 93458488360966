import {
  Spinner,
  useColorModeValue,
  Link,
  Grid,
  Heading,
  Stack,
  Box,
  AspectRatio,
} from "@chakra-ui/react";
import { getActiveProgram } from "API/activeProgramApi";
import useErrorToast from "hook/error-toast";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link as RoLink } from "react-router-dom";
import { useAppSelector } from "app/hook";

import Subject from "./Subject";

interface StudentProgramRecordedVideosProps {}

const StudentProgramRecordedVideos: FC<
  StudentProgramRecordedVideosProps
> = () => {
  const borderClr = useColorModeValue("gray.100", "gray.700");
  const { subjectId } = useAppSelector((state) => state.programState);
  const [playingVideoId, setPlayingVideoId] = useState<string>();

  const { id } = useParams<{ id: string }>();
  const {
    data: activeProgram,
    isLoading,
    error,
  } = useQuery<IActiveProgram>(["activeProgram", id, subjectId], () =>
    getActiveProgram(id, subjectId)
  );
  const [videosrc, setvideosrc] = useState<string | undefined>();

  const [isdefaultavailable, setisdefaultavailable] = useState<boolean>(false);
  useErrorToast(error);

  return (
    <>
      {isLoading ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <>
          <Heading size="md">
            <Link as={RoLink} to="/student/dashboard" color="teal.500">
              Dashboard
            </Link>{" "}
            / Program Details
          </Heading>
          <Stack direction={["column", "row"]} spacing="30px" my="20px">
            <Box
              border="1px"
              width={["100%", "65%"]}
              borderColor={borderClr}
              borderRadius="md"
              
            >
              <AspectRatio ratio={16 / 9}>
                <iframe title="naruto" src={videosrc} allowFullScreen />
              </AspectRatio>
            </Box>
            <Box
              my="20px"
              width={["100%", "35%"]}
              border="1px"
              borderColor={borderClr}
              borderRadius="md"
              overflow={'auto'}
              maxH={['50vh', '445px']}
            >
              {activeProgram?.program.subjects?.map((e: ISubject) => (
                <Subject
                  key={e?._id}
                  subject={e}
                  setvideosrc={setvideosrc}
                  setPlayingVideoId={setPlayingVideoId}
                  playingVideoId={playingVideoId}
                  setisdefaultavailable={setisdefaultavailable}
                  isdefaultavailable={isdefaultavailable}
                />
              ))}
            </Box>
          </Stack>
        </>
      )}
    </>
  );
};
export default StudentProgramRecordedVideos;
