import {
  Box,
  Heading,
  useColorModeValue,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import useErrorToast from "hook/error-toast";
// import { GoCheck, GoTrashcan, GoX, GoPencil } from "react-icons/go";
import EditComponent from "./EditComponent";
import { addSubject, getSubjects } from "API/subjectApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";

const AdminSubjects = () => {
  const cache = useQueryClient();
  const toast = useToast();
  const { data, error, isLoading } = useQuery<{ name: string; _id: string }[]>(
    "subjects",
    getSubjects
  );
  const {
    isLoading: addLoading,
    error: isAddError,
    mutate: addMutate,
  } = useMutation(addSubject, {
    onSuccess: (data) => {
      toast({
        title: "Subject Added",
        description: `${data.name} has been added`,
        status: "success",
      });
      cache.invalidateQueries("subjects");
    },
  });
  useErrorToast(error);
  useErrorToast(isAddError);

  return (
    <>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">Subjects</Heading>
      </HStack>

      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
        minH="400px"
        p="20px"
      >
        {/* Add new subject */}

        <Formik
          initialValues={{ name: "" }}
          onSubmit={(values) => {
            addMutate(values.name);
          }}
        >
          <Form>
            {" "}
            <HStack
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pb="20px"
              fontSize="lg"
              spacing="15px"
            >
              <Box flex="1">
                <InputControl
                  name="name"
                  inputProps={{ placeholder: "Enter new subject" }}
                />
              </Box>
              <Box w="100px" flexShrink={0} textAlign="right">
                <SubmitButton
                  isLoading={addLoading}
                  colorScheme="teal"
                  w="100%"
                >
                  Add New
                </SubmitButton>
              </Box>{" "}
            </HStack>
          </Form>
        </Formik>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {" "}
            {data?.map((subject) => (
              <Box
                py={3}
                borderBottom="1px"
                borderColor="gray.200"
                key={subject._id}
              >
                <EditComponent
                  key={subject._id}
                  name={subject.name}
                  _id={subject._id}
                />
              </Box>
            ))}
          </>
        )}
      </Box>
    </>
  );
};

export default AdminSubjects;

// eslint-disable-next-line no-lone-blocks
{
  /* <Editable
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderTop="1px"
          borderColor={useColorModeValue("gray.100", "gray.700")}
          py="10px"
          defaultValue="Science, Environment & Technology"
          fontSize="lg"
          isPreviewFocusable={true}
        >
          <Box flex="1">
            <EditablePreview />
            <EditableInput />
          </Box>

          <Box w="100px" flexShrink={0} textAlign="right">
            <IconButton
              colorScheme="teal"
              variant="ghost"
              aria-label="Edit Subject"
              icon={<GoPencil />}
              ms="10px"
            />
            <IconButton
              colorScheme="teal"
              variant="ghost"
              aria-label="Save Subject"
              icon={<GoCheck />}
              ms="10px"
            />
            <IconButton
              colorScheme="teal"
              variant="ghost"
              aria-label="Delete Subject"
              icon={<GoTrashcan />}
              ms="10px"
            />
          </Box>
        </Editable> */
}
