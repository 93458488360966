import {
  Spinner,
  Heading,
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useAppSelector } from "app/hook";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  createCareerPlan,
  getCareerPlanByStudentId,
  updateCareerPlan,
} from "API/careerPlanApi";
import { useLocation, useHistory } from "react-router-dom";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const CareerPlanEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const studyPlan = {
    id: "",
    topic: "",
    studyPlan: "",
    remark: "",
    months: {},
  };
  const [inputs, setInputs] = useState({
    class: "",
    idNo: "",
    panels: [],
    primaryObjective: "",
    secondaryObjective: "",
    year: "",
  });
  const [planInput, setPlanInput] = useState([{ ...studyPlan, id: "1" }]);

  // const { subjectId } = useAppSelector((state) => state.programState);
  // const { id } = useParams<{ id: string }>();
  // const {
  //   data: activeProgram,
  //   isLoading,
  //   error,
  // } = useQuery(["activeProgram", id, subjectId], () =>
  //   getActiveProgram(id, subjectId)
  // );
  // console.log(activeProgram);
  const [user, setUser] = useState({});
  const { role } = useAppSelector((state) => state.auth);
  const currentUser = useAppSelector((state) => state.auth.user);
  const item = location.state?.user;
  console.log(item);

  useEffect(() => {
    if (role === "student") {
      setUser(currentUser);
    } else {
      console.log(item);
      setUser(item);
    }
  }, [currentUser, item, role]);

  const userId = user?._id;

  const createCareerPlanMutation = useMutation(createCareerPlan);
  const updateCareerPlanMutation = useMutation(updateCareerPlan);

  //   const [tests, setTests] = useState<ITestShowCase[]>([]);
  //   const { subjectId } = useAppSelector((state) => state.programState);
  //   const dispatch = useAppDispatch();
  //   const [searchTest, setSearchTest] = useState("");
  //   const bgClr = useColorModeValue("red.50", "gray.700");

  //   const { id } = useParams<{ id: string }>();

  //   useEffect(() => {
  //console.log(activeProgram);
  //     if (activeProgram && subjectId) {
  //       const testIds = activeProgram?.tests.map((t) => t?.test?._id);
  //       const testNotTaken = activeProgram?.program?.tests.filter((test) => {
  //         return subjectId === test.subject.toString() &&
  //           testIds?.includes(test._id)
  //           ? false
  //           : true;
  //       });
  //       const testTaken = activeProgram?.tests
  //         .filter((t) =>
  //           t?.test?.subject.toString() === subjectId ? true : false
  //         )
  //         .map((t) => {
  //           return {
  //             ...t?.test,
  //             attempts: t.testAttempts.length,
  //             userTestId: t._id,
  //           };
  //         });
  //       setTests([...testNotTaken, ...testTaken]);
  //     }
  //   }, [activeProgram, subjectId]);

  //   useErrorToast(error);
  //   const borderClr = useColorModeValue("gray.100", "gray.700");
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "panels") {
      const newPanels = [...inputs.panels];
      const index = e.target.id;
      newPanels[index] = value;
      setInputs((prev) => ({ ...prev, [name]: newPanels }));
    } else setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleStudyPlanChange = (e, index) => {
    if (parseInt(e.target.value) > 10) {
      e.target.value = 10;
    }
    const { name, value, dataset } = e.target;
    const updatedPlanInput = [...planInput];

    if (name === "month") {
      const monthIndex = Number(dataset.monthIndex);
      updatedPlanInput[index].months = { ...planInput[index].months };
      updatedPlanInput[index].months[months[monthIndex]] = value;
    } else {
      updatedPlanInput[index][name] = value;
    }

    setPlanInput(updatedPlanInput);
  };

  const handleDelte = (e, id) => {
    const updated = planInput.filter((plan) => plan.id !== id);
    setPlanInput(updated);
  };

  const addMoreTopic = (e) => {
    const newId = (planInput.length + 1).toString();
    const newStudyPlan = { ...studyPlan, id: newId };
    setPlanInput([...planInput, newStudyPlan]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...inputs,
      studyPlan: [...planInput],
      student: user?._id,
    };
    let isEdit = careerPlan ? true : false;
    console.log(careerPlan);

    let response;
    if (isEdit) {
      response = await updateCareerPlanMutation.mutateAsync({
        id: careerPlan._id,
        obj,
      });
      // console.log(obj);
    } else {
      response = await createCareerPlanMutation.mutateAsync(obj);
      isEdit = true;
    }
    // // Handle success
    if (response.success) {
      toast({
        title: response.message,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const { data: careerPlan, isLoading } = useQuery(
    ["careerPlan", userId],
    () => getCareerPlanByStudentId(userId),
    {
      enabled: !!userId,
    }
  );

  useEffect(() => {
    if (careerPlan) {
      setInputs({
        class: careerPlan.class,
        idNo: careerPlan.idNo,
        panels: careerPlan.panels,
        primaryObjective: careerPlan.primaryObjective,
        secondaryObjective: careerPlan.secondaryObjective,
        year: careerPlan.year,
      });

      setPlanInput(careerPlan.studyPlan);
    }
  }, [careerPlan]);

  const handleGoBack = () => {
    history.goBack(); // Redirect to the previous page
  };

  const handleCancelClick = () => {
    history.push("/student/dashboard");
  };

  return (
    <>
      {isLoading ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <></>
      )}
      <Heading size="md" display={"flex"} flex>
        <Text
          color={"teal"}
          mr={"15px"}
          onClick={handleGoBack}
          cursor={"pointer"}
        >
          Back
        </Text>
        <Text>Edit Career Plan</Text>
      </Heading>

      <VStack
        mt={"25px"}
        p={"15px"}
        borderRadius={"10px"}
        border={"1px solid gray"}
        align={"start"}
      >
        <HStack>
          <Text mr={"25px"}>
            Student Name: <Text as={"b"}>{user?.name}</Text>
          </Text>
          <Text>
            School Name: <Text as={"b"}>{user?.school?.name}</Text>
          </Text>
        </HStack>
        <Box w={"100%"} border={"1px solid black.600"}></Box>
        <form style={{ width: "100%" }}>
          <HStack
            w={"100%"}
            display={"flex"}
            gridGap={"100px"}
            justifyContent={"space-between"}
          >
            <VStack align={"start"} flex={"1"}>
              <Text fontSize="lg" mb={"0"}>
                Class
              </Text>
              <Input
                borderColor={"gray.300"}
                style={{ marginTop: "0px" }}
                name="class"
                value={inputs.class}
                onChange={handleChange}
                type="text"
                isRequired={true}
                required
              />
            </VStack>
            <VStack align={"start"} flex={"1"}>
              <Text fontSize="lg" mb={"0"}>
                ID No.
              </Text>
              <Input
                borderColor={"gray.300"}
                style={{ marginTop: "0px" }}
                name="idNo"
                value={inputs.idNo}
                onChange={handleChange}
                type="text"
              />
            </VStack>
          </HStack>
          <VStack mt={"15px"} align={"start"} w={"100%"}>
            <Text fontSize="lg" mb={"0"}>
              Name of Panel Members to Review
            </Text>
            <HStack w={"100%"} display={"flex"}>
              {Array.from({ length: 4 }).map((_, index) => (
                <Input
                  key={index}
                  borderColor={"gray.300"}
                  style={{ marginTop: "0px" }}
                  name="panels"
                  value={
                    inputs.panels.length > index ? inputs.panels[index] : ""
                  }
                  onChange={handleChange}
                  id={index}
                  flex={"1"}
                  type="text"
                />
              ))}
            </HStack>
          </VStack>
          <HStack
            w={"100%"}
            marginTop={"15px"}
            display={"flex"}
            gridGap={"100px"}
            justifyContent={"space-between"}
          >
            <VStack align={"start"} flex={"1"}>
              <Text fontSize="lg" mb={"0"}>
                Primary Objective
              </Text>
              <Input
                borderColor={"gray.300"}
                style={{ marginTop: "0px" }}
                name="primaryObjective"
                value={inputs.primaryObjective}
                onChange={handleChange}
                type="text"
              />
            </VStack>
            <VStack align={"start"} flex={"1"}>
              <Text fontSize="lg" mb={"0"}>
                Secondary Objective
              </Text>
              <Input
                borderColor={"gray.300"}
                style={{ marginTop: "0px" }}
                name="secondaryObjective"
                value={inputs.secondaryObjective}
                onChange={handleChange}
                type="text"
              />
            </VStack>
          </HStack>
          <HStack
            w={"100%"}
            display={"flex"}
            gridGap={"100px"}
            justifyContent={"space-between"}
            marginTop={"15px"}
          >
            <VStack align={"start"} w={"45.7%"}>
              <Text color={"black"} fontSize="lg" mb={"0"}>
                Year
              </Text>
              <Input
                borderColor={"gray.300"}
                style={{ marginTop: "0px" }}
                name="year"
                value={inputs.year}
                onChange={handleChange}
                type="text"
              />
            </VStack>
          </HStack>

          {planInput.map((plan, index) => (
            <VStack bg={"#dedede"} key={index} my={"15px"} px={"10px"}>
              <HStack
                w={"100%"}
                display={"flex"}
                gridGap={"100px"}
                justifyContent={"space-between"}
                marginTop={"15px"}
              >
                <VStack align={"start"} flex={"1"}>
                  <Text fontSize="lg" mb={"0"}>
                    Topic
                  </Text>
                  <Input
                    borderColor={"gray.300"}
                    style={{ marginTop: "0px" }}
                    name="topic"
                    value={plan.topic}
                    onChange={(e) => handleStudyPlanChange(e, index)}
                    type="text"
                    bg={"white"}
                  />
                </VStack>
                <VStack align={"start"} flex={"1"}>
                  <Text fontSize="lg" mb={"0"}>
                    Study Plan
                  </Text>
                  <Input
                    borderColor={"gray.300"}
                    style={{ marginTop: "0px" }}
                    name="studyPlan"
                    value={plan.studyPlan}
                    onChange={(e) => handleStudyPlanChange(e, index)}
                    type="text"
                    bg={"white"}
                  />
                </VStack>
                <VStack align={"start"} flex={"1"}>
                  <Text fontSize="lg" mb={"0"}>
                    Remark
                  </Text>
                  <Input
                    borderColor={"gray.300"}
                    style={{ marginTop: "0px" }}
                    name="remark"
                    value={plan.remark}
                    onChange={(e) => handleStudyPlanChange(e, index)}
                    type="text"
                    bg={"white"}
                  />
                </VStack>
              </HStack>
              <VStack align={"start"} py={"15px"}>
                <Text fontSize="lg" mb={"0"}>
                  Monthly Review
                </Text>
                <HStack gridGap={"10px"} flexWrap={"wrap"}>
                  {months.map((month, monthIndex) => (
                    <InputGroup w={"12%"} key={month} borderRadius={"0"}>
                      <InputLeftAddon
                        borderRadius={"0"}
                        bg={"#c1c1c1"}
                        children={month}
                      />
                      <Input
                        focusBorderColor="none"
                        id={index}
                        width={"50px"}
                        bg={"white"}
                        name="month"
                        data-month-index={monthIndex}
                        value={plan.months[month] || ""}
                        onChange={(e) => handleStudyPlanChange(e, index)}
                        type="number"
                      />
                    </InputGroup>
                  ))}
                </HStack>
                {plan.id !== "1" && (
                  <HStack w={"100%"} justifyContent={"flex-end"}>
                    <DeleteIcon
                      color={"red"}
                      fontSize={"20px"}
                      cursor={"pointer"}
                      onClick={(e) => handleDelte(e, plan.id)}
                    />
                  </HStack>
                )}
              </VStack>
            </VStack>
          ))}

          <Text
            as={"b"}
            color={"teal"}
            onClick={(e) => addMoreTopic()}
            cursor={"pointer"}
          >
            Add More Topic
          </Text>
          <HStack mt={"10px"}>
            <Button colorScheme="teal" size="sm" onClick={handleSubmit}>
              Submit
            </Button>
            <Button
              size="sm"
              bg={"#999797"}
              color={"white"}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </HStack>
        </form>
      </VStack>
    </>
  );
};
export default CareerPlanEdit;
