import { Flex, Text, VStack } from "@chakra-ui/react";
import { getPersonalInterviewData } from "API/personalInterviewApi";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

const CurrentAndGeneralInfo = ({ user }) => {
  const [currentAndGeneralInfo, setCurrentAndGeneralInfo] = useState([
    {
      label: "Population Statistics / General Knowledge",
      values: "",
    },
    {
      label: "Geographical Policies / schemes / Statistics / General Knowledge",
      values: "",
    },
    {
      label: "Historical Policies / schemes / Statistics / General Knowledge",
      values: "",
    },
    {
      label:
        "Major trade commerce & Industry Policies / schemes / Statistics / General Knowledge",
      values: "",
    },
    {
      label:
        "State Budget / deficit / Policies / schemes / Statistics / General Knowledge",
      values: "",
    },
    {
      label: "Seasons Policies / schemes / Statistics / General Knowledge",
      values: "",
    },
    {
      label: "Crop Policies / schemes / Statistics / General Knowledge",
      values: "",
    },
    { label: "Job Key task", values: "" },
    { label: "Job Financial / designation", values: "" },
    {
      label: "Job Sector & industry manufacturing / growing",
      values: "",
    },
    { label: "Job Key activities", values: "" },
  ]);

  const { data: personalInterviewData } = useQuery(
    ["personalInterviewData"],
    () => getPersonalInterviewData(user)
  );

  useEffect(() => {
    if (
      personalInterviewData?.data?.currentAndGeneralInfo &&
      personalInterviewData?.data?.currentAndGeneralInfo.length !== 0
    ) {
      const newFields = [];
      personalInterviewData?.data?.currentAndGeneralInfo.map((field) =>
        newFields.push({ label: field.label, values: field.values })
      );
      setCurrentAndGeneralInfo(newFields);
    }
  }, [personalInterviewData]);

  return (
    <VStack
      alignItems={"flex-start"}
      w={"100%"}
      paddingX={"25px"}
      marginBottom={"10px"}
    >
      <Text as={"b"} fontSize={"20px"}>
        Current And General Information
      </Text>
      <Flex
        flexDirection={"row"}
        flexWrap={"wrap"}
        w={"100%"}
        justifyContent={"space-between"}
      >
        {currentAndGeneralInfo.map((data, index) => (
          <Text
            key={index}
            w={"30%"}
            color={"black"}
            style={{
              marginTop: "5px",
            }}
          >
            <Text
              display={"inline"}
              fontWeight={600}
            >{`${data.label} : `}</Text>{" "}
            {data.values === "" ? "N/A" : data.values}
          </Text>
        ))}
      </Flex>
    </VStack>
  );
};

export default CurrentAndGeneralInfo;
