import { FC } from "react";
import {
  Heading,
  Stack,
  HStack,
  Text,
  Box,
  Badge,
  Flex,
} from "@chakra-ui/react";
import UpdateLicence from "./UpdateLicence";

interface PurchasedProductProps {
  PurchasedProduct: IProduct;
}
const PurchasedProduct: FC<PurchasedProductProps> = ({ PurchasedProduct }) => {
  return (
    <>
      <Box w={"75%"}>
        <Heading fontSize="md">
          {PurchasedProduct.product?.name}
          {PurchasedProduct.product?.access.map((access: any) => (
            <Badge variant={"solid"} colorScheme={"teal"} ml={"1"} key={access}>
              {access}
            </Badge>
          ))}
        </Heading>
        <Box>
          <Flex mt={"2"}>
            <Text color={"teal"}>
              {PurchasedProduct?.product?.program?.name}
            </Text>
            <Box d={["none", "flex"]} px="2" color={"red.500"}>
              •
            </Box>
            <Text color={"teal"}>
              Duration: {PurchasedProduct?.product?.duration} months
            </Text>
          </Flex>

          <Stack spacing="0px">
            <Box>
              <Text as="span">Subjects: </Text>{" "}
              {PurchasedProduct?.product?.program?.subjects?.map(
                (subject: any) => (
                  <Text
                    key={subject._id}
                    as="span"
                    textTransform="capitalize"
                    pr="1"
                  >
                    {subject?.name},
                  </Text>
                )
              )}
            </Box>
          </Stack>
        </Box>
      </Box>

      <HStack width={"25%"} spacing={0}>
        <Box w={"45%"} textAlign={"center"}>
          <Text fontSize={"20px"} fontWeight={"bold"}>
            {PurchasedProduct.quantity}
          </Text>
          <Text fontSize={"12px"}>Licences</Text>
        </Box>
        <UpdateLicence PurchasedProduct={PurchasedProduct} />
      </HStack>
    </>
  );
};

export default PurchasedProduct;
