import { Box, HStack, Flex, Stack } from "@chakra-ui/layout";
import {
  Table as CTable,
  Tr,
  Tbody,
  Spinner,
  Td,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  Avatar,
} from "@chakra-ui/react";
import { FC } from "react";
import { GoKebabVertical } from "react-icons/go";
// import { useState } from "react";
import { Link as RoLink, useHistory } from "react-router-dom";
import TableHeader from "components/dumb/Table/TableHeader";
import DeleteStudent from "./DeleteStudent";
import { useAppSelector } from "app/hook";

interface StudentTableProps {
  isLoading: boolean;
  items: IStudent[];
  disableUpdate: boolean;
  tableHeader: {
    id: string;
    label: string;
    alignRight: boolean;
  }[];
  editRoute: string;
}

const StudentTable: FC<StudentTableProps> = ({
  isLoading,
  items,
  disableUpdate,
  tableHeader,
  editRoute,
}) => {
  const itemsCount = items?.length;

  const { role } = useAppSelector((state) => state.auth);

  const history = useHistory();
  const handleMenuItemClick = (item: any) => {
    history.push({
      pathname: "/student/career-plan",
      state: { item },
    });
  };

  const handleViewInterviewClick = (item: any) => {
    history.push({
      pathname: "/interview",
      state: { item },
    });
  };
  const handleSpeakingSkill = (item: any) => {
    history.push({
      pathname: "/speaking-skill",
      state: { item },
    });
  };
  const handleWritingSkill = (item: any) => {
    history.push({
      pathname: "/writing-skill",
      state: { item },
    });
  };
  return (
    <Box overflow="hidden">
      <Box overflowX="auto">
        <CTable variant="striped" maxH="100vh">
          <TableHeader tableHeaders={tableHeader} />
          <Tbody>
            {isLoading ? (
              <Flex
                as="tr"
                width="100%"
                height="100%"
                justify="center"
                align="center"
              >
                <Spinner as="td" />
              </Flex>
            ) : itemsCount === 0 ? (
              <Flex
                as="tr"
                width="100%"
                height="100%"
                justify="center"
                align="center"
              >
                <Text as="td">No Student Found!</Text>
              </Flex>
            ) : (
              <>
                {items?.map((item, i) => (
                  <Tr key={item._id}>
                    <Td>
                      <HStack>
                        <Avatar name={item.name} src={item.avatar} />
                        <Stack>
                          <Text fontSize="sm">{item.name}</Text>
                          <Text fontSize="xs">{item.email}</Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>{item?.school?.name}</Td>
                    {!disableUpdate && (
                      <Td>
                        <Menu closeOnBlur={true}>
                          <MenuButton
                            as={IconButton}
                            icon={<GoKebabVertical />}
                            variant="ghost"
                          ></MenuButton>
                          <MenuList>
                            {role === "staff" ? (
                              <></>
                            ) : (
                              <>
                                <MenuItem
                                  as={RoLink}
                                  to={`${editRoute}/${item._id}`}
                                >
                                  Edit
                                </MenuItem>

                                <MenuItem
                                  as={RoLink}
                                  to={`${editRoute}/${item._id}/aasign-program`}
                                >
                                  Programs
                                </MenuItem>
                              </>
                            )}

                            <MenuItem
                              as={RoLink}
                              to={`${editRoute}/${item._id}/reports`}
                            >
                              Reports
                            </MenuItem>
                            <MenuItem
                              as={RoLink}
                              to={`${editRoute}/${item._id}/performance-reports`}
                            >
                              Performance Reports
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleViewInterviewClick(item)}
                            >
                              Personal Interview
                            </MenuItem>
                            <MenuItem onClick={() => handleSpeakingSkill(item)}>
                              Speaking Skill
                            </MenuItem>
                            <MenuItem onClick={() => handleWritingSkill(item)}>
                              Writing SKill
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick(item)}>
                              Career Plan
                            </MenuItem>
                            {role === "staff" ? (
                              <></>
                            ) : (
                              <DeleteStudent userId={item._id} />
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    )}
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </CTable>
      </Box>
    </Box>
  );
};
export default StudentTable;
