import { FC, useState } from "react";
import { getProgramById } from "API/programApi";
import { Box } from "@chakra-ui/react";
import { useQuery } from "react-query";
import Tests from "./Tests";

interface SubjectsProps {
  program: string;
}

const Subjects: FC<SubjectsProps> = ({ program: oldProgramId }) => {
  const programId = oldProgramId;
  const [subjectId] = useState<string>("");
  const { data: program } = useQuery<IProgram>(
    ["program", programId, subjectId],
    () => getProgramById(programId, subjectId),
    {
      enabled: !!programId,
    }
  );

  return (
    <>
      {program?.subjects.map((subject) => (
        <Box key={subject._id}>
          {/* <Tr key={subject._id} bg={'teal.100'}>
            <Td colSpan={5}>{subject.name}</Td>
          </Tr>
          <Tests subjectId={subject._id} programId={program._id} /> */}
          <Box bg={"teal.100"} p={"10px"} textTransform={'capitalize'}>
            {subject.name}
          </Box>
          <Tests subjectId={subject._id} programId={program._id} />
        </Box>
      ))}
    </>
  );
};
export default Subjects;
