import { useQuery } from "react-query";

import useErrorToast from "hook/error-toast";
import { FC } from "react";

import { getPrograms } from "API/programApi";

import { Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";

import TestProgram from "./TestProgram/TestProgram";

interface TestProgramsProps {}

const TestPrograms: FC<TestProgramsProps> = () => {
  const {
    data: programData,
    isLoading: programLoading,
    error: programError,
  } = useQuery<IAllProgram[]>("programs", getPrograms);

  useErrorToast(programError);

  return (
    <>
      {programLoading ? (
        <Spinner />
      ) : (
        <>
          <Stack mt="4">
            {programData
              ?.map((program) => (
                <TestProgram program={program} key={program._id} />
              ))
              .reverse()}
          </Stack>
        </>
      )}
    </>
  );
};

export default TestPrograms;
