import { Select } from "@chakra-ui/select";
import { useAppDispatch, useAppSelector } from "app/hook";
import { setSchoolId } from "app/features/schoolFilter/schoolFilter";


interface ProgramSelectProps {
  school: ISchool[] | undefined
}

const SchoolSelect= (props:ProgramSelectProps) => {
  const { schoolId } = useAppSelector((state) => state.schoolFilter);
  const { school:data} = props;
  const dispatch = useAppDispatch();

  return (
    <Select
      placeholder="Schools"
      size="sm"
      value={schoolId ?? ""}
      rounded="md"
      display={{ base: "none", md: "block", lg: "block" }}
      textTransform="capitalize"
      onChange={(e) => {
        dispatch(setSchoolId(e.target.value));
      }}
    >
      {data &&
        data?.map((e:ISchool) => (
          <option key={e._id} value={e._id}>
            {e.name}
          </option>
        ))}
    </Select>
  );
};
export default SchoolSelect;
