import { axios } from "./AxiosInstance";

interface IFilterPerformance {
    page?: number;
    size?: number;
    programId?: string;
    searchText?: string;
}

export const filterPerformance = async ({
    page,
    size,
    programId,
    searchText,
}: IFilterPerformance) => {
    try {
        const { data } = await axios.post(`/performance/filter`, {
            page,
            size,
            programId,
            searchText,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const getPerformanceById = async ({
    id,
    subjectId
}: {
    id: string;
    subjectId: string;
}) => {
    try {
        const { data } = await axios.post(`/performance/get`, {
            performanceId: id,
            subjectId
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const getPerformancesByProgramId = async ({
    programId,
}: {
    programId: string;
}) => {
    try {
        const { data } = await axios.post(`/performance/getByProgram`, {
            programId
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};



export const deleteManyPerformances = async (ids: string[]) => {
    try {
        const { data } = await axios.post(`/performance/deleteMany`, {
            ids
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};


export const updatePerformance = async ({
    name,
    basic_objective_academics,
    career_objectives_1,
    career_objectives_2,
    programId,
    performanceId,
}: {
    name: string,
    basic_objective_academics: string,
    career_objectives_1: string,
    career_objectives_2: string,
    programId: string,
    performanceId: string,

}) => {
    try {
        const { data } = await axios.patch(`/performance/update`, {
            name,
            basic_objective_academics,
            career_objectives_1,
            career_objectives_2,
            programId,
            performanceId,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const addPerformance = async ({
    name,
    basic_objective_academics,
    career_objectives_1,
    career_objectives_2,
    programId,
    // performanceId,
}: {
    name: string,
    basic_objective_academics: string,
    career_objectives_1: string,
    career_objectives_2: string,
    programId: string,
    // performanceId: string,
}) => {
    try {
        const { data } = await axios.post(`/performance/add`, {
            name,
            basic_objective_academics,
            career_objectives_1,
            career_objectives_2,
            programId,
            // performanceId,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};
export const addParameterToPerformance = async ({
    parameter,
    performanceId,
}: {
    parameter:
    {
        name: string;
        marks: number;
        lenovo: string[];
        subject: string;
    }
    performanceId: string;
}) => {
    try {
        const { data } = await axios.post(`/performance/addParameter`, {
            parameter: parameter,
            performanceId,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};
export const updateParameterOfPerformance = async ({
    parameter,
    performanceId,
    parameterId
}: {
    parameter:
    {
        name: string;
        marks: number;
        lenovo: string[];
        subject: string;
    }
    performanceId: string;
    parameterId: string;
}) => {
    try {
        const { data } = await axios.post(`/performance/updateParameter`, {
            parameter: parameter,
            performanceId,
            parameterId
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const removeParameterFromPerformance = async ({
    performanceId,
    parameterId
}: {

    performanceId: string;
    parameterId: string;
}) => {
    try {
        const { data } = await axios.post(`/performance/removeParameter`, {
            performanceId,
            parameterId
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};


