import { Flex, Grid, Heading, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { updateProfile } from "API/authApi";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { useMutation } from "react-query";
import { resetPasswordValidationSchema } from "validation";

const Settings = () => {
  const toast = useToast();

  const {
    isLoading,
    error: passwordError,
    mutateAsync,
  } = useMutation(updateProfile, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Password Changed",
        description: `Now Login with new password`,
      });
    },
  });
  useErrorToast(passwordError);

  return (
    <Grid placeItems="center" height="60vh">
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={async (values) => {
          try {
            await mutateAsync({ password: values.password });
          } catch (error) {}
        }}
        validationSchema={resetPasswordValidationSchema}
      >
        <Form>
          <Stack width="500px" height="300px" boxShadow="xl" p="4" pt="8">
            <Heading color="main.500" fontSize="2xl">
              Change Password
            </Heading>
            <InputControl
              label="New Password:"
              name="password"
              inputProps={{
                placeholder: "Enter New Password",
                type: "password",
                focusBorderColor: "main.400",
                // mb: "8",
              }}
            />
            <InputControl
              label="Confirm Password:"
              name="confirmPassword"
              inputProps={{
                placeholder: "Enter New Password",
                type: "password",
                focusBorderColor: "main.400",
                // mb: "8",
              }}
            />
            <Flex justify="flex-end">
              <SubmitButton
                isLoading={isLoading}
                mt="4"
                type="submit"
                colorScheme="green"
              >
                Update
              </SubmitButton>
            </Flex>
          </Stack>
        </Form>
      </Formik>
    </Grid>
  );
};
export default Settings;
