import { Flex, Stack, Text } from "@chakra-ui/layout";
import { deleteProgramSubject, updateProgram } from "API/programApi";
import { Formik, Form } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDisclosure } from "@chakra-ui/hooks";
import AddAndClose from "components/smart/AddAndClose";
import UpdateProgramSubject from "./UpdateSubject/UpdateProgramSubject";
import { DeleteIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/button";

interface UpdateProgramProps {
  program: IAllProgram;
  onClose: () => void;
}

const UpdateProgram: FC<UpdateProgramProps> = ({ program, onClose }) => {
  const cache = useQueryClient();
  const { isOpen, onToggle } = useDisclosure();
  const { isLoading, mutateAsync, error } = useMutation(updateProgram, {
    onSuccess: () => {
      cache.invalidateQueries("programs");
      onClose();
    },
  });
  useErrorToast(error);

  return (
    <>
      <Formik
        initialValues={{ programName: program.name }}
        onSubmit={(values) => {
          try {
            mutateAsync({
              id: program._id,
              programName: values.programName,
            });
          } catch (error) {}
        }}
      >
        <Form>
          <Stack minHeight="200px" justify="space-around">
            <InputControl name="programName" label="Program Name" />
            <SubmitButton isLoading={isLoading}>Update Program</SubmitButton>
          </Stack>
        </Form>
      </Formik>
      <AddAndClose text="Add Subject" onToggle={onToggle} isOpen={isOpen} />
      {isOpen && <UpdateProgramSubject onClose={onClose} program={program} />}

      {!isOpen &&
        program.subjects.map((subject) => (
          <SubjectItem
            onClose={onClose}
            key={subject._id}
            subject={subject}
            programId={program._id}
          />
        ))}
    </>
  );
};
export default UpdateProgram;

const SubjectItem = ({
  programId,
  subject,
  onClose,
}: {
  programId: string;
  subject: ISubject;
  onClose: () => void;
}) => {
  const cache = useQueryClient();
  const { isLoading, mutateAsync, error } = useMutation(deleteProgramSubject, {
    onSuccess: () => {
      cache.invalidateQueries("programs");
      onClose();
    },
  });

  useErrorToast(error);

  return (
    <>
      <Flex justify="space-between" p="4" boxShadow="md" align="center">
        <Text>{subject.name}</Text>
        <IconButton
          aria-label="delete button"
          isLoading={isLoading}
          onClick={async () => {
            try {
              await mutateAsync({
                subjectId: subject._id,
                programId,
              });
            } catch (error) {}
          }}
          ml="4"
          icon={<DeleteIcon />}
        />
      </Flex>
    </>
  );
};
