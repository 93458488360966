import { Thead, Tr, Th } from "@chakra-ui/react";
import { FC } from "react";
import { useColorModeValue } from "@chakra-ui/system";

export interface TableHeaderProps {
  tableHeaders: {
    id: string;
    label: string;
    alignRight: boolean;
  }[];
}

const TableHeader: FC<TableHeaderProps> = ({ tableHeaders }) => {
  return (
    <Thead>
      <Tr bg={useColorModeValue("gray.50", "gray.700")}>
        {tableHeaders.map((item) => (
          <Th key={item.id} align={item.alignRight ? "right" : "left"}>
            {item.label}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};
export default TableHeader;
