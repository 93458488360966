import {
  Box,
  Center,
  Flex,
  Heading,
  Link,
  Spinner,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";
import useErrorToast from "hook/error-toast";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import MCQSResult from "./MCQSResultPreview";
import MCQMResult from "./MCQMResultPreview";
import TFResult from "./TFResultPreview";
import LAResult from "./LAResultPreview";
import { getAttemptedTestById } from "API/attemptedTestApi";
import { useHistory } from "react-router-dom";
const TestResultDetails = () => {
  const { testId } = useParams<{ testId: string }>();

  const history = useHistory();
  const {
    isLoading,
    data: attemptedTest,
    error,
  } = useQuery<IAttemptedTest>(["attemptedTest", testId], () =>
    getAttemptedTestById({ id: testId })
  );
  useErrorToast(error);

  if (isLoading)
    return (
      <Center>
        <Spinner />
      </Center>
    );
  return (
    <Stack>
      <Flex justify="space-between" mb={"4"}>
        <Stack>
          <Heading fontSize="xl">
            <Link onClick={history.goBack} color="teal.500">
              Back
            </Link>{" "}
            / {attemptedTest?.test?.name}
          </Heading>
          <Text>
            Test level : {attemptedTest?.test.level} | Subject :{" "}
            {attemptedTest?.test.subject?.name}
          </Text>
        </Stack>
      </Flex>

      <Box>
        <Stack
          direction={["column", "row"]}
          spacing={"4"}
          bg={"green.200"}
          p={"4"}
        >
          <Stack
            direction={["column", "row"]}
            spacing={["4", "0"]}
            w={["100%", "80%"]}
          >
            <Text>Questions attempted: {attemptedTest?.attemptedQuestion}</Text>
            <Text color={"red"} px="2" d={["none", "block"]}>
              •
            </Text>
            <Text>Not Attempted: {attemptedTest?.notAttemptedQuestion}</Text>
            <Text color={"red"} px="2" d={["none", "block"]}>
              •
            </Text>
            <Text>Correct answer: {attemptedTest?.correctAnswer}</Text>
            <Text color={"red"} px="2" d={["none", "block"]}>
              •
            </Text>
            <Text>Wrong answer: {attemptedTest?.wrongAnswer}</Text>
            <Text color={"red"} px="2" d={["none", "block"]}>
              •
            </Text>
            <Text>Time Taken: {attemptedTest?.timeTaken}s</Text>
          </Stack>
          <Stack spacing={0} w={["100%", "20%"]}>
            <Heading fontSize="lg" textAlign={["left", "right"]}>
              {" "}
              You got total {attemptedTest?.totalMarks} marks
            </Heading>
          </Stack>
        </Stack>
        {attemptedTest?.questionResult?.map((question, index) => (
          <Stack
            key={index}
            border="1px solid #ccc"
            p="4"
            borderTop="none"
            borderBottomRadius={
              index === attemptedTest?.questionResult?.length - 1 ? "xl" : "0"
            }
          >
            <Flex justify="space-between">
              <Heading fontSize="lg">
                {index + 1}/{attemptedTest?.questionResult?.length} :
                {question.question}
                {question.questionImg && (
                  <Image
                    my={"20px"}
                    src={question.questionImg}
                    alt="Question image"
                  />
                )}
              </Heading>
              <Text>{question.marks ?? 0} marks</Text>
            </Flex>
            {question.type.toLowerCase() === "mcqs" && (
              <MCQSResult question={question as any} />
            )}
            {question.type.toLowerCase() === "tf" && (
              <TFResult question={question as any} />
            )}
            {question.type.toLowerCase() === "mcqm" && (
              <MCQMResult question={question as any} />
            )}
            {question.type.toLowerCase() === "la" && (
              <LAResult question={question as any} />
            )}
            {!question.attempted && <Text color="red.600">Not attempted</Text>}
            {question.type.toLowerCase() !== "la" && (
              <>
                <Text color="blue">
                  <Heading fontSize="md" as="span">
                    Justification
                  </Heading>
                  : {question.answerJustification}
                </Text>
                {question.justificationImg && (
                  <Image
                    my={"20px"}
                    maxW={"600px"}
                    src={question.justificationImg}
                    alt="Justification image"
                  />
                )}
              </>
            )}
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};

export default TestResultDetails;
