import { Box } from "@chakra-ui/layout";
import { getStudentById } from "API/studentApi";
import useErrorToast from "hook/error-toast";
// import { Form, Formik } from "formik";
// import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { FC } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ActiveProgramList from "./ActiveProgramList/ActiveProgramList";

interface AssignProgramToStudentProps {
  school: ISchool;
}

const AssignProgramToStudent: FC<AssignProgramToStudentProps> = ({
  school,
}) => {
  // const toast = useToast();
  const { id } = useParams<{ id: string }>();
  // const cache = useQueryClient();
  const {
    data: student,

    error: errorStudent,
  } = useQuery<IStudent>(["student", id], () => getStudentById(id));

  useErrorToast(errorStudent);
  // const { error, isLoading, mutateAsync } = useMutation(
  //   "addActiveProgram",
  //   addActiveProgram,
  //   {
  //     onSuccess: () => {
  //       cache.invalidateQueries(["student", id]);
  //       toast({
  //         title: "Program Add",
  //         status: "success",
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     },
  //   }
  // );

  // useErrorToast(error);
  // useErrorToast(errorStudent);

  return (
    // <Formik
    //   initialValues={{ expiresAt: "", programId: "" }}
    //   onSubmit={async (values) => {
    //     try {
    //       await mutateAsync({
    //         expiresAt: values.expiresAt,
    //         programId: values.programId,
    //         studentId: id,
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }}
    // >
    //   <Form>
    //     <Grid gridGap="4">
    //       <GridItem>
    //         {student && <ActiveProgramList student={student} school={school} />}
    //       </GridItem>
    //       <GridItem>
    //         <InputControl
    //           label="Valid Upto"
    //           name="expiresAt"
    //           inputProps={{
    //             type: "date",
    //             min: new Date().toISOString().split("T")[0],
    //           }}
    //         />
    //       </GridItem>
    //       <GridItem>
    //         <SelectControl label="Program" name="programId">
    //           <option value="">Select Program</option>
    //           {school?.program.map((program) => (
    //             <option value={program._id} key={program._id}>
    //               {program.name}
    //             </option>
    //           ))}
    //         </SelectControl>
    //       </GridItem>
    //       <GridItem>
    //         <SubmitButton isLoading={isLoading}>Submit</SubmitButton>
    //       </GridItem>
    //     </Grid>
    //   </Form>
    // </Formik>
    <>
      <Box>
        {student && <ActiveProgramList student={student} school={school} />}
      </Box>
    </>
  );
};
export default AssignProgramToStudent;
