import {
  AlertDialog as CAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import React, { FC } from "react";

interface AlertDialogProps {
  dialogMessage: string;
  dialogHeading: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const AlertDialog: FC<AlertDialogProps> = ({
  dialogMessage,
  dialogHeading,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}) => {
  const cancelRef = React.useRef(null);

  return (
    <>
      <CAlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{dialogHeading}</AlertDialogHeader>
          <AlertDialogCloseButton _focus={{ boxShadow: 'none' }} />
          <AlertDialogBody>{dialogMessage}</AlertDialogBody>
          <AlertDialogFooter>
            <Button _focus={{ boxShadow: 'none' }} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button
              isLoading={isLoading}
              _focus={{ boxShadow: 'none' }}
              onClick={onConfirm}
              colorScheme="red"
              ml={3}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </CAlertDialog>
    </>
  );
};
export default AlertDialog;
