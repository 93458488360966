import { Select } from "@chakra-ui/select";
import { useAppDispatch, useAppSelector } from "app/hook";
import {  setStatus } from "app/features/batchStatus/batchStatus";

// interface ProgramSelectProps {}

const BatchStatusSelect= () => {
  const { status } = useAppSelector((state) => state.batchStatus);
  const dispatch = useAppDispatch();

  const  data  = [
    {
        name: "ALL",
        value: "ALL"
    },
    {
        name: "ASSIGNED",
        value: "ASSIGNED"
    },
    {
        name: "NOT ASSIGNED",
        value: "NOTASSIGNED"

    }
  ];

  return (
    <Select
      size="sm"
      value={status ?? ""}
      rounded="md"
      display={{ base: "none", md: "block", lg: "block" }}
      textTransform="capitalize"
      onChange={(e) => {
        dispatch(setStatus(e.target.value));
      }}
    >
      {data &&
        data?.map((e) => (
          <option key={e.name} value={e.value}>
            {e.name}
          </option>
        ))}
    </Select>
  );
};
export default BatchStatusSelect;
