import {
  Box,
  Select,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { getProducts } from "API/productApi";
import React, { useState } from "react";
import { useQuery } from "react-query";
import AddEditProduct from "./AddEditProduct";
import ProductTable from "./ProductTable";
import { getAllPrograms } from "API/programApi";

const AdminProducts = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef(null);
  const { data: products, isLoading } = useQuery<IProduct[]>(
    "products",
    getProducts
  );
  const [program, setProgram] = useState<string>("");

  const { data: programs } = useQuery<IProgram[]>(
    "allprograms",
    getAllPrograms
  );

  return (
    <>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">Products</Heading>
        <HStack spacing={"50px"}>
          <Select
            placeholder="all programs"
            size="sm"
            rounded="md"
            value={program}
            display={{ base: "none", md: "block", lg: "block" }}
            textTransform="capitalize"
            onChange={(e) => {
              setProgram(e.target.value);
            }}
          >
            {programs?.map((p) => (
              <option key={p._id} value={p._id}>
                {p.name}
              </option>
            ))}
          </Select>
          <Button colorScheme="teal" onClick={onOpen} px={"50px"}>
            Add Product
          </Button>
        </HStack>
      </HStack>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
        pos="relative"
      >
        {/* <Stack direction={["column", "row"]} bg="gray.100" p="4">
          <Box w={["100%", "60%"]}>Search</Box>
          <Box w={["100%", "40%"]}>Permission filter</Box>
        </Stack> */}
        {products && (
          <ProductTable
            products={products}
            program={program}
            isLoading={isLoading}
          />
        )}
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Create a new product
          </DrawerHeader>
          <DrawerBody>
            <AddEditProduct isUpdate={false} onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AdminProducts;
