import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
interface GenerticState<T> {
  questions: T[];
  totalMarks: number | null;
  attemptedQuestion: number | null;
  notAttemptedQuestion: number | null;
  correctAnswer: number | null;
  wrongAnswer: number | null;
  timeTaken: number | null;
  testDate: Date | null;
}

// Define the initial state using that type
const initialState: GenerticState<
  | IAnsweredTFQuestion
  | IAnsweredMCQSQuestion
  | IAnsweredMCQMQuestion
  | IAnsweredLAQuestion
> = {
  questions: [],
  totalMarks: null,
  attemptedQuestion: null,
  notAttemptedQuestion: null,
  correctAnswer: null,
  wrongAnswer: null,
  timeTaken: null,
  testDate: null,
};

export const questionslice = createSlice({
  name: "questions",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setQuestions: <T>(state: GenerticState<T>, action: PayloadAction<T[]>) => {
      state.questions = action.payload;
    },
    setQuestionAnswer: (
      state,
      action: PayloadAction<{
        index: number;
        isCorrect?: boolean;
        marks?: number;
        tfSubmittedAnswer?: string | undefined;
        mcqsSubmittedAnswer?: number | undefined;
        mcqmSubmittedAnswer?: number[] | undefined;
        laSubmittedAnswer?: string | undefined;
        attempted: boolean;
      }>
    ) => {
      state.questions[action.payload.index].isCorrect =
        action.payload.isCorrect;
      (state.questions[action.payload.index] as any).tfSubmittedAnswer =
        action.payload.tfSubmittedAnswer;
      (state.questions[action.payload.index] as any).mcqsSubmittedAnswer =
        action.payload.mcqsSubmittedAnswer;
      (state.questions[action.payload.index] as any).mcqmSubmittedAnswer =
        action.payload.mcqmSubmittedAnswer;
      (state.questions[action.payload.index] as any).laSubmittedAnswer =
        action.payload.laSubmittedAnswer;
      state.questions[action.payload.index].marks = action.payload.marks;
      state.questions[action.payload.index].attempted =
        action.payload.attempted;
    },
    setResult: (
      state,
      action: PayloadAction<{
        totalMarks: number;
        attemptedQuestion: number;
        notAttemptedQuestion: number;
        correctAnswer: number;
        wrongAnswer: number;
        timeTaken: number;
      }>
    ) => {
      state.totalMarks = action.payload.totalMarks;
      state.attemptedQuestion = action.payload.attemptedQuestion;
      state.notAttemptedQuestion = action.payload.notAttemptedQuestion;
      state.correctAnswer = action.payload.correctAnswer;
      state.wrongAnswer = action.payload.wrongAnswer;
      state.timeTaken = action.payload.timeTaken;
      state.testDate = new Date();
    },
  },

  // reducers: {
});

export const { setQuestions, setQuestionAnswer, setResult } =
  questionslice.actions;

export default questionslice.reducer;
