import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getProgramById } from "API/programApi";
import useErrorToast from "hook/error-toast";
import { Spinner } from "@chakra-ui/spinner";
import { HStack, Heading, Link } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import Chapters from "./components/Chapters/Chapters";
import { useAppDispatch } from "app/hook";
import { setProgram } from "app/features/program/programSlice";
import { Link as RoLink } from "react-router-dom";
import { useAppSelector } from "app/hook";
interface UpdateProgramProps {}

const UpdateProgram: FC<UpdateProgramProps> = () => {
  const [subjectId, setSubjectId] = useState("");
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const {
    data: program,
    isLoading,
    error,
  } = useQuery<IProgram>(
    ["program", id, subjectId],
    () => getProgramById(id, subjectId),
    { onSuccess: (data) => dispatch(setProgram({ subjectId, program: data })) }
  );

  useErrorToast(error);
  // console.log(subjectId);

  const { role } = useAppSelector((state) => state.auth);

  return (
    <>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">
          <Link as={RoLink} to={`/${role}/dashboard/programs`} color="teal.500">
            Programs
          </Link>{" "}
          / {program?.name}
        </Heading>
      </HStack>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Select
            mt="8"
            placeholder="Select Subject"
            rounded="md"
            textTransform="capitalize"
            value={subjectId}
            onChange={(e) => {
              setSubjectId(e.target.value);
            }}
          >
            {program?.subjects?.map((e: ISubject) => (
              <option key={e?.name} value={e._id}>
                {e.name}
              </option>
            ))}
          </Select>

          <Chapters />
        </>
      )}
    </>
  );
};
export default UpdateProgram;
