import { axios } from "./AxiosInstance";

export const getStudentById = async (id: string | undefined | null) => {
  if (id && id.length > 0) {
    try {
      const { data } = await axios.get(`/student/${id}`);
      return data;
    } catch (error: any) {
      throw Error(error.response.data.message);
    }
  }
};

export const getStudents = async ({
  searchText,
  page,
  size,
  schoolId,
}: {
  searchText: string;
  page: number;
  size: number;
  schoolId: string;
}) => {
  try {
    const { data } = await axios.post(`/student/filterStudent`, {
      searchText,
      page,
      size,
      schoolId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const getAllStudents = async (schoolId: string | undefined) => {
  try {
    const { data } = await axios.get(`/student/all/${schoolId}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }

};

export const getListOfStudents = async (programId: string) => {
  try {
    const { data } = await axios.post(`/student/getList`, {
      programId
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};


export const getListByProgram = async (programId:  IProgram | undefined , status: string, searchText: string, schoolId: string, batchId: string | undefined ) => {
  try {
    const { data } = await axios.post(`/student/getListByProgram`, {
      programId,
      status, 
      searchText,
      schoolId,
      batchId
    });
    
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getStudentByEmailOrMobile = async (searchText: string) => {
  // console.log("test");
  
  try {
    const { data } = await axios.post(`student/getStudentByEmailOrMobile`, {
      searchText
    });
    // console.log(data);
    
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};


export const addStudent = async ({
  name,
  email,
  mobile,
  password,
  schoolId,
  gender,
  dateOfBirth,
  uid,
  interestedProgramId
}: {
  name: string;
  email: string;
  password: string;
  mobile: number;
  schoolId: string;
  gender: string;
  dateOfBirth: string | Date;
  uid: string;
  interestedProgramId: string;
}) => {
  try {
    const { data } = await axios.post(`/student/addStudent`, {
      name,
      email,
      mobile,
      schoolId,
      password,
      gender,
      dateOfBirth,
      uid,
      interestedProgramId
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateStudent = async ({
  name,
  email,
  mobile,
  password,
  gender,
  dateOfBirth,
  uid,
  schoolId,
  interestedProgramId,
  userId,
}: {
  name: string;
  userId: string;
  email: string;
  password: string;
  mobile: number;
  gender: string;
  dateOfBirth: string | Date;
  uid: string;
  schoolId: string;
  interestedProgramId: string;
}) => {
  try {
    const updateData = {
      userId,
      name,
      email,
      mobile,
      password: password.length > 0 ? password : undefined,
      gender,
      dateOfBirth,
      schoolId,
      newUid: uid,
      interestedProgramId: interestedProgramId
    };

    const { data } = await axios.patch(`/student/updateStudent`, updateData);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const deleteStudent = async ({ userId }: { userId: string }) => {
  try {
    const { data } = await axios.delete(`/student/${userId}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
