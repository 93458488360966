import { chakra } from "@chakra-ui/react";

export const RoundedCard = chakra("div", {
  // attach style props
  baseStyle: {
    background: "white",
    padding: 4,
    borderRadius: "xl",
    boxShadow: "xl",
  },

  // Two variants: rounded and smooth
});

export const SmoothCard = chakra("div", {
  baseStyle: {
    background: "white",
    padding: 4,
    borderRadius: "base",
    boxShadow: "md",
  },
});
