import { Center, Flex, Heading, Stack, Text } from "@chakra-ui/layout";
import { Button, Image } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { getTestById } from "API/testApi";
import { retestSubmit, submitUserTest } from "API/userTestApi";
import { setQuestions, setResult } from "app/features/question/questionSlice";
import { useAppDispatch, useAppSelector } from "app/hook";
import useErrorToast from "hook/error-toast";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import LAQuestion from "./LAQuestion";
import MCQMQuestion from "./MCQMQuestion";
import MCQSQuestion from "./MCQSQuestion";
import TFQuestion from "./TFQuestion";
import Timer from "./Timer";
type QuestionType =
  | IAnsweredTFQuestion
  | IAnsweredMCQSQuestion
  | IAnsweredMCQMQuestion
  | IAnsweredLAQuestion;

const TestGivingPanel = () => {
  const [timer, setTimer] = useState(0);
  const cache = useQueryClient();
  const { programId, subjectId } = useAppSelector(
    (state) => state.programState
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const { testId, userTestId, retest, activeProgramId } = useParams<{
    testId: string;
    userTestId: string;
    retest: string;
    activeProgramId: string;
  }>();
  const isRetest = retest === "true" ? true : false;
  const history = useHistory();
  const {
    mutate: testSubmitMutate,
    error: testSubmitError,
    isLoading: testSubmitLoading,
  } = useMutation("submit-user-test", submitUserTest, {
    onSuccess: (data) => {
      cache.invalidateQueries(["activeProgram", programId, subjectId]);
      cache.invalidateQueries("user");
      history.replace(
        data.isResultPending
          ? `/student/dashboard/test-wait`
          : `/student/dashboard/test-result/${data.attemptedId}`
      );
    },
  });
  const {
    mutate: retestSubmitMutate,
    error: retestSubmitError,
    isLoading: retestSubmitLoading,
  } = useMutation("retest", retestSubmit, {
    onSuccess: (data) => {
      cache.invalidateQueries(["activeProgram", programId, subjectId]);
      cache.invalidateQueries("user");
      history.replace(
        data.isResultPending
          ? `/student/dashboard/test-wait`
          : `/student/dashboard/test-result/${data.attemptedId}`
      );
    },
  });

  const { questions } = useAppSelector((state) => state.question);

  const currentQuestion: QuestionType = questions[currentQuestionIndex];
  const dispatch = useAppDispatch();
  const {
    isLoading,
    data: test,
    error,
  } = useQuery<ITest>(["test", testId], () => getTestById(testId), {
    onSuccess: (data) => dispatch(setQuestions(data?.questions)),
  });

  useErrorToast(error);
  useErrorToast(testSubmitError);
  useErrorToast(retestSubmitError);

  const nextQuestionHandler = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };
  const prevQuestionHandler = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const onFinishHandler = () => {
    let marks = 0;
    let attemptedCount = 0;
    let isCorrectCount = 0;

    for (let question of questions) {
      if (question.attempted) {
        marks += question.marks ?? 0;
        isCorrectCount += question.isCorrect ? 1 : 0;
        attemptedCount++;
      }
    }
    dispatch(
      setResult({
        totalMarks: marks,
        attemptedQuestion: attemptedCount,
        notAttemptedQuestion: questions.length - attemptedCount,
        correctAnswer: isCorrectCount,
        wrongAnswer: attemptedCount - isCorrectCount,
        timeTaken: timer,
      })
    );
    // add one second delay

    if (isRetest) {
      retestSubmitMutate({
        questions,
        attemptedQuestion: attemptedCount,
        correctAnswer: isCorrectCount,
        notAttemptedQuestion: questions.length - attemptedCount,
        timeTaken: timer,
        totalMarks: marks,
        wrongAnswer: attemptedCount - isCorrectCount,
        userTestId,
      });
    } else {
      testSubmitMutate({
        questions,
        attemptedQuestion: attemptedCount,
        correctAnswer: isCorrectCount,
        notAttemptedQuestion: questions.length - attemptedCount,
        timeTaken: timer,
        totalMarks: marks,
        wrongAnswer: attemptedCount - isCorrectCount,
        testId,
        activeProgramId,
      });
    }
  };
  if (isLoading) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <>
      <Stack spacing={8}>
        <Flex justify="space-between">
          <Stack>
            <Heading fontSize="xl"> {test?.name}</Heading>
            <Text>
              Test level : {test?.level} | Subject : {test?.subject?.name}
            </Text>
          </Stack>
          <Timer timer={timer} setTimer={setTimer} />
        </Flex>

        <Stack border="1px solid #ccc" borderRadius="xl" p="4">
          <Heading fontSize="lg">
            {currentQuestionIndex + 1}/{questions?.length} :
            {currentQuestion?.question} 
            {currentQuestion.questionImg && 
                 <Image my={'20px'} src={currentQuestion?.questionImg} alt='Question image' />
                }
            
          </Heading>
          {currentQuestion?.type.toLowerCase() === "la" && (
            <LAQuestion
              index={currentQuestionIndex}
              prevQuestionHandler={prevQuestionHandler}
              nextQuestionHandler={nextQuestionHandler}
              question={currentQuestion as IAnsweredLAQuestion}
              setIsLastQuestion={setIsLastQuestion}
              isLastQuestion={currentQuestionIndex === questions.length - 1}
            />
          )}
          {currentQuestion?.type.toLowerCase() === "mcqm" && (
            <MCQMQuestion
              index={currentQuestionIndex}
              prevQuestionHandler={prevQuestionHandler}
              nextQuestionHandler={nextQuestionHandler}
              question={currentQuestion as IAnsweredMCQMQuestion}
              setIsLastQuestion={setIsLastQuestion}
              isLastQuestion={currentQuestionIndex === questions.length - 1}
            />
          )}
          {currentQuestion?.type.toLowerCase() === "mcqs" && (
            <MCQSQuestion
              index={currentQuestionIndex}
              prevQuestionHandler={prevQuestionHandler}
              nextQuestionHandler={nextQuestionHandler}
              question={currentQuestion as IAnsweredMCQSQuestion}
              setIsLastQuestion={setIsLastQuestion}
              isLastQuestion={currentQuestionIndex === questions.length - 1}
            />
          )}
          {currentQuestion?.type.toLowerCase() === "tf" && (
            <TFQuestion
              prevQuestionHandler={prevQuestionHandler}
              nextQuestionHandler={nextQuestionHandler}
              question={currentQuestion as IAnsweredTFQuestion}
              index={currentQuestionIndex}
              isLastQuestion={currentQuestionIndex === questions.length - 1}
              setIsLastQuestion={setIsLastQuestion}
            />
          )}
        </Stack>
        {isLastQuestion && (
          <Flex justify="flex-end">
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={onFinishHandler}
              isLoading={testSubmitLoading || retestSubmitLoading}
            >
              Submit Test
            </Button>
          </Flex>
        )}
      </Stack>
    </>
  );
};

export default TestGivingPanel;
