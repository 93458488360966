import {
  Stack,
  Flex,
  IconButton,
  Text,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { Tag } from "@chakra-ui/tag";
import { removeTest } from "API/testApi";
import { RoundedCard } from "chakra";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useDisclosure } from "@chakra-ui/hooks";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import UpdateTest from "./UpdateTest";
import TestQuestionsTable from "./TestQuestionsTable";

interface TestProps {
  test: ITest;
}

const Test: FC<TestProps> = ({ test }) => {
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();
  const cache = useQueryClient();

  const {
    isLoading: deleteLoading,
    error: deleteError,
    mutateAsync: deleteMutateAsync,
  } = useMutation(removeTest, {
    onSuccess: () => {
      toast({
        title: "Test Deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      cache.invalidateQueries(["program", test.program, test.subject]);
    },
  });
  useErrorToast(deleteError);
  return (
    <RoundedCard
      // m="auto"
      bg={isOpen ? "white" : "whiteAlpha.800"}
      transition="all 0.2s ease-in-out"
      _hover={{
        transform: isOpen ? "Scale(1)" : "Scale(1.025)",
        transition: "all 0.2s ease-in-out",
      }}
      mt="8"
      minH="16"
      key={test._id}
    >
      <Flex justify="space-between" onClick={onToggle}>
        <Stack>
          <Text>{test.name}</Text>
          <Flex>
            <Tag mr="4">{test.level}</Tag>
            <Tag>{`Questions: ${test.totalQuestions}`}</Tag>
          </Flex>
        </Stack>
        <Stack>
          {/* <IconButton size="sm" aria-label="edit" icon={<FaEdit />} /> */}
          {isOpen ? (
            <ChevronRightIcon color="teal.400" fontSize="2xl" />
          ) : (
            <ChevronDownIcon color="teal.400" fontSize="2xl" />
          )}
        </Stack>
      </Flex>
      {isOpen && (
        <>
          <Stack my="2">
            <Divider />
            <Flex justify="flex-end">
              <UpdateTest test={test} />
              <IconButton
                isLoading={deleteLoading}
                onClick={async () => {
                  try {
                    await deleteMutateAsync(test._id);
                  } catch (error) {}
                }}
                size="sm"
                aria-label="remove"
                icon={<FaTrash />}
              />{" "}
            </Flex>

            <TestQuestionsTable test={test} />
          </Stack>
        </>
      )}
    </RoundedCard>
  );
};
export default Test;
