import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state
interface PerformanceFilterState {
    page: number;
    size: number;
    searchText: string;
    programId: string | undefined;
}

// Define the initial state using that type
const initialState: PerformanceFilterState = {
    page: 1,
    size: 10,
    programId: undefined,
    searchText: "",
};

export const performanceFilter = createSlice({
    name: "performanceFilter",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setPageDefault: (state) => {
            state.page = initialState.page;
        },
        incrementPage: (state) => {
            if (state.page >= 1) {
                state.page++;
            }
        },
        decrementPage: (state) => {
            if (state.page > 1) {
                state.page--;
            }
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string>) => {
            console.log(action.payload);

            state.searchText = action.payload;
        },

        setProgramId: (state, action: PayloadAction<string>) => {
            state.programId = action.payload;
        },

        filterDefaultState: (state) => {
            state.page = 1;
            state.size = 10;
            state.programId = undefined;
            state.searchText = "";
        },
    },
});

export const {
    filterDefaultState,
    setPageDefault,
    incrementPage,
    decrementPage,
    setProgramId,
    setSize,
    setSearchText,
} = performanceFilter.actions;

export default performanceFilter.reducer;
