import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  createPersonalInterview,
  getPersonalInterviewData,
} from "API/personalInterviewApi";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

const EditConcludingQuestion = ({ tab, setTab, user: currUser }) => {
  const toast = useToast();
  const history = useHistory();
  const [concludingQuestionLabels, setConcludingQuestionLabels] = useState([
    {
      label: "Enforcing on your core capabilities & skills which you missed",
      values: "",
      new: false,
    },
    { label: "Knowledge", values: "", new: false },
    { label: "Skills", values: "", new: false },
    { label: "Attitude", values: "", new: false },
    { label: "Values", values: "", new: false },
    { label: "Ethics", values: "", new: false },
    { label: "Achievements", values: "", new: false },
    { label: "Value addition", values: "", new: false },
    { label: "Creativity", values: "", new: false },
  ]);

  const createPersonalInterviewMutation = useMutation(createPersonalInterview);

  // const { user } = useAppSelector((state) => state.auth);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(currUser);
  }, [currUser]);

  const { data: personalInterviewData } = useQuery(
    ["personalInterview"],
    () => getPersonalInterviewData(user),
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    if (
      personalInterviewData?.data?.concludingQuestions &&
      personalInterviewData?.data?.concludingQuestions.length !== 0
    ) {
      const newFields = [];
      personalInterviewData?.data?.concludingQuestions.map((field) =>
        newFields.push({
          label: field.label,
          values: field.values,
          new: false,
        })
      );
      setConcludingQuestionLabels(newFields);
    }
  }, [personalInterviewData]);

  const handleInputChange = (e, index) => {
    const newFields = [...concludingQuestionLabels];
    newFields[index].values = e.target.value;
    setConcludingQuestionLabels(newFields);
  };

  const handleNewInputLabelChange = (e, index) => {
    const newFields = [...concludingQuestionLabels];
    newFields[index].label = e.target.value;
    setConcludingQuestionLabels(newFields);
  };

  const handleInsertField = (index) => {
    const newFields = [...concludingQuestionLabels];
    newFields.splice(index + 1, 0, { label: "", values: "", new: true });
    setConcludingQuestionLabels(newFields);
  };

  const handleRemoveInputField = (index) => {
    const newFields = [...concludingQuestionLabels];
    newFields.splice(index, 1);
    setConcludingQuestionLabels(newFields);
  };

  const handleSaveClick = async () => {
    const data = [];
    concludingQuestionLabels.map((field, index) => {
      if (field.label !== "") {
        data.push({
          label: field.label,
          values: field.values === "" ? "N / A" : field.values,
          order: index,
        });
      }
      return "";
    });
    let response = await createPersonalInterviewMutation.mutateAsync({
      data,
      type: "concludingQuestions",
      user,
    });
    if (response.success) {
      toast({
        title: response.message,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      history.push({
        pathname: "/interview",
        state: { item: user },
      });
    }
  };

  return (
    <>
      <VStack w={"100%"} px={4}>
        {concludingQuestionLabels.map((key, index) =>
          !key.new ? (
            <Box w={"100%"} key={index}>
              <Text>{key.label}</Text>
              <HStack>
                <Input
                  onChange={(e) => handleInputChange(e, index)}
                  value={key.values}
                ></Input>
                <Button
                  colorScheme="teal"
                  fontSize={"xl"}
                  onClick={() => handleInsertField(index)}
                >
                  +
                </Button>
              </HStack>
            </Box>
          ) : (
            <Box w={"100%"} key={index}>
              {/* <HStack my={"16px"}>
                <Input value={"Field Label"} readOnly></Input>
                <Input value="Field Value" readOnly></Input>
                <Button
                  colorScheme="red"
                  onClick={() => handleRemoveInputField(index)}
                >
                  -
                </Button>
              </HStack> */}
              <HStack>
                <Input
                  placeholder="Field Label"
                  value={key.label}
                  onChange={(e) => handleNewInputLabelChange(e, index)}
                ></Input>
                <Input
                  placeholder="Field Value"
                  onChange={(e) => handleInputChange(e, index)}
                  value={key.values}
                ></Input>
                <Button
                  colorScheme="red"
                  onClick={() => handleRemoveInputField(index)}
                >
                  -
                </Button>
              </HStack>
            </Box>
          )
        )}
      </VStack>
      <HStack w="100%" px={4} py={"30px"}>
        <Button colorScheme="teal" onClick={handleSaveClick}>
          Save
        </Button>
        <Button colorScheme="teal" onClick={() => setTab(tab - 1)}>
          Previous
        </Button>
        <Button
          bg={"grey"}
          color={"White"}
          onClick={() =>
            history.push({
              pathname: "/interview",
              state: { item: user },
            })
          }
        >
          Cancel
        </Button>
      </HStack>
    </>
  );
};

export default EditConcludingQuestion;
