import { MenuItem, useToast } from "@chakra-ui/react";
import { deleteStaff } from "API/staffApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";

interface DeleteStaffProps {
  userId: string;
}

const DeleteStaff: FC<DeleteStaffProps> = ({ userId }) => {
  const toast = useToast();
  const cache = useQueryClient();
  const { isLoading, mutateAsync, error } = useMutation(deleteStaff, {
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Staff deleted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      cache.invalidateQueries("staffs");
    },
  });
  useErrorToast(error);
  return (
    <MenuItem
      onClick={async () => {
        try {
          await mutateAsync({
            userId: userId,
          });
        } catch (error) {}
      }}
    >
      {isLoading ? "Deleteing" : "Delete"}
    </MenuItem>
  );
};
export default DeleteStaff;
