import {
  Box,
  Container,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  Link,
} from "@chakra-ui/react";
import programbannerimg from "assets/images/nios-img.jpg";
// import testiimg01 from "assets/images/testi-01.jpg";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link as RoLink } from "react-router-dom";
// import CompTestimonials from './CompTestimonials';
import StaticFooter from "./StaticFooter";
const ProgramNIOS_01 = () => {
  return (
    <>
      <Box bg="gray.50">
        <Container py={["24px", "80px"]} maxW="container.xl">
          <Stack direction={["column-reverse", "row"]} alignItems={"center"}>
            <Box w={["100%", "70%"]}>
              <Heading as="h1" mb="6" fontSize={["30px", "45px"]}>
                NIOS-01 (National Institute of Open Studies) Preparing students
                for class X
              </Heading>
              <Text mb="40px" fontSize={["14px", "20px"]}>
                It was started by the Central Board of Secondary Education
                (CBSE) in 1979.
                <br />
                <br />
                Authority to register, examine and certify students registered
                with it up to pre-degree level courses
                <br />
                <br />
                Largest Open Schooling in the world with enrolment of 40 lakhs
                in last 5 years.
                <br />
                <br />
                Students can take direct admission for Class
                <br />
                1. Secondary (Class X)
                <br />
                2. Sr. Secondary (Class XII)
              </Text>
              <Box
                as={RoLink}
                to="/signup"
                px={8}
                py={4}
                textTransform="uppercase"
                color="white"
                fontWeight="bold"
                borderRadius="full"
                bgGradient="linear(to-r, teal.500, green.500)"
                _hover={{
                  bgGradient: "linear(to-r, red.500, yellow.500)",
                }}
              >
                Sign Up Now
              </Box>
              <Box
                as={Link}
                px={8}
                py={4}
                ml={4}
                textTransform="uppercase"
                border="2px"
                borderColor="teal.500"
                fontWeight="bold"
                borderRadius="full"
                href="https://kpexam-my.sharepoint.com/:w:/p/info/EYxifgVYpXFElnkxMi0WCJkB0yXWIZITUUdKCf69U7mujA?e=NEgXMr"
                isExternal
              >
                Learn more
              </Box>
            </Box>
            <Box w={["100%", "30%"]}>
              <Image
                src={programbannerimg}
                alt="CIVIL SERVICES FOUNDATION PROGRAM LEVEL -1"
                boxSize="100%"
              />
              {/* <AspectRatio maxW='940px' ratio={1 / 1}>
                                <iframe
                                    title="VIII – Graduation Program"
                                    src="https://www.youtube.com/embed/Fipb2E_3LMw"
                                    allowFullScreen
                                />
                            </AspectRatio> */}
            </Box>
          </Stack>
        </Container>
      </Box>
      <Box bg={"purple.200"}>
        <Container
          py={["24px", "100px"]}
          maxW="container.lg"
          textAlign={"center"}
        >
          <Heading as="h2" mb="50px" fontSize={["24px", "36px"]}>
            What we offer
          </Heading>
          {/* <Text mb="40px" fontSize={['14px', '18px']}>These programs are designed for masses helping, students who are financially low & students fall short of capabilities but aspiring to get into professional courses or government job.</Text> */}

          <Stack
            direction={["column", "row"]}
            spacing={"12px"}
            fontSize={["18px", "20px"]}
            mt={"30px"}
          >
            <Box w={["100%", "50%"]} textAlign={"left"}>
              <List spacing={5}>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Working on fundamentals
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Live lectures
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Recorded lectures for revision
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Test on each topic. Students can take multiple test and
                  practice
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Free Course on Graphic design, MS Office & Tally
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Separate log in for each student
                </ListItem>
              </List>
            </Box>
            <Box w={["100%", "50%"]} textAlign={"left"}>
              <List spacing={5}>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Lectures will be conducted twice / thrice a week.
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Query handling by respective faculty
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Notes on each chapter
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Career guidance
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={BsFillArrowRightCircleFill}
                    color="purple.500"
                  />
                  Preparation for competitive exams
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Container>
      </Box>

      <Box>
        <Container
          py={["24px", "100px"]}
          maxW="container.xl"
          textAlign={"center"}
        >
          <Heading as="h2" mb="5" fontSize={["24px", "36px"]}>
            Program content
          </Heading>
          <Box overflow="hidden">
            <Box overflowX="auto">
              <Table
                colorScheme="purple"
                border="1px"
                borderColor="gray.200"
                borderRadius="10px"
              >
                <TableCaption>
                  1. Students chose any Five Subjects Minimum
                  <br />
                  2. Students to consider minimum one language and maximum two
                  languages
                  <br />
                  3. Other three or more subjects can be considered.
                </TableCaption>
                <Thead>
                  <Tr bg={"blue.100"}>
                    <Th
                      fontSize={"14px"}
                      borderRight={"1px"}
                      borderRightColor="gray.200"
                    >
                      GROUP A – LANGUAGES
                    </Th>
                    <Th
                      fontSize={"14px"}
                      borderRight={"1px"}
                      borderRightColor="gray.200"
                    >
                      GROUP – B SUBJECTS
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      English
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Mathematics
                    </Td>
                  </Tr>
                  <Tr bg={"gray.100"}>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Hindi
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Social Science
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Marathi
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Economics
                    </Td>
                  </Tr>
                  <Tr bg={"gray.100"}>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Urdu
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Business Studies
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Arabic
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Home Science
                    </Td>
                  </Tr>
                  <Tr bg={"gray.100"}>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Data Entry Operation
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Psychology
                    </Td>
                  </Tr>
                  <Tr bg={"gray.100"}>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Indian Culture & Heritage
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Painting
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container pb={["24px", "100px"]} maxW="container.lg">
          <Heading as="h2" my="5" fontSize={["24px", "36px"]}>
            Knowledge Park Training Academy coaching fees
          </Heading>
          <Box overflow="hidden">
            <Box overflowX="auto">
              <Table
                colorScheme="purple"
                border="1px"
                borderColor="gray.200"
                borderRadius="10px"
              >
                <Tbody>
                  <Tr bg={"blue.100"}>
                    <Th
                      fontSize={"14px"}
                      borderRight={"1px"}
                      borderRightColor="gray.200"
                      colSpan={4}
                    >
                      SECONDARY COURSE FEES
                    </Th>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Number of subjects
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td
                      borderRight={"1px"}
                      borderRightColor="gray.200"
                      colSpan={2}
                    >
                      Free Coaching on the following Subjects
                    </Td>
                  </Tr>

                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      (i) for 5 subjects
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      11,000/-
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      1
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Graphic Design
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      (ii) for each additional subject
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      2,000/-
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      2
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      MS Office
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      3
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Tally
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200"></Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      4
                    </Td>
                    <Td borderRight={"1px"} borderRightColor="gray.200">
                      Personality development program
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </Container>
      </Box>

      <StaticFooter />
    </>
  );
};

export default ProgramNIOS_01;
