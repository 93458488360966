import { useState } from "react";
import {
  Heading,
  Box,
  Button,
  useColorModeValue,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { GoPlus } from "react-icons/go";
import { Link as RoLink } from "react-router-dom";
import { filterPerformance } from "API/performanceApi";
import { useQuery } from "react-query";
import useErrorToast from "hook/error-toast";
import AdminQuestionTable from "./PerformancesTable/PerformancesTable";
import ProgramSelect from "./ProgramSelect";
import {  useAppSelector } from "app/hook";

import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
} from "app/features/performanceFilter/performanceFilter";
import TableSearch from "components/dumb/Table/TableSearch";
import TablePaginator from "components/dumb/Table/TablePaginator";

const Performances = () => {
  const [resetCheckBox, setResetCheckBox] = useState(false);
  const { role } = useAppSelector((state) => state.auth);
  const { programId, size, page, searchText } = useAppSelector(
    (state) => state.performanceFilter
  );
  // const dispatch = useAppDispatch();

  const { data, isLoading, error } = useQuery(
    ["performances", page, size, programId, searchText],
    () => {
      setResetCheckBox(true);
      return filterPerformance({
        page,
        size,
        programId,
        searchText,
      });
    },
    { onSettled: () => setResetCheckBox(false), keepPreviousData: true }
  );

  useErrorToast(error);

  return (
    <>
      <Heading size="md">All Performances</Heading>

      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderTopRadius="md"
        pos="relative"
      >
        <Stack w="100%" direction={["column", "row"]} p="15px">
          <Box w={"30%"}>
            <TableSearch
              searchText={searchText}
              setPageDefault={setPageDefault}
              setSearchText={setSearchText}
              searchLabel="Search by performance name"
            />
          </Box>
          <Box flex="1" textAlign="right">
            <HStack>
              {/* <QuestionTypeSelect /> */}
              <ProgramSelect />
              {/* <SubjectSelect
                subject={subjectId}
                selectOutput={(value) => {
                  dispatch(setSubjectId(value));
                  dispatch(setPageDefault());
                }}
              /> */}

              <Box flex="0">
                <Button
                  rightIcon={<GoPlus />}
                  colorScheme="teal"
                  variant="solid"
                  size="sm"
                  as={RoLink}
                  to={`/${role}/dashboard/performances/add`}
                >
                  Add Performance
                </Button>
              </Box>
            </HStack>
          </Box>
        </Stack>
        <AdminQuestionTable
          resetCheckBox={resetCheckBox}
          items={data?.performances}
          isLoading={isLoading}
          disableUpdate={false}
        />
        <TablePaginator
          count={data?.count}
          decrementPage={decrementPage}
          incrementPage={incrementPage}
          page={data?.page}
          setPageDefault={setPageDefault}
          setSize={setSize}
          size={size}
          totalPages={data?.totalPage}
        />
      </Box>
    </>
  );
};

export default Performances;
