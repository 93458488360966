/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  getSpeakingSkill,
  saveAudio,
  updateSkillData,
} from "API/speakingSkillApi";
import { useAppSelector } from "app/hook";
import { useEffect, useState } from "react";
import { FaEdit, FaMicrophone, FaPause, FaPlay, FaStop } from "react-icons/fa";
import { ReactMic } from "react-mic";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";

const SpeakingSkillView = () => {
  const toast = useToast();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [currIndex, setCurrIndex] = useState(null);
  const [formData, setFormData] = useState(null);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [skillData, setSkillData] = useState([
    {
      index: 0,
      topic: "Speak about yourself",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 1,
      topic: "My first day to school / college",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 2,
      topic: "My favourite subject and the topic",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 3,
      topic: "Describe your best friend",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 4,
      topic: "My favorite sports",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 5,
      topic: "Speak on any current topic",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 6,
      topic: "How self-respect and dignity is important to a person",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 7,
      topic:
        "How your parents nurturing helped you to develop your personality ",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 8,
      topic: "Privileges and rights of your neighbor",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
    {
      index: 9,
      topic: "Describe your favorite pet animal",
      audioUrl: "",
      marks: "",
      remarks: "",
    },
  ]);

  const [isAddingNewTopic, setIsAddingNewTopic] = useState(false);
  const [newTopic, setNewTopic] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [orginalData, setOriginalData] = useState([]);

  // const userId = useAppSelector((state) => state.auth.uid);
  let { role, user: currentUser } = useAppSelector((state) => state.auth);

  const hasInterviewAccess = user?.activePrograms?.some((program) =>
    program.purchasedProduct?.product?.access?.includes("speakingSkill")
  );

  useEffect(() => {
    if (role === "student") {
      setUser(currentUser);
    } else {
      setUser(location.state?.item);
    }
  }, [role, currentUser, location.state]);

  const [recordingStates, setRecordingStates] = useState(
    Array(skillData.length).fill(false)
  );

  const startRecording = (index) => {
    setRecordingStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? true : false))
    );
    setCurrIndex(index);
    toast({
      title: "Recording started",
      status: "warning",
      duration: 4000,
      isClosable: true,
    });
  };

  const stopRecording = (index) => {
    setRecordingStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? false : state))
    );
  };

  // const onData = (recordedBlob) => {
  //   console.log("Chunk of real-time data:", recordedBlob);
  // };

  const onStop = async (recordedBlob) => {
    // console.log("Recorded Blob: ", recordedBlob);
    const fileSizeLimit = 3 * 1024 * 1024; // 3MB in bytes
    if (recordedBlob.blob.size > fileSizeLimit) {
      toast({
        title: "The audio file exceed the limit of 3MB",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append("audio", recordedBlob.blob, "audio.wav");
    setFormData(formData);
  };

  const mutation = useMutation((formData) => saveAudio(formData), {
    onSuccess: (data) => {
      const newSKillData = data.data.topics;
      setSkillData(newSKillData);
      setFormData(null);
      toast({
        title: "Recording saved successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (error) => {
      // console.error("Error uploading audio:", error);
      setFormData(null);
      toast({
        title: "Error! Try Again later",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  const updateMutation = useMutation(
    (newSKillData) => updateSkillData(newSKillData, user),
    {
      onSuccess: (data) => {
        toast({
          title: "Updated successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: "Error! Try Again later",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      },
    }
  );

  const { data: speakingSkillData } = useQuery(
    ["speakingSkillData"],
    () => getSpeakingSkill(user),
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    if (
      speakingSkillData?.data?.topics &&
      speakingSkillData?.data?.topics?.length !== 0
    ) {
      setSkillData(speakingSkillData?.data?.topics);
    }
  }, [speakingSkillData]);

  useEffect(() => {
    if (formData && currIndex >= 0) {
      formData.append("index", currIndex);
      formData.append("userId", user._id);
      formData.append("skillData", JSON.stringify(skillData));
      mutation.mutate(formData);
    }
  }, [formData]);

  const handleNewTopic = (e) => {
    if (newTopic === "") {
      toast({
        title: "New Topic can't be empty",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    const index = skillData.length;
    const newSKillData = skillData;
    newSKillData.push({
      index,
      topic: newTopic,
      audioUrl: "",
      marks: "",
      remarks: "",
    });
    updateMutation.mutate(newSKillData);
    setSkillData(newSKillData);
    setNewTopic("");
    setIsAddingNewTopic(false);
  };

  const handleEditMarks = (index, value) => {
    setSkillData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, marks: value } : item
      )
    );
  };
  const handleEditRemarks = (index, value) => {
    setSkillData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, remarks: value } : item
      )
    );
  };

  const handleEditChanges = () => {
    updateMutation.mutate(skillData);
    setIsEditing(false);
  };
  return (
    <>
      {!hasInterviewAccess ? (
        <HStack placeItems="center">
          <Heading color={"red"}>No Data Available</Heading>
        </HStack>
      ) : (
        <VStack spacing={4} width="100%" padding={4} position={"relative"}>
          <HStack width={"100%"} justifyContent={"space-between"}>
            <Text as="h2" fontSize="xl" fontWeight="bold">
              Speaking Skills L-3
            </Text>
            <HStack>
              <Button
                colorScheme="teal"
                onClick={
                  isEditing
                    ? handleEditChanges
                    : () => {
                        setIsEditing(true);
                        setOriginalData(skillData);
                      }
                }
              >
                {isEditing ? (
                  <Text>Save</Text>
                ) : (
                  <>
                    <Text mr={"5px"}>Update Marks/Remarks</Text> <FaEdit />
                  </>
                )}
              </Button>

              {isEditing ? (
                <Button
                  colorScheme="teal"
                  onClick={() => {
                    setSkillData(orginalData);
                    setOriginalData([]);
                    setIsEditing(false);
                  }}
                >
                  <Text>Cancel</Text>
                </Button>
              ) : null}
            </HStack>
          </HStack>

          <Table>
            <Thead>
              <Tr bg={"#d0cece"} border={"2px"} borderColor={"#a9a9a9"}>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                >
                  N O.
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                >
                  TOPIC
                </Th>
                {/* <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                >
                  MAX. 3 MINUTES
                </Th> */}
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                >
                  Record
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                >
                  Play
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                >
                  <HStack>
                    <Text>Marks</Text>
                  </HStack>
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                >
                  <HStack>
                    <Text>Remark</Text>
                  </HStack>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {skillData.map((data, index) => (
                <Tr key={index} border={"2px"} borderColor={"#a9a9a9"}>
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                  >
                    {data.index + 1}
                  </Td>
                  <Td
                    padding={0}
                    paddingLeft={"10px"}
                    alignItems={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                  >
                    {data.topic}
                  </Td>
                  {/* <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                  ></Td> */}
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                  >
                    {/* <HStack> */}
                    <IconButton
                      marginTop={"5px"}
                      marginBottom={"5px"}
                      icon={
                        recordingStates[index] ? <FaStop /> : <FaMicrophone />
                      }
                      // disabled={role === "student" ? false : true}
                      colorScheme="teal"
                      variant="outline"
                      aria-label="Record"
                      onClick={
                        recordingStates[index]
                          ? () => stopRecording(index)
                          : () => startRecording(index)
                      }
                    />
                    <ReactMic
                      record={recordingStates[index]}
                      width={0}
                      height={0}
                      onStop={onStop}
                    />
                    {/* </HStack> */}
                  </Td>
                  <Td
                    textAlign={"center"}
                    borderRight={"2px"}
                    paddingY={"10px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                  >
                    <IconButton
                      icon={
                        skillData[index].audioUrl === playingAudio ? (
                          <FaPause />
                        ) : (
                          <FaPlay />
                        )
                      }
                      colorScheme="teal"
                      variant="outline"
                      aria-label="Play"
                      onClick={() => {
                        if (skillData[index].audioUrl === playingAudio) {
                          setPlayingAudio(null);
                        } else if (skillData[index].audioUrl !== "") {
                          setPlayingAudio(skillData[index].audioUrl);
                          toast({
                            title: "Playing Recording",
                            status: "warning",
                            duration: 4000,
                            isClosable: true,
                          });
                        } else {
                          setPlayingAudio(null);
                          toast({
                            title: "No Recording found ",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                          });
                        }
                      }}
                    />
                  </Td>
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                    bg={"#ffe699"}
                  >
                    {isEditing ? (
                      <Input
                        value={data.marks ? data.marks : ""}
                        onChange={(e) => handleEditMarks(index, e.target.value)}
                        size="sm"
                        width={"50px"}
                        backgroundColor={"white"}
                      />
                    ) : (
                      <Text fontWeight={"bold"}>{data.marks}</Text>
                    )}
                  </Td>
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                    bg={"#ffe699"}
                  >
                    {isEditing ? (
                      <Input
                        value={data.remarks ? data.remarks : ""}
                        onChange={(e) =>
                          handleEditRemarks(index, e.target.value)
                        }
                        width={"fit-content"}
                        backgroundColor={"white"}
                      />
                    ) : (
                      <Text>{data.remarks}</Text>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <HStack w={"100%"} justifyContent={"flex-end"}>
            {isAddingNewTopic && (
              <Input
                placeholder="Your New topic"
                value={newTopic}
                onChange={(e) => setNewTopic(e.target.value)}
              ></Input>
            )}
            <Button
              disabled={skillData.length === 15}
              colorScheme="teal"
              onClick={
                isAddingNewTopic
                  ? handleNewTopic
                  : () => setIsAddingNewTopic(true)
              }
            >
              {isAddingNewTopic ? "Add" : "Add New Topic"}
            </Button>
          </HStack>

          {playingAudio && (
            <audio
              id="myAudio"
              controls
              src={playingAudio}
              style={{
                position: "absolute",
                top: "-25px",
                right: "500px",
                display: "none",
              }}
              autoPlay
            ></audio>
          )}
        </VStack>
      )}
    </>
  );
};

export default SpeakingSkillView;
