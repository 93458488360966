import { Center, Grid, GridItem, Heading } from "@chakra-ui/layout";
import { Flex, Spinner, FormLabel } from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { FC, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getSchools } from "API/schoolApi";
import CustomSelect from "./CustomSelect";
import { useToast } from "@chakra-ui/toast";
import useErrorToast from "hook/error-toast";
import { useHistory, useParams } from "react-router";
import { addStaff, getStaffById, updateStaff } from "API/staffApi";
import {
  addStaffValidationSchema,
  updateStaffValidationSchema,
} from "validation/staffValidation";

import { getAllPrograms } from "API/programApi";
import { getSubjects } from "API/subjectApi";

interface AddAndUpdateStaffProps {
  isUpdate?: boolean;
  redirectTo: string;
}

const AddAndUpdateStaff: FC<AddAndUpdateStaffProps> = ({
  isUpdate = false,
  redirectTo,
}) => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const {
    data,
    isLoading: isLoadingStaff,
    error: errorStaff,
  } = useQuery(["staff", id], () => getStaffById(id), {
    enabled: isUpdate,
    refetchInterval: 1000,
  });
  const { data: subjects } = useQuery<ISubject[]>("subjects", getSubjects);

  const schoolId = data?.school?._id;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (schoolId) {
      setValue(schoolId);
    }
  }, [schoolId]);

  const { data: programs } = useQuery<IProgram[]>(
    "allprograms",
    getAllPrograms
  );

  const {
    error: updateError,
    isLoading: updateIsLoading,
    mutateAsync: updateMutate,
  } = useMutation("updateStaff", updateStaff, {
    onSuccess: () => {
      toast({
        title: "Staff Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push(redirectTo);
      console.log(redirectTo);
    },
  });

  const pageSchool = 0;
  const sizeSchool = 0;
  const searchTextSchool = undefined;

  const { data: dataSchool } = useQuery<ISchoolFilters>(
    ["users", pageSchool, sizeSchool, searchTextSchool],
    () => {
      return getSchools({
        page: pageSchool,
        size: sizeSchool,
        searchText: searchTextSchool,
      });
    }
    // { onSettled: () => setResetCheckBox(false), keepPreviousData: true }
  );

  const {
    error: addError,
    isLoading: addIsLoading,
    mutateAsync: addMutate,
  } = useMutation("addStaff", addStaff, {
    onSuccess: () => {
      toast({
        title: "Staff Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push(redirectTo);
    },
  });
  useErrorToast(updateError);
  useErrorToast(errorStaff);
  useErrorToast(addError);

  const getDEfaultValueInterestedPrograms = () => {
    return data?.interestedPrograms.map((newData: any) => newData._id) ?? [];
  };
  const getDEfaultValuesubjects = () => {
    return data?.subjects.map((newData: any) => newData._id) ?? [];
  };

  return (
    <>
      {isLoadingStaff ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Formik
          validationSchema={
            isUpdate ? updateStaffValidationSchema : addStaffValidationSchema
          }
          initialValues={
            isUpdate
              ? {
                  name: data?.name ?? "",
                  mobile: data?.mobile ?? 0,
                  email: data?.email ?? "",
                  password: "",
                  confirmPassword: "",
                  schoolId: data?.school._id ?? "",
                  interestedProgram:
                    data?.interestedPrograms.map(
                      (newdata: any) => newdata._id
                    ) ?? [],
                  subjects:
                    data?.subjects.map((newdata: any) => newdata._id) ?? [],
                }
              : {
                  name: "",
                  mobile: 0,
                  email: "",
                  password: "",
                  schoolId: "",
                  confirmPassword: "",
                  interestedProgram: [],
                  subjects: [],
                }
          }
          onSubmit={async (values) => {
            if (isUpdate) {
              try {
                await updateMutate({
                  userId: id,
                  ...values,
                  schoolId: value,
                });
              } catch (error) {}
            } else {
              try {
                await addMutate({
                  ...values,
                  schoolId: value,
                });
              } catch (error) {}
            }
          }}
        >
          <Form>
            <Grid gridColumn={{ base: 1, sm: 2 }} gridGap="4">
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <Heading fontSize="xl">
                  {isUpdate ? "Update" : "Add"} Staff
                </Heading>
              </GridItem>

              <GridItem>
                <InputControl
                  name="name"
                  inputProps={{ placeholder: "Enter Teacher Name" }}
                  label="Teacher Name"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="email"
                  inputProps={{
                    placeholder: "Enter Teacher Email",
                    type: "email",
                  }}
                  label="Teacher Email"
                />
              </GridItem>
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <InputControl
                  name="mobile"
                  inputProps={{
                    placeholder: "Enter Teacher Mobile No.",
                    type: "number",
                  }}
                  label="Teacher Mobile No."
                />
              </GridItem>
              <GridItem>
                <SelectControl
                  label="School Id"
                  name="schoolId"
                  selectProps={{ placeholder: "Select School Id" }}
                  defaultValue={value}
                  onChange={(e) =>
                    setValue((e.target as HTMLInputElement).value)
                  }
                >
                  {dataSchool?.schools?.map((school) => (
                    <option value={school._id} key={school._id}>
                      {school.name}
                    </option>
                  ))}
                </SelectControl>
              </GridItem>
              <GridItem>
              <FormLabel>Assign programs</FormLabel>
                <Field
                  className="custom-select"
                  name="interestedProgram"
                  options={
                    programs?.map((program) => ({
                      label: program.name,
                      value: program._id,
                    })) ?? []
                  }
                  defaultValue={getDEfaultValueInterestedPrograms()}
                  component={CustomSelect}
                  placeholder="Select multi programs..."
                  isMulti={true}
                />
              </GridItem>
              <GridItem>
              <FormLabel>Assign subjects</FormLabel>
                <Field
                  className="custom-select"
                  name="subjects"
                  options={
                    subjects?.map((subject) => ({
                      label: subject.name,
                      value: subject._id,
                    })) ?? []
                  }
                  defaultValue={getDEfaultValuesubjects()}
                  component={CustomSelect}
                  placeholder="Select multi subjects..."
                  isMulti={true}
                />
              </GridItem>

              <GridItem>
                <InputControl
                  name="password"
                  inputProps={{
                    placeholder: "Enter Password",
                    type: "password",
                  }}
                  label="Password"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="confirmPassword"
                  inputProps={{
                    placeholder: "Confirm Password",
                    type: "password",
                  }}
                  label="Confirm Password"
                />
              </GridItem>

              <Flex align="flex-end">
                <SubmitButton
                  isLoading={addIsLoading || updateIsLoading}
                  width="full"
                >
                  Submit
                </SubmitButton>
              </Flex>
            </Grid>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default AddAndUpdateStaff;
