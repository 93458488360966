import { Box, Stack } from "@chakra-ui/layout";
import { Button, Heading } from "@chakra-ui/react";
import { getSchools } from "API/schoolApi";
import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
} from "app/features/userFilter/userFilter";
import { useAppSelector } from "app/hook";
import TablePaginator from "components/dumb/Table/TablePaginator";
import TableSearch from "components/dumb/Table/TableSearch";
import useErrorToast from "hook/error-toast";
import { FC, useState } from "react";
import { GoPlus } from "react-icons/go";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import SchoolTable from "./SchoolTable";

interface AdminSchoolUsersProps {}

const AdminSchoolUsers: FC<AdminSchoolUsersProps> = () => {
  const TABLE_HEAD = [
    { id: "name", label: "Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "city", label: "City", alignRight: false },
    { id: "noStudent", label: "No. of Student", alignRight: false },
    { id: "programs", label: "Programs", alignRight: true },
    {
      id: "actions",
      //   flag: { read: true, edit: true, remove: true },
      //   callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: "Actions",
      alignRight: false,
    },
  ];
  const [resetCheckBox, setResetCheckBox] = useState(false);

  const { size, page, searchText } = useAppSelector(
    (state) => state.userFilter
  );

  const { data, isLoading, error } = useQuery<ISchoolFilters>(
    ["users", page, size, searchText],
    () => {
      setResetCheckBox(true);
      return getSchools({
        page,
        size,
        searchText,
      });
    },
    { onSettled: () => setResetCheckBox(false), keepPreviousData: true }
  );

  useErrorToast(error);
  // console.log(data);

  return (
    <>
      <Heading fontSize="2xl">Manage Schools</Heading>
      {data && (
        <>
          <Stack
            w="100%"
            direction={["column", "row"]}
            p="15px"
            justify="space-between"
          >
            <TableSearch
              searchText={searchText}
              setPageDefault={setPageDefault}
              setSearchText={setSearchText}
            />
            <Box flex="0">
              <Button
                rightIcon={<GoPlus />}
                colorScheme="teal"
                variant="solid"
                size="sm"
                as={Link}
                to="/admin/dashboard/users/school/add"
              >
                Add School
              </Button>
            </Box>
          </Stack>
          <SchoolTable
            isLoading={isLoading}
            items={data?.schools}
            resetCheckBox={resetCheckBox}
            tableHeader={TABLE_HEAD}
            disableUpdate={false}
          />
          <TablePaginator
            count={data?.count}
            decrementPage={decrementPage}
            incrementPage={incrementPage}
            page={data?.page}
            setPageDefault={setPageDefault}
            setSize={setSize}
            size={size}
            totalPages={data?.totalPage}
          />
        </>
      )}
    </>
  );
};
export default AdminSchoolUsers;
