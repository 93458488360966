import { Flex, Heading, Stack, Text } from "@chakra-ui/layout";
import { FC } from "react";
import Modal from "components/UI/Modal";
import { useDisclosure } from "@chakra-ui/hooks";
import { GoMail } from "react-icons/go";
import EditEmail from "../components/EditEmail";
import { Button } from "@chakra-ui/react";

const EmailSent: FC<{ email: string; password: string }> = ({
  email,
  password,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal onClose={onClose} open={isOpen}>
        <EditEmail onClose={onClose} oldEmail={email} password={password} />
      </Modal>
      <Flex fontSize="8xl" align="center" justify="center" color="blue.600">
        <GoMail />
      </Flex>
      <Stack align="center" justify="center">
        <Heading fontSize="md" color="blue.400">
          Verification Link Sent on {email}
        </Heading>
        <Text color="blue.700">Kindly Check Your Mail </Text>
        <Button
          onClick={() => {
            // setEmail(null);
            onOpen();
          }}
        >
          Update Email Address
        </Button>
      </Stack>
    </>
  );
};

export default EmailSent;
