import {
  Flex,
  Box,
  Stack,
  Link,
  Text,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";

import { Formik, Form } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { sendEmailVerifyToken, userSignUp, verifyCaptcha } from "API/authApi";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import { useHistory } from "react-router";
import { useState } from "react";
import { signupValidationSchema } from "validation";
import useErrorToast from "hook/error-toast";
import ReCAPTCHA from "react-google-recaptcha";
const Signup = () => {
  // const [isStudent, setIsStudent] = useState(true);
  const [isStudent, setIsStudent] = useState<boolean>(true);
  const history = useHistory();
  const gray200500 = useColorModeValue("gray.200", "gray.500");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [price, setPrice] = useState(planDetails.planPrice);
  const {
    isLoading: tokenLoading,
    error: tokenError,
    mutateAsync: tokenMutateAsync,
  } = useMutation(sendEmailVerifyToken, {
    onSuccess: () => {
      history.push(`/email-sent/${email}`, { password });
    },
  });
  const { isLoading, mutateAsync, error } = useMutation(userSignUp, {
    onSuccess: async () => {
      try {
        if (email) {
          await tokenMutateAsync(email);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  const queryClient = useQueryClient();
  // const [heading, SetHeading] = useState("");

  useErrorToast(error);
  useErrorToast(tokenError);
  // const toggle = () => {
  //   setIsStudent((prev) => !prev);
  // };
  const handleClick = (btn: string) => {
    if (btn === "student") {
      setIsStudent(true);
    } else {
      setIsStudent(false);
    }
  }
  const [isDisabled, setIsDisabled] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const onChange = async (value: any) => {
    setRecaptchaValue(value);
    try {
      const data = await verifyCaptcha({ recaptchaValue });
      data.message === "success" ? setIsDisabled(false) : setIsDisabled(true);

    } catch (error) {

    }
  }
  return (
    <>
      <Flex minH={"100vh"} justify={"center"} alignItems={"center"}>

        <Formik
          validationSchema={signupValidationSchema}
          initialValues={{
            email: "",
            password: "",
            name: "",
            mobile: "",
            confirmPassword: "",
          }}
          onSubmit={async (values) => {
            console.log(values);
            setPassword(values.password);
            setEmail(values.email);
            await mutateAsync({
              email: values.email,
              password: values.password,
              role: isStudent ? "student" : "school",
              name: values.name,
              mobile: parseInt(values.mobile),
            });
            queryClient.invalidateQueries("user");
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Stack
                spacing={0}
                mx={"auto"}
                w={"100%"}
                maxW={"800px"}
                px={[0, 6]}

              >
                <Stack direction={['column', 'row']} spacing={'0'}
                  shadow={[null, "base"]}
                  borderWidth={[null, "1px"]}
                  borderColor={gray200500}
                  borderRadius={"xl"}
                >
                  <Box w={'35%'} display={['none', 'flex']} >
                    <Stack spacing={'4'} bg={'purple.100'} p={'4'}
                      borderLeftRadius={'xl'}
                    >
                      <Text fontSize={'16px'} fontWeight={'bold'} color={'purple.600'}>Individual students and institutions can sign up directly.</Text>
                      <Text fontSize={'16px'} fontWeight={'bold'} color={'purple.600'}>The student who belongs to Institution, Please ask your Institution to create an account for you.</Text>
                    </Stack>
                  </Box>
                  <Box

                    px={[5, 8]}
                    w={['100%', '65%']}
                  >
                    {" "}
                    <HStack mb="0" pt="4">
                      <Text
                        // color={isStudent ? "teal.500" : "gray.500"}
                        color={'white'}
                       // onClick={toggle}
                       onClick={() => handleClick("student")}
                        border="2px"
                        bg={isStudent ? "teal.500" : "gray.500"}
                        transition="ease-in-out 0.2s"
                        px={'4'}
                        py={'2'}
                        w={'50%'}
                        textAlign={'center'}
                        cursor={'pointer'}
                      >
                        Individual student
                      </Text>
                      <Text
                        // color={!isStudent ? "teal.500" : "gray.500"}
                        color={'white'}
                        // onClick={toggle}
                        onClick={() => handleClick("school")}
                        border="2px"
                        bg={
                          !isStudent ? "teal.500" : "gray.500"
                        }
                        transition="ease-in-out 0.2s"
                        px={'4'}
                        py={'2'}
                        w={'50%'}
                        textAlign={'center'}
                        cursor={'pointer'}
                      >
                        Institution
                      </Text>
                    </HStack>
                    <Stack
                      direction={{ base: "column", lg: "row" }}
                      alignItems="center"
                      spacing={{ base: 4, lg: 10 }}
                      pb={[4, 10]}
                      pt={[4, 5]}
                    >

                      <Box w="100%">
                        <Stack bg={'purple.100'} p={'3'} spacing={'3'} mb={'5'} display={['flex', 'none']}>
                          <Text color={'purple.600'} fontSize={'12px'} fontWeight={'bold'}>Individual students and institutions can sign up directly.</Text>
                          <Text color={'purple.600'} fontSize={'12px'} fontWeight={'bold'}>The student who belongs to Institution can ask their Institution to create an account for them.</Text>
                        </Stack>
                        <Stack spacing={3}>
                          <Text fontSize={"20px"} textTransform={"uppercase"}>
                            {isStudent ? "Sign up for Student" : "Sign up for Institution"}
                          </Text>
                          <InputControl
                            name="name"
                            label={isStudent ? "Student Name" : " Institution Name"}
                            inputProps={{}}
                            isRequired={true}
                          />

                          <InputControl
                            isRequired={true}
                            name="email"
                            label="Email"
                            inputProps={{}}
                          />
                          <InputControl
                            isRequired={true}
                            name="mobile"
                            label="Contact no"
                            inputProps={{ type: "tel", pattern: "[0-9]{10}" }}
                          />
                          <InputControl
                            isRequired={true}
                            name="password"
                            label="Password"
                            inputProps={{ type: "password" }}
                          />
                          <InputControl
                            isRequired={true}
                            name="confirmPassword"
                            label="Confirm Password"
                            inputProps={{ type: "password" }}
                          />

                          {/* {planDetails.planType !== 2 && (
                          <>
                            <FormControl id="licenses" isRequired>
                              <FormLabel>No. of licenses</FormLabel>
                              <NumberInput
                                defaultValue={1}
                                min={1}
                                clampValueOnBlur={true}
                                keepWithinRange={true}
                                isRequired
                                onChange={(e) => {
                                  setPrice(planDetails.planPrice * parseInt(e));
                                  setFieldValue("licenses", parseInt(e));
                                }}
                              >
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </FormControl>
                            <ErrorMessage name="licenses" />{" "}
                          </>
                        )} */}
                          {/* <FormControl id="discountCode">
                        <FormLabel>Discount code</FormLabel>
                        <InputGroup>
                          <Input type="text" />
                          <InputRightElement width="5rem">
                            <Button size="sm">Apply</Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl> */}
                          <Box>
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY??""}
                              onChange={onChange}
                            />
                          </Box>
                          <Stack spacing={10}>
                             <SubmitButton
                             isDisabled={isDisabled}
                              isLoading={isLoading || tokenLoading}
                              w="full"
                              colorScheme="red"
                              type="submit"
                              mt="20px"
                            >
                              Sign up
                            </SubmitButton>
                            
                          </Stack>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
                <Box textAlign="center" mt="15px !important">
                  <Text fontSize="12px" color="gray.600">
                    By submitting this form, I confirm that I have read and
                    understood the K. P. Training Academy{" "}
                    <Link
                      color="blue.400"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy.
                    </Link>
                  </Text>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      </Flex>
    </>
  );
};

export default Signup;
