import { useAppSelector } from "app/hook";
import React, { FC, useEffect, useState } from "react";

import Chapter from "./Chapter/Chapter";
import TestGenerator from "./Chapter/TestGenerator/TestGenerator";
import Tests from "./Chapter/Tests/Tests";

interface ChaptersProps {}

const Chapters: FC<ChaptersProps> = () => {
  const { program } = useAppSelector((state) => state.programState);

  const [chapters, setChapters] = useState<string[][]>([]);

  useEffect(() => {
    setChapters([
      ...new Array(program?.chapters?.length).fill("").map((_, index) => []),
    ]);
  }, [program]);

  const topicsId = chapters.flatMap((chapter) => chapter);
  const resetTopicsId = () => {
    setChapters([]);
  };
  const handleTopicClick = (topicId: string, index: number) => {
    if (chapters) {
      if (chapters[index].includes(topicId)) {
        setChapters(
          chapters.map((chapter, i) => {
            if (i === index) {
              return chapter.filter((topic) => topic !== topicId);
            }
            return chapter;
          })
        );
      } else {
        setChapters(
          chapters.map((chapter, i) => {
            if (i === index) {
              return [...chapter, topicId];
            }
            return chapter;
          })
        );
      }
    }
  };

  return (
    <>
      {program?.chapters?.map((chapter: IChapter, index) => (
        <React.Fragment key={chapter._id}>
          <Chapter
            index={index}
            topicIds={chapters[index] as any}
            handleTopicClick={handleTopicClick}
            chapter={chapter}
          />
        </React.Fragment>
      ))}
      {program?.chapters && (
        <>
          {" "}
          <TestGenerator resetTopicsId={resetTopicsId} topicIds={topicsId} />
          {program?.tests && <Tests tests={program?.tests} />}
        </>
      )}
    </>
  );
};
export default Chapters;
