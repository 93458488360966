import { MenuItem, useToast } from "@chakra-ui/react";
import { deleteStudent } from "API/studentApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";

interface DeleteStudentProps {
  userId: string;
}

const DeleteStudent: FC<DeleteStudentProps> = ({ userId }) => {
  const toast = useToast();
  const cache = useQueryClient();
  const { isLoading, mutateAsync, error } = useMutation(deleteStudent, {
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Student deleted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      cache.invalidateQueries("students");
    },
  });
  useErrorToast(error);
  return (
    <MenuItem
      onClick={async () => {
        try {
          await mutateAsync({
            userId: userId,
          });
        } catch (error) {}
      }}
    >
      {isLoading ? "Deleteing" : "Delete"}
    </MenuItem>
  );
};
export default DeleteStudent;
