import {
  Heading,
  Select,
  HStack,
  Box,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { FC, useState, useEffect } from "react";
import { getStudentById } from "API/studentApi";
import { useQuery } from "react-query";

import Subjects from "./Subjects";
import { useAppSelector } from "app/hook";

interface ReportsStudentsProps {}

const ReportsStudents: FC<ReportsStudentsProps> = () => {
  const [program, setProgram] = useState<string>("");
  const { uid } = useAppSelector((state) => state.auth);
  

  // console.log(student);
  const { data: student } = useQuery<IStudent>(["student", uid], () =>
    getStudentById(uid)
  );

  useEffect(() => {
    if (student) {
      if (student.activePrograms.length > 0) {
        setProgram(student.activePrograms[0].program._id);
      }
    }
  }, [student]);
  return (
    <>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">Reports</Heading>
      </HStack>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
        pos="relative"
      >
        <Box p={"20px"}>
          <Select
            // placeholder="all programs"

            rounded="md"
            value={program}
            display={{ base: "none", md: "block", lg: "block" }}
            textTransform="capitalize"
            onChange={(e) => {
              setProgram(e.target.value);
            }}
          >
            {student?.activePrograms?.map((activeProgram) => (
              <option
                key={activeProgram.program._id}
                value={activeProgram.program._id}
              >
                {activeProgram.program.name}
              </option>
            ))}
          </Select>
        </Box>

        {/* <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Tests</Th>
              <Th textAlign={'center'}>Attempt</Th>
              <Th>Level</Th>
              <Th isNumeric>Avg.Time</Th>
              <Th isNumeric>Avg.Marks</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Subjects program={program} student={student} />
          </Tbody>
        </Table> */}
        <Stack
          py={"10px"}
          bg={"gray.100"}
          direction={"row"}
          spacing={"0px"}
          textTransform={"uppercase"}
          fontSize={"12px"}
          fontWeight={"bold"}
        >
          <Box w={"60%"} p={"10px"}>
            Tests
          </Box>
          <Box w={"10%"} p={"10px"}>
            Attempt
          </Box>
          <Box w={"10%"} p={"10px"}>
            Level
          </Box>
          <Box w={"10%"} p={"10px"}>
            Avg.Time
          </Box>
          <Box w={"10%"} p={"10px"}>
            Avg.Marks
          </Box>
        </Stack>
        <Subjects program={program} />
      </Box>
    </>
  );
};
export default ReportsStudents;
