import { Badge, Box, Button, Flex, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { getPurchasedProducts } from "API/purchasedProdcutsApi";
import LoadingSpinner from "components/dumb/LoadingSpinner";
import { FC } from "react";
import { useQuery } from "react-query";
import { Link as RoLink } from "react-router-dom";

interface SchoolPurchasedProductProps { }

const SchoolPurchasedProduct: FC<SchoolPurchasedProductProps> = () => {
  const { data: purchasedProducts, isLoading } = useQuery<IPurchasedProduct[]>(
    "purchasedProducts",
    getPurchasedProducts
  );

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <Heading size={'md'}>Dashboard</Heading>
      <Box
        my="20px"
        border="1px"
        borderColor={'gray.100'}
        borderRadius="md"
        pos="relative"
      >
        {Number(purchasedProducts?.length) === 0 && (
          <Box size="sm" textAlign={"center"} py={'100px'}>
            <Text fontSize={'20px'} m="auto" py={'20px'} w={'50%'}>You have no active programs.
            </Text>
            <Button colorScheme={'teal'} as={RoLink} to="/buy-products">Program registration</Button>
          </Box>
        )}
        {purchasedProducts?.map((product) => (
          <Stack
            p="4"
            key={product._id}
            justify="space-between"
            borderBottom="1px"
            borderColor="gray.200"
            spacing={'0px'}
            direction={["column", "row"]}
            alignItems={'center'}

          >
            <Box w={'75%'}>
              <Heading fontSize="md">{product?.product?.name}
                {product?.product?.access.map((access) => (
                  <Badge variant={"solid"} colorScheme={"teal"} ml={"1"} key={access}>
                    {access}
                  </Badge>
                ))}</Heading>
              <Box>
                <Flex mt={'2'}>
                  <Text
                    color={"teal"}
                  >
                    {product?.product?.program?.name}
                  </Text>
                  <Box d={["none", "flex"]} px="2" color={"red.500"}>
                    •
                  </Box>
                  <Text color={"teal"}>Duration: {product?.product?.duration} months</Text>
                 
                </Flex>

                <Stack spacing="0px">

                  <Box>
                    <Text as="span">Subjects: </Text>{" "}
                    {product?.product?.program?.subjects?.map((subject) => (
                      <Text
                        key={subject._id}
                        as="span"
                        textTransform="capitalize"
                        pr="1"
                      >
                        {subject?.name},
                      </Text>
                    ))}
                  </Box>
                </Stack>
              </Box>
            </Box>

            <HStack width={"25%"} spacing={0}>
              <Box w={'45%'} textAlign={'center'}>
                <Text fontSize={'20px'} fontWeight={'bold'}>{product.quantity}</Text>
                <Text fontSize={'12px'}>Licences</Text>
              </Box>
              <Box w={'55%'} pl={'15px'}>
                {product.quantity > 0
                  ? <Button w={'100%'} colorScheme={'teal'} as={RoLink}
                    to={`/school/dashboard/assign-product/${product._id}`}>Assign students</Button>
                  : <Button w={'100%'} colorScheme={'purple'} as={RoLink}
                    to={'/buy-products'}>Get program</Button>
                }
              </Box>

            </HStack>
          </Stack>
        ))}
      </Box>
    </>
  );
};
export default SchoolPurchasedProduct;
