import {
  Spinner,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Link,
  Badge,
  useColorModeValue,
  Grid,
  Heading,
  Box,
  Stack,
  Text,
} from "@chakra-ui/react";
import { getActiveProgram } from "API/activeProgramApi";
import useErrorToast from "hook/error-toast";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { FaSearch } from "react-icons/fa";
import { Link as RoLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hook";
import { setProgram } from "app/features/program/programSlice";

interface StudentProgramTestsProps {}

const StudentProgramTests: FC<StudentProgramTestsProps> = () => {
  const [tests, setTests] = useState<ITestShowCase[]>([]);
  const { subjectId } = useAppSelector((state) => state.programState);
  const dispatch = useAppDispatch();
  const [searchTest, setSearchTest] = useState("");
  const bgClr = useColorModeValue("red.50", "gray.700");

  const { id } = useParams<{ id: string }>();

  const {
    data: activeProgram,
    isLoading,
    error,
  } = useQuery<IActiveProgram>(["activeProgram", id, subjectId], () =>
    getActiveProgram(id, subjectId)
  );

  useEffect(() => {
    //console.log(activeProgram);
    if (activeProgram && subjectId) {
      const testIds = activeProgram?.tests.map((t) => t?.test?._id);
      const testNotTaken = activeProgram?.program?.tests.filter((test) => {
        return subjectId === test.subject.toString() &&
          testIds?.includes(test._id)
          ? false
          : true;
      });
      const testTaken = activeProgram?.tests
        .filter((t) =>
          t?.test?.subject.toString() === subjectId ? true : false
        )
        .map((t) => {
          return {
            ...t?.test,
            attempts: t.testAttempts.length,
            userTestId: t._id,
          };
        });
      setTests([...testNotTaken, ...testTaken]);
    }
  }, [activeProgram, subjectId]);

  useErrorToast(error);
  const borderClr = useColorModeValue("gray.100", "gray.700");

  return (
    <>
      {isLoading ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <>
          <Heading size="md">
            <Link as={RoLink} to="/student/dashboard/" color="teal.500">
              Dashboard
            </Link>{" "}
            / {activeProgram?.program?.name} tests
          </Heading>
          <Box
            my="20px"
            border="1px"
            width="100%"
            borderColor={borderClr}
            borderRadius="md"
          >
            <Box w="100%" backgroundColor={bgClr} padding="4">
              <Select
                placeholder="Select Subject"
                rounded="md"
                textTransform="capitalize"
                backgroundColor="white"
                value={subjectId}
                onChange={(e: any) => {
                  dispatch(
                    setProgram({
                      program: activeProgram?.program,
                      subjectId: e.target.value,
                    })
                  );
                }}
              >
                {activeProgram?.program.subjects?.map((e: ISubject, index) => {
                  if (!subjectId && index === 0) {
                    dispatch(
                      setProgram({
                        program: activeProgram?.program,
                        subjectId: e._id,
                      })
                    );
                  }
                  return (
                    <option key={e?.name} value={e._id}>
                      {e.name}
                    </option>
                  );
                })}
              </Select>
            </Box>
            {subjectId && (
              <Box w="100%">
                <Box p="4">
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaSearch color="gray.300" />}
                    />
                    <Input
                      type="tel"
                      onChange={(e) => {
                        setSearchTest(e.target.value.trim());
                      }}
                      placeholder="Search by test name"
                    />
                  </InputGroup>
                </Box>

                <Box borderTop="1px" borderColor="gray.100">
                  {tests &&
                    tests
                      .filter((test) =>
                        test.name
                          ?.toLowerCase()
                          .includes(searchTest.toLowerCase())
                      )
                      .map((test) => (
                        <Stack
                          direction={["column", "row"]}
                          key={test._id}
                          spacing={["10px", "0px"]}
                          borderBottom="1px"
                          borderColor="gray.100"
                          p="4"
                        >
                          <Box w={["100%", "80%"]}>
                            <Heading as="h5" size="sm" mb={["2", "1"]}>
                              {test.name}
                              <Badge
                                ml="1"
                                colorScheme={
                                  test.level === "easy"
                                    ? "blue"
                                    : test.level === "normal"
                                    ? "purple"
                                    : test.level === "difficult"
                                    ? "orange"
                                    : ""
                                }
                              >
                                {test.level}
                              </Badge>
                            </Heading>
                            <Stack
                              direction={["column", "row"]}
                              spacing={["10px", "0px"]}
                            >
                              <Text>
                                Total Questions: {test.totalQuestions}
                              </Text>
                              <Text color={"red"} px="2" d={["none", "block"]}>
                                •
                              </Text>
                              <Text>No. of attempt: {test.attempts}</Text>
                            </Stack>
                          </Box>

                          <Stack
                            direction={["column", "row"]}
                            w={["100%", "20%"]}
                            spacing={["10px", "0px"]}
                            justifyContent={["flex-start", "flex-end"]}
                            alignItems={["start", "center"]}
                          >
                            <Button
                              colorScheme="teal"
                              variant="link"
                              as={RoLink}
                              to={
                                test.attempts
                                  ? `/student/dashboard/test/${test.userTestId}/true`
                                  : `/student/dashboard/test/${test._id}/false`
                              }
                              w={["auto", "50%"]}
                              textAlign={["left", "center"]}
                              justifyContent={["flex-start", "center"]}
                            >
                              Test Details
                            </Button>
                            <Button
                              colorScheme="teal"
                              as={RoLink}
                              w={["100%", "40%"]}
                              to={
                                test.attempts
                                  ? `/student/dashboard/start-test/${test._id}/true/${id}/${test.userTestId}`
                                  : `/student/dashboard/start-test/${test._id}/false/${id}/random`
                              }
                            >
                              {test.attempts ? "Retake" : "Start"}
                            </Button>
                          </Stack>
                        </Stack>
                      ))}
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default StudentProgramTests;
