import { FC } from "react";
import { useQuery } from "react-query";
import { getPaymentById } from "API/paymentApi";
import { Badge, Divider, DrawerBody, Flex, Stack, Text } from "@chakra-ui/react";
import { format } from "date-fns";
interface ViewPaymentProps {
  paymentId: string;
  // peyment:IAllPayment;
  onClose: () => void;
}
const ViewPayment: FC<ViewPaymentProps> = ({ paymentId, onClose }) => {
  // const [paymentId, setPaymentId] = useState("");
  const { data: payment } = useQuery<IAllPayment>(
    ["payment", paymentId],
    (key) => getPaymentById({ paymentId }),
    {
      enabled: !!paymentId,
    }
  );
  return (
    <>
      <DrawerBody>
        <Stack spacing={"20px"}>
          <Text>Payment id: {payment?.paymentId}</Text>
          <Text>Order id: {payment?.orderId}</Text>
          <Text>Payment status:
            <Badge
              variant="solid"
              colorScheme={
                payment?.status === "captured"
                  ? "green"
                  : payment?.status === "pending"
                    ? "orange"
                    : ""
              }
            >
              {payment?.status}
            </Badge>
          </Text>
          <Text>Payment date: 
          {payment?.paymentDate &&
                      format(
                        new Date(payment?.paymentDate ?? ""),
                        "dd-MM-yyyy, HH:mm:ss"
                      )}
            </Text>
          <Text>Buyer name: {payment?.user?.name}</Text>
          <Text>Buyer email: {payment?.user?.email}</Text>
          <Text>Buyer contact: {payment?.user?.mobile}</Text>
        </Stack>
        {payment?.products?.map((product) => (
          <Flex
            key={product._id}
            alignItems={"center"}
            justifyContent={"space-between"}
            py={"10px"}
            borderTop={"1px"}
            borderColor={"gray.100"}
            mt={'4'}
          >
            <Text>{product.productId.name}</Text>
            <Text>{product.quantity}</Text>
            <Text>Rs.{product.productId.price}</Text>
          </Flex>
        ))}
        <Divider />
        <Stack spacing={"20px"} mt="4" textAlign={'right'} fontWeight={'bold'}>
          <Text>Subtotal: Rs.{payment?.subTotal}</Text>
          <Text>Payment getway fee:  Rs.{payment?.tnxFee}</Text>
          <Text>Total:  Rs.{payment?.total}</Text>
        </Stack>
      </DrawerBody>
    </>
  );
};

export default ViewPayment;
