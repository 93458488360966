import Staffs from "pages/dashboard/shared/Users/Staffs/Staffs";

const AdminSchoolStaffs = () => {
  return (
    <Staffs
      editStaffRoute={`/admin/dashboard/users/staff/edit`}
      addStaffRoute={`/admin/dashboard/users/staff/add`}
    />
  );
};

export default AdminSchoolStaffs;
