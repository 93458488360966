import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getProgramById, updateProgramDetails } from "API/programApi";
import useErrorToast from "hook/error-toast";
import { Spinner } from "@chakra-ui/spinner";
import { HStack, Heading, Link } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useAppDispatch } from "app/hook";
import { setProgram } from "app/features/program/programSlice";
import { Link as RoLink } from "react-router-dom";
import ReactQuill from "react-quill";
import { useMutation } from "react-query";
import "react-quill/dist/quill.snow.css";
import { useToast } from "@chakra-ui/toast";
import { useAppSelector } from "app/hook";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};
interface AdminProgramDetailsProps {}

const AdminProgramDetails: FC<AdminProgramDetailsProps> = () => {
  const [subjectId] = useState("");
  const toast = useToast();

  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const {
    data: program,
    isLoading,
    error,
  } = useQuery<IProgram>(
    ["program", id, subjectId],
    () => getProgramById(id, subjectId),
    { onSuccess: (data) => dispatch(setProgram({ subjectId, program: data })) }
  );

  const { mutateAsync } = useMutation(updateProgramDetails, {
    onSuccess: () => {
      dispatch(setProgram({ subjectId, program }));
      toast({
        title: "Program details updated successfully",
        status: "success",
      });
    },
  });

  useErrorToast(error);

  const [content, setContent] = useState("");
  const onChange = (content: any, delta: any, source: any, editor: any) => {
    setContent(editor.getHTML());
  };

  useEffect(() => {
    if (program) {
      setContent(program.details);
    }
  }, [program]);
  const { role } = useAppSelector((state) => state.auth);

  return (
    <>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">
          <Link as={RoLink} to={`/${role}/dashboard/programs`} color="teal.500">
            Programs
          </Link>{" "}
          / {program?.name}
        </Heading>
      </HStack>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReactQuill
            style={{ marginTop: "25px" }}
            value={content}
            modules={modules}
            theme="snow"
            onChange={onChange}
            placeholder="Content goes here..."
          />
          {program && (
            <Button
              colorScheme="teal"
              mt="25px"
              onClick={async () => {
                await mutateAsync({
                  id: program._id,
                  programDetails: content,
                });
              }}
            >
              Submit
            </Button>
          )}
        </>
      )}
    </>
  );
};
export default AdminProgramDetails;
