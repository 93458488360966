import { useMutation, useQuery, useQueryClient } from "react-query";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC, useState } from "react";
import { getSubjects } from "API/subjectApi";
import { Badge, Box, Flex, FormControl, Select, FormLabel } from "@chakra-ui/react";
import { GoTrashcan } from "react-icons/go";
import { addProgram } from "API/programApi";
import { useToast } from "@chakra-ui/toast";
interface AddProgramProps {
  onClose: () => void;
}

const AddProgram: FC<AddProgramProps> = ({ onClose }) => {
  const cache = useQueryClient();
  const toast = useToast();
  const [subjectIds, setSubjectIds] = useState<string[]>([]);
  const { data: subjectData, error } = useQuery("subjects", getSubjects);
  useErrorToast(error);

  const {
    error: addProgramError,
    isLoading: addProgramLoading,
    mutateAsync,
  } = useMutation(addProgram, {
    onSuccess: () => {
      onClose();
      cache.invalidateQueries("programs");
      toast({ title: "Program added successfully", status: "success" });
    },
  });

  const filteredSubjects = subjectData?.filter(
    (subject: ISubject) => !subjectIds.includes(subject._id)
  );

  const removeSubject = (id: string) => {
    setSubjectIds(subjectIds.filter((subjectId) => subjectId !== id));
  };

  useErrorToast(addProgramError);

  return (
    <Formik
      initialValues={
        { name: "", subjects: [] } as { name: string; subjects: string[] }
      }
      onSubmit={async (values) => {
        values.subjects = subjectIds;
        try {
          await mutateAsync(values);
        } catch (error) {
          console.log(error);
        }
      }}
    >
      <Form>
        <InputControl name="name" label="Program Name:" />
        {/* <Text fontWeight="semibold" mt="4">
          Choose Subject for Program
        </Text> */}
        {/* <SelectControl
        name="select"
        selectProps={{ placeholder: "Select Subject" }}
        onChange={(e) => {
          setSubjectIds((prev) => [...prev, e.target.value]);
        }}
        >
          {subjectData &&
            filteredSubjects?.map((e: ISubject) => (
              <option key={e?.name} value={e._id}>
                {e.name}
              </option>
            ))}
          </SelectControl> */}
          <FormControl mt={4}>
  <FormLabel>Choose Subject for Program</FormLabel>
  <Select
          placeholder="Select Subject"
          textTransform="capitalize"
          onChange={(e) => {
            setSubjectIds((prev) => [...prev, e.target.value]);
          }}
        >
          {subjectData &&
            filteredSubjects?.map((e: ISubject) => (
              <option key={e?.name} value={e._id}>
                {e.name}
              </option>
            ))}
        </Select>
</FormControl>
        
        <Flex py={4} flexWrap="wrap">
          {subjectData
            ?.filter((subject: ISubject) => subjectIds.includes(subject._id))
            .map((subject: ISubject) => (
              <Badge
                key={subject._id}
                display="flex"
                alignItems="center"
                mr="2"
                mb="2"
                colorScheme="teal"
              >
                {subject.name}{" "}
                <Box
                  ml="4"
                  as={GoTrashcan}
                  onClick={() => removeSubject(subject._id)}
                />
              </Badge>
            ))}
        </Flex>
        <SubmitButton mb="6" mt="2" isLoading={addProgramLoading}>
          Add Program
        </SubmitButton>
      </Form>
    </Formik>
  );
};
export default AddProgram;
