import { useAppSelector } from "app/hook";
import { FC } from "react";
import Topic from "./Topic/Topic";
import { Stack } from "@chakra-ui/react";

interface TopicsProps {
  topics: ITopic[];
  topicIds: string[];
  handleTopicClick: (topicId: string) => void;
}

const Topics: FC<TopicsProps> = ({ topics, handleTopicClick, topicIds }) => {
  const { program, subjectId } = useAppSelector((state) => state.programState);

  const subjectName = program?.subjects?.find(
    (e: ISubject) => e._id === subjectId
  )?.name;

  return (
    <>
      <Stack align="flex-end" my="8">
        {topics.map((topic: ITopic) => (
          <Topic
            isSelected={topicIds?.includes(topic._id)}
            handleTopicClick={handleTopicClick}
            subjectName={subjectName}
            topic={topic}
            key={topic?._id}
          />
        ))}
      </Stack>
    </>
  );
};
export default Topics;
