import { useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import { useQuery } from "react-query";
import { useAuth } from "hook/auth-hooks";
import { fetchUser } from "API/authApi";
import ProtectedRoute from "components/ProtectedRoute";

import { useAppDispatch, useAppSelector } from "app/hook";
import { setUser } from "app/features/auth/authSlice";
import { schoolRoutes } from "routes/schoolRoutes";
import { adminRoutes } from "routes/adminRoutes";
import { unProtectedRoutes } from "routes/unProtectRoutes";
import { studentRoutes } from "routes/studentRoutes";
import { staffRoutes } from "routes/staffRoutes";
import { sharedRoutes } from "routes/sharedRoutes";
import DashboardNavigation from "components/Navigation/DashboardNavigation";
import { Container } from "@chakra-ui/react";
import RegularNavigation from "components/Navigation/RegularNavigation";
function App() {
  // const [cookies, setCookie, removeCookie] = useCookies(["jwt"]);
  // const jwt = cookies.jwt;
  const { role, uid } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  useQuery(
    `user`,
    () => {
      if (role && uid) {
        return fetchUser({ role: role, uid: uid });
      }
      return null;
    },
    {
      enabled: !!uid,
      onSuccess: (data) => {
        dispatch(setUser(data));
      },

      onError: (error) => {
        
        if (location.pathname.toLowerCase() !== "/login") {
          history.replace("/");
        }
      },
    }
  );

  const { setCookieLogin } = useAuth();

  useEffect(() => {
    setCookieLogin();
  }, [setCookieLogin]);

  const routes = [
    ...studentRoutes,
    ...adminRoutes,
    ...schoolRoutes,
    ...staffRoutes,
    ...sharedRoutes,
    ...unProtectedRoutes,
  ];

  return (
    <>
      {/* <Container py={[5, 12]} maxW="container.xl"> */}
      <Switch>
        {routes.map((route) => {
          if (route.type.includes("notProtected")) {
            return (
              <ProtectedRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                type={route.type}
              >
                <RegularNavigation />
                <route.component />
              </ProtectedRoute>
            );
          } else {
            return (
              <ProtectedRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                type={route.type}
              >
                <DashboardNavigation />
                <Container py={[5, 12]} maxW="container.xl">
                  <route.component />
                </Container>
              </ProtectedRoute>
            );
          }
        })}
        <Route path="*" component={() => <h1>Page Not Found</h1>} />
      </Switch>
      {/* </Container> */}
    </>
  );
}

export default App;
