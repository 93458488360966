import { Stack } from "@chakra-ui/layout";
import { updateTopic } from "API/topicApi";
import { useAppSelector } from "app/hook";
import { Formik, Form } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";

interface UpdateTopicProps {
  topicName: string;
  topicVideoLink: string;
  topicId: string;
  onClose: () => void;
}

const UpdateTopic: FC<UpdateTopicProps> = ({
  topicName,
  topicId,
  topicVideoLink,
  onClose,
}) => {
  const cache = useQueryClient();

  const { program, subjectId } = useAppSelector((state) => state.programState);
  const { isLoading, mutateAsync, error } = useMutation(updateTopic, {
    onSuccess: () => {
      cache.invalidateQueries(["program", program?._id, subjectId]);
      onClose();
    },
  });
  useErrorToast(error);
  return (
    <Formik
      initialValues={{ topicName: topicName, topicVideoLink: topicVideoLink }}
      onSubmit={(values) => {
        try {
          mutateAsync({
            id: topicId,
            topicName: values.topicName,
            topicVideoLink: values.topicVideoLink,
          });
        } catch (error) {}
      }}
    >
      <Form>
        <Stack minHeight="200px" justify="space-around">
          <InputControl name="topicName" label="Topic Name" />
          <InputControl name="topicVideoLink" label="Topic VideoLink" />
          <SubmitButton isLoading={isLoading}>Update Topic</SubmitButton>
        </Stack>
      </Form>
    </Formik>
  );
};
export default UpdateTopic;
