import { Box, HStack, Flex, Stack } from "@chakra-ui/layout";
import {
  Table as CTable,
  Tr,
  Tbody,
  Spinner,
  Td,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  Avatar,
} from "@chakra-ui/react";
import { FC } from "react";
import { GoKebabVertical } from "react-icons/go";
// import { useState } from "react";
import { Link as RoLink } from "react-router-dom";
import TableHeader from "components/dumb/Table/TableHeader";
import DeleteStaff from "./DeleteStaff";

interface StaffTableProps {
  isLoading: boolean;
  items: IStaff[];
  disableUpdate: boolean;
  tableHeader: {
    id: string;
    label: string;
    alignRight: boolean;
  }[];
  editStaffRoute: string;
}

const StaffTable: FC<StaffTableProps> = ({
  isLoading,
  items,
  disableUpdate,
  tableHeader,
  editStaffRoute,
}) => {
  const itemsCount = items?.length;

  return (
    <Box overflow="hidden">
      <Box overflowX="auto">
        <CTable variant="striped" maxH="100vh">
          <TableHeader tableHeaders={tableHeader} />
          <Tbody>
            {isLoading ? (
              <Flex
                as="tr"
                width="100%"
                height="100%"
                justify="center"
                align="center"
              >
                <Spinner as="td" />
              </Flex>
            ) : itemsCount === 0 ? (
              <Flex
                as="tr"
                width="100%"
                height="100%"
                justify="center"
                align="center"
              >
                <Text as="td">No Teacher Found!</Text>
              </Flex>
            ) : (
              <>
                {items?.map((item, i) => (
                  <Tr key={item._id}>
                    <Td>
                      <HStack>
                        <Avatar />
                        <Stack>
                          <Text fontSize="sm">{item.name}</Text>
                          <Text fontSize="xs">{item.email}</Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>{item?.school.name}</Td>
                    {!disableUpdate && (
                      <Td>
                        <Menu closeOnBlur={true}>
                          <MenuButton
                            as={IconButton}
                            icon={<GoKebabVertical />}
                            variant="ghost"
                          ></MenuButton>
                          <MenuList>
                            <MenuItem
                              as={RoLink}
                              to={`${editStaffRoute}/${item._id}`}
                            >
                              Edit
                            </MenuItem>
                            <DeleteStaff userId={item._id} />
                          </MenuList>
                        </Menu>
                      </Td>
                    )}
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </CTable>
      </Box>
    </Box>
  );
};
export default StaffTable;
