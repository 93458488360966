import {
  AdminDashboard,
  AdminImportQuestions,
  AdminQuestions,
  AdminBatches,
  AdminPerformances,
  AdminSubjects,
  AdminUpdateQuestion,
  AdminPrograms,
  AdminProgramUpdate,
  AdminProgramDetails,
  AdminTestSection,
  AddSchool,
  AdminSchoolUsers,
  AdminTestProgram,
  UpdateSchool,
} from "pages/dashboard/AdminDashboard";
import AdminProducts from "pages/dashboard/AdminDashboard/AdminProducts/AdminProducts";
import AdminAddStaffs from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolStaffs/AdminAddStaffs";
import AdminSchoolStaffs from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolStaffs/AdminSchoolStaffs";
import AdminUpdateStaffs from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolStaffs/AdminUpdateStaffs";
import AdminAddStudent from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolStudents/AdminAddStudent";
import AdminAssignProgramStudent from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolStudents/AdminAssignProgramStudent";
import AdminSchoolStudents from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolStudents/AdminSchoolStudents";
import AdminUpdateStudent from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolStudents/AdminUpdateStudent";
import ViewSchool from "pages/dashboard/AdminDashboard/AdminUsers/AdminSchoolUsers/ViewSchool";
import TestResultDetails from "pages/dashboard/StudentDashboard/TestResultPreview/TestResultPreview";
import Reports from "pages/dashboard/SchoolDashboard/SchoolUsers/SchoolStudents/Reports";
import TestResult from "pages/dashboard/StudentDashboard/TestResult/TestResult";
import StudentTest from "pages/dashboard/StudentDashboard/StudentTest/StudentTest";
import AddBatch from "pages/dashboard/AdminDashboard/AdminBatchSection/AddBatch";
import UpdateBatch from "pages/dashboard/AdminDashboard/AdminBatchSection/UpdateBatch";
import AssignBatch from "pages/dashboard/shared/Batches/AssignBatch";
import AddPerformance from "pages/dashboard/AdminDashboard/AdminPerformanceSection/AddPerformance";
import UpdatePerformance from "pages/dashboard/AdminDashboard/AdminPerformanceSection/UpdatePerformance";
import UpdateProgram from "pages/dashboard/shared/Batches/UpdateProgram";
export const adminRoutes = [
  {
    type: ["admin"],
    path: "/admin/dashboard/performances/add",
    component: AddPerformance,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/batches/add",
    component: AddBatch,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/batches/edit/:id/assign-batch",
    component: AssignBatch,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/batches/edit/:id/update-program",
    component: UpdateProgram,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/batches/edit/:id",
    component: UpdateBatch,
    protect: true,
    exact: false,
  }
  ,
  {
    type: ["admin"],
    path: "/admin/dashboard/performances/edit/:id/structure",
    component: UpdateProgram,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/performances/edit/:id",
    component: UpdatePerformance,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: `/admin/dashboard/users/:schoolId/student/edit/:id/reports`,
    component: Reports,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/test/:testId/:retest",
    component: StudentTest,
    exact: false,
    protect: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/test-result/:testId",
    component: TestResult,
    exact: true,
    protect: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard",
    component: AdminDashboard,
    exact: true,
    protect: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/questions",
    component: AdminQuestions,
    protect: true,
    exact: false,
  },

  {
    type: ["admin"],
    path: "/admin/dashboard/batches",
    component: AdminBatches,
    protect: true,
    exact: false,
  },

  {
    type: ["admin"],
    path: "/admin/dashboard/performances",
    component: AdminPerformances,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/update-question/:type/:id",
    component: AdminUpdateQuestion,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/import-questions",
    component: AdminImportQuestions,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/subjects",
    component: AdminSubjects,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/programs",
    component: AdminPrograms,
    exact: true,
    protect: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/programs/:id/programdetails",
    component: AdminProgramDetails,
    exact: false,
    protect: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/programs/:id",
    component: AdminProgramUpdate,
    exact: false,
    protect: true,
  },

  {
    type: ["admin"],
    path: "/admin/dashboard/test-manager",
    component: AdminTestSection,
    protect: true,
    exact: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/test-manager/:id",
    component: AdminTestProgram,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/school",
    component: AdminSchoolUsers,
    protect: true,
    exact: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/school/add",
    component: AddSchool,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/school/edit/:id",
    component: UpdateSchool,
    protect: true,
    exact: false,
  },

  {
    type: ["admin"],
    path: "/admin/dashboard/users/school/view/:id",
    component: ViewSchool,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/staffs",
    component: AdminSchoolStaffs,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/staff/add",
    component: AdminAddStaffs,
    protect: true,
    exact: false,
  },

  {
    type: ["admin"],
    path: "/admin/dashboard/test-review/:testId",
    component: TestResultDetails,
    exact: true,
    protect: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/staff/edit/:id",
    component: AdminUpdateStaffs,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/:schoolId/students",
    component: AdminSchoolStudents,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/:schoolId/student/add",
    component: AdminAddStudent,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/:schoolId/student/edit/:id",
    component: AdminUpdateStudent,
    protect: true,
    exact: true,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/users/:schoolId/student/edit/:id/aasign-program",
    component: AdminAssignProgramStudent,
    protect: true,
    exact: false,
  },
  {
    type: ["admin"],
    path: "/admin/dashboard/products",
    component: AdminProducts,
    protect: true,
    exact: true,
  },
];
