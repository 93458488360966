import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useAppDispatch } from "app/hook";
import { setAllAuth, defaultState } from "app/features/auth/authSlice";
export const useAuth = () => {
  const [cookies, , removeCookie] = useCookies(["jwt"]);
  const dispatch = useAppDispatch();
  // const { signOut } = useGoogleLogout({
  //   clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  // });

  const login = useCallback(
    (uid: string | null, token: string | null, role: string | null) => {
      dispatch(setAllAuth({ uid, token, role, user: null }));
    },
    [dispatch]
  );

  const logout = () => {
    removeCookie("jwt", { path: "/" });
    dispatch(defaultState());
    window.location.reload();
    // history.push("/login");
  };

  const setCookieLogin = useCallback(async () => {
    const { jwt } = cookies;
    // console.log(jwt);

    if (jwt?.token) {
      if (jwt.token && jwt.uid && jwt.role) {
        login(jwt.uid, jwt.token, jwt.role);
      }
    } else {
      login(null, null, null);
    }
  }, [cookies, login]);

  return { login, logout, setCookieLogin };
};
