import { Box, Stack, Divider, Button, Text, useToast } from "@chakra-ui/react";
import { getPendingPayment, cancelPayment } from "API/paymentApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

interface PendingPaymentsProps {}

const PendingPayments: FC<PendingPaymentsProps> = () => {
  const pendingPayments = useQuery<IPayment[]>(
    "pendingPayments",
    getPendingPayment
  );

  useErrorToast(pendingPayments.error);
  return (
    <>
      {pendingPayments?.data && pendingPayments?.data?.length > 0 && (
        <Box bg={"orange.100"} p={"15px"} mb={"30px"}>
          <Text fontWeight={"bold"} color={"orange.500"} mb={"10px"}>
            Your recent order payment Failed, Make payment again or cancel order
          </Text>{" "}
          {pendingPayments.data?.map((payment) => (
            <PendingPaymentProduct payment={payment} key={payment._id} />
          ))}
        </Box>
      )}
    </>
  );
};
export default PendingPayments;

const PendingPaymentProduct: FC<{ payment: IPayment }> = ({ payment }) => {
  const toast = useToast();
  const cache = useQueryClient();

  const mutateCancelPayment = useMutation(cancelPayment, {
    onSuccess: () => {
      cache.invalidateQueries("pendingPayments");
      toast({
        title: "Payment Cancelled",
        description: "Payment has been cancelled",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  useErrorToast(mutateCancelPayment.error);
  return (
    <Box
      key={payment._id}
      border={"1px"}
      borderColor={"orange.300"}
      borderRadius={"5px"}
      p="15px"
      d="block"
    >
      <Stack spacing={"10px"}>
        {payment.products.map((product) => (
          <Stack
            direction={["column", "row"]}
            spacing={"10px"}
            key={product._id}
          >
            <Box w={["100%", "70%"]}>{product.productId.name}</Box>
            <Box w={["50%", "15%"]}>Qty: {product.quantity}</Box>
            <Box w={["50%", "15%"]} textAlign={"right"}>
              {product.productId.price * product.quantity}
            </Box>
          </Stack>
        ))}
        <Divider borderColor={"orange.300"} />
        <Box>
          <Button
            colorScheme={"teal"}
            as={Link}
            to={`/buy-products/checkout/${payment._id}`}
          >
            Try again
          </Button>
          <Button
            isLoading={mutateCancelPayment.isLoading}
            variant={"ghost"}
            ml={"5px"}
            colorScheme={"teal"}
            onClick={() => {
              mutateCancelPayment.mutate({
                paymentId: payment._id,
              });
            }}
          >
            Cancel order
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
