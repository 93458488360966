import { Box, Stack, Text, VStack } from "@chakra-ui/react";
import { getPersonalInterviewData } from "API/personalInterviewApi";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

const AboutSelf = ({ user }) => {
  const [aboutSelf, setAboutSelf] = useState([
    { label: "Name", values: "" },
    { label: "Father", values: "" },
    { label: "Mother", values: "" },
    { label: "Brother", values: "" },
    { label: "Sister", values: "" },
    { label: "Date Of Birth", values: "" },
    { label: "Place Of Birth", values: "" },
    { label: "School", values: "" },
    { label: "School Name", values: "" },
    { label: "Medium Of Instruction", values: "" },
    { label: "Place", values: "" },
    { label: "Till Class", values: "" },
    { label: "Achievements Sports", values: "" },
    { label: "Achievements Academics", values: "" },
    { label: "Achievements Others", values: "" },
    { label: "Jr. College", values: "" },
  ]);

  const { data: personalInterviewData } = useQuery(
    ["personalInterviewData"],
    () => getPersonalInterviewData(user)
  );

  useEffect(() => {
    if (
      personalInterviewData?.data?.aboutSelf &&
      personalInterviewData?.data?.aboutSelf.length !== 0
    ) {
      const newFields = [];
      personalInterviewData?.data?.aboutSelf.map((field) =>
        newFields.push({ label: field.label, values: field.values })
      );
      setAboutSelf(newFields);
    }
  }, [personalInterviewData]);

  return (
    <VStack
      alignItems={"flex-start"}
      w={"100%"}
      paddingX={"25px"}
      marginBottom={"10px"}
    >
      <Text as={"b"} fontSize={"20px"}>
        About Self
      </Text>
      <Stack
        flexDirection={"row"}
        flexWrap={"wrap"}
        w={"100%"}
        justifyContent={"space-between"}
      >
        {aboutSelf.map((data, index) => (
          <Box key={index} w="30%" color="black" marginTop="5px">
            <Text as="span" fontWeight={600}>
              {`${data.label} : `}
            </Text>
            <Text as="span">{data.values === "" ? "N/A" : data.values}</Text>
          </Box>
        ))}
      </Stack>
    </VStack>
  );
};

export default AboutSelf;
