import { Badge, Box, Heading, Stack, Text } from "@chakra-ui/react";
import { getPaymentById } from "API/paymentApi";
import LoadingSpinner from "components/dumb/LoadingSpinner";
import PaymentButton from "components/smart/PaymentButton";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

const Checkout = () => {
  const { paymentId } = useParams<{ paymentId: string }>();
  const history = useHistory();
  useEffect(() => {
    if (!paymentId) {
      history.goBack();
    }
  }, [history, paymentId]);

  const { data, isLoading } = useQuery<IPayment>(
    ["payment", paymentId],
    () => getPaymentById({ paymentId }),
    {
      enabled: !!paymentId,
      onError: (err) => {
        history.goBack();
      },
    }
  );
  if (isLoading) return <LoadingSpinner />;
  return (
    <>
      <Box>
        <Box w={["100%", "50%"]} m="auto">
          <Heading size="lg" mb="6">
            Checkout
          </Heading>
          {data &&
            data?.products.map((product) => (
              <Box
                bg="white"
                shadow={"md"}
                p={"4"}
                mb={"20px"}
                key={product._id}
              >
                <Text fontWeight={"bold"}>{product.productId.name}</Text>
                <Box>
                  {product.productId.access.map((access) => (
                    <Badge colorScheme={"teal"} mr={"2"} key={access}>
                      {access}
                    </Badge>
                  ))}
                </Box>
                <Text>Duration: {product.productId.duration} months</Text>
                <Text fontWeight={"bold"} fontSize={"16px"} color="teal">
                  Rs.{product.productId.price * product.quantity}
                </Text>
              </Box>
            ))}

          {data && (
            <>
              <Stack alignItems="flex-end" mb={"20px"}>
                <Text fontWeight="bold">Subtotal: Rs. {data.subTotal}</Text>
                <Text fontWeight="bold">
                  Payment Gateway fee (2%): Rs. {data.tnxFee}
                </Text>
                <Text fontWeight="bold" color="red">
                  Total: Rs. {data.total}
                </Text>
              </Stack>
              <PaymentButton orderId={data.orderId} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Checkout;
