import { useEffect } from 'react';
import { useToast } from '@chakra-ui/toast';
const useErrorToast = (error: unknown) => {
  const toast = useToast();
  useEffect(() => {
    if (error instanceof Error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  }, [error, toast]);
};
export default useErrorToast;
