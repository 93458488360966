import {
  Editable,
  EditableInput,
  EditablePreview,
  useEditableControls,
} from "@chakra-ui/editable";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/layout";
import {
  ButtonGroup,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { deleteSubject, updateSubject } from "API/subjectApi";
import { FC, useState, useRef } from "react";
import { GoTrashcan } from "react-icons/go";
import { useMutation, useQueryClient } from "react-query";
import { Spinner } from "@chakra-ui/spinner";
import useErrorToast from "hook/error-toast";
import { useToast } from "@chakra-ui/toast";

interface EditComponentProps {
  name: string;
  _id: string;
}

const EditComponent: FC<EditComponentProps> = ({ name, _id }) => {
  const toast = useToast();
  const cache = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);
  const { isLoading, error, mutateAsync } = useMutation(updateSubject, {
    onSuccess: (data) => {
      toast({
        title: "Subject updated",
        description: `${name} has been updated`,
        status: "success",
      });
      cache.invalidateQueries("subjects");
    },
  });
  const {
    isLoading: deleteLoading,
    error: isDeleteError,
    mutate: deleteMutate,
  } = useMutation(deleteSubject, {
    onSuccess: (data) => {
      toast({
        title: "Subject Deleted",
        description: `${name} has been deleted`,
        status: "success",
      });
      cache.invalidateQueries("subjects");
    },
  });

  useErrorToast(error);
  useErrorToast(isDeleteError);

  const handleDelete = (id: string) => {
    deleteMutate(id);
  };

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isLoading || deleteLoading ? (
      <Spinner />
    ) : isEditing ? (
      <ButtonGroup justifyContent="center" size="sm" ml="4">
        <IconButton icon={<CheckIcon />} {...(getSubmitButtonProps() as any)} />
        <IconButton icon={<CloseIcon />} {...(getCancelButtonProps() as any)} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center" ml="4">
        <IconButton
          size="sm"
          icon={<EditIcon />}
          {...(getEditButtonProps() as any)}
          mr="2"
        />
        <IconButton
          aria-label="Delete Subject"
          size="sm"
          icon={<GoTrashcan />}
          onClick={() => setIsOpen(true)}
        />
      </Flex>
    );
  };

  return (
    <>
      <Editable
        //   textAlign="center"
        defaultValue={name}
        fontSize="2xl"
        isPreviewFocusable={false}
        display="flex"
        justifyContent="center"
        alignItems="center"
        textTransform="capitalize"
        onSubmit={async (value) => {
          if (value !== name) {
            try {
              await mutateAsync({ name: value, id: _id });
            } catch (error) {}
          }
        }}
      >
        <EditablePreview width="full" />
        <EditableInput />
        <EditableControls />
      </Editable>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Subject
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? All the questions related to the subject will be
              deleted.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete(_id)}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
export default EditComponent;
