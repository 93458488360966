import { Box, Container, Heading, Text, Stack} from '@chakra-ui/react'
import StaticFooter from './StaticFooter'
const Refund = () => {
    return (
        <>
            <Box bg="purple">
                <Container py={["24px", "80px"]} maxW="container.md" color={'white'}>
                    <Heading as='h1' mb="6" fontSize={["30px", "45px"]}>Refunds Policy</Heading>
                    <Text>Last updated September 29, 2021</Text>
                </Container>
            </Box>
            <Container py={["24px", "80px"]} maxW="container.md">
                <Stack spacing={'20px'}>
                    <Text>
                        If you choose to cancel your order, you may do so within 14 days from purchase
                        date with giving appropriate reason.
                        You can send us order cancellaion request at contact@kpexam.com.
                        We will be notify you of the approval or rejection of your refund.
                        If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                    </Text>
                    <Text fontWeight={'bold'}>Late or missing refunds</Text>
                    <Text>
                        If you haven’t received a refund yet, first check your bank account again.
                        Then contact your credit card company, it may take some time before your refund is officially posted.
                        Next contact your bank. There is often some processing time before a refund is posted.
                        If you’ve done all of this and you still have not received your refund yet, please contact us at contact@kpexam.com.
                    </Text>
                    <Text fontWeight={'bold'}>Sale items</Text>
                    <Text>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</Text>
                </Stack>
            </Container>
            <StaticFooter />
        </>
    )
}

export default Refund
