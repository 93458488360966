import { useAppSelector } from "app/hook";
import AddAndUpdateStudent from "pages/dashboard/shared/Users/Students/Add&UpdateStudentForm";
import React from "react";

const AddStudents = () => {
  const { uid } = useAppSelector(({ auth }) => auth);

  return (
    <AddAndUpdateStudent
      redirectTo="/school/dashboard/users/student"
      schoolId={uid ?? ""}
    />
  );
};

export default AddStudents;
