import { FC } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import Topics from "./Topics/Topics";
import UpdateChapter from "./UpdateChapter";
import Modal from "components/UI/Modal";
import { IconButton } from "@chakra-ui/button";
import { useAppSelector } from "app/hook";
import useErrorToast from "hook/error-toast";
import { useQueryClient, useMutation } from "react-query";
import { deleteChapterById } from "API/chapterApi";
import { Box, Flex, Stack, Text, Badge } from "@chakra-ui/react";

interface ChapterProps {
  chapter: IChapter;
}

const Chapter: FC<ChapterProps> = ({ chapter }) => {
  const { isOpen, onToggle } = useDisclosure();
  const {
    isOpen: isModalUpdateOpen,
    onOpen: onModalUpdateOpen,
    onClose: onModalUpdateClose,
  } = useDisclosure();
  const cache = useQueryClient();

  const { program, subjectId } = useAppSelector((state) => state.programState);
  const { isLoading, mutateAsync, error } = useMutation(deleteChapterById, {
    onSuccess: () => {
      cache.invalidateQueries(["program", program?._id, subjectId]);
    },
  });
  useErrorToast(error);
  return (
    <>
      {
        <Modal
          modalSize="md"
          onClose={onModalUpdateClose}
          open={isModalUpdateOpen}
        >
          <UpdateChapter
            chapterId={chapter._id}
            onClose={onModalUpdateClose}
            chapterName={chapter.name}
          />
        </Modal>
      }
      <Box borderBottom='1px'
        borderColor='gray.200'>
        <Flex
          justify="center"
          alignItems="center"
          p="4"
          key={chapter._id}
        >
          <Stack width="100%" >
            <Flex justify="space-between" onClick={onToggle}>
              <Box>
                <Text><Badge colorScheme='green' variant='solid'>
                  Chapter
                </Badge> {chapter?.name}</Text>
              </Box>
              {isOpen ? (
                <ChevronRightIcon color="teal.600" fontSize="3xl" />
              ) : (
                <ChevronDownIcon color="teal.600" fontSize="3xl" />
              )}
            </Flex>
          </Stack>
          <IconButton
            aria-label="Edit"
            onClick={onModalUpdateOpen}
            ml="4"
            icon={<EditIcon />}
          />
          <IconButton
            aria-label="delete button"
            isLoading={isLoading}
            onClick={async () => {
              try {
                mutateAsync(chapter._id);
              } catch (error) { }
            }}
            ml="4"
            icon={<DeleteIcon />}
          />
        </Flex>
            {isOpen && (
               <Box p="4" pt="0">
               <Box border="1px"
                 borderColor="gray.200"
               >
              <Topics chapterId={chapter._id} topics={chapter.topics} />
              </Box>
        </Box>
            )}
         
      </Box>
    </>
  );
};
export default Chapter;