import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import filterQuestionReducer from "./features/questionFilter/questionFilterSlice";
import userFilterReducer from "./features/userFilter/userFilter";
import batchFilterReducer from "./features/batchFilter/batchFilter";
import performanceFilterReducer from "./features/performanceFilter/performanceFilter";
import programReducer from "./features/program/programSlice";
import questionReducer from "./features/question/questionSlice";
import  batchStatusFilter  from "./features/batchStatus/batchStatus";
import schoolFilter from "./features/schoolFilter/schoolFilter";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    filterQuestion: filterQuestionReducer,
    userFilter: userFilterReducer,
    batchFilter: batchFilterReducer,
    programState: programReducer,
    question: questionReducer,
    performanceFilter: performanceFilterReducer,
    batchStatus: batchStatusFilter,
    schoolFilter: schoolFilter
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
