import React from "react";
import { Container } from "@chakra-ui/react";
import { Stack, Flex } from "@chakra-ui/react";
import { Center, Heading } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { GoMail } from "react-icons/go";
import { useQuery } from "react-query";
import { emailVerify } from "API/authApi";
import { useParams } from "react-router-dom";
import { Spinner } from "@chakra-ui/spinner";

const EmailVerify = () => {
  const { token } = useParams<{ token: string }>();
  const { error, isLoading } = useQuery("password-token", () =>
    emailVerify(token)
  );
  console.log(error);

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : error ? (
        <Container maxW="1300px">
          <Stack align="center" justify="center" height="100vh">
            <Heading fontSize="xl" color="red.400">
              Email verification Failed ! Please try again!
            </Heading>
            <Button as={Link} to="/login" variant="outline" colorScheme="blue">
              Go Back
            </Button>
          </Stack>
        </Container>
      ) : (
        <Container maxW="1300px">
          <Stack height="50vh" align="center" justify="center">
            <Flex
              fontSize="8xl"
              align="center"
              justify="center"
              color="blue.600"
            >
              <GoMail />
            </Flex>
            <Stack align="center">
              <Heading fontSize="xl" color="blue.400">
                Email Verified! You can now login.
              </Heading>
              <Button
                as={Link}
                to="/login"
                variant="outline"
                colorScheme="blue"
              >
                Login
              </Button>
            </Stack>
          </Stack>
        </Container>
      )}{" "}
    </>
  );
};

export default EmailVerify;
