import Students from "pages/dashboard/shared/Users/Students/Students";

const StaffStudents = () => {
  return (
    <Students
      editRoute="/staff/dashboard/users/student/edit"
      addRoute={``}
      schoolId={""}
      showAddButton={false}
    />
  );
};

export default StaffStudents;
