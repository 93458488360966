import { axios } from "./AxiosInstance";
export const initPayment = async () => {
  try {
    const { data } = await axios.get("/payment/init");
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const validatePayment = async ({
  razorpay_payment_id,
}: {
  razorpay_payment_id: any;
}) => {
  try {
    const { data } = await axios.post(`/payment/validate`, {
      razorpay_payment_id,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getPaymentById = async ({ paymentId }: { paymentId: string }) => {
  try {
    const { data } = await axios.get(`/payment/${paymentId}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const getPayments = async ({
  page,
  size,
  searchText,
}: {
  page: number;
  size: number;
  searchText: string;
}) => {
  try {
    const { data } = await axios.post(`/payment/getPayments`, {
      page,
      size,
      searchText,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getPendingPayment = async () => {
  try {
    const { data } = await axios.get(`/payment/pending`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const cancelPayment = async ({ paymentId }: { paymentId: string }) => {
  try {
    const { data } = await axios.post(`/payment/cancel`, {
      paymentId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
