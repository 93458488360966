import { Box, Stack } from "@chakra-ui/layout";
import { Button, Heading } from "@chakra-ui/react";
import { getStaffs } from "API/staffApi";
import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
} from "app/features/userFilter/userFilter";
import { useAppSelector } from "app/hook";
import TablePaginator from "components/dumb/Table/TablePaginator";
import TableSearch from "components/dumb/Table/TableSearch";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { GoPlus } from "react-icons/go";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import StaffTable from "./StaffTable";

interface StaffsProps {
  addStaffRoute: string;
  editStaffRoute: string;
}

const Staffs: FC<StaffsProps> = ({ addStaffRoute, editStaffRoute }) => {
  const TABLE_HEAD = [
    { id: "Staff", label: "Teacher", alignRight: false },
    { id: "School", label: "School Name", alignRight: false },
    {
      id: "actions",
      //   flag: { read: true, edit: true, remove: true },
      //   callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: "Actions",
      alignRight: false,
    },
  ];

  const { size, page, searchText } = useAppSelector(
    (state) => state.userFilter
  );

  const { data, isLoading, error } = useQuery<IStaffFilters>(
    ["staffs", page, size, searchText],
    () => {
      return getStaffs({
        page,
        size,
        searchText,
      });
    }
    // { enabled: schoolId ? true : false }
  );

  useErrorToast(error);

  console.log(data);

  return (
    <>
      <Heading fontSize="2xl">Manage Teacher</Heading>
      {data && (
        <>
          <Stack
            w="100%"
            direction={["column", "row"]}
            p="15px"
            justify="space-between"
          >
            <TableSearch
              searchText={searchText}
              setPageDefault={setPageDefault}
              setSearchText={setSearchText}
            />
            <Box flex="0">
              <Button
                rightIcon={<GoPlus />}
                colorScheme="teal"
                variant="solid"
                size="sm"
                as={Link}
                to={addStaffRoute}
              >
                Add Teacher
              </Button>
            </Box>
          </Stack>
          <StaffTable
            editStaffRoute={editStaffRoute}
            isLoading={isLoading}
            items={data?.staffs}
            tableHeader={TABLE_HEAD}
            disableUpdate={false}
          />
          <TablePaginator
            count={data?.count}
            decrementPage={decrementPage}
            incrementPage={incrementPage}
            page={data?.page}
            setPageDefault={setPageDefault}
            setSize={setSize}
            size={size}
            totalPages={data?.totalPage}
          />
        </>
      )}
    </>
  );
};
export default Staffs;
