import { Box, Flex, Circle, Text, HStack } from "@chakra-ui/react";

const StepIndicator = ({ step }) => {
  const steps = {
    1: "About self",
    2: "Area of expertise",
    3: "Current and general info",
    4: "Concluding interview",
  };

  return (
    <Box w="100%" position="relative" p="4">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Box
          flex="1"
          height="2px"
          bg="gray.300"
          position="absolute"
          top="25%"
          left="0"
          right="0"
        />

        <HStack w={"80%"} marginX={"auto"} justifyContent={"space-between"}>
          {Object.keys(steps).map((key, index) => (
            <Flex
              key={index}
              direction="column"
              alignItems="center"
              position="relative"
              zIndex="1"
            >
              <Circle
                size="44px"
                bg={parseInt(step) === parseInt(key) ? "teal" : "grey"}
                color="white"
                fontWeight={"bold"}
              >
                {key}
              </Circle>
              <Text color={parseInt(step) === parseInt(key) ? "teal" : "black"}>
                {" "}
                {steps[key]}
              </Text>
            </Flex>
          ))}
        </HStack>
      </Flex>
    </Box>
  );
};

export default StepIndicator;
