import { axios } from "./AxiosInstance";

export const getStaffById = async (id: string) => {
  try {
    const { data } = await axios.get(`/staff/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getStaffs = async ({
  searchText,
  page,
  size,

}: {
  searchText: string;
  page: number;
  size: number;
}) => {
  try {
    const { data } = await axios.post(`/staff/filterStaff`, {
      searchText,
      page,
      size,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const addStaff = async ({
  name,
  email,
  mobile,
  password,
  schoolId,
  interestedProgram,
  subjects,
}: {
  name: string;
  email: string;
  password: string;
  mobile: number;
  schoolId: string;
  interestedProgram: string[];
  subjects: string[];
}) => {
  try {
    const { data } = await axios.post(`/staff/addStaff`, {
      name,
      email,
      mobile,
      schoolId,
      password,
      interestedProgram,
      subjects,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateStaff = async ({
  userId,
  name,
  email,
  mobile,
  password,
  interestedProgram,
  schoolId,
  subjects
}: {
  name: string;
  email: string;
  password: string;
  mobile: number;
  userId: string;
  interestedProgram: string[];
  schoolId: string;
  subjects: string[];
}) => {
  try {
    const updateData = {
      userId,
      name,
      email,
      mobile,
      password: password.length > 0 ? password : undefined,
      interestedProgram,
      schoolId,
      subjects
    };

    const { data } = await axios.patch(`/staff/updateStaff`, updateData);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const deleteStaff = async ({ userId }: { userId: string }) => {
  try {
    const { data } = await axios.delete(`/staff/${userId}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
