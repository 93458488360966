import { Flex, Stack, Text } from "@chakra-ui/layout";
import { FC } from "react";

interface TopicProps {
  topic: ITopic;
  subjectName: string | undefined;
  handleTopicClick: (topicId: string) => void;
  isSelected: boolean;
}

const Topic: FC<TopicProps> = ({ topic, handleTopicClick, isSelected }) => {
  return (
    <>
      <Flex
        onClick={() => handleTopicClick(topic._id)}
        aling="center"
        p="2"
        bg={isSelected ? "teal.100" : "white"}
        key={topic._id}
        boxShadow="md"
        borderRadius="md"
        width="100%"
        justify="space-between"
      >
        <Stack width="100%">
          <Text>Topic Name: {topic?.name}</Text>
        </Stack>
      </Flex>
    </>
  );
};
export default Topic;
