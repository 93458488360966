import { axios } from "./AxiosInstance";

export const addProgram = async ({
  name,
  subjects,
}: {
  name: string;
  subjects: string[];
}) => {
  try {
    const { data } = await axios.post(`/program/add`, { name, subjects });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getPrograms = async () => {
  try {
    const { data } = await axios.get(`/program`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getAllPrograms = async () => {
  try {
    const { data } = await axios.get(`/program/all`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const getProgramById = async (id: string, subjectId: string) => {
  try {
    const { data } = await axios.post(`/program/get`, { id, subjectId });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateProgram = async ({
  id,
  programName,
}: {
  id: string;
  programName: string;
}) => {
  try {
    const { data } = await axios.patch(`/program`, {
      programName,
      programId: id,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateProgramDetails = async ({
  id,
  programDetails,
}: {
  id: string;
  programDetails: string;
}) => {
  try {
    const { data } = await axios.patch(`/program/details`, {
      programDetails,
      programId: id,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const deleteProgramById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/program/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const deleteProgramSubject = async ({
  programId,
  subjectId,
}: {
  programId: string;
  subjectId: string;
}) => {
  try {
    const { data } = await axios.delete(
      `/program/deleteSubject/${subjectId}/${programId}`
    );
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateProgramSubject = async ({
  programId,
  subjectId,
}: {
  programId: string;
  subjectId: string;
}) => {
  try {
    const { data } = await axios.patch(`/program/updateSubject`, {
      subjectId,
      programId: programId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getProgramQuestionBySubject = async (
  programId: string,
  subjectId: string
) => {
  try {
    const { data } = await axios.post(`/program/getQuestions`, {
      programId,
      subjectId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
