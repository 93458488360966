import { axios } from "./AxiosInstance";

export const addActiveProgram = async ({
  programId,
  expiresAt,
  studentId,
}: {
  programId: string;
  expiresAt: string;
  studentId: string;
}) => {
  try {
    const { data } = await axios.post(`/active-program/add`, {
      programId,
      expiresAt,
      studentId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const removeActiveProgram = async ({
  studentId,
  programId,
}: {
  programId: string;
  studentId: string;
}) => {
  try {
    const { data } = await axios.delete(
      `/active-program/${programId}/${studentId}`
    );
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateActiveProgram = async ({
  expiryDate,
  activeProgramId,
}: {
  expiryDate: string;
  activeProgramId: string;
}) => {
  try {
    const { data } = await axios.patch(`/active-program/`, {
      expiryDate,
      activeProgramId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateActiveProgramForMany = async (
  expiryDate:String,
  studentIds:string[]|undefined,
  programId:string|undefined
) => {
  try {    
    const { data } = await axios.patch(`/active-program/enddate`, {
      expiryDate,
      studentIds,
      programId
    });
    
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getActiveProgram = async (id: string, subjectId: string) => {
  try {
    const { data } = await axios.post(`/active-program/get`, {
      id,
      subjectId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
