import { axios } from "./AxiosInstance";
export const getPurchasedProducts = async () => {
  try {
    const { data } = await axios.get(`/purchased-product/`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const getPurchasedProductById = async (id: string) => {
  try {
    const { data } = await axios.get(`/purchased-product/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateLicences = async ({ id, quantity }: { id: string, quantity: number }) => {
  try {
    const { data } = await axios.post(`/purchased-product/${id}/update-licences`, {
      quantity
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const assignPurchasedProductToStudent = async ({
  studentsIds,
  purchasedProductId,
}: {
  studentsIds: string[];
  purchasedProductId: string;
}) => {
  try {
    const { data } = await axios.post(`/purchased-product/assign-program`, {
      studentsIds,
      purchasedProductId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
