import { FC } from "react";
import { Link as RoLink } from "react-router-dom";
import { useAppSelector } from "app/hook";
import { format } from "date-fns";
import {
  Heading,
  Stack,
  Text,
  Box,
  useColorModeValue,
  Button,
  HStack,
} from "@chakra-ui/react";

interface StudentDashboardProps {}

const StudentDashboard: FC<StudentDashboardProps> = () => {
  const { user: student }: { user: IStudent } = useAppSelector(
    (state) => state.auth
  );

  return (
    <>
      <Heading size="md">Dashboard</Heading>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
        minH="50vh"
      >
        <Box p="4">
          <Text size="sm" color={useColorModeValue("gray.500", "gray.700")}>
            MY PROGRAMS
          </Text>
        </Box>
        <Box borderTop="1px" borderColor="gray.100">
          {Number(student?.activePrograms?.length) === 0 && (
            <Box size="sm" textAlign={"center"} py={"100px"}>
              <Text fontSize={"20px"} m="auto" py={"20px"} w={["90%", "50%"]}>
                You have no active programs.
              </Text>
              <Button colorScheme={"teal"} as={RoLink} to="/buy-products">
                Enroll for program
              </Button>
            </Box>
          )}
          {student?.activePrograms.map((activeProgram) => (
            <Stack
              direction={["column", "row"]}
              key={activeProgram?.purchasedProduct?._id}
              spacing={["10px", "0px"]}
              borderBottom="1px"
              borderColor="gray.100"
              p="4"
            >
              <Box
                w={["100%", "70%"]}
                as={RoLink}
                to={`/student/dashboard/programs/${activeProgram._id}`}
              >
                <Heading
                  as="h5"
                  size="sm"
                  textTransform={"uppercase"}
                  mb={["2", "1"]}
                >
                  {activeProgram?.program?.name}
                </Heading>

                <Stack direction={["column", "row"]} spacing={["10px", "0px"]}>
                  <Text>
                    Start Date:{" "}
                    {format(new Date(activeProgram?.createdAt), "dd-MM-yyyy")}
                  </Text>
                  <Text color={"red"} px="2" d={["none", "block"]}>
                    •
                  </Text>
                  <Text>
                    End Date:{" "}
                    {format(new Date(activeProgram?.expiredAt), "dd-MM-yyyy")}
                  </Text>
                  <Text color={"red"} px="2" d={["none", "block"]}>
                    •
                  </Text>
                  <Text>
                    Total Tests: {activeProgram?.program?.tests.length}
                  </Text>
                </Stack>
                <Text color={"teal"} mt={["10px", "0px"]}>
                  Program details & Study Materials
                </Text>
              </Box>

              <Stack
                direction={["column", "row"]}
                w={["100%", "30%"]}
                spacing={["10px", "0px"]}
                justifyContent={["flex-start", "flex-end"]}
                alignItems={["start", "center"]}
              >
                {/* <Button
                  colorScheme="teal"
                  w={["auto", "40%"]}
                  variant="link"
                  display={['flex', 'none']}
                  as={RoLink}
                  to={`/student/dashboard/programs/${activeProgram._id}`}
                  textAlign={["left", "center"]}
                  justifyContent={["flex-start", "center"]}
                >
                  Details
                </Button> */}
                <HStack w={"100%"} justifyContent={["flex-start", "flex-end"]}>
                  {activeProgram?.purchasedProduct?.product.access.includes(
                    "coaching" as ProductType
                  ) && (
                    <Button
                      colorScheme="teal"
                      w={"50%"}
                      as={RoLink}
                      to={`/student/dashboard/programs/${activeProgram._id}/recordedvideos`}
                    >
                      Play videos
                    </Button>
                  )}
                  {/* {activeProgram?.purchasedProduct?.product.access.includes(
                    "report" as ProductType
                  ) && (
                    <Button
                      colorScheme="teal"
                      w={"50%"}
                      as={RoLink}
                      to={`/student/dashboard/reports`}
                    >
                      View Reports
                    </Button>
                  )} */}
                  {activeProgram?.purchasedProduct?.product.access.includes(
                    "test" as ProductType
                  ) && (
                    <Button
                      colorScheme="teal"
                      w={"50%"}
                      as={RoLink}
                      to={`/student/dashboard/programs/${activeProgram._id}/viewalltests`}
                    >
                      Tests
                    </Button>
                  )}
                  {/* {activeProgram?.program?.tests.length > 0 && (
                  <Button
                    colorScheme="teal"
                    w={'50%'}
                    as={RoLink}
                    to={`/student/dashboard/programs/${activeProgram._id}/viewalltests`}
                  >
                    Tests
                  </Button>
                )} */}
                </HStack>
              </Stack>
            </Stack>
          ))}
        </Box>
      </Box>
    </>
  );
};
export default StudentDashboard;
