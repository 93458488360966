import { Select } from "@chakra-ui/select";
import { getSubjects } from "API/subjectApi";
import { FC } from "react";
import useErrorToast from "hook/error-toast";
import { useQuery } from "react-query";

interface SubjectSelectProps {
  subject: string | undefined;
  selectOutput: (subject: string) => void;
}

const SubjectSelect: FC<SubjectSelectProps> = ({ subject, selectOutput }) => {
  const { data, error } = useQuery("subjects", getSubjects);
  useErrorToast(error);

  return (
    <Select
      placeholder="Subjects"
      size="sm"
      rounded="md"
      name="subject"
      display={{ base: "none", md: "block", lg: "block" }}
      textTransform="capitalize"
      value={subject ?? ""}
      onChange={(e) => selectOutput(e.target.value)}
    >
      {data &&
        data?.map((e: ISubject) => (
          <option key={e?.name} value={e?.name}>
            {e.name}
          </option>
        ))}
    </Select>
  );
};
export default SubjectSelect;
