import { Box, Container, Heading, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import StaticFooter from './StaticFooter'

const TermsAndConditions = () => {
    return (
        <>
            <Box bg="purple">
                <Container py={["24px", "80px"]} maxW="container.md" color={'white'}>
                    <Heading as='h1' mb="6" fontSize={["30px", "45px"]}>Terms and Conditions</Heading>
                    <Text>Last updated September 29, 2021</Text>
                </Container>
            </Box>
            <Container py={["24px", "80px"]} maxW="container.md">
                <Stack spacing={'20px'}>
                    <Heading as="h2" size={'md'}>Introduction</Heading>
                    <Text>
                        These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, KP Exam accessible at https://kpexam.com/.
                        <br /><br />
                        These Terms will be applied fully and affect your use of this Website. By using this Website, you agreed to accept all terms and conditions written here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
                    </Text>
                    <Heading as="h2" size={'md'}>Intellectual Property Rights</Heading>
                    <Text>
                        Other than the content you own, under these Terms, KP Training Academy and/or its licensors own all the intellectual property rights and materials contained in this Website.
                        <br /><br />
                        You are granted a limited license only for purposes of viewing the material contained on this Website.
                    </Text>
                    <Heading as="h2" size={'md'}>Restrictions</Heading>
                    <Text>
                        You are specifically restricted from all of the following:
                    </Text>
                    <UnorderedList pl="16px">
                        <ListItem>Publishing any Website material in any other media;</ListItem>
                        <ListItem>Selling, sublicensing, and/or otherwise commercializing any Website material;</ListItem>
                        <ListItem>Publicly performing and/or showing any Website material;</ListItem>
                        <ListItem>Using this Website in any way that is or may be damaging to this Website;</ListItem>
                        <ListItem>Using this Website in any way that impacts user access to this Website;</ListItem>
                        <ListItem>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</ListItem>
                        <ListItem>Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website;</ListItem>
                        <ListItem>Using this Website to engage in any advertising or marketing.</ListItem>
                    </UnorderedList>
                    <Text>
                        Certain areas of this Website are restricted from being accessed by you and KP Training Academy may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.
                    </Text>
                    <Heading as="h2" size={'md'}>Your Content</Heading>
                    <Text>
                        In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images, or other material you choose to display on this Website. By displaying Your Content, you grant KP Training Academy a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
                        <br /><br />
                        Your Content must be your own and must not be invading any third-party’s rights. KP Training Academy reserves the right to remove any of Your Content from this Website at any time without notice.
                    </Text>
                    <Heading as="h2" size={'md'}>No warranties</Heading>
                    <Text>
                        This Website is provided “as is,” with all faults, and KP Training Academy expresses no representations or warranties, of any kind related to this
                        Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.
                    </Text>
                    <Heading as="h2" size={'md'}>Limitation of liability</Heading>
                    <Text>
                        In no event shall KP Training Academy, nor any of its officers, directors, and employees shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  KP Training Academy, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.
                    </Text>
                    <Heading as="h2" size={'md'}>Indemnification</Heading>
                    <Text>
                        You hereby indemnify to the fullest extent KP Training Academy from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.
                    </Text>
                    <Heading as="h2" size={'md'}>Severability</Heading>
                    <Text>
                        If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
                    </Text>
                    <Heading as="h2" size={'md'}>Variation of Terms</Heading>
                    <Text>
                        KP Training Academy is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
                    </Text>
                    <Heading as="h2" size={'md'}>Assignment</Heading>
                    <Text>
                        The KP Training Academy is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                    </Text>
                    <Heading as="h2" size={'md'}>Entire Agreement</Heading>
                    <Text>
                        These Terms constitute the entire agreement between KP Training Academy and you in relation to your use of this Website and supersede all prior agreements and understandings.
                    </Text>
                    <Heading as="h2" size={'md'}>Governing Law & Jurisdiction</Heading>
                    <Text>
                        These Terms will be governed by and interpreted in accordance with the laws of the State of in, and you submit to the non-exclusive jurisdiction of the state and federal courts located in for the resolution of any disputes.
                    </Text>
                </Stack>
            </Container>
            <StaticFooter />
        </>
    )
}

export default TermsAndConditions
