import { Heading, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { updateActiveProgram } from "API/activeProgramApi";
import Modal from "components/UI/Modal";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router";

interface UpdateActiveProgramModalProps {
  isOpen: boolean;
  onClose: () => void;
  acitveProgram: IActiveProgram;
}

const UpdateActiveProgramModal: FC<UpdateActiveProgramModalProps> = ({
  isOpen,
  onClose,
  acitveProgram,
}) => {
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const cache = useQueryClient();

  const { error, isLoading, mutateAsync } = useMutation(
    "updateActiveProgram",
    updateActiveProgram,
    {
      onSuccess: () => {
        cache.invalidateQueries(["student", id]);
        toast({
          title: "Active Program Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  useErrorToast(error);

  return (
    <>
      <Modal onClose={onClose} open={isOpen}>
        <Formik
          initialValues={{
            expiryDate: format(new Date(acitveProgram.expiredAt), "yyyy-MM-dd"),
          }}
          onSubmit={async (values) => {
            try {
              await mutateAsync({
                expiryDate: values.expiryDate,
                activeProgramId: acitveProgram._id,
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Form>
            <Stack mt="4">
              <Heading fontSize="xl" mb="2">
                Update Active Program
              </Heading>
              <InputControl
                label="Expiry Date"
                name="expiryDate"
                inputProps={{
                  type: "date",

                  min: new Date().toISOString().split("T")[0],
                }}
              />
              <SubmitButton isLoading={isLoading}>Update</SubmitButton>
            </Stack>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
export default UpdateActiveProgramModal;
