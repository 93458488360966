import {
  Spinner,
  Heading,
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Grid,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAppSelector } from "app/hook";
import { getCareerPlanByStudentId } from "API/careerPlanApi";
import html2pdf from "html2pdf.js";
import { useHistory, useLocation } from "react-router-dom";
import logo from "assets/images/banner-logo.jpeg";

const cellBorderStyle = {
  borderWidth: "2px",
  borderColor: "gray",
  padding: "5px 15px 5px 15px",
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const CareerPlan = (props) => {
  //   const [tests, setTests] = useState<ITestShowCase[]>([]);
  //   const { subjectId } = useAppSelector((state) => state.programState);
  //   const dispatch = useAppDispatch();
  //   const [searchTest, setSearchTest] = useState("");
  //   const bgClr = useColorModeValue("red.50", "gray.700");

  //   const { id } = useParams<{ id: string }>();

  //   useEffect(() => {
  //console.log(activeProgram);
  //     if (activeProgram && subjectId) {
  //       const testIds = activeProgram?.tests.map((t) => t?.test?._id);
  //       const testNotTaken = activeProgram?.program?.tests.filter((test) => {
  //         return subjectId === test.subject.toString() &&
  //           testIds?.includes(test._id)
  //           ? false
  //           : true;
  //       });
  //       const testTaken = activeProgram?.tests
  //         .filter((t) =>
  //           t?.test?.subject.toString() === subjectId ? true : false
  //         )
  //         .map((t) => {
  //           return {
  //             ...t?.test,
  //             attempts: t.testAttempts.length,
  //             userTestId: t._id,
  //           };
  //         });
  //       setTests([...testNotTaken, ...testTaken]);
  //     }
  //   }, [activeProgram, subjectId]);

  //   useErrorToast(error);
  //   const borderClr = useColorModeValue("gray.100", "gray.700");

  const history = useHistory();
  const { role } = useAppSelector((state) => state.auth);
  const [user, setUser] = useState({});

  const currentUser = useAppSelector((state) => state.auth.user);

  const [studyPlan, setStudyPlan] = useState([]);

  const { data: careerPlan, isLoading } = useQuery(
    ["careerPlan", user?._id],
    () => getCareerPlanByStudentId(user?._id)
  );

  const location = useLocation();
  const item = location.state?.item;

  useEffect(() => {
    if (role === "student") {
      setUser(currentUser);
    } else {
      setUser(item);
    }

    getCareerPlanByStudentId(user?._id);
  }, [user?._id, currentUser, role, item]); // Run the

  useEffect(() => {
    if (careerPlan) {
      setStudyPlan(careerPlan.studyPlan);
    }
  }, [careerPlan]);
  const toast = useToast();
  const handleDownloadPDF = async () => {
    // Get the component's HTML content
    const componentHTML = document.getElementById("pdfContent");

    const headerElement = document.createElement("div");
    headerElement.classList.add("pdf-header");

    headerElement.innerHTML = `
    <div style="text-align: center; display: flex;  align-items: center;
    gap: 3;">
      <img src=${logo} style="height: 100px; display: flex; justify-content: center; align-items: center;">
      <h1 style="font-weight: bold; color: red; margin-left: 25px;">KP Training Academy</h1>
    </div>
  `;

    componentHTML.insertBefore(headerElement, componentHTML.firstChild);

    // Set options for pdf generation (optional)
    const options = {
      margin: 10,
      filename: "career_plan.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a3", orientation: "landscape" },
    };

    toast({
      title: "Download started",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    // Generate the PDF
    html2pdf().from(componentHTML).set(options).save();
    setTimeout(() => {
      componentHTML.removeChild(headerElement);
    }, 100);
  };

  const handleEditClick = () => {
    history.push({
      pathname: "/student/career-plan/edit",
      state: { user },
    });
  };

  const handleGoBack = () => {
    history.goBack(); // Redirect to the previous page
  };
  return (
    <>
      {user?.activePrograms?.length <= 0 || studyPlan.length === 0 ? (
        <HStack placeItems="center">
          <Heading color={"red"}>No Data Available</Heading>
        </HStack>
      ) : isLoading ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <>
          <HStack justifyContent={"space-between"}>
            <Text fontSize="4xl">Career Plan</Text>
            <HStack>
              <Button
                colorScheme="teal"
                borderRadius="full"
                onClick={handleGoBack}
              >
                Back
              </Button>
              <Button
                colorScheme="teal"
                borderRadius="full"
                onClick={handleDownloadPDF}
              >
                Download Pdf
              </Button>
              <Button colorScheme="teal" px={"30px"} onClick={handleEditClick}>
                Edit
              </Button>
            </HStack>
          </HStack>

          <VStack
            mt={"25px"}
            p={"15px"}
            borderRadius={"10px"}
            border={"1px solid gray"}
            id="pdfContent"
          >
            <VStack w={"100%"} align={"start"}>
              <HStack justifyContent={"space-between"} w={"100%"}>
                <Text>
                  Student name: <Text as={"b"}>{user?.name}</Text>
                </Text>
                <Text as={"b"}>{careerPlan?.year}</Text>
              </HStack>
              <Text m={"0px"}>
                Name of School: <Text as={"b"}>{user?.school?.name}</Text>
              </Text>
              <Text>
                Class:{" "}
                <Text as={"b"}>
                  {careerPlan?.class ? `${careerPlan?.class}th` : "NA"}
                </Text>
              </Text>
              <Text>
                ID No: <Text as={"b"}>{careerPlan?.idNo || "NA"}</Text>
              </Text>
              <Text>
                Primary Objective:{" "}
                <Text as={"b"}>{careerPlan?.primaryObjective || "NA"}</Text>
              </Text>
              <Text>
                Secondary Objective:{" "}
                <Text as={"b"}>{careerPlan?.secondaryObjective || "NA"}</Text>
              </Text>
              <Text>
                Name of Panel Members to Review:
                {careerPlan?.panels?.map((panel, index) => (
                  <Text key={index} as={"b"}>
                    {panel}
                    {",  "}
                  </Text>
                )) || <Text as={"b"}>"NA"</Text>}
              </Text>
            </VStack>
            <Box px={"25px"} paddingTop={"20px"} width={"100%"}>
              <Table variant="simple" width={"100%"}>
                <Thead bg={"#c1c1c1"}>
                  <Tr>
                    <Th
                      {...cellBorderStyle}
                      alignItems={"flex-end"}
                      borderBottom={"0px"}
                    >
                      <Text as={"b"} color={"black"}>
                        #
                      </Text>
                    </Th>
                    <Th {...cellBorderStyle} borderBottom={"0px"}>
                      <Text as={"b"} color={"black"}>
                        Topic
                      </Text>
                    </Th>
                    <Th {...cellBorderStyle} borderBottom={"0px"}>
                      <Text as={"b"} color={"black"}>
                        Study Plan
                      </Text>
                    </Th>
                    <Th {...cellBorderStyle} colSpan={12} textAlign={"center"}>
                      <Text as={"b"} color={"black"}>
                        Monthly Review
                      </Text>
                    </Th>
                    <Th {...cellBorderStyle} borderBottom={"0px"}>
                      <Text as={"b"} color={"black"}>
                        Remark
                      </Text>
                    </Th>
                  </Tr>

                  <Tr>
                    <Th {...cellBorderStyle} borderTop={"0px"}>
                      &nbsp;
                    </Th>
                    <Th {...cellBorderStyle} borderTop={"0px"}>
                      &nbsp;
                    </Th>
                    <Th {...cellBorderStyle} borderTop={"0px"}>
                      &nbsp;
                    </Th>
                    {months.map((month) => (
                      <Th key={month} {...cellBorderStyle}>
                        <Text as={"b"} color={"black"}>
                          {month}
                        </Text>
                      </Th>
                    ))}
                    <Th {...cellBorderStyle} borderTop={"0px"}>
                      &nbsp;
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {studyPlan.length > 0 ? (
                    studyPlan.map((plan, index) => (
                      <React.Fragment key={index}>
                        <Tr>
                          <Td {...cellBorderStyle}>{index + 1}</Td>
                          <Td {...cellBorderStyle}>{plan.topic}</Td>
                          <Td {...cellBorderStyle}>{plan.studyPlan}</Td>
                          {months.map((month) => (
                            <Td key={month} {...cellBorderStyle} colSpan={1}>
                              {plan.months[month] ? plan.months[month] : "NA"}
                            </Td>
                          ))}
                          <Td {...cellBorderStyle}>{plan.remark}</Td>
                        </Tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <React.Fragment>
                      <Tr>
                        <Td {...cellBorderStyle}>NA</Td>
                        <Td {...cellBorderStyle}>NA</Td>
                        <Td {...cellBorderStyle}>NA</Td>
                        {months.map((month) => (
                          <Td key={month} {...cellBorderStyle} colSpan={1}>
                            NA
                          </Td>
                        ))}
                        <Td {...cellBorderStyle}>NA</Td>
                      </Tr>
                    </React.Fragment>
                  )}
                </Tbody>
              </Table>
            </Box>

            <Heading size="md" w={"100%"} color={"Red"} align={"start"}>
              RATING PARAMETERS: Rating out of 10 marks. Excellent 10-8, Good
              8-6, Average 6-4, Low 4-2, Poor 2-0
            </Heading>
          </VStack>
        </>
      )}
    </>
  );
};
export default CareerPlan;
