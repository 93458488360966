import { HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import StepIndicator from "./StepIndicator";
import EditAboutSelf from "./EditAboutSelf";
import EditAreaOfExpertise from "./EditAreaOfExpertise";
import EditCurrentInfoAndKnowledge from "./EditCurrentInfoAndKnowledge";
import EditConcludingQuestion from "./EditConcludingQuestion";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "app/hook";

const EditPersonalInterview = () => {
  const [user, setUser] = useState(null);
  const [tab, setTab] = useState(1);
  let { role, user: currentUser } = useAppSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    if (role === "student") {
      setUser(currentUser);
    } else {
      setUser(location.state?.item);
    }
  }, [role, currentUser, location.state]);

  return (
    <VStack>
      <HStack w={"100%"}>
        <Text textTransform="uppercase" as={"b"} fontSize={"20px"}>
          preparing for personal interview
        </Text>
      </HStack>
      <VStack
        paddingY={"15px"}
        w={"full"}
        border="1px"
        borderColor="gray.400"
        borderRadius={"8px"}
      >
        <StepIndicator step={tab} />

        {/* FORM  */}
        {tab === 1 ? (
          <EditAboutSelf tab={tab} setTab={setTab} user={user ? user : null} />
        ) : tab === 2 ? (
          <EditAreaOfExpertise
            tab={tab}
            setTab={setTab}
            user={user ? user : null}
          />
        ) : tab === 3 ? (
          <EditCurrentInfoAndKnowledge
            tab={tab}
            setTab={setTab}
            user={user ? user : null}
          />
        ) : (
          <EditConcludingQuestion
            tab={tab}
            setTab={setTab}
            user={user ? user : null}
          />
        )}
      </VStack>
    </VStack>
  );
};

export default EditPersonalInterview;
