import Products from "pages/buyProducts/BuyProducts";
import Checkout from "pages/buyProducts/Checkout";
import Settings from "pages/dashboard/shared/Settings";
import MyProfile from "pages/dashboard/shared/MyProfile";
import CareerPlanView from "pages/dashboard/StudentDashboard/CareerPlan/CareerPlanView";
import CareerPlanEdit from "pages/dashboard/StudentDashboard/CareerPlan/CareerPlanEditi";
import Personalinterview from "pages/product/personalInterviewView/Personalinterview";
import SpeakingSkillView from "pages/product/SpeakingSkill/SpeakingSkillView";
import WritingSkillView from "pages/product/WritingSkill/WritingSkillView";
import EditPersonalInterview from "pages/product/EditPersonalInterview";

export const sharedRoutes = [
  {
    type: ["student", "school", "admin", "staff"],
    path: "/student/career-plan/edit",
    component: CareerPlanEdit,
    exact: true,
    protect: true,
  },
  {
    type: ["school", "admin", "staff"],
    path: "/student/career-plan",
    component: CareerPlanView,
    exact: true,
    protect: true,
  },
  {
    type: ["student", "school"],
    path: "/buy-products",
    component: Products,
    exact: true,
    protect: true,
  },
  {
    type: ["student", "school", "admin", "staff"],
    path: "/Settings",
    component: Settings,
    exact: false,
    protect: true,
  },
  {
    type: ["student", "school", "admin", "staff"],
    path: "/MyProfile",
    component: MyProfile,
    exact: false,
    protect: true,
  },
  {
    type: ["student", "school"],
    path: "/buy-products/checkout/:paymentId",
    component: Checkout,
    exact: false,
    protect: true,
  },

  {
    type: ["student", "school", "admin", "staff"],
    path: "/interview",
    component: Personalinterview,
    exact: false,
    protect: false,
  },
  {
    type: ["student", "school", "admin", "staff"],
    path: "/speaking-skill",
    component: SpeakingSkillView,
    exact: false,
    protect: false,
  },
  {
    type: ["student", "school", "admin", "staff"],
    path: "/writing-skill",
    component: WritingSkillView,
    exact: false,
    protect: false,
  },
  {
    type: ["student", "school", "admin", "staff"],
    path: "/edit/interview",
    component: EditPersonalInterview,
    exact: false,
    protect: false,
  },
];
