import {
  Heading,
  HStack,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import { isInteger } from "formik";
import { useEffect, useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import RichTextEditorModal from "./RichTextEditorModal";
import ReadTextModal from "./ReadTextModal";
import { addUpdateData, getWritingSkill } from "API/writingSkillApi";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "app/hook";

const WritingSkillView = () => {
  const location = useLocation();
  const toast = useToast();
  const [data, setData] = useState([
    {
      index: "1",
      topic: "LETTER",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "2",
      topic: "School",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "3",
      topic: "Government office",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "4",
      topic: "Covering letter for job",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "5",
      topic: "COMPOSITION",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "6",
      topic: "Description",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "7",
      topic: "Narration",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "8",
      topic: "Exposition",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "9",
      topic: "Argumentation",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "10",
      topic: "PRECIS WRITING (summary or a gist)",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "11",
      topic: "Critical",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "12",
      topic: "Rhetorical",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "13",
      topic: "Research",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "14",
      topic: "Literary",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "15",
      topic: "ESSAY",
      ans: "",
      marks: "",
      remarks: "",
    },
    {
      id: "16",
      topic: "ANSWER WRITING",
      ans: "",
      marks: "",
      remarks: "",
    },
  ]);

  const [user, setUser] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReadModalOpen, setIsReadModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [orginalData, setOriginalData] = useState([]);

  let { role, user: currentUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (role === "student") {
      setUser(currentUser);
    } else {
      setUser(location.state?.item);
    }
  }, [role, currentUser, location.state]);

  const hasAccess = user?.activePrograms?.some((program) =>
    program.purchasedProduct?.product?.access?.includes("writingSkill")
  );

  const { data: writingSkillData } = useQuery(
    ["writingSkillData"],
    () => getWritingSkill(user),
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    if (
      writingSkillData?.data?.topics &&
      writingSkillData?.data?.topics?.length !== 0
    ) {
      setData(writingSkillData?.data?.topics);
    }
  }, [writingSkillData]);

  const updateMutation = useMutation(
    (newSKillData) => addUpdateData(newSKillData, user),
    {
      onSuccess: (data) => {
        // console.log(data);
        toast({
          title: "Updated successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: "Error! Try Again later",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      },
    }
  );

  const handleUploadClick = (index) => {
    setSelectedData(data[index]["ans"]);
    setSelectedIndex(index);
    setIsModalOpen(true);
  };

  const handleSave = (text) => {
    const updatedData = [...data];
    updatedData[selectedIndex].ans = text;
    updateMutation.mutate(updatedData);
    setData(updatedData);
    setSelectedData(null);
    setIsModalOpen(false);
  };

  const handleReadClick = (index) => {
    setSelectedIndex(index);
    setSelectedData(data[index].ans);
    setIsReadModalOpen(true);
  };

  const handleEditMarks = (index, value) => {
    setData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, marks: value } : item
      )
    );
  };
  const handleEditRemarks = (index, value) => {
    setData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, remarks: value } : item
      )
    );
  };

  const handleEditChanges = () => {
    updateMutation.mutate(data);
    setIsEditing(false);
  };

  return (
    <>
      {!hasAccess ? (
        <HStack placeItems="center">
          <Heading color={"red"}>No Data Available</Heading>
        </HStack>
      ) : (
        <VStack align="start" w="full" p={4}>
          <HStack width={"100%"} justifyContent={"space-between"}>
            <Text as="h2" fontSize="xl" fontWeight="bold">
              Writing Skills L-3
            </Text>
            <HStack>
              <Button
                colorScheme="teal"
                onClick={
                  isEditing
                    ? handleEditChanges
                    : () => {
                        setIsEditing(true);
                        setOriginalData(data);
                      }
                }
              >
                {isEditing ? (
                  <Text>Save</Text>
                ) : (
                  <>
                    <Text mr={"5px"}>Update Marks/Remarks</Text> <FaEdit />
                  </>
                )}
              </Button>

              {isEditing ? (
                <Button
                  colorScheme="teal"
                  onClick={() => {
                    setData(orginalData);
                    setOriginalData([]);
                    setIsEditing(false);
                  }}
                >
                  <Text>Cancel</Text>
                </Button>
              ) : null}
            </HStack>
          </HStack>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr bg={"#d0cece"} border={"2px"} borderColor={"#a9a9a9"}>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                  textAlign={"center"}
                >
                  N O.
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                  textAlign={"center"}
                >
                  TOPIC
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                  textAlign={"center"}
                >
                  Read
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                  textAlign={"center"}
                >
                  Edit
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                  textAlign={"center"}
                >
                  MARKS
                </Th>
                <Th
                  color={"black"}
                  borderRight={"2px"}
                  style={{
                    borderColor: "#a9a9a9",
                  }}
                  textAlign={"center"}
                >
                  REMARK
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((topic, index) => (
                <Tr key={index} border={"2px"} borderColor={"#a9a9a9"}>
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                  >
                    {index + 1}
                  </Td>
                  <Td
                    paddingLeft={"5px"}
                    paddingY={"5px"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                    color={isInteger(topic.id) ? "#c8462b" : "black"}
                    fontWeight={isInteger(topic.id) ? "500" : "0"}
                  >
                    {topic.topic}
                  </Td>
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                    color={"white"}
                    bg={"#c00000"}
                  >
                    <FaEye
                      cursor="pointer"
                      size={"20px"}
                      style={{
                        margin: "5px auto",
                      }}
                      onClick={() => handleReadClick(index)}
                    />
                  </Td>
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                    color={"white"}
                    bg={"#00b050"}
                  >
                    <FaEdit
                      size={"20px"}
                      cursor="pointer"
                      style={{
                        margin: "5px auto",
                      }}
                      onClick={() => handleUploadClick(index)}
                    />
                  </Td>
                  <Td
                    padding={0}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                    bg={"#ffe699"}
                  >
                    {isEditing ? (
                      <Input
                        value={topic.marks ? topic.marks : ""}
                        onChange={(e) => handleEditMarks(index, e.target.value)}
                        size="sm"
                        width={"50px"}
                        backgroundColor={"white"}
                      />
                    ) : (
                      <Text fontWeight={"bold"}>{topic.marks}</Text>
                    )}
                  </Td>
                  <Td
                    padding={"5px 0px"}
                    textAlign={"center"}
                    borderRight={"2px"}
                    style={{
                      borderColor: "#a9a9a9",
                    }}
                    bg={"#ffe699"}
                  >
                    {isEditing ? (
                      <Input
                        value={topic.remarks ? topic.remarks : ""}
                        onChange={(e) =>
                          handleEditRemarks(index, e.target.value)
                        }
                        width={"fit-content"}
                        backgroundColor={"white"}
                      />
                    ) : (
                      <Text>{topic.remarks}</Text>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </VStack>
      )}
      <RichTextEditorModal
        isOpen={isModalOpen}
        onClose={() => {
          setSelectedIndex(null);
          setSelectedData(null);
          setIsModalOpen(false);
        }}
        onSave={handleSave}
        data={selectedData}
        setSelectedIndex={setSelectedIndex}
        setSelectedData={setSelectedData}
      />
      <ReadTextModal
        isOpen={isReadModalOpen}
        onClose={() => {
          setSelectedIndex(null);
          setSelectedData(null);
          setIsReadModalOpen(false);
        }}
        data={selectedData}
      />
    </>
  );
};

export default WritingSkillView;
