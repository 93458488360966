import { Avatar, Box, Container, Divider, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import StaticFooter from "./StaticFooter";
import takidarprofile from "assets/images/takidar-photo.jpg";

const Takidar = () => {
    return (
        <>
            <Container py={["24px", "80px"]} maxW="container.md">
                <Box border={'1px'} borderColor={'gray.100'} borderRadius={'15px'}>
                    <Stack direction={'row'} spacing={'0px'} p="4" alignItems={'center'}>
                        <Box>
                            <Avatar size='2xl' name='Jaleeluzzama B. Takidar' src={takidarprofile} />{' '}
                        </Box>
                        <Box pl={'20px'} flex={'1'}>
                            <Text fontSize={'20px'} fontWeight={'bold'} color={'purple'}>Jaleeluzzama B. Takidar</Text>
                            <Text fontWeight={'bold'}>Director Technology and Finance</Text>
                            <Text>B.E.(Elec & Telecom), MMS, FIE, Cert. Engr.</Text>
                        </Box>
                    </Stack>
                    <Divider />
                    <Stack p="4" spacing={'4'}>
                        <Text fontWeight={'bold'}>HIGHLIGHTS OF EXPERIENCE</Text>
                        <UnorderedList pl="16px">
                            <ListItem>40 years of Corporate Experience in the field of Information Technology in reputed Manufacturing companies, like Bajaj Auto Ltd, KSB Pumps Ltd (German Multinational), etc.</ListItem>
                            <ListItem>Management: (IT department, IT infrastructure, Team, Vendor, IT Service, IT operations, Application development and support, Office systems, Enterprise wide networking and messaging, Change and conflict)</ListItem>
                            <ListItem>Projects Management (Enterprise wide): (Planning, budgeting, staffing, expenses control, implementation, multiple external partners, support after go-live )</ListItem>
                            <ListItem>Strategy formulation and implementation: (Aligning IT strategy with Business strategy, Master Data ownership, Enterprise wide Common systems, Messaging and information interchange, Adopting Group’s Global IT strategy for Security, Control and Audit for India)</ListItem>
                            <ListItem>Team Building and Development: (BPR technique to improve productivity, mentoring, subordinates development to take up higher / additional responsibilities)</ListItem>
                            <ListItem>Project Guide: In capacity as Fellow of Institute of Engineers (India) - Project Guide for AMIE students of Electronics and Computers Engineering branch.</ListItem>
                            <ListItem>Certified auditor for auditing ISO processes.</ListItem>
                            <ListItem>Business visit to USA, Germany, Hong Kong</ListItem>
                            <ListItem>Project Manager for SAP implementation (end to end) at KSB Pumps Ltd – included implementation strategy, planning, coordinating with external SAP consultants and German SAP and Business leads, budgeting and expenses control, business core team formation and management for data preparation and business process mapping, implementation, go-live and support after go-live.</ListItem>
                            <ListItem>Advisor and Project Manager for sister concern, MIL Controls Ltd for ERP optimizing and enhancements, Database and application performance improvements and KSB Group’s Global IT strategy implementation</ListItem>
                            <ListItem>Consultant – IT Projects for development of web based applications with interface to corporate SAP to support Marketing strategy</ListItem>
                            <ListItem>IT lead in the Project to set up all IT and connectivity requirements at KSB Tech, KSB Group’s technical design center at Pune. Also liaised with government agency and obtained STPI approval for this 100% export oriented unit.</ListItem>
                        </UnorderedList>
                        
                    </Stack>
                </Box>
            </Container>
            <StaticFooter />
        </>
    )
}

export default Takidar
