import { Flex, Text } from "@chakra-ui/react";
import { intervalToDuration } from "date-fns";
import { FC, useEffect } from "react";
import { FaClock } from "react-icons/fa";
import { appendZerotoString } from "utils/functions";

interface TimerProps {
  setTimer: React.Dispatch<React.SetStateAction<number>>;
  timer: number;
}

const Timer: FC<TimerProps> = ({ timer, setTimer }) => {
  // const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [setTimer]);
  const { minutes, seconds, hours } = intervalToDuration({
    start: 0,
    end: timer * 1000,
  });
  return (
    <Flex
      justify="center"
      align="center"
      bg="orange"
      px="3"
      h={'40px'}
      borderRadius="md"
      flexShrink="0"
    >
      <FaClock fontSize={20} />
      <Text ml="2" p="0">{`${appendZerotoString(hours!)}: ${appendZerotoString(
        minutes!
      )}: ${appendZerotoString(seconds!)}`}</Text>
    </Flex>
  );
};
export default Timer;
