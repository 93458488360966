import AddAndUpdateStaff from "pages/dashboard/shared/Users/Staffs/Add&UpdateStaffForm";

const AdminUpdateStaffs = () => {
  return (
    <AddAndUpdateStaff
      redirectTo={`/admin/dashboard/users/staffs`}
      isUpdate={true}
    />
  );
};

export default AdminUpdateStaffs;
