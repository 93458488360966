import { Flex, Stack, Badge, Text } from "@chakra-ui/react";
import { useAppSelector } from "app/hook";
import { FC } from "react";
import { Link } from "react-router-dom";

interface TestProgramProps {
  program: IAllProgram;
}

const TestProgram: FC<TestProgramProps> = ({ program }) => {
  const { role } = useAppSelector((state) => state.auth);
  return (
    <>
      <Flex
        p="4"
        minH="16"
        key={program._id}
        boxShadow="xl"
        borderRadius="xl"
        justify="space-between"
        align="center"
      >
        <Stack as={Link} to={`/${role}/dashboard/test-manager/${program._id}`}>
          <Text>{program.name}</Text>
          <Flex>
            {program.subjects.map((subject) => (
              <Badge
                key={subject._id}
                display="flex"
                alignItems="center"
                mr="2"
                colorScheme="teal"
              >
                {subject.name}
              </Badge>
            ))}
          </Flex>
        </Stack>
      </Flex>
    </>
  );
};
export default TestProgram;
