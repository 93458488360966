import { Button } from "@chakra-ui/react";
import { FC } from "react";
import { useHistory } from "react-router-dom";

interface TestWaitingProps {}

const TestWaiting: FC<TestWaitingProps> = () => {
  const history = useHistory();
  return (
    <>
      Your test has not been reviewed yet. Please ask your parent or teacher to review it.{" "}
      <Button
        onClick={() => {
          history.goBack();
          console.log(history.goBack());
        }}
      >
        Go Back
      </Button>
    </>
  );
};
export default TestWaiting;
