import { createSlice } from "@reduxjs/toolkit";
// import type { RootState } from "app/store";

// Define a type for the slice state


// Define the initial state using that type
const initialState = {
  school: [{
    name:"",
    _id:""
  }],
  schoolId: "",
};

export const schoolSlice = createSlice({
  name: "school",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSchool: (
      state,
      action
    ) => {
      state.school = action.payload;
    },
    setSchoolId: (state, action) => {
      state.schoolId = action.payload;
    },
  },
});

export const { setSchool, setSchoolId } = schoolSlice.actions;

export default schoolSlice.reducer;
