import { Heading, Stack, Text, Flex, Grid } from "@chakra-ui/react";
import { setQuestionAnswer } from "app/features/question/questionSlice";
import { useAppDispatch } from "app/hook";
import { Formik, Form } from "formik";
import { FC, useEffect, useState } from "react";
import PrevAndNextButton from "./PrevAndNextButton";

interface MCQMQuestionProps {
  question: IAnsweredMCQMQuestion;
  prevQuestionHandler: () => void;
  nextQuestionHandler: () => void;
  index: number;
  isLastQuestion: boolean;
  setIsLastQuestion: React.Dispatch<React.SetStateAction<boolean>>;
}
const MCQMQuestion: FC<MCQMQuestionProps> = ({
  question,
  prevQuestionHandler,
  nextQuestionHandler,
  index,
  isLastQuestion,
  setIsLastQuestion,
}) => {
  const [mcqMAnswer, setMcqMAnswer] = useState<number[]>(
    question?.mcqmSubmittedAnswer ?? []
  );
  useEffect(() => {
    setMcqMAnswer(question?.mcqmSubmittedAnswer ?? []);
  }, [question]);
  const dispatch = useAppDispatch();
  return (
    <>
      <Heading fontSize="md" fontWeight="semibold">
        Choose correct options
      </Heading>

      <Formik
        enableReinitialize
        initialValues={{ mcqMOptions: question?.mcqMOptions, isPrev: false }}
        onSubmit={(values) => {
          if (mcqMAnswer === question.mcqMAnswer) {
            dispatch(
              setQuestionAnswer({
                index,
                mcqmSubmittedAnswer: mcqMAnswer,
                isCorrect: true,
                marks: question.positiveMarks,
                attempted: true,
              })
            );
          } else if (mcqMAnswer.length === 0) {
            dispatch(
              setQuestionAnswer({
                index,
                attempted: false,
              })
            );
          } else {
            dispatch(
              setQuestionAnswer({
                index,
                mcqmSubmittedAnswer: mcqMAnswer,
                isCorrect: false,
                marks: -question.negativeMarks,
                attempted: true,
              })
            );
          }
          values.isPrev ? prevQuestionHandler() : nextQuestionHandler();
          if (isLastQuestion) {
            setIsLastQuestion(true);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Stack>
              {values.mcqMOptions.map((option, index) => (
                <Flex
                  key={index}
                  name={`mcqMOptions.${index}`}
                  align="center"
                  onClick={() => {
                    setMcqMAnswer((prev) => {
                      if (prev.includes(index)) {
                        return prev.filter((answer) => answer !== index);
                      }

                      return [...prev, index];
                    });
                  }}
                >
                  <Grid
                    placeItems="center"
                    boxSize={"50px"}
                    borderRadius="xl"
                    flexShrink="0"
                    color={mcqMAnswer?.includes(index) ? "white" : "black"}
                    bg={mcqMAnswer?.includes(index) ? "teal.400" : "gray.200"}
                  >
                    {String.fromCharCode(65 + index)}
                  </Grid>{" "}
                  <Text ml="4">{option}</Text>
                </Flex>
              ))}
            </Stack>

            <PrevAndNextButton setFieldValue={setFieldValue} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MCQMQuestion;
