import SchoolPurchasedProduct from "./SchoolPurchasedProduct/SchoolPurchasedProduct";

const SchoolDashboard = () => {
  return (
    <>
      <SchoolPurchasedProduct />
    </>
  );
};

export default SchoolDashboard;
