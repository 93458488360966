import { Flex, Text, VStack } from "@chakra-ui/react";
import { getPersonalInterviewData } from "API/personalInterviewApi";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

const ConcludingInterview = ({ user }) => {
  const [concludingQuestions, setConcludingQuestions] = useState([
    {
      label: "Enforcing on your core capabilities & skills which you missed",
      values: "",
    },
    { label: "Knowledge", values: "" },
    { label: "Skills", values: "" },
    { label: "Attitude", values: "" },
    { label: "Values", values: "" },
    { label: "Ethics", values: "" },
    { label: "Achievements", values: "" },
    { label: "Value addition", values: "" },
    { label: "Creativity", values: "" },
  ]);

  const { data: personalInterviewData } = useQuery(
    ["personalInterviewData"],
    () => getPersonalInterviewData(user)
  );

  useEffect(() => {
    if (
      personalInterviewData?.data?.concludingQuestions &&
      personalInterviewData?.data?.concludingQuestions.length !== 0
    ) {
      const newFields = [];
      personalInterviewData?.data?.concludingQuestions.map((field) =>
        newFields.push({ label: field.label, values: field.values })
      );
      setConcludingQuestions(newFields);
    }
  }, [personalInterviewData]);

  return (
    <VStack
      alignItems={"flex-start"}
      w={"100%"}
      paddingX={"25px"}
      marginBottom={"10px"}
    >
      <Text as={"b"} fontSize={"20px"}>
        Concluding Questions
      </Text>
      <Flex
        flexDirection={"row"}
        flexWrap={"wrap"}
        w={"100%"}
        justifyContent={"space-between"}
      >
        {concludingQuestions.map((data, index) => (
          <Text
            key={index}
            w={"30%"}
            color={"black"}
            style={{
              marginTop: "5px",
            }}
          >
            <Text
              display={"inline"}
              fontWeight={600}
            >{`${data.label} : `}</Text>{" "}
            {data.values === "" ? "N/A" : data.values}
          </Text>
        ))}
      </Flex>
    </VStack>
  );
};

export default ConcludingInterview;
