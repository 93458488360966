import { InputGroup } from "@chakra-ui/input";
import { Box } from "@chakra-ui/layout";
import {
  IconButton,
  InputRightElement,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from "@reduxjs/toolkit";
import { filterDefaultState } from "app/features/questionFilter/questionFilterSlice";
import { useAppDispatch } from "app/hook";
import { Formik, Form } from "formik";
import { InputControl } from "formik-chakra-ui";
import { FC, useEffect } from "react";
import { GoSearch, GoX } from "react-icons/go";

interface TableSearchProps {
  setSearchText: ActionCreatorWithPayload<string, string>;
  setPageDefault: ActionCreatorWithoutPayload<string>;
  searchText: string;
  searchLabel?: string;
}

const TableSearch: FC<TableSearchProps> = ({
  setPageDefault,
  searchText,
  setSearchText,
  searchLabel,
}) => {
  const dispatch = useAppDispatch();
  const { onToggle, isOpen } = useDisclosure({ defaultIsOpen: true });
  function showClearbtn() {
    onToggle();
  }
  function showSearchbtn() {
    dispatch(setSearchText(""));
    dispatch(filterDefaultState());
    onToggle();
  }
  useEffect(() => {
    return ()=>{
      dispatch(setSearchText(""))
    } 
  }, [dispatch,setSearchText])
  
  return (
    <Box
      pe="{[0, 6]}"
      w="100%"
    >
      <Formik
        enableReinitialize={isOpen}
        initialValues={{ search: searchText }}
        onSubmit={(values) => {
          dispatch(setSearchText(values.search));
          dispatch(setPageDefault());

          showClearbtn();
        }}
      >
        <Form>
          <InputGroup size="sm">
            <InputControl
              name="search"
              inputProps={{
                w: "full",
                size: "sm",
                rounded: "md",
                bg:"white",
                placeholder: searchLabel ?? "Search",
              }}
            />
            {isOpen ? (
              <IconButton
                ml="2"
                aria-label="search"
                size="sm"
                type="submit"
                colorScheme="teal"
                icon={<GoSearch color="gray.50" />}
              />
            ) : (
              <InputRightElement
                ml="2"
                aria-label="clear search"
                size="sm"
                onClick={showSearchbtn}
              >
                <Icon as={GoX} />
              </InputRightElement>
            )}
          </InputGroup>
        </Form>
      </Formik>
    </Box>
  );
};
export default TableSearch;
