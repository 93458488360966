import { axios } from "./AxiosInstance"

export const submitContact = async ({ email, mobile, message, name }: {
    name: string;
    email: string;
    message: string;
    mobile: number;
}) => {
    try {
        const { data } = await axios.post(`/contact`, {
            name,
            message,
            email,
            mobile,
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const updateEmail = async ({ email, oldEmail, password }: {
    oldEmail: string;
    email: string;
    password: string;
}) => {
    try {
        const { data } = await axios.post(`/update-email/`, {
            email,
            oldEmail,
            password
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};