import { axios } from "./AxiosInstance";

export const addChapter = async ({
  programId,
  subjectId,
  chapterName,
}: {
  programId: string;
  subjectId: string;
  chapterName: string;
}) => {
  try {
    const { data } = await axios.post(`/chapter/add`, {
      programId,
      subjectId,
      chapterName,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const deleteChapterById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/chapter/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateChapter = async ({
  id,
  chapterName,
}: {
  id: string;
  chapterName: string;
}) => {
  try {
    const { data } = await axios.patch(`/chapter`, {
      chapterName,
      chapterId: id,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
