import {
  Box,
  Heading,
  HStack,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  decrementPage,
  incrementPage,
  setPageDefault,
  setSearchText,
  setSize,
} from "app/features/userFilter/userFilter";
import PaymentHistoryTable from "./PaymentHistory/PaymentHistoryTable";
import { getPayments } from "API/paymentApi";
import { useQuery } from "react-query";
import TablePaginator from "components/dumb/Table/TablePaginator";
import { useAppSelector } from "app/hook";
import TableSearch from "components/dumb/Table/TableSearch";
const AdminDashboard = () => {
  const { size, page, searchText } = useAppSelector(
    (state) => state.userFilter
  );


  const { data, isLoading } = useQuery<{
    page: number;
    totalPage: number;
    count: number;
    payments: IPayment[];
  }>(["payments", page, size, searchText], () =>
    getPayments({ page, size, searchText })
  );

  return (
    <>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">Dashboard</Heading>
        {/* <Button>Export to CSV</Button> */}
      </HStack>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
        pos="relative"
      >
        <Stack bg="gray.50" p="4" spacing={0}>
        <TableSearch
              searchLabel="Search Order ID ..."
              searchText={searchText}
              setPageDefault={setPageDefault}
              setSearchText={setSearchText}
            />
        </Stack>

        {data && (
          <>
            <PaymentHistoryTable
              payments={data?.payments}
              isLoading={isLoading}
            />{" "}
            <TablePaginator
              count={data?.count}
              decrementPage={decrementPage}
              incrementPage={incrementPage}
              page={data?.page}
              setPageDefault={setPageDefault}
              setSize={setSize}
              size={size}
              totalPages={data?.totalPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default AdminDashboard;
