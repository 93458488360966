import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import StaticFooter from "./StaticFooter";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton, TextareaControl } from "formik-chakra-ui";
import { FcCustomerSupport, FcDepartment } from "react-icons/fc";
import { submitContact } from "API/sharedApi";
import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/toast";
import useErrorToast from "hook/error-toast";
import { verifyCaptcha } from "API/authApi";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

const ContactUs = () => {
  const toast = useToast();

  const { error, mutateAsync } = useMutation(submitContact, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Email successfully sent to recipient",
        description: `we will get back to you shortly`,
      });
    },
  });
  useErrorToast(error);
  const [isDisabled, setIsDisabled] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const onChange = async (value: any) => {
    setRecaptchaValue(value);
    try {
      const data = await verifyCaptcha({ recaptchaValue });
      data.message === "success" ? setIsDisabled(false) : setIsDisabled(true);

    } catch (error) {

    }
  }

  return (
    <>
      <Box bg="purple">
        <Container py={["24px", "80px"]} maxW="container.md" color={"white"}>
          <Heading as="h1" mb="6" fontSize={["30px", "45px"]}>
            Need Help?
          </Heading>
          <Text>
            Please use the form below to contact us. <br />
            We will never spam you, or sell your email to third parties.
          </Text>
        </Container>
      </Box>
      <Container py={["24px", "80px"]} maxW="container.md">
        <Stack
          spacing={["15px", "24px"]}
          direction={["column", "row"]}
          mb="30px"
        >
          <Flex w={["100%", "50%"]} bg="gray.100" borderRadius={"4px"} p="4">
            <Square size="40px">
              <Icon as={FcCustomerSupport} w={12} h={12} />
            </Square>
            <Box flex="1" pl={"3"}>
              <Link href="tel:9960261168">+91 9960261168</Link>
              <br />
              <Link href="mailto:contact@kpexam.com">contact@kpexam.com</Link>
            </Box>
          </Flex>
          <Flex w={["100%", "50%"]} bg="gray.100" borderRadius={"4px"} p="4">
            <Square size="40px">
              <Icon as={FcDepartment} w={12} h={12} />
            </Square>
            <Box flex="1" pl={"3"}>
              <Text>
                Clover Citadel, Salunke Vihar, Wanawari, Pune, Maharashtra
                411040
              </Text>
            </Box>
          </Flex>
        </Stack>
        <Divider mb="20px" />
        <Formik
          initialValues={{ name: "", email: "", mobile: "", message: "" }}
          onSubmit={async (values) => {
            try {
              await mutateAsync({ ...values, mobile: Number(values.mobile) });
            } catch (error) {}
          }}
        >
          <Form>
            <Stack spacing={"20px"}>
              <InputControl isRequired name="name" label="Your name" />
              <InputControl
                inputProps={{ type: "email" }}
                isRequired
                name="email"
                label="Your email"
              />
              <InputControl
                inputProps={{ type: "number" }}
                isRequired
                name="mobile"
                label="Your contact no."
              />

              <TextareaControl name="message" label="Message" />
              <Box>
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY??""}
                              onChange={onChange}
                            />
                          </Box>
              <SubmitButton isDisabled={isDisabled} w={"100%"} colorScheme="teal">
                Submit
              </SubmitButton>
            </Stack>
          </Form>
        </Formik>
      </Container>
      <StaticFooter />
    </>
  );
};

export default ContactUs;
