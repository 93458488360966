import { axios } from "./AxiosInstance";

interface IFilterQuestion {
  page?: number;
  size?: number;
  questionType?: string;
  level?: string;
  subject?: string;
  searchText?: string;
}
interface IAdderQuestion {
  page?: number;
  size?: number;
  questionType?: string;
  level?: string;
  subjectId: string;
  searchText?: string;
  programId: string;
}
interface IAddQuestion {
  file: any;
  questionType: string;
  subject: string;
}
interface IUpdateLAQuestion {
  _id: string | undefined;
  question: string;
  positiveMarks: number;
  negativeMarks: number;
  answerJustification: string;
  tags: string[];
  level: QuestionLevel;
  type: string;
}
interface IUpdateTFQuestion {
  _id: string | undefined;
  question: string;
  positiveMarks: number;
  negativeMarks: number;
  answerJustification: string;
  tags: string[];
  level: QuestionLevel;
  type: string;
  tFAnswer: string;
}

interface IUpdateMCQSQuestion {
  question: string;
  positiveMarks: number;
  negativeMarks: number;
  answerJustification: string;
  tags: string[];
  level: QuestionLevel;
  mcqSOptions: string[];
  mcqSAnswer: number;
}

interface IUpdateMCQMQuestion {
  question: string;
  positiveMarks: number;
  negativeMarks: number;
  answerJustification: string;
  tags: string[];
  level: QuestionLevel;
  mcqMAnswer: number[];
  mcqMOptions: string[];
}

export const filterQuestion = async ({
  page,
  size,
  questionType,
  level,
  subject,
  searchText,
}: IFilterQuestion) => {
  try {
    const { data } = await axios.post(`/question/filter`, {
      page,
      size,
      questionType,
      level,
      subject,
      searchText,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const questionAdderFilter = async ({
  page,
  size,
  level,
  questionType,
  subjectId,
  searchText,
  programId,
}: IAdderQuestion) => {
  try {
    const { data } = await axios.post(`/question/adder-filter`, {
      questionType,
      level,
      subjectId,
      programId,
      searchText,
      page,
      size,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const addQuestions = async ({
  file,
  questionType,
  subject,
}: IAddQuestion) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("questionType", questionType);
    formData.append("subjectName", subject);
    const { data } = await axios.post(`/question/add`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const fetchQuestionById = async (id: string, type: string) => {
  try {
    const { data } = await axios.get(`/question/${type}/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateLAQuestion = async (values: IUpdateLAQuestion) => {
  try {
    const { data } = await axios.patch(`/question`, values);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateMCQSQuestion = async (values: IUpdateMCQSQuestion) => {
  try {
    const { data } = await axios.patch(`/question`, values);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateMCQMQuestion = async (values: IUpdateMCQMQuestion) => {
  try {
    const { data } = await axios.patch(`/question`, values);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateTFQuestion = async (values: IUpdateTFQuestion) => {
  try {
    const { data } = await axios.patch(`/question`, values);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

// export const deleteQuestionById = async (id: string) => {
//   try {
//     const { data } = await axios.delete(`/question/${id}`);
//     return data;
//   } catch (error: any) {
//     throw Error(error.response.data.message);
//   }
// };

export const deleteQuestionsById = async (ids: string[]) => {
  try {
    const { data } = await axios.post(`/question/deleteMany`, { ids });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
