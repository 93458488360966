import {
  Badge,
  Center,
  Box,
  Heading,
  Stack,
  Text,
  Button,
  useColorModeValue,
  Spinner,
  Link
} from "@chakra-ui/react";

import { getTestById } from "API/testApi";
import { getUserTestById } from "API/userTestApi";
import { format } from "date-fns";
import useErrorToast from "hook/error-toast";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link as RoLink, useHistory } from "react-router-dom";
import { useAppSelector } from "app/hook";


const StudentTest = () => {
  const { role } = useAppSelector((state) => state.auth);
  const borderClr = useColorModeValue("gray.100", "gray.700");
  const bgClr = useColorModeValue("red.50", "gray.700");
  const { testId, retest } = useParams<{ testId: string; retest: string }>();
  const isRetest = retest === "true" ? true : false;
  const [test, setTest] = useState<ITest>();
  const history = useHistory();
  const { isLoading, data, error } = useQuery<ITest | IUserTest>(
    [isRetest ? "retest" : "test", testId],
    () => (isRetest ? getUserTestById(testId) : getTestById(testId)),
    {
      onSuccess: (data) => {
        if (isRetest) {
          setTest((data as IUserTest).test);
        } else {
          setTest(data as ITest);
        }
      },
    }
  );
    console.log(testId);
    
  useErrorToast(error);
  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <Heading size="md">
            <Link color="teal.500" onClick={history.goBack}>
              Reports
            </Link>{" "}
            / {test?.name}
          </Heading>
          {(data as IUserTest)?.testAttempts === undefined && (
            <Box
              size="sm"
              textAlign={"center"}
              py={"100px"}
              border="1px"
              width="100%"
              borderColor={borderClr}
              borderRadius="md"
              my="20px"
            >
              <Text fontSize={"20px"} m="auto" py={"20px"} w={["90%", "50%"]}>
                You have been not attempted test yet.
              </Text>
            </Box>
          )}
          {(data as IUserTest).testAttempts && (
            <Box
              my="20px"
              border="1px"
              width="100%"
              borderColor={borderClr}
              borderRadius="md"
            >
              <Stack
                w="100%"
                backgroundColor={bgClr}
                padding="20px"
                direction={["column", "row"]}
                align="flex-start"
                justifyContent="space-between"
              >
                <Stack>
                  {/* <Text> Total Marks: 100</Text> */}
                  <Text>
                    Subject:
                    <Text as="span" textTransform={"capitalize"}>
                      {" "}
                      {test?.subject?.name}
                    </Text>
                  </Text>
                  <Text>
                    Total Question: {(data as IUserTest)?.test?.totalQuestions}
                  </Text>
                </Stack>

                <Stack>
                  <Text>
                    Avg. Time:{" "}
                    {new Date(
                      Math.floor(
                        (data as IUserTest).testAttempts.reduce(
                          (total, next) => total + next.timeTaken,
                          0
                        ) / (data as IUserTest).testAttempts.length
                      ) * 1000
                    )
                      .toISOString()
                      .substr(14, 5)}
                  </Text>
                  <Text>
                    {" "}
                    Avg. marks obtained:{" "}
                    {Math.ceil(
                      (data as IUserTest).testAttempts.reduce(
                        (total, next) => total + next.totalMarks,
                        0
                      ) / (data as IUserTest).testAttempts.length
                    )}
                  </Text>
                </Stack>

                <Stack>
                  <Text>
                    No. of attempt: {(data as IUserTest)?.testAttempts.length}
                  </Text>
                  <Text>
                    Level:
                    <Badge
                      ml="1"
                      variant="solid"
                      colorScheme={
                        (data as IUserTest)?.test?.level === "easy"
                          ? "blue"
                          : (data as IUserTest)?.test?.level === "normal"
                          ? "purple"
                          : (data as IUserTest)?.test?.level === "difficult"
                          ? "orange"
                          : ""
                      }
                    >
                      {(data as IUserTest)?.test?.level}
                    </Badge>
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing="0">
                <Text
                  fontSize={"sm"}
                  p="4"
                  fontWeight={"bold"}
                  color="gray.500"
                >
                  THE TEST ATTEMPTS LIST
                </Text>
                {(data as IUserTest).testAttempts.map((attempt) => (
                  <Stack
                    spacing="0"
                    key={attempt._id}
                    direction={["column", "row"]}
                    borderTop={"1px"}
                    borderColor={"gray.100"}
                    p="4"
                  >
                    <Box flex="1">
                      <Heading fontSize="md" mb={["10px", "0px"]}>
                        {format(
                          new Date(attempt.testDate),
                          "MMM dd, yyyy hh:mm a"
                        )}
                      </Heading>
                      <Stack direction={["column", "row"]} spacing="1">
                        <Text>
                          Time takes to complete: {attempt.timeTaken}s
                        </Text>
                        <Box d={["none", "flex"]} color={"red.500"}>
                          •
                        </Box>
                        <Text>
                          Question attempted: {attempt.attemptedQuestion}
                        </Text>
                        <Box d={["none", "flex"]} color={"red.500"}>
                          •
                        </Box>
                        <Text>
                          Not attempted: {attempt.notAttemptedQuestion}
                        </Text>
                        <Box d={["none", "flex"]} color={"red.500"}>
                          •
                        </Box>
                        {attempt.isResultPending ? (
                          <Text color={"red"}>
                            Your test has not been reviewed yet
                          </Text>
                        ) : (
                          <>
                            <Text>Marks obtained: {attempt.totalMarks}</Text>
                            <Box d={["none", "flex"]} color={"red.500"}>
                              •
                            </Box>
                            <Text>Correct answer: {attempt.correctAnswer}</Text>
                            <Box d={["none", "flex"]} color={"red.500"}>
                              •
                            </Box>
                            <Text>Wrong answer: {attempt.wrongAnswer}</Text>
                          </>
                        )}
                      </Stack>
                    </Box>
                    <Box>
                      <RoLink
                        to={`/${role}/dashboard/test-review/${attempt._id}`}
                      >
                        <Button
                          isDisabled={attempt.isResultPending}
                          colorScheme="teal"
                          w={["100%", "auto"]}
                          mt={["10px", "0px"]}
                        >
                          Details
                        </Button>
                      </RoLink>
                    </Box>
                    <Stack></Stack>
                  </Stack>
                ))}
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default StudentTest;
