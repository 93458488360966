import { Badge, Box, Heading, Link } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Stack,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { FC, useState } from "react";
import TagInput from "components/TagInput";
import { useMutation, useQueryClient } from "react-query";
import { updateLAQuestion } from "API/questionApi";
import useErrorToast from "hook/error-toast";
import { useToast } from "@chakra-ui/toast";
import { Link as RoLink } from "react-router-dom";
interface UpdateQuestionLAProps {
  question: ILAQuestion;
  role: RoleType | null | undefined;
}

const UpdateQuestionLA: FC<UpdateQuestionLAProps> = ({ question, role }) => {
  const [tags, setTags] = useState<string[]>([...question?.tags]);
  const toast = useToast();
  const cache = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(updateLAQuestion, {
    onSuccess: () => {
      cache.invalidateQueries(["question", question._id]);
      toast({ title: "Question Updated Successfully", status: "success" });
    },
  });
  useErrorToast(error);
  return (
    <>
      <Heading size="md" mb="4">
        <Link as={RoLink} to={`/${role}/dashboard/questions`} color="teal.500">
          Questions
        </Link>{" "}
        / Edit Long Question
      </Heading>
      <HStack justify="space-between">
        <Badge>{question.subjectName}</Badge>

        {question.isPremium && <Badge colorScheme="yellow">Premium</Badge>}
      </HStack>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
      >
        <Formik
          initialValues={{
            question: question.question,
            positiveMarks: question.positiveMarks,
            negativeMarks: question.negativeMarks,
            answerJustification: question.answerJustification,
            tags: tags,
            level: question.level,
            questionImg: question?.questionImg,
            justificationImg: question?.justificationImg,
          }}
          onSubmit={async (values) => {
            values.tags = tags;
            const data = { ...values, _id: question._id, type: question.type };
            try {
              await mutateAsync(data);
            } catch (error) {}
          }}
        >
          <Form>
            <Stack direction={["column", "row"]} spacing="20px">
              <VStack
                w={{ base: "100%", lg: "30%" }}
                spacing="15px"
                padding="20px"
                bg={useColorModeValue("gray.50", "gray.700")}
              >
                <SelectControl
                  isRequired
                  name="level"
                  selectProps={{ rounded: "md" }}
                >
                  <option value="easy">Easy</option>
                  <option value="normal">Normal</option>
                  <option value="difficult">Difficult</option>
                </SelectControl>
                <TagInput tags={tags} setTags={setTags} />
              </VStack>
              <Box w={{ base: "100%", lg: "70%" }} padding="20px">
                <VStack>
                  <InputControl label="Question" name="question" />
                  <InputControl
                    label="Answer Justification"
                    name="answerJustification"
                  />
                </VStack>

                <VStack>
                  <InputControl
                    label="Positive Marks"
                    name="positiveMarks"
                    inputProps={{ type: "number" }}
                  />
                  <InputControl
                    label="Negative Marks"
                    name="negativeMarks"
                    inputProps={{ type: "number" }}
                  />
                </VStack>
                <Box>
                  <InputControl label="Question Image Url" name="questionImg" />
                  {question?.questionImg && 
                      <Box mb={'10px'}>
                    <Link rel="noreferrer" target={'_blank'} color="teal.600" href={question?.questionImg}>Preview image</Link>
                    </Box>
                    }
                  <InputControl
                    label="Justification Image Url"
                    name="justificationImg"
                  />
                  {question?.justificationImg && 
                      <Box mb={'10px'}>
                    <Link rel="noreferrer" target={'_blank'} color="teal.600" href={question?.justificationImg}>Preview image</Link>
                    </Box>
                    }
                </Box>
               
                <HStack direction="row" spacing={4} align="center" mt="20px">
                  <SubmitButton
                    type="submit"
                    colorScheme="teal"
                    variant="solid"
                    px="30px"
                    isLoading={isLoading}
                  >
                    Save
                  </SubmitButton>
                  <Button
                    as={RoLink}
                    to={`/${role}/dashboard/questions`}
                    type="reset"
                    colorScheme="teal"
                    variant="ghost"
                  >
                    Cancel
                  </Button>
                </HStack>
              </Box>
            </Stack>
          </Form>
        </Formik>
      </Box>
    </>
  );
};
export default UpdateQuestionLA;
