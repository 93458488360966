import {
  Spinner,
  Select,
  Button,
  useColorModeValue,
  Link,
  Badge,
  Divider,
  Grid,
  Heading,
  HStack,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";
import { getProgramById } from "API/programApi";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link as RoLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hook";
import { setProgram } from "app/features/program/programSlice";
interface StaffProgramProps {}

const StaffProgram: FC<StaffProgramProps> = () => {
  const borderClr = useColorModeValue("gray.100", "gray.700");
  const { subjectId } = useAppSelector((state) => state.programState);
  const { user: staff }: { user: IStaff } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();
  const {
    data: programData,
    isLoading,
    error,
  } = useQuery<IProgram>(["program", id, subjectId], () =>
    getProgramById(id, subjectId)
  );

  useErrorToast(error);

  return (
    <>
      {isLoading ? (
        <Grid placeItems="center" h="100vh">
          <Spinner />
        </Grid>
      ) : (
        <>
          <Heading size="md">
            <Link as={RoLink} to="/Staff/dashboard" color="teal.500">
              Dashboard
            </Link>{" "}
            / Program Details
          </Heading>
          <Stack direction={["column-reverse", "row"]} spacing="20px" my="20px">
            <Box
              border="1px"
              width="100%"
              borderColor={borderClr}
              borderRadius="md"
              padding="20px"
            >
              <Stack spacing="20px">
                <Select
                  placeholder="Select Subject"
                  rounded="md"
                  textTransform="capitalize"
                  value={subjectId}
                  onChange={(e: any) => {
                    console.log("slected");

                    dispatch(
                      setProgram({
                        program: programData,
                        subjectId: e.target.value,
                      })
                    );
                  }}
                >
                  {programData?.subjects?.map((e: ISubject, index) => {
                    if (!subjectId && index === 0) {
                      dispatch(
                        setProgram({
                          program: programData,
                          subjectId: e._id,
                        })
                      );
                    }
                    return (
                      <option key={e?.name} value={e._id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Select>
                <Divider my="4" />
                {subjectId && (
                  <>
                    <Stack spacing={4}>
                      {programData?.chapters.map((chapter, i) => (
                        <Stack key={chapter._id} spacing={2}>
                          <HStack pl="2">
                            <Badge colorScheme="green">Chapter</Badge>
                            <Text fontWeight="bold">{chapter.name}</Text>
                          </HStack>
                          <Stack>
                            {chapter.topics.map((topic, j) => (
                              <HStack pl="4" key={topic._id}>
                                <Badge colorScheme="pink">Topic</Badge>
                                <Text fontWeight="bold">{topic.name}</Text>
                              </HStack>
                            ))}
                          </Stack>
                          <Divider />
                        </Stack>
                      ))}
                    </Stack>
                  </>
                )}
              </Stack>
            </Box>
            <Box
              my="20px"
              width={["100%", "48%"]}
              border="1px"
              borderColor={borderClr}
              borderRadius="md"
              padding="40px"
            >
              <Stack spacing="20px" mb="5px">
                <Heading size="md">Program {programData?.name}</Heading>
                <Text>Duration: 6 months</Text>
                {/* <Text size="sm">
                  The detailed Maharashtra SSC board syllabus 10th for Maths is
                  tabulated below. Go through the same and prepare for the exam
                  in a planned way. The detailed Maharashtra SSC board syllabus
                  10th for Maths is tabulated below. Go through the same and
                  prepare for the exam in a planned way.
                </Text> */}
                {programData?.tests?.length !== 0 && (
                  <>
                    <Text as="span" fontWeight="bold">
                      No. of Tests: {programData?.tests?.length}
                    </Text>
                    <Button
                      colorScheme="teal"
                      variant="solid"
                      as={RoLink}
                      to={`/staff/dashboard/program/${programData?._id}/school/${staff.school._id}`}
                    >
                      Review tests
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
        </>
      )}
    </>
  );
};
export default StaffProgram;
