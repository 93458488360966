import React from "react";
import { useParams } from "react-router-dom";
import UpdateQuestionLA from "./UpdateQuestionLA";
import UpdateQuestionMCQM from "./UpdateQuestionMCQM";
import UpdateQuestionMCQS from "./UpdateQuestionMCQS";
import UpdateQuestionTF from "./UpdateQuestionTF";
import { Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchQuestionById } from "API/questionApi";
import { Flex } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import useErrorToast from "hook/error-toast";
import { useAppSelector } from "app/hook";

const UpdateQuestion = () => {
  const { type, id } = useParams<{ type: string; id: string }>();
  const { role } = useAppSelector((state) => state.auth);

  const { data, isLoading, error } = useQuery(["question", id], () => {
    if (type === undefined || id === undefined) {
      return <Redirect to={`/${role}/dashboard`} />;
    }

    return fetchQuestionById(id, type);
  });

  useErrorToast(error);

  if (isLoading) {
    return (
      <Flex justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }

  switch (type?.toLowerCase()) {
    case "tf":
      return <UpdateQuestionTF role={role} question={data} />;
    case "mcqs":
      return <UpdateQuestionMCQS role={role} question={data} />;
    case "mcqm":
      return <UpdateQuestionMCQM role={role} question={data} />;
    case "la":
      return <UpdateQuestionLA role={role} question={data} />;
    default:
      return <Redirect to="/admin/dashboard" />;
  }
};

export default UpdateQuestion;
