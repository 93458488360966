import {
  Heading,
  Text,
  HStack,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";
import ActiveProgram from "./ActiveProgram";

interface ActiveProgramListProps {
  school: ISchool;
  student: IStudent;
}

const ActiveProgramList: FC<ActiveProgramListProps> = ({ school, student }) => {
  return (
    <>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="md">Active Programs</Heading>
      </HStack>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
        pos="relative"
      >
        {student?.activePrograms?.length > 0 ? (
              student?.activePrograms?.map((activeProgram) => (
                <ActiveProgram
                  studentId={student?._id}
                  key={activeProgram?._id}
                  activeProgram={activeProgram}
                />
              ))
            ) : (
              <Text>No Active Program</Text>
            )}
      </Box>
    </>
    // <Accordion allowToggle>
    //   <AccordionItem>
    //     <AccordionButton>
    //       <Heading fontSize="xl" flex="1" textAlign="left">
    //         Active Programs
    //       </Heading>
    //       <AccordionIcon />
    //     </AccordionButton>

    //     <AccordionPanel pb={4}>
    //       <OrderedList>
    //         {student?.activePrograms?.length > 0 ? (
    //           student?.activePrograms?.map((activeProgram) => (
    //             <ActiveProgram
    //               studentId={student?._id}
    //               key={activeProgram?._id}
    //               activeProgram={activeProgram}
    //             />
    //           ))
    //         ) : (
    //           <Text>No Active Program</Text>
    //         )}
    //       </OrderedList>
    //     </AccordionPanel>
    //   </AccordionItem>
    // </Accordion>
  );
};
export default ActiveProgramList;
