import Topics from "./Topics";
import { Heading } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getActiveProgram } from "API/activeProgramApi";
import { useQuery } from "react-query";

interface SubjectProps {
  subject: ISubject;
  setvideosrc: (videosrc: string | undefined) => void;
  setPlayingVideoId: (videosrc: string | undefined) => void;
  playingVideoId: string | undefined;
  isdefaultavailable: boolean;
  setisdefaultavailable: (isdefaultvideoavailable: boolean) => void;
}

const Subject: FC<SubjectProps> = ({
  subject,
  setvideosrc,
  playingVideoId,
  setPlayingVideoId,
  isdefaultavailable,
  setisdefaultavailable,
}) => {
  const { id } = useParams<{ id: string }>();
  const { data: activeProgram } = useQuery<IActiveProgram>(
    ["activeProgram", id, subject?._id],
    () => getActiveProgram(id, subject?._id)
  );
  const [shouldshowsubject, setshouldshowsubject] = useState(false);
  useEffect(() => {
    const videourls: (string | undefined)[] = [];
    activeProgram?.program.chapters.forEach((chapter) => {
      chapter.topics.forEach((topic) => {
        videourls.push(topic.videolink);
      });
    });
    if (videourls.length > 0) {
      setshouldshowsubject(true);
    }
  }, [activeProgram]);
  return (
    <>
      {shouldshowsubject ? (
        <>
          {" "}
          <Heading
            textTransform={"capitalize"}
            size="md"
            padding={["2", "20px"]}
          >
            {subject?.name}
          </Heading>
          <Topics
            subjId={subject?._id}
            setvideosrc={setvideosrc}
            setPlayingVideoId={setPlayingVideoId}
            playingVideoId={playingVideoId}
            isdefaultavailable={isdefaultavailable}
            setisdefaultavailable={setisdefaultavailable}
          />
        </>
      ) : null}
    </>
  );
};
export default Subject;
