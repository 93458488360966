import {
  Box,
  Heading,
  useColorModeValue,
  Text,
  Stack,
  Link,
  Button,
  Image,
  Input,
} from "@chakra-ui/react";
import { Link as RoLink } from "react-router-dom";
import { GoCloudUpload } from "react-icons/go";
import importimg from "assets/images/import-csv.svg";
import { ErrorMessage, Form, Formik } from "formik";
import { SelectControl, SubmitButton } from "formik-chakra-ui";
import { useRef } from "react";
import { getSubjects } from "API/subjectApi";
import useErrorToast from "hook/error-toast";
import { useMutation, useQuery } from "react-query";
import { importQuestionValidationSchema } from "validation";
import { addQuestions } from "API/questionApi";
import { useToast } from "@chakra-ui/toast";
import { useAppSelector } from "app/hook";

const ImportQuestions = () => {
  const { role } = useAppSelector((state) => state.auth);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const toast = useToast();
  const { data, error } = useQuery("subjects", getSubjects);
  const {
    isLoading,
    error: questionAddError,
    mutateAsync,
  } = useMutation(addQuestions, {
    onSuccess: (data) => {
      toast({
        title: "Questions Imported From CSV File",
        description: data?.message,
        status: "success",
      });
    },
  });

  useErrorToast(error);
  useErrorToast(questionAddError);

  return (
    <>
      <Heading size="md">
        <Link as={RoLink} to={`/${role}/dashboard/questions`} color="teal.500">
          Questions
        </Link>{" "}
        / Import questions
      </Heading>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
        minH="400px"
        p="20px"
        textAlign="center"
      >
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          w={["100%", "400px"]}
          m="auto"
          py="30px"
        >
          <Image src={importimg} alt="Import CSV" boxSize="200px" />
          <Text color="gray.500" mb="15px !important">
            Select Subject , download sample excel sheet file, Fill the data and
            upload CSV file here
          </Text>

          <Formik
            validationSchema={importQuestionValidationSchema}
            initialValues={{ file: undefined, questionType: "", subject: "" }}
            onSubmit={async (values) => {
              try {
                await mutateAsync({
                  file: values.file,
                  questionType: values.questionType,
                  subject: values.subject,
                });
              } catch (error) {}
            }}
          >
            {({ setFieldValue, values, errors }) => (
              <Form>
                <SelectControl
                  name="questionType"
                  placeholder="Select Question Type"
                  defaultValue="TF"
                  mb="4"
                >
                  <option value="MCQS">MCQ (Single)</option>
                  <option value="MCQM">MCQ (Multiple)</option>
                  <option value="TF">True and False</option>
                  <option value="LA">Long Answer</option>
                </SelectControl>
                <SelectControl
                  name="subject"
                  selectProps={{
                    rounded: "md",
                    textTransform: "capitalize",

                    placeholder: "Select Subject",
                    mb: "4",
                  }}
                >
                  {data &&
                    data?.map((e: any) => (
                      <option key={e?.name} value={e?.name}>
                        {e.name}
                      </option>
                    ))}
                </SelectControl>
                <Input
                  ref={fileInputRef}
                  name="file"
                  onChange={(event: any) => {
                    setFieldValue("file", event.target.files[0]);
                  }}
                  type="file"
                  hidden
                />

                {values.file && (
                  <Button
                    onClick={() => fileInputRef.current?.click()}
                    variant="outline"
                    colorScheme="teal"
                    mb="4"
                  >
                    File - {(values as any).file.name}
                  </Button>
                )}
                {!values.file && (
                  <Button
                    leftIcon={<GoCloudUpload />}
                    colorScheme="teal"
                    variant="solid"
                    w="100%"
                    mb="4"
                    width="full"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Choose CSV File
                  </Button>
                )}
                <Text
                  textAlign="start"
                  mb="4"
                  color="red"
                  fontWeight="400"
                  fontSize="sm"
                >
                  <ErrorMessage name="file" />
                </Text>
                <SubmitButton isLoading={isLoading}>
                  Upload CSV File
                </SubmitButton>
              </Form>
            )}
          </Formik>

          <br />
          {/* Change download URL as per question type selections */}
          <Link color="teal" href="#">
            Download sample Excelsheet
          </Link>
        </Stack>
      </Box>
    </>
  );
};

export default ImportQuestions;
