import * as yup from "yup";

export const addSchoolValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email required!"),
  password: yup
    .string()
    .min(4, "password is too sort")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  name: yup.string().required("Name required!"),
  mobile: yup
    .number()
    .min(1000000000, "Number not valid!")
    .max(9999999999, "Number not valid!")
    .required("Mobile required!"),
  address: yup.string().required("Address required!"),
  city: yup.string().required("City required!"),
  state: yup.string().required("State required!"),
  pincode: yup
    .number()
    .min(100000, "Pincode not valid!")
    .max(999999, "Pincode not valid!")
    .required("Pincode required!"),
});

export const updateSchoolValidationSchema = yup.object().shape({
  email: yup.string().email(),
  password: yup
    .string()
    .min(4, "password is too sort")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  name: yup.string(),
  mobile: yup
    .number()
    .min(1000000000, "Number not valid!")
    .max(9999999999, "Number not valid!"),
  address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  pincode: yup
    .number()
    .min(100000, "Pincode not valid!")
    .max(999999, "Pincode not valid!"),
});
