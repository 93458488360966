import {
  HStack,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Stack,
  Table,
  // Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { assignBatchToStudent, getBatchById } from "API/batchApi";
import { getListByProgram } from "API/studentApi";
import LoadingSpinner from "components/dumb/LoadingSpinner";
import useErrorToast from "hook/error-toast";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import TableSearch from "components/dumb/Table/TableSearch";
import SchoolLevelSelect from "./SchoolSelect";
import {
  setPageDefault,
  setSearchText,
} from "app/features/batchFilter/batchFilter";
import { useAppSelector } from "app/hook";
import { getAllSchools } from "API/schoolApi";
import { setStatus } from "app/features/batchStatus/batchStatus";
import { useDispatch } from "react-redux";
import { updateActiveProgramForMany } from "API/activeProgramApi";

interface AssignBatchProps { }


const AssignBatch: FC<AssignBatchProps> = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  dispatch(setStatus("ASSIGNED"));

  const toast = useToast();
  const cache = useQueryClient();

  const {
    data: batch,
    isLoading: batchLoading,
    error: errorBatch,
  } = useQuery<IBatch>(["batch", id], () => getBatchById(id));
  const { searchText } = useAppSelector(
    (state) => state.batchFilter
  );
  const {
    data: school
  } = useQuery<ISchool[]>(["schools"], () => getAllSchools());

  const { status } = useAppSelector((state) => state.batchStatus);

  const { schoolId } = useAppSelector((state) => state.schoolFilter);
  const showCheckbox = status === "ALL" ? true : false;

  const [expiryDate,setExpiryDate] = useState(
    ""
  );
  const {
    data: students,
    error,
    isLoading,
  } = useQuery<IStudent[]>(
    ["allstudents", batch?.program, status, searchText, schoolId, batch?._id,expiryDate],
    () => { return getListByProgram(batch?.program, status, searchText, schoolId, batch?._id) },
    {
      enabled: !!batch?.program,
    }
  );
    

  const [checkedItems, setCheckedItems] = useState(
    new Array(students?.length).fill(false)
  );
  const [items,setItems] = useState<IStudent[] | undefined>(
    []
  );
  // const {
  //   data:updateStatus,
  //   error:updateError
  // } = useQuery(
  //   ["Active",expiryDate],
  //   () => { return updateActiveProgramForMany(expiryDate,items) }
  // );


  const unSelectCheckbox = () => {
    setCheckedItems(new Array(students?.length).fill(false))
  }


  const mutateAssignStudents = useMutation(assignBatchToStudent, {
    onSuccess: () => {
      cache.invalidateQueries(["batch", id]);
      cache.invalidateQueries("students");
      toast({
        title: "Success",
        description: "Students assigned successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  useErrorToast(error || errorBatch || mutateAssignStudents.error);
  if (isLoading || batchLoading) return <LoadingSpinner isFull />;
  const styles = {
    input: {
      width: "55%",
      border: "1 px solid black",
      flexdirection: "row",
      borderRadius:"5px", 
      padding: "0 20px",
      marginRight: "5px"
    },
    form: {
      display: "flex",
    }
  }
  
  const programDetails = batch?.program;
  const HandleEndDate =async (e:any)=>{
    e.preventDefault();
    const studentIds = items?.map(student=>student._id);
    await updateActiveProgramForMany(e.target.endDate.value,studentIds,programDetails?._id);
    setExpiryDate(e.target.endDate.value)
    unSelectCheckbox()
  }


  return (
    <>
      {batch && (
        <>
          <Stack>
            <Heading size={"md"}>Updata program end date</Heading>

            <Stack spacing="0">
              <Flex
                p={2}
                borderTopRadius="xl"
                bg="pink"
                alignItems={'center'}
                justify="space-between"
              >
                <Text w={'10%'} fontWeight="bold" align="center">{batch.name}</Text>
                <Stack w="90%" direction={["column", "row"]} p="15px">
                  <Box w={"50%"}>
                    <TableSearch
                      searchText={searchText}
                      setPageDefault={setPageDefault}
                      setSearchText={setSearchText}
                      searchLabel="Search by batch name"
                    />
                  </Box>

                  <Box flex="1" textAlign="right">
                    <HStack>
                      {/* <QuestionTypeSelect /> */}
                      <SchoolLevelSelect school={school} />
                      {/* <BatchStatusSelect /> */}
                    </HStack>
                  </Box>
                </Stack>
              </Flex>
              <HStack justifyContent={'space-between'} bg={"lightgreen"} p={2} px={4}>
                <Text fontWeight={'bold'}>{programDetails?.name}</Text>

                {/* <Formik
                  initialValues={{
                    endDate: format(
                      new Date(batch?.endDate ?? new Date().getDate()),
                      "yyyy-MM-dd"
                    )
                  }}
                  onSubmit={(e, event) => {
                    console.log(itemsId)
                  }}
                >

                  <Form onSubmit={(e) => { e.preventDefault() }}>
                    <HStack >
                      <InputControl
                        w={'35%'}
                        name="endDate"
                        inputProps={{
                          placeholder: "Enter Start Date",
                          type: "date",
                        }}
                      />
                      <Button
                        type="submit"
                        size="md"
                        isLoading={mutateAssignStudents.isLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          handleEndDate();
                          unSelectCheckbox();
                        }}
                      >
                        Change program end date of selected students
                      </Button>
                    </HStack>
                  </Form>

                </Formik> */}
                
                <HStack>
                  <form style={styles.form} onSubmit={(e)=>HandleEndDate(e)}>
                    <input
                          style={styles.input}
                          name="endDate"
                          placeholder=""
                          type={'date'}
                        />
                    <Button
                        type="submit"
                        size="md"
                        isLoading={mutateAssignStudents.isLoading}
                      >Change end date
                      </Button>
                  </form>
                </HStack>


              </HStack>
              <Table border="0.5px solid #ccc" borderTop="none">
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox
                        hidden={showCheckbox}
                        colorScheme="teal"
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => {
                          setCheckedItems(
                            new Array(students?.length).fill(e.target.checked)
                          )
                          const temp=e.target.checked ? students : [];
                          setItems(temp);
                          
                        }
                        }
                      />
                    </Th>
                    <Th>Student Name</Th>
                    <Th>Program end date</Th>
                    <Th>Email</Th>
                    <Th>Mobile</Th>
                    <Th>School</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {students?.map((student, i) => (
                    <Tr key={student._id}>
                      <Td>
                        <Flex align="center">
                          <Checkbox
                            hidden={showCheckbox}
                            colorScheme="teal"
                            isChecked={checkedItems[i]}
                            onChange={(e) => {
                              const checkItems = new Array(students?.length)
                                .fill(checkedItems[i])
                                .map((_, checkI) => {
                                  if (checkI === i) {
                                    return e.target.checked;
                                  }
                                  return checkedItems[checkI];
                                });
                              setCheckedItems(checkItems);
                              const temp = students?.filter((q, i) => checkItems[i] === true) ??
                                [];
                                setItems(temp);
                            }}
                          />
                          {/* {batch.assignedStudents.map((assignedStudent) => {

                            if (assignedStudent === student._id) {
                              return (
                                <Badge
                                  colorScheme="green"
                                  ml={2}
                                  key={student._id}
                                >
                                  Assigned
                                </Badge>
                              );
                            }
                            return null;
                          })} */}
                        </Flex>
                      </Td>

                      <Td>{student.name}</Td>
                      {
                        student.activePrograms.map(activeProgram=>{
                          if(activeProgram?.program.toString()===programDetails?._id){
                            return <Td key={activeProgram._id}>{format(
                              new Date(activeProgram.expiredAt),
                              "yyyy-MM-dd"
                            )}</Td>
                          }
                          return null;
                        })
                        
                      }
                      <Td>{student.email}</Td>
                      <Td>{student.mobile}</Td>
                      <Td>{student.school?.name}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};
export default AssignBatch;
