import { FC } from "react";
import { getProgramById } from "API/programApi";
import { Stack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import Test from "./Test";

interface TestsProps {
  programId: string;
  subjectId: string;
}

const Tests: FC<TestsProps> = ({ programId, subjectId }) => {
  const { data: program } = useQuery<IProgram>(
    ["program", programId, subjectId],
    () => getProgramById(programId, subjectId)
  );

  return (
    <>
      {program?.tests.map((test) => (
        <Stack
          direction={"row"}
          key={test._id}
          spacing={"0px"}
          borderTop={"1px"}
          borderTopColor={"gray.200"}
        >
          <Test test={test} />
        </Stack>
      ))}
    </>
  );
};
export default Tests;
