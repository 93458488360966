import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Spinner,
  Table as CTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import { FcFinePrint } from "react-icons/fc";
import ViewPayment from "./ViewPayment";
import { format } from "date-fns";

interface IPaymentHistoryTableProps {
  payments: IPayment[];
  isLoading: boolean;
}

const PaymentHistoryTable: FC<IPaymentHistoryTableProps> = ({
  payments,
  isLoading,
}) => {
  const paymentsCount = payments?.length;
  const [paymentId, setpaymentId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const onView = (id: string) => {
    setpaymentId(id);
    onOpen();
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Payment details</DrawerHeader>
          <ViewPayment onClose={onClose} paymentId={paymentId} />
        </DrawerContent>
      </Drawer>
      {isLoading ? (
        <Box width="100%" height="100%">
          <Spinner />
        </Box>
      ) : paymentsCount === 0 ? (
        <Flex w="100%" minH="50vh" align="center" justify="center">
          <VStack>
            <Icon as={FcFinePrint} w={16} h={16} />
            <Text>No Payment Found!</Text>
          </VStack>
        </Flex>
      ) : (
        <CTable variant="simple">
          <Thead>
            <Tr>
              <Th>Payment Id</Th>
              <Th>Date and time</Th>
              <Th>name</Th>
              <Th>mobile no</Th>
              <Th>order amount</Th>
              <Th>status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <>
              {payments.map((payment) => (
                <Tr key={payment?._id}>
                  <Td>{payment?.paymentId}</Td>
                  <Td>
                    {payment?.paymentDate &&
                      format(
                        new Date(payment?.paymentDate ?? ""),
                        "dd-MM-yyyy, HH:mm:ss"
                      )}
                  </Td>
                  <Td>{payment?.user?.name}</Td>
                  <Td>{payment?.user?.mobile} </Td>
                  <Td>
                    <Text
                      fontSize="md"
                      fontWeight={"bold"}
                      color={"purple.500"}
                    >
                      Rs.{payment?.total}
                    </Text>
                  </Td>
                  <Td>
                    <Badge
                      variant="solid"
                      colorScheme={
                        payment?.status === "captured"
                          ? "green"
                          : payment?.status === "pending"
                          ? "orange"
                          : ""
                      }
                    >
                      {payment?.status}
                    </Badge>
                  </Td>
                  <Td>
                    <Button
                      ref={btnRef}
                      colorScheme="teal"
                      onClick={() => onView(payment?._id)}
                    >
                      Details
                    </Button>
                  </Td>
                </Tr>
              ))}
            </>
          </Tbody>
        </CTable>
      )}
    </>
  );
};

export default PaymentHistoryTable;
