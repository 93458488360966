import { Center, Grid, GridItem, Heading } from "@chakra-ui/layout";
import { Flex, Spinner } from "@chakra-ui/react";
import {
  addPerformance,
  getPerformanceById,
  updatePerformance,
} from "API/performanceApi";
import { Formik, Form } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { FC } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addPerformanceValidationSchema,
  updatePerformanceValidationSchema,
} from "validation/performanceValidation";
import { useToast } from "@chakra-ui/toast";
import useErrorToast from "hook/error-toast";
import { useHistory, useParams } from "react-router";
import { getAllPrograms } from "API/programApi";

interface AddAndUpdatePerformanceProps {
  isUpdate?: boolean;
}

const AddAndUpdatePerformance: FC<AddAndUpdatePerformanceProps> = ({
  isUpdate = false,
}) => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const {
    data,
    isLoading: isLoadingPerformance,
    error: errorPerformance,
  } = useQuery<IPerformance>(
    ["performance", id, ""],
    () =>
      getPerformanceById({
        id,
        subjectId: "",
      }),
    {
      enabled: isUpdate,
    }
  );

  const {
    error: updateError,
    isLoading: updateIsLoading,
    mutateAsync: updateMutate,
  } = useMutation("updatePerformance", updatePerformance, {
    onSuccess: () => {
      toast({
        title: "Performance Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/dashboard/performances");
    },
  });
  const {
    error: addError,
    isLoading: addIsLoading,
    mutateAsync: addMutate,
  } = useMutation("addPerformance", addPerformance, {
    onSuccess: () => {
      toast({
        title: "Performance Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/dashboard/performances");
    },
  });
  useErrorToast(updateError);
  useErrorToast(errorPerformance);
  useErrorToast(addError);

  const { data: allprograms } = useQuery<IProgram[]>(
    "allprograms",
    getAllPrograms
  );

  return (
    <>
      {isLoadingPerformance ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Formik
          validationSchema={
            isUpdate
              ? updatePerformanceValidationSchema
              : addPerformanceValidationSchema
          }
          initialValues={
            isUpdate
              ? {
                  name: data?.name ?? "",
                  basic_objective_academics:
                    data?.basic_objective_academics ?? "",
                  career_objectives_1: data?.career_objectives_1 ?? "",
                  career_objectives_2: data?.career_objectives_2 ?? "",
                  programId: data?.program._id ?? "",
                }
              : {
                  name: "",
                  basic_objective_academics: "",
                  career_objectives_1: "",
                  career_objectives_2: "",
                  programId: "",
                }
          }
          onSubmit={async (values) => {
            if (isUpdate) {
              try {
                await updateMutate({
                  performanceId: id,
                  ...values,
                });
              } catch (error) {}
            } else {
              try {
                await addMutate({
                  ...values,
                });
              } catch (error) {}
            }
          }}
        >
          <Form>
            <Grid gridColumn={{ base: 1, sm: 2 }} gridGap="4">
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <Heading fontSize="xl">
                  {isUpdate ? "Update" : "Add"} Performance
                </Heading>
              </GridItem>

              <GridItem>
                <InputControl
                  name="name"
                  inputProps={{ placeholder: "Enter Performance Name" }}
                  label="Performance Name"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="career_objectives_1"
                  inputProps={{
                    placeholder: "Career Objectives 1",
                  }}
                  label="Enter Career Objectives 1"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="career_objectives_2"
                  inputProps={{
                    placeholder: "Career Objectives 2",
                  }}
                  label="Enter Career Objectives 2"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="basic_objective_academics"
                  inputProps={{
                    placeholder: "Basic Objective Academics",
                  }}
                  label="Enter Basic Objective Academics"
                />
              </GridItem>

              <SelectControl name="programId" label="Program Id">
                <option value="">Select Program</option>
                {allprograms?.map((program) => (
                  <option value={program?._id}>{program?.name}</option>
                ))}
              </SelectControl>

              <Flex align="flex-end">
                <SubmitButton
                  isLoading={addIsLoading || updateIsLoading}
                  width="full"
                >
                  Submit
                </SubmitButton>
              </Flex>
            </Grid>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default AddAndUpdatePerformance;
