import {
  HStack,
  Box,
  Badge,
  Button,
  Checkbox,
  Flex,
  Heading,
  Stack,
  Table,
  // Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { assignBatchToStudent, deleteStudentFromBatch, getBatchById } from "API/batchApi";
import { getListByProgram } from "API/studentApi";
// import { useAppSelector } from "app/hook";
import LoadingSpinner from "components/dumb/LoadingSpinner";
import useErrorToast from "hook/error-toast";
import { FC,  useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import TableSearch from "components/dumb/Table/TableSearch";
import SchoolLevelSelect from "./SchoolSelect";
import {
  setPageDefault,
  setSearchText,
} from "app/features/batchFilter/batchFilter";
import BatchStatusSelect from "./BatchStatus";
import { useAppSelector } from "app/hook";
import { getAllSchools } from "API/schoolApi";

interface AssignBatchProps { }



const AssignBatch: FC<AssignBatchProps> = () => {
  const { id } = useParams<{ id: string }>();


  const toast = useToast();
  const cache = useQueryClient();
  const {
    data: batch,
    isLoading: batchLoading,
    error: errorBatch,
  } = useQuery<IBatch>(["batch", id], () => getBatchById(id));
  const { searchText } = useAppSelector(
    (state) => state.batchFilter
  );
  const {
    data: school
  } = useQuery<ISchool[]>(["schools"], () => getAllSchools());

  const { status } = useAppSelector((state) => state.batchStatus);

  const { schoolId } = useAppSelector((state) => state.schoolFilter);
  const showCheckbox = status === "ALL" ? true : false;

  const {
    data: students,
    error,
    isLoading,
  } = useQuery<IStudent[]>(
    ["allstudents", batch?.program, status, searchText, schoolId, batch?._id],
    () => { return getListByProgram(batch?.program, status, searchText, schoolId, batch?._id) },
    {
      enabled: !!batch?.program,
    }
  );


  const [checkedItems, setCheckedItems] = useState(
    new Array(students?.length).fill(false)
  );
  const unSelectCheckbox = ()=>{
    setCheckedItems(new Array(students?.length).fill(false))
  }
  const assignAndDeleteStudentFunction = ()=>{
    status ==="ASSIGNED"? deleteStudents() : assignStudents();
  }
  const itemsId =
    students?.filter((q, i) => checkedItems[i] === true).map((q) => q._id) ??
    [];

  const mutateAssignStudents = useMutation(assignBatchToStudent, {
    onSuccess: () => {
      cache.invalidateQueries(["batch", id]);
      cache.invalidateQueries("students");
      toast({
        title: "Success",
        description: "Students assigned successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const mutateNotAssignStudents = useMutation(deleteStudentFromBatch, {
    onSuccess: () => {
      cache.invalidateQueries(["batch", id]);
      cache.invalidateQueries("students");
      toast({
        title: "Success",
        description: "Students deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  useErrorToast(error || errorBatch || mutateAssignStudents.error);
  if (isLoading || batchLoading) return <LoadingSpinner isFull />;

  const assignStudents = async () => {
    await mutateAssignStudents.mutate({
      batchId: id,
      studentsIds: itemsId,
    });
  };
  const deleteStudents = async () => {
    await mutateNotAssignStudents.mutate({
      batchId: id,
      studentsIds: itemsId,
    });
  };

  let buttonBackground = status === "ASSIGNED"?"red" : "green";
  let buttonText = status === "ASSIGNED"?"Remove Selected Students from the batch":"Assign selected batch to selected students";
  

  return (
    <>
      {batch && (
        <>
          <Stack>
            <Heading size={"md"}>Assign Batch</Heading>

            <Stack spacing="0">
              <Flex
                p={4}
                borderTopRadius="xl"
                bg="pink"
                justify="space-between"
              >
                <Text>{batch.name}</Text>

                <Stack w="90%" direction={["column", "row"]} p="15px">
                  <Box w={"50%"}>
                    <TableSearch
                      searchText={searchText}
                      setPageDefault={setPageDefault}
                      setSearchText={setSearchText}
                      searchLabel="Search by batch name"
                    />
                  </Box>

                  <Box flex="1" textAlign="right">
                    <HStack>
                      {/* <QuestionTypeSelect /> */}
                      <SchoolLevelSelect school={school} />
                      <BatchStatusSelect />
                    </HStack>
                  </Box>
                </Stack>
              </Flex>
              {itemsId && itemsId.length > 0 && (
                <Flex bg={buttonBackground} p={2}>
                  <Button
                    size="sm"
                    isLoading={mutateAssignStudents.isLoading}
                    onClick={()=> {
                      unSelectCheckbox();
                      assignAndDeleteStudentFunction();
                    }}
                  >
                    {buttonText}
                  </Button>
                </Flex>
              )}
              <Table border="0.5px solid #ccc" borderTop="none">
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox
                        hidden={showCheckbox}
                        colorScheme="teal"
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) =>
                          setCheckedItems(
                            new Array(students?.length).fill(e.target.checked)
                          )
                        }
                      />
                    </Th>
                    <Th>Student Name</Th>
                    {/* <Th>Program end date</Th> */}
                    <Th>Email</Th>
                    <Th>Mobile</Th>
                    <Th>School</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {students?.map((student, i) => (
                    <Tr key={student._id}>
                      <Td>
                        <Flex align="center">
                          <Checkbox
                            hidden={showCheckbox}
                            colorScheme="teal"
                            isChecked={checkedItems[i]}
                            onChange={(e) => {
                              const checkItems = new Array(students?.length)
                                .fill(checkedItems[i])
                                .map((_, checkI) => {
                                  if (checkI === i) {
                                    return e.target.checked;
                                  }
                                  return checkedItems[checkI];
                                });
                              setCheckedItems(checkItems);
                            }}
                          />
                          {batch.assignedStudents.map((assignedStudent) => {
                            if (assignedStudent === student._id) {
                              return (
                                <Badge
                                  colorScheme="green"
                                  ml={2}
                                  key={student._id}
                                >
                                  Assigned
                                </Badge>
                              );
                            }
                            return null;
                          })}
                        </Flex>
                      </Td>

                      <Td>{student.name}</Td>
                      {/* <Td>{format(
                        new Date(batch?.endDate),
                        "yyyy-MM-dd"
                      )}</Td> */}
                      <Td>{student.email}</Td>
                      <Td>{student.mobile}</Td>
                      <Td>{student.school?.name}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};
export default AssignBatch;
