import AddAndUpdateStudent from "pages/dashboard/shared/Users/Students/Add&UpdateStudentForm";
import { useParams } from "react-router-dom";

const AdminUpdateStudent = () => {
  const { schoolId } = useParams<{ schoolId: string }>();

  return (
    <AddAndUpdateStudent
      redirectTo={`/admin/dashboard/users/${schoolId}/students`}
      schoolId={schoolId ?? ""}
      isUpdate={true}
    />
  );
};

export default AdminUpdateStudent;
