import { axios } from "./AxiosInstance";

export const getAttemptedTestById = async ({ id }: { id: string }) => {
  try {
    const { data } = await axios.get(`/attempted-test/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getListofAttemptedTestByProgramAndSchool = async ({
  programId,
  schoolId,
}: {
  programId: string;
  schoolId: string;
}) => {
  try {
    const { data } = await axios.post(`/attempted-test/get-all/`, {
      programId,
      schoolId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const testReviewStarted = async ({
  id,
  staffId,
}: {
  id: string;
  staffId: string;
}) => {
  try {
    const { data } = await axios.post(`/attempted-test/start-review`, {
      id,
      staffId,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const submitReview = async ({
  isDraft,
  id,
  questions,
  staffName,
}: {
  isDraft: boolean;
  id: string;
  questions: any;
  staffName: string;
}) => {
  try {
    const { data } = await axios.post(
      isDraft
        ? `/attempted-test/draft-review`
        : `/attempted-test/submit-review`,
      {
        id,
        questions,
        staffName,
      }
    );
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
