import { axios } from "./AxiosInstance";

export const getQuestionCountByLevel = async ({
  topicsId,
  level,
  questionType,
}: {
  topicsId: string[];
  level: string;
  questionType: string | undefined;
}) => {
  try {
    const { data } = await axios.post(`/test/getQuestionCountByLevel`, {
      topicsId,
      level,
      questionType,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const generateTest = async ({
  topicsId,
  level,
  numberOfTest,
  testNames,
  questionType,
}: {
  topicsId: string[];
  testNames: string[];
  level: string;
  numberOfTest: number;
  questionType: string | undefined;
}) => {
  try {
    const { data } = await axios.post(`/test/createTest`, {
      topicsId,
      level,
      numberOfTest,
      testNames,
      questionType,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const removeTest = async (testId: string) => {
  try {
    const { data } = await axios.delete(`/test/${testId}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateTest = async ({
  testId,
  testName,
}: {
  testId: string;
  testName: string;
}) => {
  try {
    const { data } = await axios.patch(`/test/updateTest`, {
      testId,
      name: testName,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getTestById = async (testId: string) => {
  try {
    const { data } = await axios.post(`/test/getTestById`, { testId });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
