import { Box, HStack, Flex } from "@chakra-ui/layout";
import {
  Table as CTable,
  Tr,
  Tbody,
  Spinner,
  Td,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import { GoKebabVertical } from "react-icons/go";
// import { useState } from "react";
import { Link as RoLink } from "react-router-dom";
import TableHeader from "components/dumb/Table/TableHeader";

interface SchoolTableProps {
  isLoading: boolean;
  items: ISchool[];
  resetCheckBox: boolean;
  disableUpdate: boolean;
  tableHeader: {
    id: string;
    label: string;
    alignRight: boolean;
  }[];
}

const SchoolTable: FC<SchoolTableProps> = ({
  isLoading,
  items,
  resetCheckBox,
  disableUpdate,
  tableHeader,
}) => {
  // const { role } = useAppSelector((state) => state.auth);
  const itemsCount = items?.length;

  return (
    <Box overflow="hidden">
      <Box overflowX="auto">
        <CTable variant="striped" minH="50vh">
          <TableHeader tableHeaders={tableHeader} />
          <Tbody>
            {isLoading ? (
              <Flex
                as="tr"
                width="100%"
                height="100%"
                justify="center"
                align="center"
              >
                <Spinner as="td" />
              </Flex>
            ) : itemsCount === 0 ? (
              <Flex
                as="tr"
                width="100%"
                height="100%"
                justify="center"
                align="center"
              >
                <Text as="td">No School Found!</Text>
              </Flex>
            ) : (
              <>
                {items?.map((school, i) => (
                  <Tr key={school._id}>
                    <Td maxW="400px">
                      {school?.name}
                      <HStack spacing={2} mt="10px"></HStack>
                    </Td>
                    <Td textTransform="capitalize">{school?.email}</Td>
                    <Td>{school?.contact?.city}</Td>
                    <Td>{school.students.length}</Td>
                    <Td>{school.program.length}</Td>
                    {!disableUpdate && (
                      <Td>
                        <Menu closeOnBlur={true}>
                          <MenuButton
                            as={IconButton}
                            icon={<GoKebabVertical />}
                            variant="ghost"
                          ></MenuButton>
                          <MenuList>
                            <MenuItem
                              as={RoLink}
                              to={`/admin/dashboard/users/school/edit/${school._id}`}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              as={RoLink}
                              to={`/admin/dashboard/users/school/view/${school._id}`}
                            >
                              View
                            </MenuItem>
                            {/* <MenuItem>Delete</MenuItem> */}
                          </MenuList>
                        </Menu>
                      </Td>
                    )}
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </CTable>
      </Box>
    </Box>
  );
};
export default SchoolTable;
