import { FC } from "react";
import { Form, Formik } from "formik";
import { useToast } from "@chakra-ui/toast";
import { useMutation, useQueryClient } from "react-query";
import { updateLicences } from "API/purchasedProdcutsApi";
import Modal from "components/UI/Modal";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import { useParams } from "react-router";
import { useDisclosure } from "@chakra-ui/react";

import { Box, Button } from "@chakra-ui/react";
interface UpdateLicenceProps {
  PurchasedProduct: IProduct;
}
const UpdateLicence: FC<UpdateLicenceProps> = ({ PurchasedProduct }) => {
  const cache = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading: isLoadingLicences, mutateAsync } = useMutation(
    updateLicences,
    {
      onSuccess: () => {
        toast({
          title: "Success",
          description: "Licences Updated Successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        cache.invalidateQueries(["school", id]);
      },
    }
  );

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Formik
          initialValues={{ quantity: PurchasedProduct.quantity }}
          onSubmit={async (values) => {
            try {
              await mutateAsync({
                id: PurchasedProduct._id,
                quantity: values.quantity,
              });
            } catch (error) {}
          }}
        >
          <Form>
            <InputControl
              name="quantity"
              label="quantity"
              inputProps={{ placeholder: "Enter licences qty:" }}
            />
            <SubmitButton isLoading={isLoadingLicences} mt={4}>
              {" "}
              Update licences
            </SubmitButton>
          </Form>
        </Formik>
      </Modal>
      <Box w={"55%"} pl={"15px"}>
        <Button w={"100%"} colorScheme={"purple"} onClick={onOpen}>
          Update Licence
        </Button>
      </Box>
    </>
  );
};

export default UpdateLicence;
