import { axios } from "./AxiosInstance";

export const getProducts = async () => {
  try {
    const { data } = await axios.get(`/product`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const getProductById = async (id: string) => {
  try {
    const { data } = await axios.get(`/product/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const addProduct = async ({
  name,
  description,
  price,
  minQ,
  duration,
  programId,
  access,
  type,
}: {
  name: string;
  description: string;
  price: number;
  minQ: number;
  duration: number;
  programId: string;
  type: string;
  access: [ProductType] | never[];
}) => {
  try {
    const { data } = await axios.post(`/product/add`, {
      name,
      description,
      price,
      minQ,
      duration,
      programId,
      access,
      type,
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const updateProduct = async ({
  name,
  description,
  price,
  minQ,
  duration,
  programId,
  productId,
  access,
  type,
}: {
  name: string;
  description: string;
  price: number;
  minQ: number;
  duration: number;
  programId: string;
  productId: string;
  access: [ProductType] | never[];
  type: string;
}) => {
  try {
    const { data } = await axios.patch(`product/update`, {
      name,
      description,
      price,
      minQ,
      duration,
      programId,
      productId,
      access,
      type
    });
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
export const deleteProduct = async (id: string) => {
  try {
    const { data } = await axios.delete(`/product/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
