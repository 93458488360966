import { axios } from "./AxiosInstance";

export interface CareerPlanData {
  studyPlan: {
    id: string;
    topic: string;
    studyPlan: string;
    remark: string;
    months: {
      [month: string]: number;
    };
  }[];
  class: string;
  idNo: string;
  panels: string[];
  primaryObjective: string;
  secondaryObjective: string;
  year: string;
}

export const createCareerPlan = async (careerPlanData: CareerPlanData) => {
  try {
    const { data } = await axios.post(`/career-plan/`, careerPlanData);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const updateCareerPlan = async ({
  id,
  obj,
}: {
  id: string;
  obj: CareerPlanData;
}) => {
  try {
    const { data } = await axios.put(`/career-plan/${id}`, obj);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getCareerPlan = async (id: String) => {
  try {
    const { data } = await axios.get(`/career-plan/${id}`);
    return data;
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};

export const getCareerPlanByStudentId = async (id: String) => {
  try {
    if (id !== undefined) {
      const { data } = await axios.get(`/career-plan/student/${id}`);
      return data;
    }
  } catch (error: any) {
    throw Error(error.response.data.message);
  }
};
