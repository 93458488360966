import { axios } from "./AxiosInstance";

export const getWritingSkill = async (user: any) => {
  try {
    const { data } = await axios.get(`/writing-skill/${user._id}`);

    return data;
  } catch (error: any) {
    throw Error(error);
  }
};

export const addUpdateData = async (newData: any, user: any) => {
  try {
    const { data } = await axios.post(`/writing-skill/${user._id}`, newData);

    return data;
  } catch (error: any) {
    throw Error(error);
  }
};
