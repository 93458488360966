import React, { useState } from "react";
import {
  Stack,
  HStack,
  Heading,
  Text,
  Button,
  useDisclosure,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useColorModeValue,
  Badge,
  Box,
  Select,
  SimpleGrid,
  Center,
  Spinner,
  FormControl,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
  Icon,
} from "@chakra-ui/react";
import Cart from "./Cart";
import { getProducts } from "API/productApi";
import { getAllPrograms } from "API/programApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addProductsToCart } from "API/cartApi";
import useErrorToast from "hook/error-toast";
import { useAppSelector } from "app/hook";
import { BsCart } from "react-icons/bs";
import PendingPayments from "./PendingPayments";
import { FcFinePrint } from "react-icons/fc";

const BuyProducts = () => {
  const { user }: { user: IStudent | ISchool } = useAppSelector(
    (state) => state.auth
  );
  const { data: products, isLoading } = useQuery<IProduct[]>(
    "products",
    getProducts
  );
  const { data: programs } = useQuery<IProgram[]>(
    "allprograms",
    getAllPrograms
  );
  const [sortType, setSortType] = useState<"ascen" | "desc">("ascen");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLInputElement>(null);
  const [program, setProgram] = useState<string>("");
  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
  const filteredProducts = products?.filter((product) => {
    return product.program._id === program || program === "";
  });
  return (
    <Box>
      <HStack justifyContent="space-between" mb={"15px"}>
        <Heading size="md">Program registration</Heading>

        <Button variant="outline" colorScheme="teal" onClick={onOpen}>
          <BsCart fontSize={24} />
          <Badge
            ml={"2"}
            fontSize="lg"
            colorScheme="red"
            borderRadius="full"
            pos="relative"
            bottom={2}
            right={4}
            mr={-4}
          >
            {user?.cart.length}
          </Badge>
          Cart
        </Button>
      </HStack>
      <HStack mb={"20px"} spacing={[3, 6]}>
        <Select
          // placeholder="Question Types"
          size="sm"
          rounded="md"
          // value={questionType ?? ""}
          // display={{ base: "none", md: "block", lg: "block" }}
          onChange={(e) => {
            const { value } = e.target;
            if (value === "ASC") {
              setSortType("ascen");
            } else if (value === "DESC") {
              setSortType("desc");
            }
          }}
        >
          <option value="ASC">Price: low to high</option>
          <option value="DESC">Price: high to low</option>
        </Select>
        <Select
          placeholder="all programs"
          size="sm"
          rounded="md"
          value={program}
          // display={{ base: "none", md: "block", lg: "block" }}
          textTransform="capitalize"
          onChange={(e) => {
            setProgram(e.target.value);
          }}
        >
          {programs?.map((p) => (
            <option key={p._id} value={p._id}>
              {p.name}
            </option>
          ))}
        </Select>
      </HStack>
      <PendingPayments />
      {Number(filteredProducts?.length) === 0 && (
        <Box size="sm" textAlign={"center"} py={"100px"}>
          <VStack>
            <Icon as={FcFinePrint} w={16} h={16} />
            <Text>No data Found!</Text>
          </VStack>
        </Box>
      )}
      <SimpleGrid columns={[1, 4]} spacing={6}>
        {filteredProducts &&
          sortType === "desc" &&
          filteredProducts
            .slice()
            .sort((a, b) => b.price - a.price)
            .map((product) => (
              <ProductItem
                product={product}
                key={product?._id}
                program={program}
              />
            ))}
        {filteredProducts &&
          sortType === "ascen" &&
          filteredProducts
            .slice()
            .sort((a, b) => a.price - b.price)
            .map((product) => (
              <ProductItem
                product={product}
                key={product?._id}
                program={program}
              />
            ))}
      </SimpleGrid>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size="lg"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Cart</DrawerHeader>
          <Cart />
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default BuyProducts;

const ProductItem = ({
  product,
  program,
}: {
  product: IProduct;
  program: string;
}) => {
  const { role } = useAppSelector((state) => state.auth);
  const [quantity, setQuantity] = useState(product.minQ);
  const textClr = useColorModeValue("gray.900", "gray.700");
  const cache = useQueryClient();
  const mutateAddToCart = useMutation(addProductsToCart, {
    onSuccess: () => {
      cache.invalidateQueries("user");
    },
  });
  useErrorToast(mutateAddToCart.error);

  return product.program._id === program || program === "" ? (
    <Stack
      bgColor="red.50"
      p={4}
      borderRadius="8px"
      spacing="12px"
      key={product?._id}
    >
      <Heading size="md" color={textClr}>
        {product?.name}
      </Heading>
      <Text color={textClr}>{product?.description}</Text>
      <Box>
        {product?.access.map((access) => (
          <Badge variant={"solid"} colorScheme={"teal"} mr={"1"} key={access}>
            {access}
          </Badge>
        ))}
      </Box>
      <Heading size="xs" color={textClr}>
        Duration: {product?.duration} month
      </Heading>
      <Heading size="xs" color={textClr}>
        Program: {product?.program?.name}
      </Heading>
      <Heading size="md" color="purple">
        Rs. {product?.price}/user
      </Heading>
      {product.minQ > 0 && (
        <Text fontSize={"12px"}>
          Min. licenses needs to purchase: {product.minQ}
        </Text>
      )}
      {role === "school" && (
        <FormControl>
          <NumberInput
            min={product?.minQ}
            size="sm"
            bg={"white"}
            value={quantity}
            onChange={(e) => {
              setQuantity(parseInt(e));
            }}
          >
            <NumberInputField id="quantity" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      )}
      <Button
        onClick={() => {
          mutateAddToCart.mutate({
            productId: product._id,
            quantity,
          });
        }}
        colorScheme={"teal"}
        variant={"outline"}
        isLoading={mutateAddToCart.isLoading}
      >
        Add to cart
      </Button>
    </Stack>
  ) : null;
};
