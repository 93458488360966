import { axios } from "./AxiosInstance";


export const getPerformanceReportByStudentIdAndPerformanceId = async ({
    studentId,
    performanceId
}: {
    studentId: string;
    performanceId: string;
}) => {
    try {
        const { data } = await axios.post(`/performanceReport/getPerformanceReportByStudentIdAndPerformanceId`, {
            studentId,
            performanceId
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};

export const addPerformanceReport = async ({
    performanceId, studentId, schoolId, isDraft, parameters_result, expectedBehavior, careerRemark, academicsRemark, year
}: {

    studentId: string,
    schoolId: string,
    performanceId: string,
    isDraft: boolean,
    parameters_result: {
        _id: string,
        name: string;
        marks: number;
        marks_obtained: number;
        lenovo: {
            name: string;
            rating: string;
        }[];
        subject: string;
    }[]
    expectedBehavior: {
        knowledge: {
            fundamental_of_subjects: number,
            voculabry_of_all_subjects: number,
            current_information_of_different_subjects: number,
        },
        skills: {
            ability_to_present_logically_seq: number,
            writing_skills: number,
            verbal_skills: number,
            ability_to_debate_and_discuss: number,
        },
        attitude: {
            to_excel: number,
            to_get_feedback: number,
            getting_along_with_people: number,
            to_work_in_group_team: number,
        },
        moralValue: {
            respectful: number,
            honest: number,
            timeliness: number,
        },
    },
    careerRemark: number,
    academicsRemark: number,
    year: number,
}) => {
    try {
        const { data } = await axios.post(`/performanceReport/add`, {
            performanceId, studentId, schoolId, isDraft, parameters_result, expectedBehavior, careerRemark, academicsRemark, year
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};
export const updatePerformanceReport = async ({
    id, performanceId, studentId, schoolId, isDraft, parameters_result, expectedBehavior, careerRemark, academicsRemark, year
}: {

    studentId: string,
    id: string,
    schoolId: string,
    performanceId: string,
    isDraft: boolean,
    parameters_result: {
        _id: string,
        name: string;
        marks: number;
        marks_obtained: number;
        lenovo: {
            name: string;
            rating: string;
        }[];
        subject: string;
    }[]
    expectedBehavior: {
        knowledge: {
            fundamental_of_subjects: number,
            voculabry_of_all_subjects: number,
            current_information_of_different_subjects: number,
        },
        skills: {
            ability_to_present_logically_seq: number,
            writing_skills: number,
            verbal_skills: number,
            ability_to_debate_and_discuss: number,
        },
        attitude: {
            to_excel: number,
            to_get_feedback: number,
            getting_along_with_people: number,
            to_work_in_group_team: number,
        },
        moralValue: {
            respectful: number,
            honest: number,
            timeliness: number,
        },
    },
    careerRemark: number,
    academicsRemark: number,
    year: number,
}) => {
    try {
        const { data } = await axios.patch(`/performanceReport/${id}`, {
            performanceId, studentId, schoolId, isDraft, parameters_result, expectedBehavior, careerRemark, academicsRemark, year
        });
        return data;
    } catch (error: any) {
        throw Error(error.response.data.message);
    }
};




