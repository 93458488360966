import { Center, Grid, GridItem, Heading } from "@chakra-ui/layout";
import { Flex, Spinner } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { FC } from "react";
import { useMutation, useQuery } from "react-query";

import { useToast } from "@chakra-ui/toast";
import useErrorToast from "hook/error-toast";
import { useHistory, useParams } from "react-router";
import { addStudent, getStudentById, updateStudent } from "API/studentApi";

import {
  addStudentValidationSchema,
  updateStudentValidationSchema,
} from "validation/studentValidation";
import { format } from "date-fns";
import { getAllPrograms } from "API/programApi";

interface AddAndUpdateStudentProps {
  isUpdate?: boolean;
  schoolId?: string;
  redirectTo: string;
}

const AddAndUpdateStudent: FC<AddAndUpdateStudentProps> = ({
  isUpdate = false,
  schoolId,
  redirectTo,
}) => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { data: allprograms } = useQuery<IProgram[]>(
    "allprograms",
    getAllPrograms
  );
  const {
    data,
    isLoading: isLoadingStudent,
    error: errorStudent,
  } = useQuery<IStudent>(["student", id], () => getStudentById(id), {
    enabled: isUpdate,
  });

  const {
    error: updateError,
    isLoading: updateIsLoading,
    mutateAsync: updateMutate,
  } = useMutation("updateStudent", updateStudent, {
    onSuccess: () => {
      toast({
        title: "Student Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push(redirectTo);
    },
  });
  const {
    error: addError,
    isLoading: addIsLoading,
    mutateAsync: addMutate,
  } = useMutation("addStudent", addStudent, {
    onSuccess: () => {
      toast({
        title: "Student Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push(redirectTo);
    },
  });
  useErrorToast(updateError);
  useErrorToast(errorStudent);
  useErrorToast(addError);

  // let programName = Object(data?.interestedProgram)["name"]

  // console.log(Object(data?.interestedProgram)["name"]);
  
  return (
    <>
      {isLoadingStudent ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Formik
          validationSchema={
            isUpdate
              ? updateStudentValidationSchema
              : addStudentValidationSchema
          }
          initialValues={
            isUpdate
              ? {
                  name: data?.name ?? "",
                  mobile: data?.mobile ?? 0,
                  email: data?.email ?? "",
                  password: "",
                  confirmPassword: "",
                  dateOfBirth: format(
                    new Date(data?.dateOfBirth ?? new Date().getDate()),
                    "yyyy-MM-dd"
                  ),
                  uid: id,
                  gender: data?.gender ?? "",
                  interestedProgramId: data?.interestedProgram ?? "",
                }
              : {
                  name: "",
                  mobile: 0,
                  email: "",
                  password: "",
                  confirmPassword: "",
                  dateOfBirth: data?.dateOfBirth ?? "",
                  gender: data?.gender ?? "",
                  uid: data?.uid ?? "",
                  interestedProgramId: "",
                }
          }
          onSubmit={async (values) => {
            if (isUpdate) {
              try {
                await updateMutate({
                  ...values,
                  userId: id,
                  schoolId: schoolId ?? "",
                });
              } catch (error) {}
            } else {
              try {
                await addMutate({
                  ...values,
                  schoolId: schoolId ?? "",
                });
              } catch (error) {}
            }
          }}
        >
          <Form>
            <Grid gridColumn={{ base: 1, sm: 2 }} gridGap="4">
              <GridItem colSpan={{ base: 1, sm: 2 }}>
                <Heading fontSize="xl">
                  {isUpdate ? "Update" : "Add"} Student
                </Heading>
              </GridItem>

              <GridItem>
                <InputControl
                  name="name"
                  inputProps={{ placeholder: "Enter Student Name" }}
                  label="Student Name"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="email"
                  inputProps={{
                    placeholder: "Enter Student Email",
                    type: "email",
                  }}
                  label="Student Email"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="mobile"
                  inputProps={{
                    placeholder: "Enter Student Mobile No.",
                    type: "number",
                  }}
                  label="Student Mobile No."
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="uid"
                  inputProps={{
                    placeholder: "Enter Enrollment ID",
                  }}
                  label="Enrollment ID"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="dateOfBirth"
                  inputProps={{
                    placeholder: "Enter Date of Birth...",
                    type: "date",
                  }}
                  label="Date of Birth"
                />
              </GridItem>
              <GridItem>
                <SelectControl
                  label="Gender"
                  name="gender"
                  selectProps={{ placeholder: "Select Gender" }}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </SelectControl>
              </GridItem>
              <GridItem>
                <SelectControl
                  label="Intrested Program"
                  name="interestedProgramId"
                  // selectProps={{ placeholder: "Select Intrested Program" }}
                >
                  <option value="{data?.interestedProgram ? Object(data?.interestedProgram)['_id'] : ''}"> {data?.interestedProgram ? Object(data?.interestedProgram)["name"] : "Select Program"}  </option>
                  {allprograms?.map((program) => (
                    <option key={program?._id} value={program?._id}>{program?.name}</option>
                    ))}
                </SelectControl>
              </GridItem>
              <GridItem>
                <InputControl
                  name="password"
                  inputProps={{
                    placeholder: "Enter Password",
                    type: "password",
                  }}
                  label="Password"
                />
              </GridItem>
              <GridItem>
                <InputControl
                  name="confirmPassword"
                  inputProps={{
                    placeholder: "Confirm Password",
                    type: "password",
                  }}
                  label="Confirm Password"
                />
              </GridItem>
              {/* <GridItem>
                <Link
                  as={Rolink}
                  to={`/school/dashboard/users/student/edit/${id}/aasign-program`}
                >
                  Assign Program
                </Link>
              </GridItem> */}

              <Flex align="flex-end">
                <SubmitButton
                  isLoading={addIsLoading || updateIsLoading}
                  width="full"
                >
                  Submit
                </SubmitButton>
              </Flex>
            </Grid>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default AddAndUpdateStudent;
