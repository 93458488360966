import { Flex, Box } from "@chakra-ui/layout";
import { addChapter } from "API/chapterApi";

import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import useErrorToast from "hook/error-toast";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDisclosure } from "@chakra-ui/hooks";
import AddAndClose from "components/smart/AddAndClose";
import { useAppSelector } from "app/hook";

interface AddChapterProps {}

const AddChapter: FC<AddChapterProps> = () => {
  const { program, subjectId } = useAppSelector((state) => state.programState);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const cache = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(addChapter, {
    onSuccess: () => {
      cache.invalidateQueries(["program", program?._id, subjectId]);
      onClose();
    },
  });
  useErrorToast(error);
  return (
    <>
     <Box p="4" bg="green.100">
      <AddAndClose text="Add Chapter" isOpen={isOpen} onToggle={onToggle} />
      </Box>
      {isOpen && (
        <Formik
          initialValues={{ chapterName: "" }}
          onSubmit={async (values) => {
            try {
              await mutateAsync({
                chapterName: values.chapterName,
                programId: program?._id ?? "",
                subjectId,
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Form>
            <Flex align="flex-end" p="4">
              <InputControl name="chapterName" label="Chapter Name" />
              <SubmitButton isLoading={isLoading} ml="8">
                Add Chapter
              </SubmitButton>
            </Flex>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default AddChapter;
