import { Box, Container, Heading, Image, LinkBox, LinkOverlay, ListItem, SimpleGrid, Text, UnorderedList } from "@chakra-ui/react";
import StaticFooter from "./StaticFooter";
import sohailprofile from "assets/images/sohail-shaikh-photo.jpg";
import takidarprofile from "assets/images/takidar-photo.jpg";
import surajprofile from "assets/images/suraj-photo.jpg";
import aninditaprofile from "assets/images/anindita-photo.jpg";
const AboutUs = () => {
  return (
    <>
      <Box bg="purple">
        <Container py={["24px", "80px"]} maxW="container.lg" color={"white"}>
          <Heading as="h1" mb="6" fontSize={["30px", "45px"]}>
            About Us
          </Heading>
          <Text>
            Redefine the process of imparting knowledge to the community.
            <br />
            Deliver the Nation competent Human Resources.
          </Text>
        </Container>
      </Box>
      <Container py={["24px", "80px"]} maxW="container.lg">
        <Text>
          KP Training academy is a part of Knowledge Park Charitable Trust, involved in identifying aspirants intending to make their career in civil services, Law & other professions. These aspirants are provided coaching, guidance & sponsorship to complete the program. This program takes aspirants beyond school specific curriculum and classroom teaching and orients them more towards the competitive world that awaits them right after school/college. This foundation program aims at gradually training/preparing students for various competitive exams.
        </Text>
        
        <Text mt={'6'} mb={'2'} fontWeight={'bold'}>We work on three different programs;</Text>
        <UnorderedList>
          <ListItem>“VIII – Graduation” Tutoring for competitive exam. (jobs & admission for professional courses)</ListItem>
          <ListItem>CLAT (Common Law Admission Test) for top National Law Colleges. </ListItem>
          <ListItem>Civil Service Foundation Program.</ListItem>
        </UnorderedList>
        <br />
        <Heading as="h2" my={'6'}>OUR DIRECTORS AND ADVISORS</Heading>
        <SimpleGrid columns={[1, 3]} spacing={8}>
          <LinkBox borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <Image src={sohailprofile} alt={'Sohail Shaikh'} minW={'100%'} />
            <Box p='4'>
              <Text fontSize={'20px'} fontWeight={'bold'} color={'purple'}>Sohail Shaikh</Text>
              <Text fontWeight={'bold'}>Director, CEO</Text>
              <Text>B.Com. MBA, DLL, LL.B -II</Text>
              <LinkOverlay href='/about-us-director-01' target={'_blank'} color={'teal'}>
                Read more
              </LinkOverlay>
            </Box>
          </LinkBox >
          <LinkBox borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <Image src={takidarprofile} alt={'Jaleeluzzama B. Takidar'} minW={'100%'} />
            <Box p='4'>
              <Text fontSize={'20px'} fontWeight={'bold'} color={'purple'}>Jaleeluzzama B. Takidar</Text>
              <Text fontWeight={'bold'}>Director Technology and Finance</Text>
              <Text>B.E.(Elec & Telecom), MMS, FIE, Cert. Engr.</Text>
              <LinkOverlay href='/about-us-director-02' target={'_blank'} color={'teal'}>
                Read more
              </LinkOverlay>
            </Box>
          </LinkBox>
          <Box borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <Image src={surajprofile} alt={'Suraj Chavhan'} minW={'100%'} />
            <Box p='4'>
              <Text fontSize={'20px'} fontWeight={'bold'} color={'purple'}>Suraj Chavhan</Text>
              <Text fontWeight={'bold'}>Director, Head of IT</Text>
              <Text>BCA, MCA</Text>
            </Box>
          </Box>
          <LinkBox borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <Image src={aninditaprofile} alt={'Anindita Sain Walia'} minW={'100%'} />
            <Box p='4'>
              <Text fontSize={'20px'} fontWeight={'bold'} color={'purple'}>Anindita Sain Walia</Text>
              <Text fontWeight={'bold'}>Project Director – CLAT</Text>
              <Text>BA, MA</Text>
              <LinkOverlay href='/about-us-project-director-01' target={'_blank'} color={'teal'}>
                Read more
              </LinkOverlay>
            </Box>
          </LinkBox>
        </SimpleGrid>

      </Container>
      <StaticFooter />
    </>
  );
};

export default AboutUs;
