import {
  Stack,
  Box,
  Select,
  ButtonGroup,
  Button,
  Text,
} from "@chakra-ui/react";
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from "@reduxjs/toolkit";

import { useAppDispatch } from "app/hook";
import { FC } from "react";

interface TablePaginatorProps {
  setSize: ActionCreatorWithPayload<number, string>;
  setPageDefault: ActionCreatorWithoutPayload<string>;
  page: number;
  size: number;
  count: number;
  totalPages: number;

  incrementPage: ActionCreatorWithoutPayload<string>;
  decrementPage: ActionCreatorWithoutPayload<string>;
}

const TablePaginator: FC<TablePaginatorProps> = ({
  setSize,
  setPageDefault,
  page,
  size = 10,
  count,
  totalPages,
  incrementPage,
  decrementPage,
}) => {
  const dispatch = useAppDispatch();
  return (
    <Stack
      w="100%"
      direction={["column", "row"]}
      p="15px"
      alignItems="center"
      justifyContent={{ base: "center", sm: "space-between" }}
    >
      <Box pe="{[0, 6]}">
        <Select
          placeholder="Show entries"
          size="sm"
          rounded="md"
          onChange={(e) => {
            dispatch(setPageDefault());
            dispatch(
              setSize(e.target.value === "" ? 10 : parseInt(e.target.value))
            );
          }}
        >
          <option value="10">Entries 10</option>
          <option value="20">Entries 20</option>
          <option value="50">Entries 50</option>
        </Select>
      </Box>
      <Box>
        <Text fontSize="sm" colorScheme="gray.200">
          Showing {(page - 1) * size + 1} to {(page - 1) * size + size} of{" "}
          {count ?? "20"} entries
        </Text>
      </Box>
      <Box>
        <ButtonGroup variant="outline" size="sm" isAttached>
          <Button
            isDisabled={page === 1}
            onClick={() => {
              dispatch(decrementPage());
            }}
          >
            Previous
          </Button>
          <Button
            isDisabled={page === totalPages}
            onClick={() => {
              dispatch(incrementPage());
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </Box>
    </Stack>
  );
};
export default TablePaginator;
