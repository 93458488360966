import { Badge, Box, Heading, Link } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Stack,
  VStack,
  HStack,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  IconButton,
} from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik } from "formik";
import {
  InputControl,
  // RadioGroupControl,
  SelectControl,
  SubmitButton,
} from "formik-chakra-ui";
import React, { FC, useState } from "react";
import TagInput from "components/TagInput";
import { useMutation, useQueryClient } from "react-query";
import { updateMCQMQuestion } from "API/questionApi";
import useErrorToast from "hook/error-toast";
import { useToast } from "@chakra-ui/toast";
import { Link as RoLink } from "react-router-dom";
import { GoTrashcan } from "react-icons/go";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";

interface UpdateQuestionMCQMProps {
  question: IMCQMQuestion;
  role: RoleType | null | undefined;
}

const UpdateQuestionMCQM: FC<UpdateQuestionMCQMProps> = ({
  question,
  role,
}) => {
  const [mcqMAnswer, setMcqMAnswer] = useState(question?.mcqMAnswer);
  const [tags, setTags] = useState<string[]>([...question?.tags]);
  const toast = useToast();
  const cache = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(updateMCQMQuestion, {
    onSuccess: () => {
      cache.invalidateQueries(["question", question?._id]);
      toast({ title: "Question Updated Successfully", status: "success" });
    },
  });
  useErrorToast(error);
  console.log(mcqMAnswer);

  return (
    <>
      <Heading size="md" mb="4">
        <Link as={RoLink} to={`/${role}/dashboard/questions`} color="teal.500">
          Questions
        </Link>{" "}
        / Multiple Choice Questions (Multi)
      </Heading>
      <HStack justify="space-between">
        <Badge>{question.subjectName}</Badge>

        {question.isPremium && <Badge colorScheme="yellow">Premium</Badge>}
      </HStack>
      <Box
        my="20px"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        borderRadius="md"
      >
        <Formik
          initialValues={{
            question: question?.question,
            positiveMarks: question?.positiveMarks,
            negativeMarks: question?.negativeMarks,
            answerJustification: question?.answerJustification,
            tags: tags,
            level: question?.level,
            mcqMOptions: question?.mcqMOptions,
            questionImg: question?.questionImg,
            justificationImg: question?.justificationImg,
          }}
          onSubmit={async (values) => {
            values.tags = tags;

            const data = {
              ...values,
              _id: question._id,
              type: question.type,
              mcqMAnswer,
            };
            try {
              await mutateAsync(data);
            } catch (error) {}
          }}
        >
          {({ values }) => (
            <Form>
              <Stack direction={["column", "row"]} spacing="20px">
                <VStack
                  w={{ base: "100%", lg: "30%" }}
                  spacing="15px"
                  padding="20px"
                >
                  <SelectControl
                    isRequired
                    name="level"
                    selectProps={{ rounded: "md" }}
                  >
                    <option value="easy">Easy</option>
                    <option value="normal">Normal</option>
                    <option value="difficult">Difficult</option>
                  </SelectControl>
                  <TagInput tags={tags} setTags={setTags} />
                </VStack>
                <Box w={{ base: "100%", lg: "70%" }} padding="20px">
                  <VStack align="start">
                    <InputControl label="Question" name="question" />

                    <FieldArray
                      name="mcqMOptions"
                      render={(arrayHelpers) => (
                        <>
                          {values.mcqMOptions.map((option, index) => (
                            <React.Fragment key={index}>
                              <InputGroup>
                                <InputLeftAddon
                                  bg={
                                    mcqMAnswer.includes(index)
                                      ? "teal.400"
                                      : "gray.200"
                                  }
                                  color={
                                    mcqMAnswer.includes(index)
                                      ? "white"
                                      : "black"
                                  }
                                  children={String.fromCharCode(65 + index)}
                                />
                                <Input
                                  as={Field}
                                  name={`mcqMOptions.${index}`}
                                  value={option}
                                  type="text"
                                  placeholder={`Option ${String.fromCharCode(
                                    65 + index
                                  )}`}
                                />
                                <InputRightAddon
                                  bg={
                                    mcqMAnswer.includes(index)
                                      ? "teal.400"
                                      : "gray.200"
                                  }
                                  children={
                                    <>
                                      <IconButton
                                        aria-label="option"
                                        fontSize="3xl"
                                        variant="ghost"
                                        _hover={{ color: "black" }}
                                        onClick={() => {
                                          setMcqMAnswer((prev) => {
                                            if (prev.includes(index)) {
                                              return prev.filter(
                                                (answer) => answer !== index
                                              );
                                            }

                                            return [...prev, index];
                                          });
                                        }}
                                        icon={
                                          mcqMAnswer.includes(index) ? (
                                            <BiCheckboxChecked />
                                          ) : (
                                            <BiCheckbox />
                                          )
                                        }
                                        color={
                                          mcqMAnswer.includes(index)
                                            ? "white"
                                            : "gray"
                                        }
                                      />
                                      <IconButton
                                        aria-label="Delete"
                                        variant="ghost"
                                        _hover={{ color: "black" }}
                                        icon={<GoTrashcan />}
                                        color={
                                          mcqMAnswer.includes(index)
                                            ? "white"
                                            : "gray"
                                        }
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        } // remove a friend from the list
                                      />
                                    </>
                                  }
                                />
                              </InputGroup>
                            </React.Fragment>
                          ))}
                          {(values.mcqMOptions.length === 0 ||
                            values.mcqMOptions.length < 6) && (
                            <Button
                              variant="ghost"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add Option
                            </Button>
                          )}
                        </>
                      )}
                    />

                    <InputControl
                      label="Answer Justification"
                      name="answerJustification"
                    />
                  </VStack>
                  <VStack>
                    <InputControl
                      label="Positive Marks"
                      name="positiveMarks"
                      inputProps={{ type: "number" }}
                    />
                    <InputControl
                      label="Negative Marks"
                      name="negativeMarks"
                      inputProps={{ type: "number" }}
                    />
                  </VStack>{" "}
                  <Box>
                    <InputControl
                      label="Question Image Url"
                      name="questionImg"
                    />
                   {question?.questionImg && 
                      <Box mb={'10px'}>
                    <Link rel="noreferrer" target={'_blank'} color="teal.600" href={question?.questionImg}>Preview image</Link>
                    </Box>
                    }
                    <InputControl
                      label="Justification Image Url"
                      name="justificationImg"
                    />
                    {question?.justificationImg && 
                      <Box mb={'10px'}>
                    <Link rel="noreferrer" target={'_blank'} color="teal.600" href={question?.justificationImg}>Preview image</Link>
                    </Box>
                    }
                  </Box>
                  <HStack direction="row" spacing={4} align="center" mt="20px">
                    <SubmitButton
                      type="submit"
                      colorScheme="teal"
                      variant="solid"
                      px="30px"
                      isLoading={isLoading}
                    >
                      Save
                    </SubmitButton>
                    <Button
                      as={RoLink}
                      to={`/${role}/dashboard/questions`}
                      type="reset"
                      colorScheme="teal"
                      variant="ghost"
                    >
                      Cancel
                    </Button>
                  </HStack>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
export default UpdateQuestionMCQM;
