import AddAndUpdateStudent from "pages/dashboard/shared/Users/Students/Add&UpdateStudentForm";
import React from "react";
import { useParams } from "react-router";

const AdminAddStudents = () => {
  const { schoolId } = useParams<{ schoolId: string }>();

  return (
    <AddAndUpdateStudent
      redirectTo={`/admin/dashboard/users/${schoolId}/students`}
      schoolId={schoolId ?? ""}
    />
  );
};

export default AdminAddStudents;
