import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles

const ReadTextModal = ({ isOpen, onClose, data }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (data) {
      setText(data);
    }
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setText(null);
        onClose();
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent maxWidth="75vw" maxHeight="75vh">
        <ModalHeader>Your Answer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReactQuill
            value={text ? text : "N/A"}
            readOnly={true}
            theme="bubble"
            style={{ height: "90%" }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              setText(null);
              onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReadTextModal;
